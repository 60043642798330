// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import jump from 'jump.js'

export default {
    bind: (element, { value }) => {
        const targetElement = value || element.dataset.scrollTarget
        const duration = element.dataset.scrollDuration || 300
        const offset = element.dataset.scrollOffset || 0

        if (!targetElement) {
            // eslint-disable-next-line no-console
            console.warn('No target element to scroll.', targetElement)
        } else {
            element.addEventListener('click', () => jump(targetElement, { duration, offset }))
        }
    },
}
