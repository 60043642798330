// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { guid as GUID } from '../functions'
import { SEARCH_LOGS } from '~/constants/log-types'
import { saveLog, updateLog } from '~/api/collect-logs'

export default (section, userId = null) => {
    const guid = GUID()

    return ({
        guid,

        saveLog: logInput => saveLog(SEARCH_LOGS, {
            guid,
            section,
            ...logInput,
            user_id: userId,
        }),

        updateLog: (log, data) => updateLog(SEARCH_LOGS, log._id, data),
    })
}
