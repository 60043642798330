// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="notification-list">
        <template v-if="unreads.length > 0">
            <div class="notification-list__group-header">
                <div class="el-badge">
                    <span>{{ $t('mainNavbar.notifications.notificationsList.new') }}</span>
                    <sup class="el-badge__content is-fixed is-dot" />
                </div>
            </div>
            <Item
                v-for="notification in unreads"
                :key="notification.id + openTime"
                :notification="notification"
                @click.native="$emit('item-click', notification.id)"
            />
        </template>

        <div class="notification-list__group-header">
            {{ $t('mainNavbar.notifications.notificationsList.earlier') }}
        </div>

        <Item
            v-for="notification in earliers"
            :key="notification.id + openTime"
            :notification="notification"
            @click.native="$emit('item-click', notification.id)"
        />
    </div>
</template>

<script>
    import _filter from 'lodash/filter'
    import Item from './Item.vue'

    export default {
        components: {
            Item,
        },

        props: {
            notifications: [Array, Object],
            openTime: [Number],
        },

        computed: {
            unreads() {
                return _filter(this.notifications, item => !item.is_read)
            },

            earliers() {
                return _filter(this.notifications, { is_read: true })
            },
        },
    }
</script>

<style lang="scss">
    @import '~assets/sass/bootstrap/colors';
    @import '~assets/sass/bootstrap/fonts';

    .notification-list {
        &__group-header {
            font-size: $font-size-xs;
            text-transform: uppercase;
            padding: .2rem .5rem;
            background-color: $gray-100;
            border-bottom: 1px solid $gray-300;

            .el-badge__content.is-fixed.is-dot {
                right: .25em;
                top: 0.15em;
            }
        }
    }

    .theme-dark {
        .notification-list {
            &__group-header {
                background-color: $dark-bg;
                border-bottom: 1px solid $dark-border;
            }
        }
    }
</style>
