// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _delay from 'lodash/delay'
import showMessage from 'element-ui/lib/message'

const createQueue = (job, messages) => {
    let after = 0

    messages.forEach((message) => {
        _delay(job, after, message)
        after += message.duration || 5000
    })
}

const popMessage = store => (serverMessage) => {
    store.commit('toast/popMessage', serverMessage)
    const { type, message, duration } = serverMessage
    showMessage({
        type,
        message,
        duration: duration || 5000,
        showClose: true,
    })
}

export default ({ store }) => {
    const serverMessages = store.state.toast.messages
    const processMessage = popMessage(store)

    createQueue(processMessage, serverMessages)
}
