const middleware = {}

middleware['auth'] = require('../app/middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['createOrganizations'] = require('../app/middleware/createOrganizations.js')
middleware['createOrganizations'] = middleware['createOrganizations'].default || middleware['createOrganizations']

middleware['guest'] = require('../app/middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['homepage-redirect'] = require('../app/middleware/homepage-redirect.js')
middleware['homepage-redirect'] = middleware['homepage-redirect'].default || middleware['homepage-redirect']

middleware['page-history'] = require('../app/middleware/page-history.js')
middleware['page-history'] = middleware['page-history'].default || middleware['page-history']

middleware['post-domain-redirect'] = require('../app/middleware/post-domain-redirect.js')
middleware['post-domain-redirect'] = middleware['post-domain-redirect'].default || middleware['post-domain-redirect']

middleware['question-doamin-redirect'] = require('../app/middleware/question-doamin-redirect.js')
middleware['question-doamin-redirect'] = middleware['question-doamin-redirect'].default || middleware['question-doamin-redirect']

middleware['root-domain-redirect'] = require('../app/middleware/root-domain-redirect.js')
middleware['root-domain-redirect'] = middleware['root-domain-redirect'].default || middleware['root-domain-redirect']

middleware['series-domain-redirect'] = require('../app/middleware/series-domain-redirect.js')
middleware['series-domain-redirect'] = middleware['series-domain-redirect'].default || middleware['series-domain-redirect']

middleware['utm'] = require('../app/middleware/utm.js')
middleware['utm'] = middleware['utm'].default || middleware['utm']

export default middleware
