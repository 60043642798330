import _get from 'lodash/get'
import _mapValues from 'lodash/mapValues'
import { normalize } from 'normalizr'
import { fetchPosts } from '~/api/ctf'
import { postArray } from '../__utils/schemas'
import { getters, mutations } from '../__modules/post-feed'

const setStoreState = ({ commit, rootState }) => ({ data: response }) => {
    const { entities, result } = normalize(response.data, postArray)

    commit('entities/posts/put', entities.posts, { root: true })
    commit('entities/users/put', entities.users, { root: true })
    commit('entities/organizations/put', entities.organizations, { root: true })
    commit('setAllIds', result)
    commit('setPagination', response.meta.pagination)
    commit('setPuzzle', response.meta.puzzle)

    const authUserId = _get(rootState, 'auth.user.id')
    const byId = _mapValues(entities.posts, post => ({
        is_own: post.user_id === authUserId,
        commentators: post.commentators,
        contents_short: post.contents_short,
        organization: post.organization ? entities.organizations[post.organization.data] : null,
    }))

    commit('setPosts', byId)
}

const state = () => ({
    byId: {},
    all: [],
    pagination: {},
    puzzle: null,
})

const actions = {
    fetch: (actionContext, { puzzleHashId, params }) => fetchPosts(puzzleHashId, params)
        .then(setStoreState(actionContext)),
}

mutations.setPuzzle = (state, puzzle) => {
    state.puzzle = puzzle
}

export default {
    state,
    getters,
    actions,
    mutations,
}
