// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { castVote } from 'viblo-sdk/api/voting'
import { VoteDir } from 'viblo-sdk/libs/interactions'
import { deserializeVote, serializeVote, effectsOnReputation } from '../voting'

export const voteAction = (type, get, put, key = 'hash_id') => async ({ commit, state }, { id, value }) => {
    const rateable = get(id, state)

    const currentScore = rateable.rated_value
    const currentVote = deserializeVote(currentScore)

    const nextVote = currentVote !== value ? value : VoteDir.None

    await castVote(type, rateable[key], nextVote)

    const nextScore = serializeVote(nextVote)

    put(id, {
        rated_value: nextScore,
        points: rateable.points + (nextScore - currentScore),
    }, commit)

    const repChange = effectsOnReputation(nextScore, currentScore, type)

    commit('entities/users/increment', {
        [rateable.user_id]: {
            reputation: repChange,
        },
    }, { root: true })
}
