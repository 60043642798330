// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div id="app-container" :class="[theme, modeThemeSubdomain]">
        <MainNavbar />
        <div id="main-content">
            <nuxt />
        </div>
        <MainFooter />
        <AuthDialog />
        <ScrollToTop />
        <!-- <ReminderWhenNotLoginPopup v-if="!authenticated" /> -->
        <PopupDownloadApp />
        <FixedBottomBar v-if="!authenticated" />
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations } from 'vuex'

    import offlineAlert from '~/components/mixins/offlineAlert'
    import MainNavbar from '~/components/subdomains/navbar/MainNavbar.vue'
    import MainFooter from '~/components/subdomains/footer/Footer.vue'
    import AuthDialog from '~/components/auth/Popup.vue'
    import ScrollToTop from '~/components/ScrollToTop.vue'

    // const ReminderWhenNotLoginPopup = () => import('~/components/dialogs/ReminderWhenNotLogin.vue')
    const FixedBottomBar = () => import('~/components/posts/FixedBottomBar.vue')
    const PopupDownloadApp = () => import('~/components/dialogs/PopupDownloadApp.vue')

    export default {
        components: {
            MainNavbar,
            AuthDialog,
            MainFooter,
            ScrollToTop,
            // ReminderWhenNotLoginPopup,
            FixedBottomBar,
            PopupDownloadApp,
        },

        mixins: [offlineAlert],

        middleware: ['root-domain-redirect'],

        computed: {
            ...mapState('settings', ['theme']),
            ...mapState('typeControl', ['modeTheme']),
            ...mapGetters('auth', ['authenticated']),
            showPopupNewsletter() {
                return !this.$store.state.settings.viewed_popup_newsletter
            },

            modeThemeSubdomain() {
                return this.$store.state.typeControl.modeTheme
            },
        },

        mounted() {
            const theme = this.$cookies.get('modeTheme') || 'default'
            this.switchMode(theme)
        },

        methods: {
            ...mapMutations({
                switchMode: 'typeControl/switchMode',
            }),
        },
    }
</script>
