// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
export const baseUrl = process.client ? `${window.location.protocol}//${window.location.host}` : process.env.APP_URL

export function answerUrl(url) {
    const commentMatch = /\/a\/[a-zA-Z0-9]+#comment-([a-zA-Z0-9]+)/.exec(url)
    const answerMatch = /^\/(a\/[a-zA-Z0-9]+)/.exec(url)
    if (commentMatch) {
        return `${baseUrl}/c/${commentMatch[1]}`
    }
    return answerMatch ? `${baseUrl}/${answerMatch[1]}` : null
}
