export default {
    data: () => ({ offlineAlertInstance: null }),

    watch: {
        // eslint-disable-next-line func-names
        '$nuxt.isOffline': function (isOffline) {
            this.handleNetworkStatusChange(isOffline)
        },
    },

    methods: {
        handleNetworkStatusChange(isOffline) {
            if (isOffline) {
                this.offlineAlertInstance = this.$notify({
                    showClose: true,
                    message: this.$t('pwa.offline_warning'),
                    type: 'error',
                    duration: 0,
                    position: 'bottom-left',
                    customClass: 'el-alert--error is-light',
                    onClose: () => {
                        this.offlineAlertInstance = null
                    },
                })
            } else if (this.offlineAlertInstance) {
                this.offlineAlertInstance.close()
            }
        },
    },

    mounted() {
        this.handleNetworkStatusChange(this.$nuxt.isOffline)
    },
}
