import Vue from 'vue'

// `Vue.prototype.$nuxt` is removed since nuxtjs@v2.14.7
// https://github.com/nuxt/nuxt.js/pull/8170

// This workaround provides `Vue.prototype.$i18n`
// that will be used instead of `Vue.prototype.$nuxt.$i18n`
export default function ({ app, store }) {
    Vue.prototype.$_i18n = app.i18n
    Vue.prototype.$_store = store
}
