// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div id="app-container" :class="theme">
        <MainNavbar />
        <div id="main-content" class="bg-faded mt-2">
            <div class="container">
                <div class="row pt-3 pb-1">
                    <div class="col-md-12">
                        <nuxt />
                    </div>
                </div>
            </div>
        </div>
        <MainFooter />
        <AuthDialog />
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import Default from './default.vue'
    import MainNavbar from '~/components/navbar/MainNavbar.vue'
    import MainFooter from '~/components/footer/Footer.vue'
    import AuthDialog from '~/components/auth/Popup.vue'

    export default {
        components: {
            MainNavbar,
            MainFooter,
            AuthDialog,
        },

        mixins: [Default],

        computed: {
            ...mapState('auth', ['guest']),
            ...mapState('settings', ['theme']),
        },
    }
</script>
