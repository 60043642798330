// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { normalize } from 'normalizr'
import { userArray } from '~/utils/store/schemas'
import { getTopUsers, getPopularTags } from '../api/placements'

const state = () => ({
    users: {},
    posts: [],
    tags: [],
    currentSharingId: null,
})

const actions = {
    async fetchTopUsers({ commit }, context) {
        const users = await getTopUsers(context)
            .then(({ data }) => data.data)

        const normalizedUsers = normalize(users, userArray)

        commit('entities/users/put', normalizedUsers.entities.users, { root: true })
        commit('setUsers', { context, users: normalizedUsers.result })
    },

    async fetchPopularTags({ commit }, context) {
        const tags = await getPopularTags(context, { limit: 15 }).then(response => response.data.data)
        commit('SET_POPULAR_TAGS', tags)
    },
}

const mutations = {
    setUsers(state, { users, context }) {
        state.users = {
            ...state.users,
            [context]: users,
        }
    },

    SET_POPULAR_TAGS(state, tags) {
        state.tags = tags
    },

    SET_SHARING_ID(state, value = null) {
        state.currentSharingId = value
    },
}

export default {
    state,
    actions,
    mutations,
}
