// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _findLastKey from 'lodash/findLastKey'
import {
    answer as toAnswer,
    comment as toComment,
    user as routeToUser,
    post as routeToPost,
    tag as routeToTag,
    feed as routeToFeed,
    series as routeToSeries,
    question as routeToQuestion,
    organization as routeToOrganization,
    absolute as absoluteUrl,
} from '~/utils/url'

function sanitizeString(str) {
    str = str.toLowerCase()
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
    str = str.replace(/đ/g, 'd')
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|=|<|>|\?|;|\/|,|\.|:|\|'| |"|&|#|\[|]|~|$|_/g, '-')
    str = str.replace(/-+-/g, '-')
    str = str.replace(/^-+|-+$/g, '')

    return str
}

function enableHtmlTags(html) {
    return html.replace(/&lt;br&gt;/g, '<br>').replace(/&lt;br\/&gt;/g, '<br/>')
}

function s4() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
}

function guid() {
    return `${Date.parse(new Date())}-${s4()}${s4()}-${s4()}-${s4()}`
}

const shortNumber = (number = 0) => {
    const postfixes = {
        1e3: 'K',
        1e6: 'M',
        1e9: 'B',
        1e12: 'T',
    }

    const magnitude = _findLastKey(postfixes, (letter, mag) => number >= mag)
    return magnitude ? `${Math.floor(number / magnitude)}${postfixes[magnitude]}` : Number(number.toFixed(2))
}

export const deserializeVote = (value) => {
    if (value === 0) {
        return 'none'
    }

    return value > 0 ? 'up' : 'down'
}

export {
    toAnswer,
    toComment,
    routeToPost,
    routeToQuestion,
    routeToTag,
    routeToUser,
    routeToFeed,
    routeToSeries,
    routeToOrganization,
    enableHtmlTags,
    sanitizeString,
    absoluteUrl,
    guid,
    shortNumber,
}
