// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div :class="classes">
        <slot />
    </div>
</template>

<script>
    import _get from 'lodash/get'
    import _map from 'lodash/map'
    import _curry from 'lodash/curry'
    import _concat from 'lodash/concat'
    import _pickBy from 'lodash/pickBy'
    import _includes from 'lodash/includes'

    import _flow from 'lodash/flow'
    import _fpMap from 'lodash/fp/map'
    import _fpFilter from 'lodash/fp/filter'

    const part = value => (value ? `-${value}` : '')
    const join = (className, breakpoint, value) => className + part(breakpoint) + part(value)

    const normalizeObject = values => _pickBy(
        values,
        (value, prop) => value && _includes(['span', 'offset'], prop)
    )

    const getClass = _curry((breakpoint, value, prop) => _get({
        span: () => join('col', breakpoint, value !== '*' ? value : ''),
        offset: () => join('offset', breakpoint, value),
    }, prop)())

    const makeClasses = (values, breakpoint) => {
        if (!!values && typeof values === 'object') {
            return _map(normalizeObject(values), getClass(breakpoint))
        }

        return getClass(breakpoint, values, 'span')
    }

    export default {
        props: {
            span: [Number, String],
            offset: {
                type: Number,
                default: 0,
            },
            xs: [Number, String, Object],
            sm: [Number, String, Object],
            md: [Number, String, Object],
            lg: [Number, String, Object],
            xl: [Number, String, Object],
        },

        computed: {
            classes() {
                const defaultClasses = makeClasses({ span: this.span, offset: this.offset })
                const responsiveClasses = _flow(
                    _fpFilter(breakpoint => this[breakpoint]),
                    _fpMap(breakpoint => makeClasses(this[breakpoint], breakpoint))
                )(['xs', 'sm', 'md', 'lg', 'xl'])

                return _concat(defaultClasses, responsiveClasses)
            },
        },
    }
</script>
