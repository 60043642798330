// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _mapValues from 'lodash/mapValues'
import { normalize } from 'normalizr'
import { userArray } from '~/utils/store/schemas'
import { getUsersList } from '../api/users'
import { PUT_USERS_TO_STATE } from './entities/users'

const state = () => ({
    current: [],
    byId: {},
})

const actions = {
    fetch: async ({ commit }, params) => {
        const users = await getUsersList(params)
            .then(response => response.data)

        const normalizedData = normalize(users.data, userArray)
        const userEntities = normalizedData.entities.users

        commit(PUT_USERS_TO_STATE, userEntities, { root: true })
        commit('setCurrent', normalizedData.result)
        commit('setUsers', userEntities)
    },
}

const mutations = {
    setCurrent: (state, ids) => {
        state.current = ids
    },

    setUsers: (state, users) => {
        state.byId = _mapValues(users, user => ({
            reputation_earned: user.reputation_earned,
        }))
    },
}

export default {
    state,
    actions,
    mutations,
}
