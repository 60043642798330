// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import Vue from 'vue'

import Message from 'element-ui/lib/message'
import MessageBox from 'element-ui/lib/message-box'
import Notification from 'element-ui/lib/notification'

import Input from 'element-ui/lib/input'
import Select from 'element-ui/lib/select'
import Option from 'element-ui/lib/option'
import OptionGroup from 'element-ui/lib/option-group'
import Switch from 'element-ui/lib/switch'
import Checkbox from 'element-ui/lib/checkbox'
import CheckboxGroup from 'element-ui/lib/checkbox-group'
import Radio from 'element-ui/lib/radio'
import RadioButton from 'element-ui/lib/radio-button'
import RadioGroup from 'element-ui/lib/radio-group'
import Autocomplete from 'element-ui/lib/autocomplete'

import Button from 'element-ui/lib/button'

import Form from 'element-ui/lib/form'
import FormItem from 'element-ui/lib/form-item'
import Alert from 'element-ui/lib/alert'
import Badge from 'element-ui/lib/badge'

import Popover from 'element-ui/lib/popover'
import Dropdown from 'element-ui/lib/dropdown'
import DropdownMenu from 'element-ui/lib/dropdown-menu'
import DropdownItem from 'element-ui/lib/dropdown-item'

import Table from 'element-ui/lib/table'
import TableColumn from 'element-ui/lib/table-column'

import Scrollbar from 'element-ui/lib/scrollbar'
import ProgressBar from 'element-ui/lib/progress'

import ElDrawer from 'element-ui/lib/drawer'
import ElMenu from 'element-ui/lib/menu'
import ElSubmenu from 'element-ui/lib/submenu'
import ElMenuItem from 'element-ui/lib/menu-item'
import ElMenuItemGroup from 'element-ui/lib/menu-item-group'

import ElTabs from 'element-ui/lib/tabs'
import ElTabPane from 'element-ui/lib/tab-pane'
import ElDialog from 'element-ui/lib/dialog'
import ElTooltip from 'element-ui/lib/tooltip'
import Skeleton from 'element-ui/lib/skeleton'
import SkeletonItem from 'element-ui/lib/skeleton-item'
import ElCarousel from 'element-ui/lib/carousel'
import ElCarouselItem from 'element-ui/lib/carousel-item'

import Loading from 'element-ui/lib/loading'
import DatePicker from 'element-ui/lib/date-picker'
import { changeLocaleElementUI } from '~/utils/element-ui'

export default function getElement({ store }) {
    changeLocaleElementUI(store.state.i18n.locale)
    Vue.use(Loading.directive)

    Vue.prototype.$loading = Loading.service
    Vue.prototype.$message = Message
    Vue.prototype.$notify = Notification
    Vue.prototype.$msgbox = MessageBox
    Vue.prototype.$alert = MessageBox.alert
    Vue.prototype.$confirm = MessageBox.confirm
    Vue.prototype.$prompt = MessageBox.prompt

    Vue.component('ElSkeleton', Skeleton)
    Vue.component('ElSkeletonItem', SkeletonItem)

    Vue.component('ElInput', Input)
    Vue.component('ElSelect', Select)
    Vue.component('ElOption', Option)
    Vue.component('ElOptionGroup', OptionGroup)
    Vue.component('ElSwitch', Switch)
    Vue.component('ElCheckbox', Checkbox)
    Vue.component('ElCheckboxGroup', CheckboxGroup)
    Vue.component('ElRadio', Radio)
    Vue.component('ElRadioButton', RadioButton)
    Vue.component('ElRadioGroup', RadioGroup)
    Vue.component('ElAutocomplete', Autocomplete)

    Vue.component('ElButton', Button)

    Vue.component('ElForm', Form)
    Vue.component('ElFormItem', FormItem)
    Vue.component('ElAlert', Alert)
    Vue.component('ElBadge', Badge)

    Vue.component('ElPopover', Popover)
    Vue.component('ElDropdown', Dropdown)
    Vue.component('ElDropdownMenu', DropdownMenu)
    Vue.component('ElDropdownItem', DropdownItem)

    Vue.component('ElTable', Table)
    Vue.component('ElTableColumn', TableColumn)

    Vue.component('ElScrollbar', Scrollbar)
    Vue.component('ElProgress', ProgressBar)

    Vue.component('ElDrawer', ElDrawer)
    Vue.component('ElMenu', ElMenu)
    Vue.component('ElSubmenu', ElSubmenu)
    Vue.component('ElMenuItemGroup', ElMenuItemGroup)
    Vue.component('ElMenuItem', ElMenuItem)
    Vue.component('ElDialog', ElDialog)
    Vue.component('ElTabs', ElTabs)
    Vue.component('ElTabPane', ElTabPane)
    Vue.component('ElTooltip', ElTooltip)
    Vue.component('ElCarousel', ElCarousel)
    Vue.component('ElCarouselItem', ElCarouselItem)

    Vue.component('ElDatePicker', DatePicker)
}
