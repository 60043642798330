// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import {
    format, parse, parseISO, formatDistance, differenceInDays, differenceInCalendarYears,
} from 'date-fns'
import vi from 'date-fns/locale/vi'
import Vue from 'vue'

/**
 * @returns {boolean}
 */
const currentLocaleVi = () => {
    const currentLocale = Vue.prototype.$_i18n.locale
    if (currentLocale === 'vi') {
        return true
    }

    return false
}
/**
 * @param   {Date|string} now
 * @param   {Date|string} then
 * @returns {string}
 */
const commonTimeFormat = (now, then) => {
    if (!(now instanceof Date)) {
        now = parseISO(now, {
            locale: currentLocaleVi() ? vi : '',
        })
    }

    if (!(then instanceof Date)) {
        then = parseISO(then, {
            locale: currentLocaleVi() ? vi : '',
        })
    }

    const isThisYear = differenceInCalendarYears(now, then) < 1

    return isThisYear
        ? format(then, 'MMM do, h:mm aaaa', {
            locale: currentLocaleVi() ? vi : '',
        })
        : format(then, 'MMM do, yyyy h:mm aaaa', {
            locale: currentLocaleVi() ? vi : '',
        })
}

/**
 * @param   {Date|string} now
 * @param   {Date|string} then
 * @returns {string|null}
 */
const relativeTimeFormat = (now, then) => {
    if (!(now instanceof Date)) {
        now = parseISO(now, {
            locale: currentLocaleVi() ? vi : '',
        })
    }

    if (!(then instanceof Date)) {
        then = parseISO(then, {
            locale: currentLocaleVi() ? vi : '',
        })
    }

    const daysPassed = differenceInDays(now, then)

    if (daysPassed < 1) {
        return formatDistance(then, now, {
            addSuffix: true,
            locale: currentLocaleVi() ? vi : '',
        })
    }

    if (daysPassed < 7) {
        return format(then, 'EEEE, h:mm aaaa', {
            locale: currentLocaleVi() ? vi : '',
        })
    }

    return null
}

const parseDateString = (date, fromFormat) => (fromFormat ? parse(date, fromFormat) : parseISO(date))

/**
 * @param {Date|string} date
 * @param {string} fromFormat
 */
export function formatTime(date, fromFormat) {
    if (date) {
        const now = new Date()
        const then = fromFormat ? parse(date, fromFormat) : date

        return commonTimeFormat(now, then)
    }

    return ''
}


/**
 * @param {Date|number|string} date
 * @param {string} format
 * @param {string|null} fromFormat
 */
export function formatDate(date, toFormat = 'LLL do, yyyy HH:mm:ss', fromFormat) {
    if (date) {
        const then = typeof date === 'string' ? parseDateString(date, fromFormat) : date

        return format(then, toFormat, {
            locale: currentLocaleVi() ? vi : '',
        })
    }

    return ''
}

/**
 * @param {Date|string} date
 * @param {string} fromFormat
 */
export function humanizeTime(date, fromFormat) {
    if (date) {
        const now = new Date()
        const then = fromFormat ? parse(date, fromFormat, new Date()) : date

        return relativeTimeFormat(now, then) || commonTimeFormat(now, then)
    }

    return ''
}
