// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _get from 'lodash/get'
import notify from 'element-ui/lib/notification'
import { answerUrl } from '~/utils/notifications'

export default (store) => {
    store.subscribe((mutation) => {
        if (mutation.type === 'notifications/shift') {
            const newNotification = mutation.payload
            const url = _get(newNotification, 'data.url')
            const message = _get(newNotification, 'data.title.html')

            notify({
                message,
                dangerouslyUseHTMLString: true,
                iconClass: 'fa fa-bell-o text-primary',
                customClass: url ? 'cursor-pointer' : null,
                showClose: true,
                offset: 50,
                onClick: () => {
                    const facebookRegex = /^(http[s]):\/\/(www\.)?facebook.com(\/.*)?/
                    if (url) {
                        if (facebookRegex.test(url)) {
                            window.open(url)
                        } else if (answerUrl(url)) {
                            window.location = answerUrl(url)
                        } else {
                            window.$nuxt.$router.push(url)
                        }
                    }
                },
            })
        }
    })
}
