// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import Vue from 'vue'

import Tag from '../components/shared/tags/Tag.vue'
import Dialog from '../components/shared/Dialog.vue'

import Tabs from '../components/shared/Tabs.vue'
import TabPane from '../components/shared/TabPane.vue'

import Markdown from '../components/contents/Markdown.vue'
import MarkdownPure from '../components/contents/MarkdownPure.vue'

import Container from '../components/layout/Container.vue'
import Row from '../components/layout/Row.vue'
import Col from '../components/layout/Col.vue'

import Card from '../components/shared/Card.vue'
import CTR from '../components/tracking/CTR.vue'

import Empty from '../components/shared/Empty.vue'
import Pagination from '../components/Pagination.vue'

export default function () {
    Vue.component('Tabs', Tabs)
    Vue.component('TabPane', TabPane)

    Vue.component('Markdown', Markdown)
    Vue.component('MarkdownPure', MarkdownPure)

    Vue.component('v-tag', Tag)
    Vue.component('v-dialog', Dialog)

    Vue.component('v-container', Container)
    Vue.component('v-row', Row)
    Vue.component('v-col', Col)

    Vue.component('v-card', Card)
    Vue.component('v-ctr', CTR)

    Vue.component('v-empty', Empty)
    Vue.component('v-pagination', Pagination)
}
