// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _findLastKey from 'lodash/findLastKey'

const postfixes = {
    1e3: 'K',
    1e6: 'M',
    1e9: 'B',
    1e12: 'T',
}

const formatNumber = (number = 0) => {
    const magnitude = _findLastKey(postfixes, (_, mag) => number >= mag)

    if (!magnitude) {
        return Number(number.toFixed(2))
    }

    const decimalForm = number / magnitude
    const integerPart = Math.floor(decimalForm)
    const tenthPlace = Number(String(decimalForm).replace(/\d+\./, '').slice(0, 1))
    const postfix = postfixes[magnitude]

    if (integerPart === String(decimalForm)) {
        return `${integerPart}${postfix}`
    }
    return `${integerPart}.${tenthPlace}${postfix}`
}

export const withCommaInThousandsPlaces = number => number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export default (value, max = 3) => (value < (10 ** max)
    ? withCommaInThousandsPlaces(value)
    : formatNumber(value)
)
