// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import jump from 'jump.js'
import '~/assets/sass/components/v-jump-to-route-hash.scss'

/**
 * @param {string} routeHash
 */
export function getHighlightFromRouteHash(routeHash) {
    const regexp = /^#(answer|comment)-([a-zA-Z0-9]{11})$/

    return regexp.test(routeHash)
        ? routeHash.replace(regexp, '[data-$1id="$2"]')
        : null
}

/**
 * @param {Element} el
 * @param {string} selector
 */
function highlightChild(el, selector) {
    const highlightEl = el.querySelector(selector)
    if (!highlightEl) {
        return
    }

    jump(highlightEl, {
        offset: -150,
        duration: 200,
        callback() {
            highlightEl.classList.add('v-highlight')
            setTimeout(() => {
                highlightEl.classList.remove('v-highlight')
            }, 5000)
        },
    })
}

function jumpTo(el) {
    return () => {
        const selector = getHighlightFromRouteHash(window.location.hash)
        highlightChild(el, selector)
    }
}

export default {
    /**
     * @param {Element} el
     */
    bind: (el) => {
        el.$_onHashChange = jumpTo(el)
    },

    /**
     * @param {Element} el
     */
    inserted: (el) => {
        el.$_onHashChange()
        window.addEventListener('hashchange', el.$_onHashChange)
    },

    /**
     * @param {Element} el
     */
    unbind: (el) => {
        window.removeEventListener('hashchange', el.$_onHashChange)
        el.$_onHashChange = undefined
    },
}
