// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _find from 'lodash/find'
import _omit from 'lodash/omit'
import _assign from 'lodash/assign'
import _without from 'lodash/without'
import { normalize } from 'normalizr'
import * as api from 'viblo-sdk/api/answers'
import { RateableType } from 'viblo-sdk/libs/interactions'
import { answer as answerSchemas } from '~/utils/store/schemas'
import { report } from '~/api/answers'

import { voteAction } from '~/utils/store/voting'
import { PUT_USERS_TO_STATE } from '../entities/users'

const state = () => ({
    byId: {},
    all: [],
})

const actions = {
    async store({ commit }, { question, values }) {
        const { answer: newAnswer } = await api.postAnswer(question, values)
            .then(response => response.data)

        const normalized = normalize(newAnswer.data, answerSchemas)
        const entities = normalized.entities
        const result = normalized.result

        commit('add', entities.answers[result])
        commit(PUT_USERS_TO_STATE, entities.users, { root: true })

        return { answer: newAnswer.data }
    },

    async update({ commit }, { id, values }) {
        const { answer } = await api.updateAnswer(id, values)
            .then(response => response.data)

        commit('update', _omit(answer.data, 'user'))

        return answer.data
    },

    report(_, { id, values }) {
        return report(id, values)
    },

    vote: voteAction(
        RateableType.Answer,
        (id, state) => state.byId[id],
        (id, values, commit) => commit('update', { id, ...values })
    ),

    async destroy({ state, commit }, hashId) {
        await api.deleteAnswer(hashId)

        const answer = _find(state.byId, { hash_id: hashId })
        commit('remove', answer.id)
        commit('entities/users/increment', {
            [answer.user_id]: {
                answers_count: -1,
            },
        }, { root: true })
    },
}

const mutations = {
    set(state, answers) {
        state.all = answers.all
        state.byId = answers.byId
    },

    add(state, answer) {
        const current = state

        state.all = [...current.all, answer.id]
        state.byId = _assign({}, current.byId, {
            [answer.id]: answer,
        })
    },

    update(state, answer) {
        const updated = _assign({}, state.byId[answer.id], answer)

        state.byId = _assign({}, state.byId, { [answer.id]: updated })
    },

    remove(state, id) {
        state.all = _without(state.all, id)
        state.byId = _omit(state.byId, id)
    },
}

export default {
    state,
    actions,
    mutations,
}
