// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <a
        v-if="!guest"
        href="javascript:void(0);"
        class="mark-all-as-read"
        :title="$t('mainNavbar.announcements.allRead')"
        @click="markAllAsRead"
    >
        {{ $t('mainNavbar.announcements.allRead') }}
    </a>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    export default {
        computed: {
            ...mapState('auth', [
                'guest',
            ]),
        },
        methods: {
            ...mapActions('announcements', [
                'markAllAsRead',
            ]),
        },
    }
</script>

<style scoped>
    .mark-all-as-read {
        color: #000000;
        font-size: 0.75rem;
    }
</style>
