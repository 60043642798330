// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
/**
 * @enum { string }
 */
const RotateDir = {
    Next: 'next',
    Prev: 'prev',
}

/**
 * @param {number} current
 * @param {number} first
 * @param {number} last
 * @param {RotateDir} dir
 */
export function rotateIndex(current, first, last, dir) {
    switch (dir) {
        case RotateDir.Prev:
            return current <= first ? last : current - 1
        case RotateDir.Next:
            return current >= last ? first : current + 1
        default:
    }

    return current
}

/**
 * @param {string} fileURL
 * @param {string} fileName
 */
export function downloadFile(fileURL, fileName) {
    // for non-IE
    if (!window.ActiveXObject) {
        const save = document.createElement('a')
        save.href = fileURL
        save.target = '_blank'
        const filename = fileURL.substring(fileURL.lastIndexOf('/') + 1)
        save.download = fileName || filename
        if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search('Chrome') < 0) {
            document.location = save.href
        // window event not working here
        } else {
            const evt = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: false,
            })
            save.dispatchEvent(evt);
            (window.URL || window.webkitURL).revokeObjectURL(save.href)
        }
    } else if (!!window.ActiveXObject && document.execCommand) {
        // for IE < 11
        const _window = window.open(fileURL, '_blank')
        _window.document.close()
        _window.document.execCommand('SaveAs', true, fileName || fileURL)
        _window.close()
    }
}


/**
 * @param {number} dateTimePicker
 * @param {number} dateTimeNow
 */
export function checkValidateScheduleDate(dateTimePicker, dateTimeNow = new Date()) {
    const maxDate = dateTimeNow + 75 * 24 * 60 * 60 * 1000 // 75 days
    const minDate = dateTimeNow + 20 * 60 * 1000 // 20 minutes

    return dateTimePicker < minDate || dateTimePicker > maxDate
}

/**
 * @param {number} dateTimePicker
 * @param {number} dateTimeNow
 */
export function checkDisabledScheduleDate(dateTimePicker, dateTimeNow = new Date()) {
    const maxDate = dateTimeNow + 75 * 24 * 60 * 60 * 1000 // 75 days
    const minDate = dateTimeNow - (24 * 60 * 60 * 1000) + (20 * 60 * 1000) // yesterday

    return dateTimePicker < minDate || dateTimePicker > maxDate
}

export function getTimeZone() {
    const currentDate = new Date()
    const userTimezoneOffset = currentDate.getTimezoneOffset()
    const userTimezone = `${(userTimezoneOffset > 0 ? '-' : '+')
        + (`0${Math.floor(Math.abs(userTimezoneOffset) / 60)}`).slice(-2)
    }:${
        (`0${Math.abs(userTimezoneOffset) % 60}`).slice(-2)}`

    return userTimezone
}
