// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _get from 'lodash/get'
import _random from 'lodash/random'
import _isEmpty from 'lodash/isEmpty'
import _findIndex from 'lodash/findIndex'

export const state = () => ({
    analytics_track_id: process.env.GOOGLE_ANALYTICS_TRACK_ID,
    banners: [],
    currentBannerIndex: null,
    motd: [],
    currentMotdIndex: null,
    currentBgIndex: null,
    logo: [],
    currentLogoIndex: 0,
    vars: [],
})

const bgColors = ['#5488c7, #A15284', '#8360c3, #5198A6', '#7f7fd5, #23709E', '#8360c3, #2ebf91', '#8A3C52, #6A82FB']

export const getters = {
    get: state => (key, whenNull) => _get(state, key, whenNull),
    currentBanner: ({ banners, currentBannerIndex }) => _get(banners, currentBannerIndex, null),
    currentMotd: ({ motd, currentMotdIndex }) => _get(motd, currentMotdIndex, null),
    currentBg: ({ currentBgIndex }) => _get(bgColors, currentBgIndex, null),
    currentLogo: ({ logo, currentLogoIndex }) => _get(logo, currentLogoIndex, null),
}

export const actions = {
    changeBanner({ commit, state }, { isVideoPage = false } = {}) {
        if (!_isEmpty(state.banners)) {
            const uizaBanner = isVideoPage ? _findIndex(state.banners, o => (o.name === 'Uiza')) : -1
            const banner = uizaBanner !== -1 ? uizaBanner : _random(state.banners.length - 1)

            commit('setConfig', {
                name: 'currentBannerIndex',
                value: banner,
            })
        }
    },

    changeMotd({ commit, state }) {
        if (!_isEmpty(state.motd)) {
            commit('setConfig', {
                name: 'currentMotdIndex',
                value: _random(state.motd.length - 1),
            })
            commit('setConfig', {
                name: 'currentBgIndex',
                value: _random(bgColors.length - 1),
            })
        }
    },

    changeLogo({ commit, state }) {
        if (!_isEmpty(state.logo)) {
            commit('setConfig', {
                name: 'currentLogoIndex',
                value: _random(state.logo.length - 1),
            })
        }
    },
}

export const mutations = {
    setConfig(state, { name, value }) {
        state[name] = value
    },
}
