// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import cookies from 'axios/lib/helpers/cookies'
import { newConnection as newEchoConnection } from 'viblo-sdk/echo'

const broadcaster = cookies.read('socket_broadcaster')

let options = {
    host: '/',
    csrfToken: cookies.read('XSRF-TOKEN'),
    broadcaster: 'socket.io',
}

if (broadcaster === 'pusher') {
    window.Pusher = require('pusher-js')

    const key = cookies.read('socket_key')
    const authEndpoint = cookies.read('auth_endpoint')
    const pusherHost = cookies.read('socket_host')
    const wsPort = cookies.read('wsPort')
    const wssPort = cookies.read('wssPort')


    options = {
        host: pusherHost,
        csrfToken: cookies.read('XSRF-TOKEN'),
        broadcaster,
        key,
        authEndpoint,
        wsHost: pusherHost,
        wsPort,
        wssPort,
        forceTLS: false,
        encrypted: true,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
    }
}

export default newEchoConnection(options)
