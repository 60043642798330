export const state = () => ({
    isDisplayed: false,
    isSkipped: false,
})

export const mutations = {
    setIsDisplayed(state, isDisplayed) {
        state.isDisplayed = isDisplayed
    },

    setIsSkipped(state, isSkipped) {
        state.isSkipped = isSkipped
    },
}
