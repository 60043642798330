import InstantClick from '@kimyvgy/nuxt-instantclick'

// eslint-disable-next-line no-useless-escape
const getFullPath = url => url.replace(/^(http[s]?\:\/\/[a-zA-Z-0-9\-\.\:]+)/, '')

export default ({ store, app: { router } }) => {
    const options = {
        delayBeforePreload: 0, // 0 ms
        cacheTTL: 5 * 60 * 1000, // 5 min
        isPreloadable: (url) => {
            // please return `true` if the URL is preloadable.
            // instantclick will have no action if returning `false`.
            const postPageReg = /\/p\/(.+-)?([a-zA-Z0-9]{11})$/
            return postPageReg.test(url)
        },
        transformURL: (url) => {
            // transform URL after by pass `filterURL` middleware
            const hashId = url.match(/([^/-]+)$/)[1]
            return `/api/p/${hashId}`
        },
    }

    InstantClick.init(options)

    InstantClick.on('wait', () => {
        window.$nuxt.$loading.start().increase(5)
    })

    InstantClick.on('change', async (url, { body }) => {
        handlePostRoute(url, body)
    })

    InstantClick.on('exit', (url) => {
        changePage(url)
    })

    // -----------------------------------------

    function changePage(url) {
        router.push(getFullPath(url)).catch()
    }

    async function handlePostRoute(url, body) {
        const shouldApplyData = (
            !store.state.postView.post
            || store.state.postView.post.slug !== body.slug
        )

        if (shouldApplyData) {
            await store.dispatch('postView/setPreloadedData', body)
        }

        changePage(url)
    }
}
