// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _each from 'lodash/each'
import * as DomUtils from '../dom-utils'
import { guid as GUID } from '../functions'

export default (section, items = '[aria-ctr-enable]') => {
    if (!section || !items) {
        throw new Error('CTR is missing argument.')
    }

    items = typeof items === 'string' ? section.querySelectorAll(items) : items

    const guid = GUID()
    const $listeners = {
        'first-seen': () => {},
        'clicked-item': () => {} // eslint-disable-line
    }

    const dispatchFirstSeen = (event) => {
        if (DomUtils.isVisibleInViewport(section)) {
            window.removeEventListener('scroll', dispatchFirstSeen)
            $listeners['first-seen']({ guid, event })
        }
    }

    const dispatchClickedItem = event => $listeners['clicked-item']({ guid, event })

    // Track first seen:
    window.addEventListener('scroll', dispatchFirstSeen)
    // Track item clicked:
    _each(items, item => item.addEventListener('click', dispatchClickedItem))

    return ({
        guid,

        on: (event, callback) => {
            $listeners[event] = callback
        },

        destroy: () => {
            // Remove listeners:
            _each(items, item => item.removeEventListener('click', dispatchClickedItem))
        },
    })
}
