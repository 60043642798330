// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { getPostsFeed } from 'viblo-sdk/api/posts'
import { getQuestionsFeed, QuestionFeedType } from 'viblo-sdk/api/questions'
import {
    getTopUsers, getTopOrganizations, getOrganizationPartners, getUserPartners, getSuggestionService,
} from '~/api/placements'
import { loadUsers, loadOrganizations } from './__utils/entities'
import { getPostInOrgByLevel } from '../api/posts'

import {
    defaultState, getters as defaultGetters, createActions, mutations as defaultMutations,
} from './__modules/post-feed'
import { suggestionService } from '~/constants/suggestions'

const state = () => ({
    ...defaultState,
    sidebar: {
        users: [],
        questions: [],
        organizations: [],
        organizationPartners: [],
        userPartners: [],
        suggestionChallenges: [],
        suggestionCourses: [],
        suggestionPuzzles: [],
    },
    postOrgLevelDiamond: null,
    postOrgLevelGold: null,
    postOrgLevelSilver: null,
    pagination: null,
})

const getters = {
    ...defaultGetters,

    getUserPartners: (state, getters, rootState, rootGetters) => {
        if (!state.sidebar.userPartners) return null
        const users = state.sidebar.userPartners

        return users.map(userObj => ({ ...userObj, is_self: userObj.id === rootGetters['auth/userId'] }))
    },
}

const actions = {
    ...createActions({
        fetchPosts: ({ feed, params }) => getPostsFeed(feed, params),
    }),

    async fetchSidebar(store) {
        const [users, questions, organizations, organizationPartners, userPartners] = await Promise.all([
            loadUsers(store, getTopUsers('sidebar').then(_ => _.data.data)),
            getQuestionsFeed(QuestionFeedType.Newest, { limit: 5 }).then(_ => _.data),
            loadOrganizations(store, getTopOrganizations('sidebar').then(_ => _.data.data)),
            getOrganizationPartners().then(_ => _.data.data),
            getUserPartners().then(_ => _.data.data),
        ])

        store.commit('setSidebar', {
            users, questions, organizations, organizationPartners, userPartners,
        })
    },

    async fetchSidebarRecommend(store) {
        const [users, questions, suggestionChallenges, suggestionCourses, suggestionPuzzles] = await Promise.all([
            loadUsers(store, getTopUsers('sidebar').then(_ => _.data.data)),
            getQuestionsFeed(QuestionFeedType.Newest, { limit: 3 }).then(_ => _.data),
            getSuggestionService({ type: suggestionService.CODE }).then(_ => _.data),
            getSuggestionService({ type: suggestionService.LEARN }).then(_ => _.data),
            getSuggestionService({ type: suggestionService.CTF }).then(_ => _.data),
        ])
        store.commit('setSidebar', {
            users, questions, suggestionChallenges, suggestionCourses, suggestionPuzzles,
        })
    },

    async getPostInOrgByLevel({ commit }, { level, page }) {
        const setPostByLevel = 'setPostsByLevel'.concat(level.charAt(0).toUpperCase() + level.slice(1))
        const postByLevel = await getPostInOrgByLevel({
            level,
            page,
        })
            .then(response => response.data)
            .catch(() => null)

        commit(setPostByLevel, postByLevel)
    },
}

const mutations = {
    ...defaultMutations,

    setSidebar(state, data) {
        state.sidebar = data
    },

    setPostsByLevelDiamond(state, data) {
        state.postOrgLevelDiamond = data.data
        state.pagination = data.meta
    },

    setPostsByLevelGold(state, data) {
        state.postOrgLevelGold = data.data
        state.pagination = data.meta
    },

    setPostsByLevelSilver(state, data) {
        state.postOrgLevelSilver = data.data
        state.pagination = data.meta
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
