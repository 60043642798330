// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX

import { fetchUserPosts } from '~/api/users'

const state = () => ({
    bookmarks: null,
    pagination: null,
    type: 'posts',
})

const getters = {}

const actions = {
    async getBookmarks({ commit }, {
        sort, page, perPage, username, type,
    }) {
        const data = await fetchUserPosts(username, `clipped-${type}`, {
            sort,
            page,
            limit: perPage,
        })
        commit('setDataBookmarks', {
            data,
            type,
        })
    },
}

const mutations = {
    setDataBookmarks(state, { data, type }) {
        state.bookmarks = data.data.data
        state.pagination = data.data.meta.pagination
        state.type = type
    },
}

export default {
    state,
    actions,
    getters,
    mutations,
}
