import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../app/store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../app/store/announcements.js'), 'announcements.js')
  resolveStoreModules(require('../app/store/auth.js'), 'auth.js')
  resolveStoreModules(require('../app/store/badges.js'), 'badges.js')
  resolveStoreModules(require('../app/store/bookmarks.js'), 'bookmarks.js')
  resolveStoreModules(require('../app/store/commentsList.js'), 'commentsList.js')
  resolveStoreModules(require('../app/store/conditionCreateOrganization.js'), 'conditionCreateOrganization.js')
  resolveStoreModules(require('../app/store/config.js'), 'config.js')
  resolveStoreModules(require('../app/store/hovercard.js'), 'hovercard.js')
  resolveStoreModules(require('../app/store/log.js'), 'log.js')
  resolveStoreModules(require('../app/store/mayFestTime.js'), 'mayFestTime.js')
  resolveStoreModules(require('../app/store/notifications.js'), 'notifications.js')
  resolveStoreModules(require('../app/store/oneTap.js'), 'oneTap.js')
  resolveStoreModules(require('../app/store/organizationsUser.js'), 'organizationsUser.js')
  resolveStoreModules(require('../app/store/pageHistory.js'), 'pageHistory.js')
  resolveStoreModules(require('../app/store/postHomeFeed.js'), 'postHomeFeed.js')
  resolveStoreModules(require('../app/store/postView.js'), 'postView.js')
  resolveStoreModules(require('../app/store/profile.js'), 'profile.js')
  resolveStoreModules(require('../app/store/promoted.js'), 'promoted.js')
  resolveStoreModules(require('../app/store/questionHomeFeed.js'), 'questionHomeFeed.js')
  resolveStoreModules(require('../app/store/questionView.js'), 'questionView.js')
  resolveStoreModules(require('../app/store/seriesView.js'), 'seriesView.js')
  resolveStoreModules(require('../app/store/settings.js'), 'settings.js')
  resolveStoreModules(require('../app/store/survey.js'), 'survey.js')
  resolveStoreModules(require('../app/store/toast.js'), 'toast.js')
  resolveStoreModules(require('../app/store/typeControl.js'), 'typeControl.js')
  resolveStoreModules(require('../app/store/userRankings.js'), 'userRankings.js')
  resolveStoreModules(require('../app/store/vueTour.js'), 'vueTour.js')
  resolveStoreModules(require('../app/store/witePostNewYear.js'), 'witePostNewYear.js')
  resolveStoreModules(require('../app/store/wrappedUpTime.js'), 'wrappedUpTime.js')
  resolveStoreModules(require('../app/store/__modules/comments.js'), '__modules/comments.js')
  resolveStoreModules(require('../app/store/__modules/post-feed.js'), '__modules/post-feed.js')
  resolveStoreModules(require('../app/store/__modules/question-feed.js'), '__modules/question-feed.js')
  resolveStoreModules(require('../app/store/__modules/user-list.js'), '__modules/user-list.js')
  resolveStoreModules(require('../app/store/__plugins/pushNewPosts.js'), '__plugins/pushNewPosts.js')
  resolveStoreModules(require('../app/store/__plugins/pushNotifications.js'), '__plugins/pushNotifications.js')
  resolveStoreModules(require('../app/store/__utils/entities.js'), '__utils/entities.js')
  resolveStoreModules(require('../app/store/__utils/schemas.js'), '__utils/schemas.js')
  resolveStoreModules(require('../app/store/entities/organizations.js'), 'entities/organizations.js')
  resolveStoreModules(require('../app/store/entities/posts.js'), 'entities/posts.js')
  resolveStoreModules(require('../app/store/entities/questions.js'), 'entities/questions.js')
  resolveStoreModules(require('../app/store/entities/tags.js'), 'entities/tags.js')
  resolveStoreModules(require('../app/store/entities/users.js'), 'entities/users.js')
  resolveStoreModules(require('../app/store/organizationView/followers.js'), 'organizationView/followers.js')
  resolveStoreModules(require('../app/store/organizationView/members.js'), 'organizationView/members.js')
  resolveStoreModules(require('../app/store/organizationView/technologyStacks.js'), 'organizationView/technologyStacks.js')
  resolveStoreModules(require('../app/store/organizationView/topMembers.js'), 'organizationView/topMembers.js')
  resolveStoreModules(require('../app/store/postView/comments.js'), 'postView/comments.js')
  resolveStoreModules(require('../app/store/profile/contact.js'), 'profile/contact.js')
  resolveStoreModules(require('../app/store/profile/followers.js'), 'profile/followers.js')
  resolveStoreModules(require('../app/store/profile/following.js'), 'profile/following.js')
  resolveStoreModules(require('../app/store/profile/posts.js'), 'profile/posts.js')
  resolveStoreModules(require('../app/store/profile/questions.js'), 'profile/questions.js')
  resolveStoreModules(require('../app/store/profile/skills.js'), 'profile/skills.js')
  resolveStoreModules(require('../app/store/questionView/answers.js'), 'questionView/answers.js')
  resolveStoreModules(require('../app/store/questionView/comments.js'), 'questionView/comments.js')
  resolveStoreModules(require('../app/store/seriesView/comments.js'), 'seriesView/comments.js')
  resolveStoreModules(require('../app/store/tagView/followers.js'), 'tagView/followers.js')
  resolveStoreModules(require('../app/store/tagView/posts.js'), 'tagView/posts.js')
  resolveStoreModules(require('../app/store/tagView/questions.js'), 'tagView/questions.js')
  resolveStoreModules(require('../app/store/writeups/puzzlePosts.js'), 'writeups/puzzlePosts.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
