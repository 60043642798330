export default {
    mainNavbar: {
        mainMenu: {
            posts: 'Bài Viết',
            questions: 'Hỏi Đáp',
            discussions: 'Thảo Luận',
        },

        quickSearch: {
            placeholder: 'Tìm kiếm trên Viblo',
            notFound: 'Chúng tôi không thể tìm thấy bất cứ điều gì. Hãy thử dùng',
            advancedSearch: 'công cụ tìm kiếm nâng cao',
        },

        announcements: {
            announcements: 'Thông tin',
            allRead: 'Đánh dấu tất cả đã đọc',
            noAnnouncement: 'Chưa có thông tin',
            allAnnouncements: 'Tất cả thông tin',
        },

        notifications: {
            notifications: 'Thông báo',
            NotificationSettings: 'Cài đặt thông báo',
            notificationsList: {
                new: 'Mới',
                earlier: 'Cũ hơn',
            },
            seeAll: 'Xem tất cả',
        },

        writeMenu: {
            title: 'Viết',
            writePost: 'Viết bài',
            newSeries: 'Series mới',
            askQuestion: 'Đặt câu hỏi',
            newVideo: 'Video mới',
        },

        userMenu: {
            login: 'Đăng nhập/Đăng ký',
            profile: 'Trang cá nhân',
            myContents: 'Quản lý nội dung',
            myActivities: 'Lịch sử hoạt động',
            reviewContents: 'Đánh giá nội dung',
            organizations: 'Tổ chức',
            preferences: 'Tùy chỉnh',
            adminDashboard: 'Trang quản trị',
        },

        setting: {
            appearances: {
                title: 'Hiển thị',
                postLayout: {
                    title: 'Bố cục bài viết',
                    detail: 'Bạn có thể chọn chế độ hiển thị danh sách bài viết "Đơn giản" - chỉ có tiêu đề và metadata hoặc chế độ "Xem trước" - sẽ xuất hiện thêm một đoạn trích nội dung.',
                    simple: 'Đơn giản',
                    preview: 'Xem trước',
                },
                itemsPerPage: {
                    title: 'Số mục trên trang',
                    detail: 'Bạn có thể tùy chỉnh số lượng các mục được hiển thị trong một trang cho danh sách được phân trang (ví dụ: trang chủ).',
                },
                highlightThemes: {
                    title: 'Màu nền cho các đoạn code',
                    detail: 'Chủ đề được sử dụng thay đổi đoạn mã',
                    dark: 'Tối',
                    light: 'Sáng',
                },
                homePage: {
                    title: 'Cài đặt trang chủ',
                    detail: 'Bạn có thể đặt các trang sau làm trang chủ để hiển thị những gì bạn quan tâm nhất.',
                    followings: 'Đang theo dõi',
                    newest: 'Bài viết mới nhất',
                },
                locale: {
                    title: 'Ngôn ngữ hiển thị',
                    detail: 'Website, email và các thông báo sẽ hiển thị với ngôn ngữ đã chọn.',
                },
                layout: {
                    title: 'Bố cục trang',
                    detail: 'Bạn hãy chọn mẫu layout sẽ được áp dụng trên trang subdomain của mình. ',
                    placeholder: 'Chọn bố cục hiển thị',
                    options: {
                        default: 'Bố cục trang 1',
                        layoutSubdomain2: 'Bố cục trang 2',
                    },
                },
                success: 'Đã cập nhật cài đặt',
            },
            notifications: {
                title: 'Thông báo',
                content: {
                    title: 'Nội dung dành cho bạn',
                    contentPeople: {
                        content: 'Nội dung từ những người bạn đã theo dõi',
                        detail: 'Chúng tôi sẽ thông báo cho bạn khi những người bạn đang theo dõi đăng tải bài viết mới.',
                    },
                    question: {
                        content: 'Những câu hỏi bạn đã tạo hoặc đã theo dõi',
                        detail: 'Chúng tôi sẽ thông báo cho bạn khi có câu trả lời mới cho những câu hỏi bạn đã hỏi hoặc theo dõi.',
                    },
                },
                comment: {
                    title: 'Nhận xét & Đề cập',
                    comments: {
                        title: 'Nhận xét',
                        detail: 'Chúng tôi sẽ thông báo cho bạn khi ai đó nhận xét về bài đăng, câu hỏi và câu trả lời của bạn.',
                    },
                    mentions: {
                        title: 'Đề cập',
                        detail: 'Chúng tôi sẽ thông báo cho bạn khi ai đó nhắc đến bạn trong các bài đăng, câu hỏi hoặc câu trả lời của họ.',
                    },
                },
                reputations: {
                    title: 'Reputations của bạn',
                    voteAndclip: {
                        title: 'Votes & Bookmarks',
                        detail: 'Chúng tôi sẽ thông báo cho bạn khi ai đó vote hoặc bookmark các bài đăng, câu hỏi hoặc câu trả lời của bạn.',
                    },
                    acceptedAnswers: {
                        title: 'Chấp nhận câu trả lời',
                        detail: 'Chúng tôi sẽ thông báo cho bạn khi câu trả lời của bạn được chấp nhận.',
                    },
                    newFollowers: {
                        title: 'Người theo dõi mới',
                        detail: 'Chúng tôi sẽ thông báo cho bạn khi một người mới bắt đầu theo dõi bạn.',
                    },
                    vibloFanpage: {
                        title: 'Viblo Fanpage',
                        detail: 'Chúng tôi sẽ thông báo cho bạn khi bài đăng của bạn được chia sẻ trên Viblo Fanpage.',
                    },
                },
                email: {
                    title: 'Email',
                    unread: {
                        title: 'Thông báo chưa đọc',
                        detail: 'Chúng tôi sẽ gửi email cho bạn khi bạn có thông báo mới chưa đọc trong ngày.',
                    },
                    newsletter: {
                        title: 'Bản tin',
                        detail: 'Chúng tôi sẽ gửi cho bạn bản tin tổng hợp vào Thứ Sáu hàng tuần.',
                    },
                },
            },
            webhooks: {
                title: 'Webhook',
                newPost: 'Bài viết mới',
                firstDetail: 'Chúng tôi sẽ gửi',
                link: 'JSON payload',
                lastDetail: 'khi các tác giả bạn đang theo dõi publish một bài viết mới.',
                form: {
                    targetURL: 'URL đích',
                    secret: 'Khóa',
                    token: 'Mã bảo mật an toàn',
                    tokenHeader: 'Tiêu đề mã bảo mật an toàn',
                    basicAuth: 'Sử dụng xác thực cơ bản',
                    username: 'Tài khoản',
                    password: 'Mật khẩu',
                    update: 'Cập nhật',
                    webhookUpdated: 'Webhook đã được cập nhật',
                    checkWebhook: 'Đã gửi yêu cầu tới {url}. Vui lòng kiểm tra nó!',
                },
                popup: {
                    title: 'Cấu trúc dữ liệu được gửi',
                    copy: 'Sao chép',
                },
                validations: {
                    url: {
                        required: 'Vui lòng nhập URL đích',
                        type: 'URL không hợp lệ',
                        max: 'Độ dài tối đa là 255 ký tự',
                    },
                    secureTokenHeader: {
                        max: 'Độ dài tối đa tiêu đề mã bảo mật là 255 ký tự',
                    },
                    secureToken: {
                        max: 'Độ dài tối đa mã bảo mật là 255 ký tự',
                    },
                    secret: {
                        max: 'Độ dài tối đa của khóa là 255 ký tự',
                    },
                },
                test: 'Kiểm tra',
                updateAndTest: 'Cập nhật & Kiểm tra',
            },
        },
    },

    welcomeHero: {
        guest: {
            welcome: 'Chào mừng đến với Viblo',
            free: 'Dịch vụ chia sẻ',
            service: 'kiến thức IT',
            sharing: 'miễn phí',
            join: 'Tham gia với chúng tôi để cập nhật những kiến thức IT mới nhất và cải thiện năng lực bản thân',
            start: 'Bắt đầu',
        },

        user: {
            welcome: 'Chào mừng bạn trở lại,',
            writings: 'Chia sẻ câu chuyện của bạn ngày hôm nay?',
            start: 'Bắt đầu ngay bây giờ!',
        },
    },

    feedBar: {
        mayfestTitle: 'Mayfest',
        followings: 'Đang theo dõi',
        newest: 'Mới nhất',
        series: 'Series',
        editorsChoice: 'Editors\' Choice',
        trending: 'Trending',
        videos: 'Videos',
        clip: 'Bookmark Của Tôi',
        createPost: 'Viết bài',
        mayfest: {
            starts: 'May Fest sắp bắt đầu, hãy chuẩn bị trước bài viết để nhận những phần quà hấp dẫn!',
            happenning: 'May Fest vẫn đang mở. Chia sẻ câu chuyện của bạn để nhận những phần quà hấp dẫn!',
            end: 'Viblo May Fest đã khép lại. Cảm ơn các bạn đã đồng hành cùng chúng tôi trong sứ mệnh lan tỏa tri thức Việt!',
        },
        contentCreator: 'Nhà sáng tạo nội dung',
    },

    alAlert: {
        followingTagsLessThanFive: {
            title: 'Chào bạn! Chúng tôi đang nỗ lực để cung cấp đến bạn những nội dung mà bạn quan tâm nhất thông qua hệ thống ',
            machineLearing: 'Viblo Machine Learning. ',
            follow: 'Vì vậy, hãy theo dõi ít nhất',
            tag: '5 chủ đề kỹ thuật',
            reading: 'mà bạn muốn đọc!',
            interest: 'Bạn muốn tìm hiểu chủ đề nào?',
        },
        termWarningVisible: {
            update: 'Tháng 9 năm 2020, chúng tôi đã được cập nhật',
            termsOfService: 'Điều khoản dịch vụ',
            detail: 'cho phần "Quyền sở hữu của chúng tôi". Nếu bạn không có bất kỳ phản hồi nào, chúng tôi cho rằng bạn đã đồng ý với điều khoản mới này.',
        },
    },

    sidebar: {
        question: 'Câu hỏi mới nhất',
        organization: 'Các tổ chức hàng đầu',
        author: 'Các tác giả hàng đầu',
        partners: 'Nhà sáng tạo nội dung',
        organizationPartners: 'Tổ chức',
        userPartners: 'Tác giả',
        suggestion: {
            question: {
                title: 'Câu hỏi đề xuất',
            },
            challenge: {
                title: 'Thử thách đề xuất',
            },
            course: {
                title: 'Khóa học đề xuất',
                level: {
                    beginning: 'Cơ bản',
                    intermediate: 'Trung cấp',
                    advanced: 'Nâng cao',
                },
            },
            puzzle: {
                title: 'Câu đố đề xuất',
            },
            post: 'Bài viết đề xuất',
            profile: {
                empty: 'Người dùng này chưa có bài viết nào.',
            },
        },
    },

    getStarted: {
        title: 'Chủ đề',
        tag: 'Chủ Đề Kỹ Thuật',
        sort: {
            title: 'Sắp xếp',
            nameASC: 'Tên: A đến Z',
            nameDESC: 'Tên: Z đến A',
            followers: 'Người theo dõi',
            posts: 'Số bài viết',
            question: 'Số  câu hỏi',
        },
        type: {
            title: 'Loại',
            all: 'Tất cả các loại',
            technical: 'Chủ đề kỹ thuật',
            others: 'Các loại khác',
        },
        search: 'Tìm kiếm chủ đề',
        continueAble: {
            follow: 'Theo dõi 5 chủ đề hoặc nhiều hơn',
            continue: 'Tiếp tục',
            followed: 'Đã theo dõi',
        },
        tagItem: {
            posts: 'bài viết',
            questions: 'câu hỏi',
            followers: 'người theo dõi',
        },
        follow: {
            following: 'Đang theo dõi',
            follow: 'Theo dõi',
        },
        beforeRouteLeave: {
            content: 'Vui lòng theo dõi ít nhất 5 chủ đề kỹ thuật để chuyển sang bước tiếp theo.',
            title: 'Bạn chưa theo dõi đủ 5 chủ đề kỹ thuật',
        },
        start: 'Bắt đầu với Viblo',
    },

    profile: {
        posts: 'Bài viết',
        series: 'Series',
        questions: 'Câu hỏi',
        answers: 'Câu trả lời',
        clips: 'Bookmark',
        followings: 'Đang theo dõi',
        followers: 'Người theo dõi',
        tags: 'Thẻ',
        reputations: 'Reputations',
        communication: 'Liên hệ',
        follow: 'Theo dõi',
        following: 'Đang theo dõi',
        edit: 'Sửa',
        layoutSwitcher: {
            simple: 'Chỉ tiêu đề',
            preview: 'Xem trước nội dung',
        },
        embed: {
            title: 'Nhúng trang cá nhân',
            close: 'Đóng',
        },
        profileStats: {
            postViews: 'Tổng số lượt xem bài viết',
            reputations: 'Reputations',
            followingTags: 'Các thẻ theo dõi',
            followingUsers: 'Đang theo dõi các người dùng',
            followers: 'Các người dùng đang theo dõi',
            posts: 'Bài viết',
            Clips: 'Bookmark',
            totalQuestions: 'Tổng số câu hỏi',
            totalAnswers: 'Tổng số câu trả lời',
        },
        statsHeader: {
            postViews: 'Lượt xem bài viết',
            reputations: 'Reputations',
            followers: 'Người theo dõi',
            posts: 'Bài viết',
        },
        badges: 'Danh hiệu',
        technicalSkills: {
            title: 'Các kĩ năng',
            noSkill: 'Chưa có dữ liệu.',
            update: 'Cập nhật ngay!',
            updateSkill: 'Cập nhật kỹ năng IT',
        },
        organizations: {
            title: 'Tổ chức',
            noOrganization: 'Chưa có tổ chức nào.',
            createOrgaization: 'Tạo tổ chức của bạn!',
            posts: 'Bài viết: ',
            members: 'Thành viên',
            follow: 'Người theo dõi',
            view: 'Lượt xem bài viết',
            totalPost: 'Tổng số  bài viết',
            newPost: 'Bài viết mới',
            createOrgaizationCondition: 'Bạn chưa đủ 100 điểm reputations để tạo tổ chức',
            description: 'Tổ chức này đã có subdomain tại',
        },
        report: 'Báo cáo',
        reportDialog: {
            title: 'Nội dung báo cáo',
            label: 'Lý do báo cáo nội dung này',
            spam: 'Spam',
            rulesViolation: 'Vi phạm quy tắc',
            harassment: 'Sự quấy rầy',
            terms: 'Điều khoản dịch vụ của Viblo',
            comment: 'Bình luận',
            reported: 'Đã gửi báo cáo',
            reportBtn: 'Báo cáo',
            validations: {
                reason: 'Vui lòng chọn một lý do.',
            },
        },
        postsTendency: 'Xu hướng bài viết',
        answered: 'Đã trả lời',
        featured: 'Được chọn',
        trending: 'Trending',
        reputationHistory: 'Lịch sử  reputations',
        reason: 'Lý do:',
        reportAt: 'Báo cáo lúc:',
        deleted: '{type} của bạn đã bị xóa',
    },

    userCard: {
        edit: 'Sửa',
        following: 'Đang theo dõi',
        follow: 'Theo dõi',
    },

    contentMenu: {
        minRead: ' phút đọc',
        posts: {
            title: 'Bài viết',
            drafts: 'Bản nháp',
            public: 'Công khai',
            unlisted: 'Bất kỳ ai có liên kết',
            spam: 'Spam',
            notReady: 'Cần chỉnh sửa',
            scheduled: 'Đã lên lịch',
            writeups: 'Writeups',
        },
        socialShare: {
            title: 'Chia sẻ lên MXH',
            drafts: 'Bản nháp',
            scheduled: 'Lên lịch',
            published: 'Đã chia sẻ',
        },
        series: 'Series',
        answers: 'Câu trả lời',
        questions: 'Câu hỏi',
        postsSpam: 'Bài viết spam',
        clips: {
            title: 'Bookmark của tôi',
            posts: 'Bài viết',
            series: 'Series',
            questions: 'Câu hỏi',
        },
        answerRequests: {
            title: 'Nhờ trả lời câu hỏi',
            receivedQuestions: 'Câu hỏi đã nhận',
            sentHistory: 'Yêu cầu đã gửi',
        },
        gallery: 'Q.lý hình ảnh',
        technicalSkill: 'Kĩ năng IT',
        violations: 'Vi phạm',
    },

    myContent: {
        search: {
            startDate: 'Ngày bắt đầu',
            endDate: 'Ngày kết thúc',
            apply: 'Áp dụng',
            tag: 'Thẻ',
            search: 'Tìm kiếm',
        },
        title: {
            drafts: 'Bản nháp',
            publicPosts: 'Bài viết công khai',
            unlistedPosts: 'Bất kỳ ai có liên kết',
            sharesDrafts: 'Bản chia sẻ nháp',
            sharesScheduled: 'Bản chia sẻ đã lên lịch',
            sharesPublished: 'Bản đã chia sẻ',
            series: 'Series',
            answers: 'Câu trả lời',
            questions: 'Câu hỏi',
            clipsPosts: 'Bài viết đã bookmark',
            clipsSeries: 'Series đã bookmark',
            clipsQuestions: 'Câu hỏi đã bookmark',
            receivedQuestions: 'Câu hỏi đã nhận',
            sentHistory: 'Yêu cầu đã gửi',
            gallery: 'Quản lý hình ảnh',
            technicalSkills: 'Kỹ năng IT',
            violations: 'Vi phạm',
            postsSpam: 'Bài viết spam',
            notReady: 'Bài viết cần được chỉnh sửa',
            scheduledPosts: 'Bài viết đã được lên lịch',
        },
        updateSkills: 'Cập nhật kỹ năng',
        noSkill: 'Bạn chưa có kỹ năng nào.',
        technicalSkillsDialog: {
            title: 'Cập nhật kỹ năng IT',
            placeholder: 'Chọn kỹ năng IT',
        },
        sentHistory: {
            title: 'Bạn có biết?',
            firstContent: 'Bạn có thể nhờ các thành viên khác trả lời giúp một câu hỏi bất kỳ nếu bạn biết rằng họ rất giỏi trong chủ đề đó. Hãy nhấp vào',
            betweenContent: '"Nhờ chuyên gia trả lời" trong Menu Câu hỏi',
            lastContent: 'chọn một người dùng sau đó gửi yêu cầu của bạn.',
        },
        action: {
            addToSeries: {
                title: 'Thêm vào series của tôi',
                empty: 'Bạn không có bất kỳ series nào.',
                create: 'Tạo một cái mới!',
                titlePopup: 'Bạn muốn thêm bài viết này này vào series nào?',
                add: 'Thêm',
                sendProposal: 'Gửi đề xuất',
                success: 'Thêm bài vào series thành công!',
                proposalSuccess: 'Đề xuất của bạn đã được gửi. Vui lòng đợi phản hồi của chủ nhân series!',
                error: 'Đã xảy ra sự cố khi thêm bài viết này vào series của bạn.',
            },
            edit: 'Sửa',
            delete: {
                title: 'Xóa',
                titleDelete: 'Xóa bài viết',
                contentDelete: 'Xóa bài viết này?',
            },
            seoSetting: 'Tùy chỉnh SEO',
        },
        series: {
            stats: {
                views: 'Lượt xem',
                votes: 'Votes',
                comments: 'Bình luận',
                clips: 'Bookmark',
                posts: 'Bài viết',
            },
            status: {
                draft: 'Bản nháp - Lần sửa cuối:',
                unlisted: 'Bất kỳ ai có liên kết - Lần sửa cuối:',
                published: 'Được phát hành: ',
                edit: 'Sửa lần cuối: ',
            },
            action: {
                add: 'Thêm bài viết',
                edit: 'Sửa',
                delete: 'Xóa',
                remove: 'Xóa',
                contentDelete: 'Bạn có muốn xóa câu trả lời này không?',
                titleRemovePosts: 'Xóa bài viết',
                contentRemovePosts: 'Xóa bài viết này?',
            },
        },
        answer: {
            yourAnswer: 'Câu trả lời của bạn:',
            vote: 'Vote:',
            answered: 'Đã trả lời',
        },
        sendRequestions: {
            status: 'Trạng thái',
            recipient: 'Người nhận',
            question: 'Câu hỏi',
            requestedAt: 'Yêu cầu lúc',
            requestedBy: 'Được yêu cầu bởi',
            writeAnswer: 'Viết câu trả lời',
            answered: 'Đã trả lời',
            noAnswerYet: 'Chưa có câu trả lời',
            accepted: 'Đã được chấp nhận',
        },
        violations: {
            type: 'Loại',
            title: 'Tiêu đề',
            reason: 'Lý do',
            deleteAt: 'Xóa lúc',
            comment: 'Lý do: ',
            entityTypes: {
                post: 'Bài viết',
                question: 'Câu hỏi',
                series: 'Series',
                answer: 'Câu trả lời',
                comment: 'Bình luận',
            },
            reasonTypes: {
                spam: 'Spam',
                poor_translation: 'Bản dịch kém',
                infringes_copyright: 'Vi phạm bản quyền',
                harashment: 'Quấy rối',
                tos_violation: 'Vi phạm quy tắc',
            },
        },
        socialShare: {
            title: 'Tiêu đề',
            creator: 'Người tạo',
            type: 'Kiểu',
            rankScore: 'Điểm xếp hạng',
            createdAt: 'Ngày tạo',
            actions: 'Hành động',
            message: 'Message',
            publishedAt: 'Xuất bản lúc',
            publishAt: 'Dự kiến Xuất bản',
        },
    },

    footer: {
        resources: {
            title: 'Tài nguyên',
            posts: 'Bài viết',
            organizations: 'Tổ chức',
            questions: 'Câu hỏi',
            tags: 'Tags',
            videos: 'Videos',
            authors: 'Tác giả',
            discussions: 'Thảo luận',
            recommendSystem: 'Đề xuất hệ thống',
            tools: 'Công cụ',
            machineLearning: 'Machine Learning',
            systemStatus: 'Trạng thái hệ thống',
            services: 'Dịch vụ',
            mobileApp: 'Ứng dụng di động',
            links: 'Liên kết',
            rightsReserved: '. All rights reserved.',
        },
        about: 'Về chúng tôi',
        help: 'Giúp đỡ',
        fAQs: 'FAQs',
        rSS: 'RSS',
        terms: 'Điều khoản',
        feedback: {
            title: 'Phản hồi',
            titleForm: 'Viblo - Dịch vụ chia sẻ kiến thức kỹ thuật miễn phí',
            lable: 'Phản hồi của bạn',
            description: 'Phản hồi của bạn sẽ được chia sẻ với đội ngũ phát triển sản phẩm của chúng tôi và được xem xét để phát triển trong tương lai.',
            lableName: 'Tên',
            lableEmail: 'Email',
            send: 'Gửi',
            cancel: 'Hủy',
            survey: 'Tham gia khảo sát',
            required: 'Nội dung phản hồi là bắt buộc',
            min: 'Nội dung phản hồi phải có ít nhất 10 ký tự',
            max: 'Nội dung phản hồi quá dài',
            success: 'Cảm ơn phản hồi của bạn!',
            error: 'Đã xảy ra sự cố khi cố gắng gửi phản hồi của bạn. Vui lòng thử lại sau.',
            validations: {
                requireContent: 'Nội dung phản hồi là bắt buộc',
                contentMin: 'Nội dung phản hồi phải có ít nhất 10 ký tự',
                contentMax: 'Nội dung phản hồi quá dài',
                typeEmail: 'Email không hợp lệ',
                requireName: 'Tên người dùng là bắt buộc',
                maxName: 'Tên quá dài',
            },
        },
    },

    question: {
        newest: 'Mới nhất',
        unsolved: 'Chưa giải quyết',
        followings: 'Đang theo dõi',
        myClips: 'Đã bookmark',
        askQuestion: 'Đặt câu hỏi',
        newestPost: 'Bài viết mới nhất',
        topAuthors: 'Các tác giả hàng đầu',
        views: 'Lượt xem: ',
        comments: 'Bình luận: ',
        cliped: 'Đã bookmark: ',
        score: 'Điểm',
        answer: 'Câu trả lời',
        view: 'Lượt xem',
        comment: 'Bình luận',
        clips: 'Bookmark',
        posts: 'Bài viết',
        clip: 'Bookmark câu hỏi này',
        unClip: 'Xóa bookmark',
        asked: 'Yêu cầu',
        unclip: 'Xóa bookmark câu hỏi này',
        isOwn: 'Bạn không thể bookmark cho câu hỏi của bạn',
        recommend: 'Có thể bạn quan tâm',
        interview_questions: 'Các câu hỏi phỏng vấn',
        votes: 'Vote: ',
        noQuestion: 'Không có câu hỏi',
        answers: 'trả lời',
    },

    userInline: {
        reputations: 'Reputations: ',
        posts: 'Bài viết: ',
        followers: 'Người theo dõi: ',
        totalPostViews: 'Tổng số lượt xem: ',
        following: 'Đã theo dõi',
        follow: 'Theo dõi',
        edit: 'Sửa',
        questions: 'Câu hỏi: ',
        answers: 'Câu trả lời: ',
        views: 'Lượt xem: ',
        comments: 'Bình luận: ',
        clips: 'Bookmark: ',
    },

    myClips: {
        type: 'Loại: ',
        posts: 'Bài viết',
        series: 'Series',
        questions: 'Câu hỏi',
        sort: 'Sắp xếp theo: ',
        published: 'Ngày xuất bản',
        clipAt: 'Được bookmark lúc',
    },

    myFollowing: {
        type: 'Đối tượng: ',
        users: 'Tác giả',
        organizations: 'Tổ chức',
    },

    cancelScheduled: {
        title: 'Huỷ lên lịch',
        confirm: 'Xác nhận',
        content: 'Bạn có chắc chắn hủy chia sẻ bài viết này không?',
        success: 'Bài đăng đã bị xóa khỏi danh sách!',
        error: 'Đã xảy ra lỗi. Không thể xóa bài đăng này!',
    },

    postBody: {
        postInOgranziation: {
            pendingIn: '| Đang chờ xử lý',
            in: 'trong',
            followedBy: 'Theo dõi bởi',
            people: 'người.',
            following: 'Đang theo dõi',
            follow: 'Theo dõi',
        },
        postActions: {
            readingTool: 'Công cụ đọc',
            clip: 'Bookmark bài viết này',
            unclip: 'Bỏ bookmark bài viết này',
            shareLinkFB: 'Chia sẻ liên kết đến trang này trên Facebook',
            shareLinkTwitter: 'Chia sẻ liên kết đến trang này trên Twitter',
            editorsChoice: 'Đánh dấu là lựa chọn của ban biên tập',
            shareToFanpage: {
                title: {
                    notYetShare: 'Chia sẻ lên Fanpage của Viblo',
                    hasBeenShare: 'Bài viết này đã được thêm vào danh sách chia sẻ Facebook',
                },
            },
            isOwner: 'Bạn không thể bookmark cho bài viết của bạn',
            error: 'Đã xảy ra lỗi. Vui lòng thử lại sau.',
        },
        postContent: {
            statusPost: {
                published: 'Đã đăng vào',
                unlisted: 'Bất kỳ ai có liên kết',
                draft: 'Bản nháp',
                lastEdited: '- Sửa lần cuối',
                scheduled: 'Đã lên lịch công khai lúc',
            },
            postAuthor: {
                following: 'Đang theo dõi',
                follow: 'Theo dõi',
                edit: 'Sửa',
                reputations: 'Reputations: ',
                followers: 'Người theo dõi: ',
                posts: 'Bài viết: ',
                views: 'Lượt xem: ',
                scroll: 'Di chuyển đến bình luận',
                showClips: 'Xem danh sách người bookmark',
                clipEmpty: 'Chưa có bookmark.',
                peopleClipped: ' người đã bookmark',
                notFollowing: 'Người dùng này chưa theo dõi bất kỳ ai.',
                notFollowed: 'Người dùng này chưa có ai theo dõi.',
            },
            solveSolution: 'Đây là một giải pháp để giải quyết',
            puzzle: 'câu đố',
            ctf: 'trong Viblo CTF.',
            helpfulLinks: 'Liên kết hữu ích:',
            backToList: 'Quay lại danh sách write-ups.',
            goToCTF: 'Đi tới câu đố trên Viblo CTF.',
            spam: 'Cảnh báo Spam:',
            firstContent: 'Bài đăng này bị đánh dấu là spam bởi',
            betweenContent: 'hệ thống của chúng tôi',
            lastContent: '. Vì vậy, nó không thể được hiển thị trong trang chủ! Ban quản trị sẽ xem xét trong thời gian sớm nhất. Nếu bạn có bất kỳ vấn đề gì, hãy gửi phản hồi cho chúng tôi.',
            notReady: {
                withAuthor: 'Bài đăng này chưa sẵn sàng để xuất bản. Bạn có thể đã vô tình công khai nó trong khi viết. Do đó, nó là thư rác. Vui lòng thay đổi trạng thái của nó thành Bản nháp để loại bỏ cảnh báo này.',
                withUser: 'Bài đăng này chưa sẵn sàng để xuất bản. Tác giả có thể đã vô tình công khai nó trong quá trình viết. Do đó, bạn nên suy nghĩ trước khi đọc bài bài này.',
            },
            notUpdate: {
                content: 'Bài đăng này đã không được cập nhật trong {year} năm',
            },
            postMenu: {
                title: 'Hiển thị các hành động',
                edit: 'Sửa',
                report: 'Báo cáo',
                addToSeries: 'Thêm vào series của tôi',
                removeOrganization: 'Xóa khỏi tổ chức',
                submitOrganization: 'Thêm vào tổ chức',
                convertQuestion: 'Chuyển thành câu hỏi',
                deletePost: 'Xóa bài viết này',
                markSpam: 'Đánh dấu là Spam',
                reportNotSpam: 'Gỡ trạng thái Spam',
                reportNotReady: 'Đánh dấu là chưa sẵn sàng',
                postModeration: 'Sửa nhanh thẻ',
                codeBlocks: 'Bôi màu code tự động',
            },
            dialogReport: {
                title: 'Nội dung báo cáo',
                titleForUser: 'Lý do xóa nội dung này',
                titleForm: 'Lý do báo cáo nội dung này',
                spam: 'Spam',
                rulesViolation: 'Vi phạm điều khoản',
                harassment: 'Quấy rối',
                infringesCopyright: 'Vi phạm bản quyền',
                poorTranslation: 'Bản dịch kém chất lượng',
                placeholder: 'Nhận xét (tối đa 255 ký tự)',
                placeholderUser: 'Nhận xét',
                vibloTerms: 'Điều khoản dịch vụ của Viblo',
                requireReason: 'Vui lòng chọn một lý do',
                report: 'Báo cáo',
                delete: 'Xóa',
                success: 'Nội dung được báo cáo thành công',
                successDeleted: 'Nội dung được xóa thành công',
                error: 'Đã xảy ra sự cố khi báo cáo nội dung này. Vui lòng thử lại sau!',
                errorDeleted: 'Đã xảy ra sự cố khi xóa nội dung này. Vui lòng thử lại sau!',
            },
            dialogAddOrganization: {
                title: 'Bạn muốn thêm bài viết này vào tổ chức nào?',
                submit: 'Thêm bài viết vào tổ chức',
                notOrganization: 'Bạn đang không thuộc một tổ chức nào.',
                createOrganization: 'Tạo tổ chức mới',
                success: 'Bài viết đã được thêm vào tổ chức {name}',
                error: 'Đã xảy ra lỗi. Không thể thêm bài viết vào tổ chức. Vui lòng thử lại sau.',
            },
            removeOrganization: {
                content: 'Bạn có muốn xóa khỏi tổ chức không?',
                title: 'Xóa khỏi tổ chức',
                success: 'Bài đăng này đã bị xóa khỏi tổ chức',
            },
            convertToQuestion: {
                title: 'Bạn có chắc chắn không?',
                content: 'Chuyển đổi bài viết thành câu hỏi, bài viết sẽ bị xóa khi hoàn thành. Bạn có chắc chắn để tiếp tục?',
                success: 'Bài viết được chuyển đổi thành câu hỏi thành công.',
                error: 'Đã xảy ra lỗi',
            },
            deletePost: {
                title: 'Xóa bài viết này',
                content: 'Bạn có muốn xóa bài viết này?',
                success: 'Bài đăng đã bị xóa',
                error: 'Đã có lỗi xảy ra!',
            },
            markSpam: {
                title: 'Đánh dấu là spam',
                content: 'Bạn có muốn đánh dấu bài đăng này là spam không',
                success: 'Bài đăng đã bị đánh dấu là Spam. Nó sẽ được xem xét bởi quản trị viên.',
                error: 'Có gì đó không ổn',
                noRight: 'Bạn không thể đánh spam bài viết này vì quản trị viên đã đánh dấu nó không phải spam',
            },
            notSpam: {
                title: 'Báo cáo không phải spam',
                content: 'Bạn có chắc bài đăng này không phải là spam?',
                success: 'Bài viết đã được gỡ spam.',
                error: 'Đã có lỗi xảy ra!',
                tooltipNotSpam: 'Gỡ spam cho bài viết',
                tooltipLink: 'Đi tới bài viết',
            },
            reportNotReady: {
                title: 'Báo cáo chưa sẵn sàng',
                content: 'Bạn có chắc bài đăng này chưa sẵn sàng để xuất bản?',
                success: 'Bài viết được đánh dấu là chưa sẵn sàng để xuất bản.',
                error: 'Đã có lỗi xảy ra!',
            },
            highlightingCode: {
                title: 'Làm nổi bật các đoạn mã',
                content: 'Bạn có muốn làm nổi bật các đoạn mã trong bài đăng này không?',
                success: 'Làm nổi bật các đoạn mã thành công',
                error: 'Đã có lỗi xảy ra!',
            },
            postModeration: {
                title: 'Sửa danh sách thẻ',
                save: 'Lưu',
                placeholder: 'Vui lòng điền thẻ phù hợp cho bài đăng này. Tối đa 5 thẻ',
            },
        },
        postSidebar: {
            contentsTree: {
                title: 'Mục lục',
                empty: 'Không có mục lục',
            },
            series: 'Bài viết thuộc series',
            organization: 'Các tổ chức được đề xuất',
        },
        recommendations: {
            related: 'Bài viết liên quan',
            moreFrom: 'Bài viết khác từ',
        },
    },

    detailQuestion: {
        stats: {
            views: 'Lượt xem',
            clips: 'Bookmark',
            answers: 'Câu trả lời',
        },
        postAnswer: 'Đăng câu trả lời',
        questionMenu: {
            title: 'Hiển thị các hành động',
            edit: 'Sửa',
            report: 'Báo cáo',
            requestAnswer: 'Nhờ chuyên gia trả lời',
            deleteQuestion: 'Xóa câu hỏi này',
            shareToFanpage: 'Chia sẻ lên Fanpage',
            questionModeration: 'Sửa nhanh thẻ',
            highlighting: 'Bội màu code tự động',
        },
        dialogRequestAnswer: {
            title: 'Nhờ trả lời câu hỏi',
            content: 'Vui lòng tìm kiếm và chọn người nhận mà bạn muốn gửi yêu cầu trả lời cho câu hỏi',
            placeholder: 'Tìm kiếm người nhận cho câu hỏi',
            note: 'Lưu ý: Bạn chỉ có thể gửi 3 yêu cầu trả lời mỗi ngày.',
            cancel: 'Hủy bỏ',
            send: 'Gửi',
            confirmRequestAnswer: {
                title: 'Xác nhận Gửi yêu cầu',
                content: 'Bạn có chắc không? Gửi yêu cầu trả lời cho',
            },
            sendRequest: 'Yêu cầu đã được gửi đến',
        },
        confirmDelete: {
            title: 'Xóa câu hỏi này',
            content: 'Bạn có muốn xóa câu hỏi này không?',
            success: 'Câu hỏi đã được xóa thành công!',
        },
        dialogQuestionModeration: {
            title: 'Kiểm duyệt câu hỏi',
            placeholder: 'Vui lòng điền thẻ phù hợp cho câu hỏi này. Tối đa 5 thẻ',
            save: 'Lưu',
            error: 'Đã xảy ra lỗi khi cố gắng cập nhật câu hỏi này',
        },
        highlightCodeAnswer: {
            title: 'Bôi màu code tự động',
            content: 'Bạn có muốn bôi màu code tự động cho nội dung câu trả lời này không?',
            success: 'Thành công! Vui lòng đợi trong giây lát để hệ thống cập nhật nội dung mới.',
        },
        comment: {
            title: 'Thêm một bình luận',
            answers: 'CÂU TRẢ LỜI',
            commentForm: {
                write: 'Viết',
                preview: 'Xem trước',
                writeAResponse: 'Viết bình luận...',
                discard: 'Hủy',
                save: 'Lưu',
                comment: 'Bình luận',
                empty: 'Không có gì để xem trước!',
                comfirmDiscard: {
                    title: 'Bạn chắc chứ',
                    content: 'Những thay đổi bạn đã thực hiện sẽ không được lưu. Bạn có chắc không?',
                },
                emoji: 'Biểu tượng cảm xúc đề xuất',
                image: 'Kéo hình ảnh hoặc nhấp để đính kèm',
                markdown: 'Hỗ trợ Markdown',
                answer: 'Trả lời',
                answering: 'Bạn đang trả lời là:',
                saveComment: 'Lưu bình luận',
                saveAnswer: 'Lưu',
            },
            answerForm: {
                answered: 'Đã trả lời',
                accepted: 'Đã được chấp nhận',
                acceptAnswer: 'Chấp nhận câu trả lời này',
                approveAnswer: 'Chấp thuận cho ',
                reject: 'Từ chối',
            },
            socialShare: {
                title: 'Chia sẻ liên kết đến câu trả lời này',
                button: 'Chia sẻ',
                close: 'Đóng',
            },
            menuAction: {
                title: 'Hiển thị các hành động',
                edit: 'Sửa',
                report: 'Báo cáo',
                deleteAnswer: 'Xóa câu trả lời này',
                highlighting: 'Bôi màu code tự động',
            },
        },
        deleteConfirm: {
            title: 'Xóa câu trả lời',
            content: 'Các câu trả lời đã xóa sẽ biến mất vĩnh viễn. Bạn có chắc không?',
            success: 'Câu trả lời này đã bị xóa.',
            error: 'Đã xảy ra lỗi. Không thể  xóa câu trả lời này.',
        },
        confirmHighlight: {
            title: 'Bôi màu code tự động',
            content: 'Bạn có muốn bôi màu code tự động cho nội dung câu trả lời này không?',
            success: 'Thành công! Vui lòng đợi trong giây lát để hệ thống cập nhật nội dung mới.',
        },
        commentHasNotAccepted: {
            reply: 'Trả lời',
            actionComment: {
                title: 'Hiển thị các hành động',
                edit: 'Sửa',
                report: 'Báo cáo',
                deleteComment: 'Xóa bình luận này',
                convertToAnswer: 'Chuyển đổi thành câu trả lời',
                highlighting: 'Bôi màu code tự động',
            },
            confirmDelete: {
                title: 'Xóa bình luận',
                content: 'Các bình luận đã xóa sẽ biến mất vĩnh viễn. Bạn có chắc không?',
            },
            confirmConvert: {
                title: 'Chuyển câu trả lời này thành câu trả lời',
                content: 'Bình luận này sẽ bị xóa sau đó được đăng dưới dạng Câu trả lời. Bạn có chắc không?',
                success: 'Đã chuyển thành câu trả lời thành công!',
                commentDeleted: 'Bình luận này đã bị xóa',
            },
            confirmHighlight: {
                title: 'Bôi màu code tự động',
                content: 'Bạn có muốn bôi màu code tự động cho nội dung bình luận này không?',
                success: 'Thành công! Vui lòng đợi trong giây lát để hệ thống cập nhật nội dung mới.',
            },
        },
    },

    commentPost: {
        comments: 'Bình luận',
        loading: 'Đang tải thêm bình luận...',
        login: 'Đăng nhập để bình luận',
        noComent: 'Chưa có bình luận nào.',
        postComment: 'Bình luận',
        viewMore: 'Xem thêm',
    },

    activities: {
        title: 'Hoạt Động Của Tôi',
        filterAction: {
            all: 'Tất cả hoạt động',
            clip: 'Bookmark',
            unclip: 'Xóa bookmark',
            upVote: 'Upvote',
            downVote: 'Downvote',
            unVote: 'Unvote',
            accept: 'Chấp nhận',
            delete: 'Xóa',
            upVoteUndo: 'Hoàn tác-Upvote',
            downVoteUndo: 'Hoàn tác-Downvote',
        },
        filterType: {
            all: 'Tất cả các loại',
            post: 'Bài viết',
            question: 'Câu hỏi',
            series: 'Series',
            organization: 'Tổ chức',
            answer: 'Câu trả lời',
            tag: 'Thẻ',
            comment: 'Bình luận',
            user: 'Người dùng',
        },
        search: 'Tìm kiếm',
        table: {
            date: 'Ngày',
            type: 'Kiểu',
            interactableTypes: {
                Post: 'Bài viết',
                User: 'Người dùng',
                Tag: 'Thẻ',
                Question: 'Câu hỏi',
                Organization: 'Tổ chức',
                Series: 'Series',
                Answer: 'Câu trả lời',
                Comment: 'Bình luận',
            },
            activity: {
                title: 'Hoạt Động',
                clip: 'Bookmark',
                unclip: 'Xóa Bookmark',
                follow: 'Theo Dõi',
                unfollow: 'Bỏ Theo Dõi',
                upvoteUndo: 'Hoàn tác/Upvote',
                downvoteUndo: 'Hoàn tác/Downvote',
                delete: 'Xóa',
                accept: 'Chấp Nhận',
                unvote: 'Unvote',
                upvote: 'Upvote',
                downvote: 'Downvote',
                acceptUndo: 'Hoàn Tác Chấp nhận',
            },
            title: 'Tiêu đề',
            actions: 'Hành động',
        },
        vote: {
            comment: {
                cannotVote: 'Bạn không thể  vote cho bình luận của bạn',
            },
            content: {
                cannotVote: 'Bạn không thể  vote cho nội dung của bạn',
            },
            needReputationToVote: 'Bạn cần 50 điểm reputations để vote',
            upvote: 'Upvote',
            downVote: 'Downvote',
        },
        comment: {
            reply: 'Trả lời',
            shareLinkComment: 'Chia sẻ đường dẫn của bình luận này',
            commentAnswer: 'Bình luận trả lời',
        },
    },

    createSeries: {
        title: 'Tiêu đề',
        save: 'Lưu',
        publishPopover: {
            publishSeries: 'Xuất bản series của bạn',
            startPublish: 'Thêm tiêu đề và bắt đâù viết một cái gì đó để xuất bản',
            visibility: 'Hiển thị:',
            public: 'Công khai',
            unlisted: 'Bất kỳ ai có liên kết',
            draft: 'Chỉ mình tôi',
            type: 'series',
            descriptionPublic: 'Mọi người có thể thấy ',
            descriptionSchedule: 'Đặt lịch công khai ',
            descriptionUnlistFirst: 'Chỉ những người có liên kết đến ',
            descriptionUnlistLast: ' này, mới có thể xem được ',
            descriptionDraftFirst: 'Chỉ có bạn mới có thể xem ',
            descriptionDraftLast: ' này. Bản nháp của bạn đã được lưu tự động khi bạn nhập',
            saveDraft: 'Lưu lại',
            publish: 'Xuất bản',
            schedule: 'Đặt lịch',
            selectDateTime: 'Chọn ngày giờ',
        },
        saving: 'Đang lưu',
        cannotSave: 'Không thể lưu series của bạn',
        saved: 'Đã lưu',
        addPosts: {
            title: 'NỘI DUNG SERIES',
            noPostYet: 'Chưa có bài viết nào. Vui lòng thêm bài vào series này để hiển thị trên trang chủ!',
            addPost: 'Thêm bài viết',
            titleDialog: 'Thêm bài viết',
            buttonAdd: 'Thêm bài viết',
            cancel: 'Hủy bỏ',
            notPost: 'Bạn không có bài đăng nào',
            write: 'Đi tới viết một bài',
            edit: 'Sửa',
            remove: 'Xóa',
            titleRemove: 'Xóa bài viết',
            contentRemove: 'Xóa bài viết này khỏi series',
        },
        validations: {
            title: 'Vui lòng nhập tiêu đề series',
            contents: 'Vui lòng nhập nội dung series',
        },
    },

    series: {
        draft: 'Bản nháp - Chỉnh sửa lần cuối',
        unlisted: 'Bất kỳ ai có liên kết - Chỉnh sửa lần cuối',
        published: 'Xuất bản',
        views: 'Lượt xem',
        clips: 'Bookmark',
        comments: 'Bình luận',
        posts: 'Bài viết',
        menuAction: {
            title: 'Hiển thị các hành động',
            edit: 'Sửa',
            report: 'Báo cáo',
            delete: 'Xóa series này',
            share: 'Chia sẻ lên Fanpage',
            highlight: 'Bôi màu code tự động',
        },
        lableShare: 'Chia sẻ liên kết đến series',
        bottom: {
            contents: 'NỘI DUNG',
            postsDraft: 'Bài viết nháp này được ẩn trong series. Vui lòng công khai bài viết này!',
            noPostsYet: 'Series này không có bất kỳ bài đăng nào',
        },
        titleComment: 'BÌNH LUẬN',
        unclip: 'Xóa bookmark',
        clip: 'Bookmark series này',
        unclipTooltip: 'Xóa bookmark series này',
        isOwn: 'Bạn không thể bookmark cho series của bạn',
        author: 'TÁC GIẢ',
        comfirmDelete: {
            title: 'Xóa series',
            content: 'Series đã xóa sẽ biến mất vĩnh viễn. Bạn chắc chứ?',
            success: 'Series đã bị xóa',
        },
        hinghlightCode: {
            title: 'Bôi màu code tự động',
            content: 'Bạn có muốn bôi màu code tự động cho nội dung series này không?',
            success: 'Thành công! Vui lòng đợi trong giây lát để hệ thống cập nhật nội dung mới!',
        },
        createButton: 'Tạo series mới',
    },

    createQuestion: {
        title: 'Tiêu đề',
        contentTag: 'Gắn thẻ câu hỏi của bạn. Tối đa 5 thẻ.',
        create: 'Tạo',
        edit: 'Sửa',
        question: 'Câu hỏi',
        discard: 'Hủy',
        errorUpdate: 'Đã xảy ra lỗi khi cố gắng cập nhật câu hỏi của bạn. Vui lòng thử lại!',
        errorAdd: 'Có lỗi đã xảy ra trong khi lưu câu hỏi của bạn. Xin vui lòng thử lại!',
        validateTitle: 'Tiêu đề câu hỏi là bắt buộc',
        validateTag: 'Thẻ câu hỏi là bắt buộc',
        validateContent: 'Nội dung câu hỏi là bắt buộc',
    },


    seoSetting: {
        title: 'Cài đặt SEO',
        titleForm: 'Tiêu đề',
        placeholderTitle: 'Nhập tiêu đề...',
        description: 'Mô tả',
        placeholderDescription: 'Nhập mô tả...',
        preview: 'Xem trước',
        desktop: 'Xem trước trên máy tính',
        mobile: 'Xem trước trên thiết bị di động',
        save: 'Lưu cài đặt',
        titleEmpty: 'Trường tiêu đề trống',
        titleShort: 'Tiêu đề của bạn quá ngắn',
        titlePerfect: 'Chúc mừng! Tiêu đề của bạn có độ dài lý tưởng để hiển thị trên công cụ tìm kiếm.',
        titleLong: 'Tiêu đề của bạn quá dài và sẽ bị cắt một số từ trong kết quả của Google',
        descriptionEmpty: 'Trường mô tả trống',
        descriptionShort: 'Mô tả của bạn quá ngắn',
        descriptionPerfect: 'Chúc mừng! Mô tả của bạn có độ dài lý tưởng để hiển thị trên công cụ tìm kiếm.',
        descriptionLong: 'Mô tả của bạn quá dài và sẽ bị cắt một số từ trong kết quả của Google',
        success: 'Cài đặt SEO đã được cập nhật thành công!',
        error: 'Có gì đó không ổn, vui lòng thử lại!',
        validateTitle: 'Tiêu đề không được để trống.',
        validateDescription: 'Mô tả không được để trống.',
        validateDescriptionMin: 'Mô tả phải có ít nhất 150 ký tự',
        validateDescriptionMax: 'Mô tả quá dài',
    },

    createPost: {
        missingTags: 'Hãy gắn ít nhất 1 thẻ để được tự động lưu bài viết đã công khai.',
        isTagDifferentFromEvents: 'Bạn cần thêm tối thiểu 1 tag, tối đa 5 tag ngoài tag Sự kiện để lưu bài viết.',
        lastSaved: 'Lưu lần cuối cùng: ',
        save: 'Đã lưu',
        title: 'Tiêu đề',
        tootipTitle: 'Mở bài viết này trong cửa sổ mới',
        lableCategories: 'Danh mục',
        Normal: 'Mặc định',
        Help: 'Trợ giúp',
        Announcement: 'Thông báo',
        lableSystem: 'Danh mục hệ thống',
        Vi_term: 'Điều khoản Tiếng Việt',
        En_term: 'Điều khoản Tiếng Anh',
        Jp_term: 'Điều khoản Tiếng Nhật',
        placeholdertag: 'Gắn thẻ bài viết của bạn. Tối đa 5 thẻ. Ít nhất 1 thẻ!',
        seoSetting: 'Cài đặt SEO',
        changeImage: {
            title: 'Thay đổi ảnh thu nhỏ',
            detailChangeImage: 'Sử dụng hình ảnh chất lượng cao sẽ thu hút nhiều người truy cập vào bài viết của bạn hơn. Theo mặc định, hình ảnh mẫu của Viblo sẽ được sử dụng làm hình ảnh thu nhỏ cho bài viết của bạn.',
            upload: 'Tải lên',
            enter: 'Nhập url ảnh',
            remove: ' Xóa ảnh',
            titleChange: 'Nhập URL hình ảnh',
            contentChange: 'Vui lòng nhập liên kết trực tiếp đến tệp hình ảnh thu nhỏ bên ngoài của bạn:',
            error: 'URL không hợp lệ',
            uploadSuccess: 'Hình ảnh tính năng đã được tải lên thành công!',
            errorUpload: 'Chiều rộng và chiều cao của hình ảnh tối thiểu phải bằng ',
            errorChage: 'Thay đổi thumbnail không thành công',
            titleConfirmRemove: 'Bạn có chắc không?',
            contentConfirmRemove: 'Nếu bạn xóa ảnh này, ảnh thu nhỏ mặc định của Viblo sẽ được sử dụng. Bạn có chắc chắn tiếp tục xóa ảnh hiện tại không?',
            removeSuccess: 'Hình ảnh tính năng đã được xóa thành công!',
            cancel: 'Hủy',
            confirm: 'Xác nhận',
        },
        leaveTitle: 'Bạn chắc chắn không',
        leaveContent: 'Nội dung bài viết của bạn không được lưu. Bạn có muốn rời đi?',
        publishPopover: {
            title: 'Thêm tiêu đề và bắt đầu viết mội cái gì đó để xuất bản',
            addIn: 'Đã thêm vào',
        },
        writeUpTitle: 'Quyền bị từ chối!',
        writeUpContent: 'Hãy giải puzzle trước. Nếu bạn đã từ bỏ, bạn cũng không thể submit write-up lại.',
        saving: 'Đang lưu',
        cannotSave: 'Không thể lưu bài viết này',
        errorAutoSave: {
            title: 'Tự động lưu không thành công',
            content: 'Không thể tự động lưu bài đăng này. Vui lòng lưu nội dung được chỉnh sửa thủ công!',
            confirm: 'OK, tôi sẽ lưu nó theo cách thủ công',
        },
        submitOrganization: {
            success: 'Đã gửi đến tổ chức ',
            error: 'Đã xảy ra lỗi. không thể gửi đến tổ chức',
        },
        removeOrganization: {
            title: 'Hủy submission',
            content: 'Bài viết này sẽ bị xóa khỏi tổ chức. Bạn có chắc không?',
            success: 'Đã xóa khỏi tổ chức',
        },
        licence: {
            title: 'Giấy phép:',
            selectLicense: 'Vui lòng chọn giấy phép thích hợp cho bài viết của bạn:',
            someRights: 'Một số quyền được bảo lưu',
            noRights: 'Không có quyền nào được bảo lưu',
            saveLicence: 'Lưu giấy phép',
            cancel: 'Hủy bỏ',
            warning: 'Đang chạy tự động lưu ... Vui lòng đợi và thử lại!',
            success: 'Giấy phép được cập nhật',
            error: 'Hãy lưu bào viết trước',
            allRightReserved: 'All rights reserved',
            noRightsReserved: 'Không có quyền nào được bảo lưu - Phạm vi công cộng',
            thisWork: 'Bài viết này',
            by: 'bởi',
            isLicensedUnder: 'được cấp phép theo',
            allRight: {
                lable: 'All rights reserved',
                shortName: 'All rights reserved',
            },
            ccBy: {
                lable: 'Chỉ Ghi công',
                shortName: 'CC BY 4.0',
            },
            ccBySA: {
                lable: 'Ghi công, Chia sẻ tương tự',
                shortName: 'CC BY-SA 4.0',
            },
            ccByND: {
                lable: 'Ghi công, Không phái sinh',
                shortName: 'CC BY-ND 4.0',
            },
            ccByNC: {
                lable: 'Ghi công,  Phi thương mại ',
                shortName: 'CC BY-NC 4.0',
            },
            ccByNCSA: {
                lable: 'Ghi công, Phi thương mại, Chia sẻ tương tự',
                shortName: 'CC BY-NC-SA 4.0',
            },
            ccByNCND: {
                lable: 'Ghi công, Phi thương mại, Không phái sinh',
                shortName: 'CC BY-NC-ND 4.0',
            },
            ccCCO: {
                lable: 'Từ bỏ bản quyền',
                shortName: 'CC0 1.0',
            },
            publicDomain: {
                lable: ' Phạm vi công cộng',
                shortName: 'Public Domain',
            },
            rules: {
                all: 'Người khác không thể phân phối, sửa lại, phỏng theo hay xây dựng dựa trên bài viết của bạn mà không có sự cho phép của bạn (hoặc khi được cho phép bởi việc sử dụng hợp lý).',
                by: 'Người khác có thể phân phối, sửa lại, phỏng theo hay xây dựng dựa trên bài viết của bạn miễn là họ có ghi bạn là tác giả của bài viết gốc',
                sa: 'Người khác phải cấp phép cho các phái sinh, tài liệu đã được sửa đổi theo các điều khoản tương tự.',
                nd: 'Người khác có thể sao chép và phân phối bài viết của bạn miễn là họ không thay đổi hoặc tạo ra các bài viết phái sinh.',
                nc: 'Người khác chỉ có thể sử dụng bài viết của bạn cho mục đích phi thương mại.',
                cc0: 'Bạn từ bỏ tất cả bản quyền và các quyền tương tự trong bài viết của mình và bài viết sẽ thuộc phạm vi công cộng.',
                pd: 'Tác phẩm này đã thuộc phạm vi công cộng và không bị giới hạn bản quyền.',
            },
        },
        puplishPopup: {
            title: 'Thêm tiêu đề, chọn ít nhất một thẻ và bắt đầu viết một cái gì đó để xuất bản.',
            publishPost: 'Xuất bản bài viết của bạn',
            contentLicensing: 'Cấp phép nội dung',
            submitOrganization: 'Gửi đến tổ chức',
            removeOrganization: 'Xóa khỏi tổ chức',
            organizationSelecter: {
                title: 'Chọn một tổ chức sẽ được thêm vào',
                cancel: 'Hủy',
                submit: 'Thêm',
            },
            confirmSubmit: {
                title: 'Sẵn sàng để gửi bài đến tổ chức',
                content: 'Điều này sẽ gửi bài viết cho tổ chức. Bạn có chắc bài viết này đã sẵn sàng để xuất bản trong tổ chức không?',
                content1: 'Bạn có chắc chắn gửi ',
                content2: ' này trong tổ chức không?',
            },
        },
        buttonSave: 'Lưu',
        buttonPublish: 'Xuất bản bài viết',
        post: 'bài viết',
        errorMessage: {
            scheduleTimeAfter: 'Thời gian đặt lịch công khai phải sau 20 phút và trước 75 ngày kể từ khi bạn tạo chúng',
        },
    },

    createVideo: {
        title: 'Tiêu đề',
        addVideo: 'Thêm video',
        changeVideo: 'Thay đổi video',
        slideUrl: 'Đường dẫn URL bài thuyết trình',
        placeholdertag: 'Gắn thẻ bài viết của bạn.',
        publishPopover: {
            title: 'Thêm tiêu đề, chọn một video và viết gì đó để  xuất bản',
        },
        noteCanAddVideo: 'Thêm tiêu đề và viết gì đó để  lưu bài viết sau đó thêm một video',
        saving: 'Đang lưu',
        saved: 'Đã lưu',
        canNotSave: 'Cannot save your post',
        addByUrl: 'Thêm video bằng đường dẫn URL',
        addVideoLabel: 'Bạn có thể thêm video bằng liên kết đa phương tiện trên Youtube hoặc Vimeo:',
        endterYourLink: 'Nhập liên kết của bạn (đường dẫn đa phương tiện đầy đủ)',
        addURL: 'Thêm đường dẫn URL',
        noteDirectVideo: 'Trực tiếp công khai trên Youtube, Vimeo.',
        mesageErrorLink: 'Vui lòng nhập đúng liên kết đa phương tiện trên Youtube hoặc Vimeo',
    },

    organization: {
        title: 'Tổ chức',
        sort: {
            label: 'Sắp xếp theo',
            posts: 'Số bài viết',
            users: 'Số thành viên',
            followers: 'Số người theo dõi',
        },
        headingTabs: {
            myOrganizations: 'Tổ  chức của tôi',
            followings: 'Đang theo dõi',
        },
        listJoined: {
            first: 'Bạn chưa theo dõi bất kỳ tổ chức nào. Hãy xem các',
            organization: 'tổ chức',
            last: 'trong Viblo!',
        },
        create: 'Tạo tổ chức',
        companyItem: {
            stast: {
                posts: 'Bài viết: ',
                followers: 'Người theo dõi: ',
                member: 'Thành viên: ',
                location: 'Địa điểm',
            },
            menuAction: {
                writePost: 'Viết bài',
                submissions: 'Bài viết chờ kiểm duyệt',
                manageMembers: 'Quản lý thành viên',
                editOrganization: 'Chỉnh sửa tổ chức',
                deleteOrganization: 'Xóa tổ chức',
                leaveOrganization: 'Thoát khỏi tổ chức',
            },
            confirmDelete: {
                title: 'Xóa tổ chức',
                content: 'Bạn có chắc chắn xóa tổ chức này không?',
            },
            confirmLeave: {
                title: 'Rời khỏi tổ chức',
                content: 'Bạn có chắc chắn rời khỏi tổ chức này không',
            },
        },
        head: 'Tổ chức của tôi - ',
        followings: 'Đang theo dõi',
        follow: 'Theo dõi',
        noFollowers: 'Tổ chức này chưa có người theo dõi.',
        invitations: {
            title: 'Lời mời',
            accept: 'Chấp nhận',
            decline: 'Từ chối',
            invitedBy: 'Được mời bởi',
            invited: 'Đã mời',
        },
        stats: {
            postView: 'Lượt xem bài viết',
            followers: 'Người theo dõi',
            posts: 'Bài viết',
            members: 'Thành viên',
            numberOf: 'Số lượng',
            from: 'có được từ',
            to: 'đến',
        },
        level: {
            gold: 'Vàng',
            silver: 'Bạc',
            diamond: 'Kim cương',
            diamondDescription: 'Nhà sáng tạo nội dung hạng Kim Cương',
            goldDescription: 'Nhà sáng tạo nội dung hạng Vàng',
            silverDescription: 'Nhà sáng tạo nội dung hạng Bạc',
        },
    },

    detailOrgaization: {
        menu: {
            posts: 'Bài viết',
            submissions: 'Nội dung chờ kiểm duyệt',
            members: 'Thành viên',
            followers: 'Người theo dõi',
            aboutUs: 'Về chúng tôi',
            stats: 'Thống kê',
            invitations: 'Lời mời',
        },
        edit: 'Sửa',
        following: 'Đang theo dõi',
        follow: 'Theo dõi',
        stast: {
            newPosts: 'Bài viết mới',
            postViews: 'Lượt xem',
            posts: 'Bài viết',
            followers: 'Người theo dõi',
            members: 'Thành viên',
        },
        notApproved: 'Tổ chức chưa được Quản trị viên phê duyệt. Sau khi được phê duyệt, bạn có thể viết bài, mời thành viên, v.v. Nếu bị từ chối, nó cũng sẽ bị xóa.',
        noMember: 'Tổ chức này chưa có thành viên nào.',
        membersList: 'Danh sách thành viên',
        technology: 'Thẻ Công nghệ',
        noTechnology: 'Chưa có công nghệ.',
        wiewAdminNoTechnology: 'Cập nhật ngay',
        dialogTag: {
            title: 'Cập nhật thẻ Công nghệ',
            placeholder: 'Chọn thẻ Công nghệ',
            save: 'Lưu',
            success: 'Thành công',
            error: 'Đã xảy ra lỗi',
        },
        about: {
            fullDescription: 'Tổ chức này chưa cập nhật mô tả đầy đủ!',
            edit: 'Sửa',
        },
        darkMode: 'Chế độ tối',
        lightMode: 'Chế độ sáng',
    },

    inviteInOrgaization: {
        title: 'Mời thành viên',
        fistContent: 'Bạn có thể mời các thành viên tham gia tổ chức',
        lastContent: '',
        formSearch: 'Tìm kiếm người dùng để mời',
        formRole: 'Xét quyền cho người dùng',
        invite: 'Mời',
        success: 'Đã mời',
        error: 'Người dùng đã được mời vào tổ chức.',
        valdateError1: 'Vui lòng điền thông tin đầu vào để mời người dùng vào tổ chức này.',
        valdateError2: 'Người dùng đã được mời vào tổ chức.',
        invitations: 'Lời mời',
        cancel: 'Hủy',
        writer: 'Người viết',
        editor: 'Biên tập viên',
        admin: 'Quản trị viên',
        owner: 'Chủ sở hữu',
        allRole: 'Tất cả vai trò',
        confirmCancel: {
            title: 'Bạn có chắc không',
            content: 'Hủy lời mời',
            success: 'Lời mời đã bị hủy!',
        },
        confirmRemove: {
            title: 'Bạn có chắc không',
            content: 'Xóa thành viên',
        },
        confirmChangeRole: {
            title: 'Đổi vai trò',
            success: 'Vai trò của thành viên đã được thay đổi!',
            setOwner: 'Nếu bạn đặt ',
            as: ' là ',
            roleOwner: ' bạn sẽ được đặt làm quản trị viên và bạn không phải là chủ sở hữu của tổ chức này. Bạn có chắc không?',
            set: 'Đặt ',
        },
        removeMember: {
            succes: 'Thành viên này đã bị xóa',
            error: 'Đã có lỗi xảy ra.',
        },
        existingMember: {
            title: 'Thành viên hiện có',
            search: 'Tìm kiếm thành viên',
            table: {
                name: 'Tên',
                role: 'Vai trò',
                action: 'Hành động',
            },
        },
        invited: 'Đã mời',
    },

    detailTag: {
        following: 'Đang theo dõi',
        follow: 'Theo dõi',
        posts: 'Bài viết',
        series: 'Series',
        questions: 'Câu hỏi',
        followers: 'Người theo dõi',
        topTag: 'Tag QA Hàng Đầu',
        popularTag: 'Tag Phổ Biến',
        allTag: 'Tất cả tags',
    },

    createOrganization: {
        title: 'Tạo tổ chức mới',
        alert: 'Tổ chức mới được tạo phải được Quản trị viên phê duyệt. Sau đó, bạn có thể viết bài, mời thành viên, v.v. Nếu bị từ chối, nó cũng sẽ bị xóa.',
        inputName: {
            title: 'Tên',
            required: 'Tên là bắt buộc',
            max: 'Tên không được dài hơn 255 ký tự',
        },
        inputTrackingID: {
            title: 'Mã theo dõi (Google Analytics)',
            max: 'Mã theo dõi không được dài hơn 20 kí tự',
        },
        googleAnalyticHelp: 'Bạn có thể sử dụng mã theo dõi google analytics v3 hoặc v4',
        inputURL: {
            title: 'URL',
            required: 'URL là bắt buộc',
            max: 'URL không được dài hơn 255 ký tự',
        },
        inputWebsite: {
            title: 'Trang web',
            max: 'Trang web không được dài hơn 253 ký tự',
            type: 'URL không hợp lệ',
        },
        inputLocation: {
            title: 'Vị trí',
            required: 'Vị trí là bắt buộc',
            max: 'Vị trí không được dài hơn 255 kí tự',
        },
        inputShotDescription: {
            title: 'Mô tả ngắn',
            required: 'Mô tả ngắn là bắt buộc',
            max: 'Mô tả ngắn không được dài hơn 500 ký tự',
            placeholder: 'Mô tả ngắn gọn về tổ chức của bạn ...',
        },
        inputFullDescription: {
            title: 'Giới thiệu về tổ chức này',
            required: 'Bài viết này là bắt buộc',
            min: 'Bài viết này phải có ít nhất 200 ký tự',
            max: 'Bài viết này không được dài hơn 5000 ký tự',
            placeholder: 'Cú pháp markdown được hỗ trợ! Ví dụ:\n'
            + 'Đến dòng tiếp theo, sử dụng HTML <br> hoặc Nhập hai lần!\n'
            + 'Tiêu đề h1~h6, sử dụng #, ##, ...!\n'
            + 'In đậm văn bản, sử dụng **text**\n',
        },
        verify: {
            first: 'Bạn cần phải',
            between: 'xác minh quyền sở hữu trang web của mình ',
            last: 'trước khi trang web của bạn được hiển thị với mọi người.',
        },
        save: 'Lưu',
        create: 'Tạo',
        update: 'Cập nhật',
        requiredAvt: 'Logo của tổ chức là bắt buộc',
        confirmEditSlug: {
            title: 'Hãy cẩn thận. Đổi tên url của tổ chức có thể có tác động khác không mong muốn. Mọi người không thể truy cập vào tổ chức của bạn bằng URL cũ. Bạn có chắc chắn để tiếp tục?',
            content: 'Bạn có chắc chắn không',
        },
        updatesuccess: 'Thành công',
        layout: {
            title: 'Bố cục trang',
            detail: 'Bạn hãy chọn mẫu layout sẽ được áp dụng trên trang subdomain tổ chức của mình.',
            placeholder: 'Chọn bố cục hiển thị',
            options: {
                default: 'Bố cục 1',
                layoutSubdomain2: 'Bố cục 2',
            },
        },
    },

    verifyOrganization: {
        verified: 'Tổ chức này đã được xác minh bởi Viblo.',
        noVerify: 'Xác minh quyền sở hữu',
        firstTitle: 'Làm theo hướng dẫn bên dưới để xác minh quyền sở hữu trang web ',
        lastTitle: 'của bạn.',
        strategies: 'Phương Pháp',
        htmlFile: {
            title: 'HTML File',
            step1: 'Bước 1: Tải file HTML',
            here: 'ở đây',
            step2: 'Bước 2: Tải tệp HTML ở trên lên máy chủ web với tên miền của bạn.',
            step3: 'Bước 3: Nhấp vào nút xác minh ở bên dưới',
            button: 'Xác minh',
        },
        htmlTag: {
            title: 'HTML <meta> Tag',
            step1: 'Bước 1: Copy đoạn mã bên dưới.',
            step2: 'Bước 2: Dán đoạn mã trên vào trong <head> phần trang chủ của trang web của bạn',
            step3: 'Bước 3: Nhấp vào nút xác minh bên dưới',
        },
        dnsRecord: {
            title: 'DNS record',
            step1: 'Bước 1: Truy cập vào trang quản lý DNS của tên miền của bạn.',
            step2: 'Bước 2: Chọn để thêm bản ghi mới',
            step3: 'Bước 3: Thêm TXT bản ghi mới như sau:',
            step4: 'Bước 4: Nhấp vào nút xác minh bên dưới.',
        },
        email: {
            title: 'Mail',
            step1: 'Chúng tôi hỗ trợ xác minh trang web bằng cách gửi email kích hoạt đến một số địa chỉ email nhất định.',
            step2: 'Vui lòng chọn một địa chỉ email dưới đây để tiếp tục:',
            sent: {
                firstTitle: 'Email xác minh đã được gửi đến địa chỉ mail đã chọn của bạn. Vẫn chưa nhận được bất kỳ email nào?',
                betweenTitle: 'Bấm vào đây',
                lastTitle: 'để gửi lại email xác minh.',
            },
            success: 'Đã gửi email! Vui lòng làm theo hướng dẫn trong email để tiến hành xác minh.',
            error: 'Đã xảy ra sự cố và không thể gửi email. Vui lòng thử lại sau.',
        },
        success: 'Xác minh thành công',
        warning: 'Chúng tôi không thể xác minh quyền sở hữu trang web của bạn. Vui lòng kiểm tra lại các bước xác minh của bạn.',
        error: 'Chúng tôi không thể tiến hành xác minh ngay bây giờ. Vui lòng thử lại sau.',
    },

    statsOrganization: {
        title: 'Số liệu thống kê cho',
        overview: {
            title: 'Tổng quan',
        },
        contribution: {
            stt: 'STT',
            title: 'Đóng góp',
            search: 'Tìm kiếm thành viên',
            table: {
                name: 'Tên',
                reputations: 'Reputations',
                tootipReputations: 'Reputations: ',
                publish: 'Bài viết đã xuất bản',
                inOrganization: ' Trong tổ chức',
                organizationPosts: 'Bài viết trong tổ chức: ',
                allIn: 'Tất cả trong Viblo',
                toolTipAllIn: 'Bài viết: ',
                postViews: 'Lượt xem',
                tootipPostView: 'Lượt xem: ',
                answers: 'Câu trả lời',
                tootipAnswers: 'Câu trả lời: ',
            },
        },
        date: {
            thisMonth: 'Tháng hiện tại',
            lastMonth: 'Tháng trước',
            twoMonths: '2 tháng trước',
        },
    },

    discussions: {
        title: 'THẢO LUẬN',
        noDiscussions: 'Chưa có cuộc thảo luận nào.',
        answer: {
            isOwner: 'đã bình luận câu trả lời của bạn trong câu hỏi',
            default: 'đã bình luận câu trả lời trong câu hỏi',
        },
        question: {
            isOwner: 'đã nhận xét câu hỏi của bạn',
            default: 'đã nhận xét cho câu hỏi',
        },
        post: {
            isOwner: 'đã bình luận bài viết của bạn',
            default: 'đã bình luận cho bài viết',
        },
        series: {
            isOwner: 'đã bình luận series của bạn',
            default: 'đã bình luận cho series',
        },
        default: 'bình luận về',
        of: 'của',
    },

    dialogRegister: {
        title: 'Hãy đăng ký một tài khoản Viblo để nhận được nhiều bài viết thú vị hơn.',
        register: 'Đăng kí',
        login: 'Đăng nhập',
        createAccount: 'Tạo tài khoản mới',
        seeMore: 'Học tập và chia sẻ với',
        forgotPassword: 'Quên mật khẩu ?',
        or: 'hoặc',
        skip: 'Bỏ qua',
    },

    dialogNoAvt: {
        title: 'Bạn chưa có ảnh đại diện',
        content: 'Đã đến lúc có một ảnh đại diện mới trên hồ sơ của bạn. Chỉ 1 bước để dễ dàng tạo ảnh đại diện tuyệt đẹp từ ảnh trong Tài khoản Viblo.',
        button: 'OK, tôi hiểu rồi!',
    },

    dialogExternalSurvey: {
        title: 'Khảo sát ý kiến người dùng',
        content: 'Chào bạn, hiện tại Viblo chúng mình đang tham khảo ý kiến người dùng để cải thiện chất lượng dịch vụ, bạn bỏ chút thời gian làm khảo sát cùng chúng mình nhé ^^!',
        confirmBtn: 'Bắt đầu',
        cancelBtn: 'Hủy',
    },

    dialogUpload: {
        title: 'Thêm hình ảnh thu nhỏ',
        dropzone: 'Kéo và thả tập tin ở đây hoặc nhấp chuột để tải tập tin lên',
        watermark: 'Chèn hình mờ Viblo',
        yourPhoto: 'Ảnh của bạn',
        cancel: 'Hủy',
        updateProfilePicture: 'Cập nhật hình ảnh đại diện',
    },

    dialogThumbnail: {
        thumbnailTitle: 'Mẫu sẵn có',
        thumbnailContent: 'Vui lòng chọn mẫu thumbnail cho bài viết này:',
        empty: 'Không có mẫu nào để xem trước',
    },

    dialogDetect: {
        title: 'Chuyển đổi hình ảnh sang LaTeX',
        dropzone: 'Giữ và thả, hoặc dán (Ctrl/Command + V) một tập tin ảnh ở đây, hoặc nhấp chuột để chọn tập tin',
        result: 'Kết quả:',
        done: 'Hoàn thành',
        notDetectFomulas: 'Không tìm thấy kết quả, vui lòng thử lại!',
        note: '(*) Vui lòng lưu ý, phiên bản hiện tại hoạt động tốt nhất với các công thức đơn dòng; những công thức phức tạp hơn sẽ được hỗ trợ trong các phiên bản tiếp theo.',
        description: 'Một công cụ dễ dàng sử dụng giúp chuyển đổi hình ảnh công thức toán học sang LaTeX.',
    },

    dialogReadingTool: {
        tooltip: 'Công cụ đọc',
        fontSize: 'Cỡ chữ',
        lineHeight: 'Độ cao hàng',
        reset: 'Đặt lại',
        default: 'Mặc định',
        fullScreen: 'Toàn màn hình',
        colorMode: 'Màu nền',
    },

    topAuthors: {
        title: 'Tác giả hàng đầu',
        sortBy: 'Sắp xếp theo',
        sortOptions: {
            reputation: 'Reputations',
            followers: 'Người theo dõi',
            posts: 'Bài viết',
        },
        rangeFilter: {
            week: 'Tuần',
            month: 'Tháng',
            all: 'Tất cả',
        },
    },

    editor: {
        placeholder: 'Cú pháp Markdown được hỗ trợ. Nhấp vào ？ để xem hướng dẫn\n'
            + 'Để xuống dòng, sử dụng thẻ <br> hoặc nhấn Enter hai lần\n'
            + 'Nhấp vào 👁 hoặc nhấn "Ctrl + P" bật/tắt chế độ xem trước\n'
            + 'Nhấp vào ▯▯ hoặc nhấn "F9" để bật/tắt chế độ xem trước song song với soạn thảo\n'
            + 'Nhấp vào 🕂 hoặc nhấn "F11" để  bật/tắt chế độ toàn màn hình \n'
            + 'Để highlight các đoạn code, hãy viết thêm tên viết thường của ngôn ngữ sau ba dấu gạch ngược (ví dụ: ```ruby)',
        lines: 'Dòng',
        words: 'Từ',
        upload: {
            uploading: 'Đang tải lên',
            fileUploading: 'Đang tải tập tin lên',
        },
        emoji: {
            title: 'Biểu tượng cảm xúc',
            categories: {
                search: 'Kết quả tìm kiếm',
                recent: 'Thường xuyên sử dụng',
                people: 'Biểu tượng mặt cười & Con người ',
                nature: 'Động vật & thiên nhiên',
                foods: 'Đồ ăn & Thức uống',
                activity: 'Hoạt động',
                places: 'Du lịch & Địa điểm',
                objects: 'Đồ vật',
                symbols: 'Ký hiệu',
                flags: 'Cờ',
                custom: 'Tùy chọn',
            },
            notFound: 'Không tìm thấy',
            search: 'Tìm kiếm',
            pick: 'Chọn biểu tượng',
        },
        actions: {
            link: 'Chèn đường dẫn (Ctrl-K)',
            image: 'Chèn ảnh (Ctrl-Alt-I)',
            embed: 'Nhúng nội dung',
            help: 'Hỗ trợ Markdown',
            shortcut: 'Bảng phím tắt',
            emoji: 'Biểu tượng cảm xúc',
            alignCenter: 'Căn giữa nội dung (Ctrl-Shift-E)',
            alignLeft: 'Căn trái nội dung (Ctrl-Shift-L)',
            alignRight: 'Căn phải nội dung (Ctrl-Shift-R)',
            fullscreen: 'Chế độ toàn màn hình (F11)',
            sideBySide: 'Chế độ xem trước (F9)',
            embedPopup: {
                title: 'Nhúng nội dung',
                label: 'Thêm đường dẫn nhúng',
                ok: 'Chèn',
                cancel: 'Hủy',
                invalid: 'Đường dẫn không hợp lệ. Chỉ chấp nhận các đường dẫn từ: Youtube, Vimeo, Slideshare, Codepen, Gist, JsFiddle, GoogleSlide, Soundcloud.',
            },
            linkPopup: {
                title: 'Chèn liên kết',
                label: 'Nhập liên kết tại đây:',
                ok: 'Chèn',
                cancel: 'Hủy',
                invalid: 'Liên kết không hợp lệ.',
                link: 'Liên kết',
                placeholder: 'Vui lòng chèn một liên kết hoặc tìm kiếm từ các bài viết của bạn',
            },
            detectMatex: 'Chuyển đổi hình ảnh sang LaTeX',
            invalidImage: 'Định dạng ảnh không hợp lệ vui lòng thử lại',
        },
    },

    dialogMarkdownHelp: {
        title: 'Markdown Help',
        linkFull: 'Link Đầy Đủ: ',
        clickto: 'Nhấp chuột để xem thêm',
        row1: {
            component: 'In đậm',
            markdown: '**In đậm** (Không có khoảng trống sau khi mở và trước khi đóng)',
        },
        row2: {
            component: 'Dòng tiếp theo',
            markdown: 'Sử dụng thẻ HTML <br> hoặc ấn nút Enter hai lần',
        },
        row3: {
            component: 'In nghiêng',
            markdown: '*In nghiêng* (Không có khoảng trống sau khi mở và trước khi đóng)',
        },
        row4: {
            component: 'Inline code',
            markdown: 'Inline code',
        },
        row5: {
            component1: 'var',
            component2: ' s = \'Đánh dấu cú pháp\';',
            markdown1: '```javascript',
            markdown2: 'var s = \'Đánh dấu cú pháp \';',
            markdown3: '```',
            markdown4: 'Nếu bạn muốn bao gồm cả ngôn ngữ và tên tệp',
            markdown5: '```javascript:app/index.js',
            markdown6: 'var s = \'Syntax Highlighting\';',
            markdown7: 'Chúng tôi hỗ trợ 64 ngôn ngữ như PHP, JavaScript, XML, YAML ...',
            markdown8: 'Đọc thêm.',
        },
        row6: {
            component: 'Liên kết',
            markdown: '[Liên kết](http://~)',
        },
        row7: {
            component: 'Hình ảnh',
        },
        row8: {
            component: 'Tiêu đề (h1~h6)',
            markdown1: '# h1 (1 dấu cách sau #)',
            markdown2: '## h2 (1 dấu cách sau ##)',
            markdown3: '### h3 (1 dấu cách sau ###)',
        },
        row10: {
            component: 'Danh sách không theo thứ tự',
            markdown: '* Item (1 dấu cách sau *)',
        },
        row11: {
            component: 'Danh sách theo thứ tự 1, 2, 3',
        },
        row12: {
            component: 'Horizontal rules',
        },
        row13: {
            component: 'Blockquotest',
            markdown: '> Blockquotes',
        },
        row14: {
            component: 'Bảng',
            markdown1: '| Cột 1 | Cột 2 |',
            markdown2: '| - | - |',
            markdown3: '| Chữ | Chữ |',
        },
        row15: {
            component: 'Escape markdown',
        },
        row16: {
            component: 'Nhúng nội dung',
            markdown1: '{@embed: URL embed của bạn}',
            markdown2: '(URL embed được hỗ trợ: Youtube, Vimeo, Slideshare, Codepen, Gist, JsFiddle, Google Slide, Soundcloud)',
        },
        row17: {
            component: 'Công thức toán học',
            markdown1: 'Inline: $f(x) = x$',
            markdown2: '(Không có dấu cách sau khi mở hoặc trước khi đóng)',
            markdown3: 'Block:',
            markdown4: '(Xem thêm)',
        },
        row18: {
            component: 'Cú pháp căn chỉnh',
            markdown1: 'Hoán đổi nội dung bên trong thẻ HTML (div, p) với ',
            markdown2: 'align',
            markdown3: 'attribute. Example:',
            markdown4: '<div align="left|right|center|justify">',
            markdown5: '  [Nội dung của bạn ở đây]',
            markdown6: '</div>',
            markdown7: '(Một số cú pháp Markdown có thể không hoạt động bên trong thẻ HTML khi căn chỉnh.)',
        },
    },

    shortcutCheatsheet: {
        title: 'Bảng phím tắt',
        shortcut: 'Phím tắt',
        description: 'Mô tả',
        row1: {
            description: 'Chuyển chế độ trích dẫn',
        },
        row2: {
            description: 'Chuyển chế độ in đậm',
        },
        row3: {
            description: 'Clean Block',
        },
        row4: {
            description: 'Chuyển về chế độ tiêu đề nhỏ hơn',
        },
        row5: {
            description: 'Chuyển chế độ in nghiêng',
        },
        row6: {
            description: 'Thả đường link',
        },
        row7: {
            description: 'Chuyển chế độ danh sách không theo thứ tự',
        },
        row8: {
            description: 'Chế độ xem trước',
        },
        row9: {
            description: 'Lưu',
        },
        row10: {
            description: 'Chuyển chế độ Codeblock',
        },
        row11: {
            description: 'Thả hình ảnh',
        },
        row12: {
            description: 'Chuyển chế độ danh sách theo thứ tự',
        },
        row13: {
            description: 'Chuyển về chế độ tiêu đề lớn hơn',
        },
        row14: {
            description: 'Chuyển chế độ Side Byside',
        },
        row15: {
            description: 'Chuyển chế độ toàn màn hình',
        },
        row16: {
            description: 'Căn trái nội dung',
        },
        row17: {
            description: 'Căn phải nội dung',
        },
        row18: {
            description: 'Căn giữa nội dung',
        },
    },

    stats: {
        answers: 'Trả lời',
        comments: 'Bình luận',
        clips: 'Bookmark',
        posts: 'Bài viết',
        views: 'Lượt xem',
        votes: 'Votes',
        suggestion: {
            question: {
                vote: 'Vote: ',
                bookmark: 'Bookmark: ',
                comment: 'Bình luận: ',
                view: 'Lượt xem: ',
            },
            challenge: {
                score: 'Điểm: ',
                completed_times: 'Tỉ lệ hoàn thành: ',
                trophied_count: 'Tỉ lệ nhận cúp: ',
            },
            course: {
                view: 'Lượt xem: ',
                user_count: 'Số lượng học viên: ',
                question_count: 'Tổng số câu hỏi: ',
                test_count: 'Tổng số luợt thi: ',
            },
            puzzle: {
                score: 'Điểm: ',
                completed_times: 'Số người hoàn thành: ',
            },
        },
    },

    search: {
        title: 'Tìm kiếm',
        results: 'kết quả',
        posts: 'Bài viết',
        questions: 'Câu hỏi',
        authors: 'Tác giả',
        tags: 'Thẻ',
        suggestions: {
            tags: {
                followers: 'Người theo dõi',
                posts: 'Số bài viết',
            },
            posts: {
                posted: 'đăng vào',
            },
            users: {
                followers: 'Người theo dõi',
                posts: 'Số bài viết',
            },
            questions: {
                asked: 'đăng lúc',
            },
        },
        searching: 'Đang tìm kiếm',
        not_found: 'Không có kết quả nào',
        sort: {
            label: 'Sắp xếp theo',
            best_match: 'Phù hợp nhất',
            clipped: 'Lượt bookmark giảm dần',
            viewed: 'Lượt xem giảm dần',
            rated: 'Lượt vote giảm dần',
            commented: 'Lượt bình luận giảm dần',
            newest: 'Mới nhất',
            oldest: 'Cũ nhất',
        },
        guide: {
            label: 'Cú pháp tìm kiếm',
            title: 'Bao gồm "Git" trong tiêu đề',
            body: 'Bao gồm "Ruby" trong nội dung',
            code: 'Bao gồm "function" trong code',
            tag: 'Có chứa thẻ "Rails"',
            not_tag: 'Không bao gồm các thẻ "Ruby" và "Rails"',
            user: 'Được tạo bởi người dùng "name"',
            clip: 'Được bookmark nhiều hơn 3 lần',
            views: 'Được xem ít nhất 10 lần',
            rate: 'Có ít nhất 10 lượt vote',
            created: 'Được tạo sau ngày 2016-09-30',
            updated: 'Được cập nhật trong khoảng 2016-09-01 và 2016-09-30',
            contain: 'Bao gồm "Ruby" hoặc "Rails"',
            not_contain: 'Không bao gồm "php"',
            title_or_body: 'Chứa cụm từ "Js performance" trong tiêu đề hoặc nội dung',
            language: 'Lọc bài viết theo ngôn ngữ (en, vi, ja)',
        },
    },

    notification: {
        label: 'Thông báo',
        noNew: 'Không có thông báo mới',
        settingsUpdated: 'Cài đặt đã được cập nhật',
        options: {
            label: 'Tùy chọn',
            markAsRead: 'Đánh dấu tất cả là đã đọc',
            deleteAll: 'Xóa tất cả',
            settings: 'Cài đặt thông báo',
        },
    },

    rss: {
        title: 'Chức năng RSS của Viblo',
        description: 'RSS (Really Simple Syndication) là một loại tệp XML giúp cho người dùng có thể cập nhận tin tức thông qua các kênh thông tin (feeds). Nguyên tắc của RSS là tối giản nên nội dung của nó được rút gọn chỉ chứa các mục quan trọng như tiêu đề, tác giả, nội dung tóm tắt, ngày tháng... để bạn có thể cập nhật thông tin một cách nhanh nhất mà không cần trực tiếp truy cập vào trang web. Bạn có thể tổng hợp thông tin từ nhiều nguồn thông qua các trình đọc RSS chuyên dụng.',
        posts: {
            title: 'Bài viết',
            newest: 'Mới nhất',
            trending: 'Trending',
            editorsChoice: 'Lựa chọn của biên tập viên',
            video: 'Video',
            mayfest: 'Viblo May Fest',
        },
        questions: {
            title: 'Câu hỏi',
            newest: 'Mới nhất',
            unsolved: 'Chưa được giải quyết',
        },
        topic: 'Chủ đề',
        seeAll: 'Xem tất cả',
        authors: 'Tác giả',
        organizations: 'Tổ chức',
    },

    headSeo: {
        following: 'Bài viết đang theo dõi - Viblo',
        followingDefault: 'Đang theo dõi',
        newest: 'Bài viết mới nhất - Viblo',
        newestDefault: 'Các bài viết mới nhất',
        series: 'Series bài viết',
        announcements: 'Thông báo',
        clipPost: 'Bài viết được bookmark',
        clipSeries: 'Series được bookmark',
        clipQuestion: 'Câu hỏi được bookmark',
        questionsNewest: 'Câu hỏi mới nhất',
        questionsUnsolved: 'Câu hỏi chưa giải quyết',
        questionsFollowings: 'Câu hỏi đang theo dõi',
        questionsAsk: 'Đặt câu hỏi',
        editorsChoice: 'Editor\'s Choice',
        trending: 'Bài viết trending',
        discussion: 'Thảo luận',
        translation: 'Bài dịch',
        homePageTitle: 'Viblo | Dịch vụ miễn phí để chia sẻ kiến thức kỹ thuật',
        defaultDescription: 'Nền tảng Công nghệ Bài báo từ Châu Á, chứa đầy thông tin mới nhất về Ngôn ngữ và framework lập trình. Ruby on Rails / PHP / Swift / Unity / Java /.Net',
        contentCreatorPost: 'Nhà sáng tạo nội dung',
    },

    titleEmoji: 'Đề xuất biểu tượng cảm xúc',
    vueTour: {
        createContent: 'Nhấp chuột vào đây để tạo một bài viết, câu hỏi hoặc loạt bài mới',
        seeQuestions: 'Nhấp chuột vào đây để xem các câu hỏi mới nhất',
        previewMode: 'Bạn có thể tùy chỉnh hiển thị các bài viết chỉ với tiêu đề  hoặc hiển với nội dung xem trước',
        sendFeebback: 'Nhấp chuột vào đây để gửi phản hồi cho chúng tôi',
        gotoSetting: 'Nhấp chuột vào đây đi tới cài đặt',
        skip: 'Bỏ qua',
        previous: 'Bước trước',
        next: 'Bước tiếp',
        finish: 'Hoàn thành',
        enterTitle: 'Nhập tiêu đề  ở đây',
        enterTag: 'Thêm các thẻ liên quan đến bài viết tại đây',
        changeImage: 'Nhấp chuột vào đây để chọn hiển thị hình ảnh mặc định',
        selectlicense: 'Nhấp chuột vào đây để chọn giấy phép phù hợp và publish bài viết',
        markdownHelp: 'Nhấp chuột vào đây để tìm hiểu về cú pháp markdown',
        enterContent: 'Nhập nội dung tại đây',
    },

    pageError: {
        error404: {
            title: 'IS ENCOUNTERING SOME PROBLEMS.',
            status: '404 - Not Found.',
        },
        error403: {
            status: '403 - Forbidden.',
            content1: 'Hãy tiếp tục giải (hoặc bỏ cuộc)',
            content2: 'câu đố này',
            content3: 'tại Viblo CTF trước để đọc được bài viết này!',
        },
        error503: 'IS CURRENTLY IN THE MAINTENANCE MODE.',
    },

    titleSeries: 'Series không có bài viết nào sẽ chỉ được hiển thị trên trang cá nhân của người dùng và sẽ không được hiển thị ở đây.',
    empty: 'Không có gì ở đây cả',
    common: {
        alert: {
            warning: 'Cảnh báo:',
        },
        actions: {
            remove: 'Xóa',
        },
    },

    reviewContents: {
        postsSpam: {
            title: 'Tiêu đề',
            author: 'Tác giả',
            published: 'Xuất bản lúc',
        },
    },

    popUpSocialShare: {
        content: 'Nội dung:',
        titleDefault: 'Chia sẻ lên Fanpage của Viblo',
        rankingScore: 'Điểm xếp hạng',
        placeholderContent: 'Nội dung của bài viết',
        placeholderRankScore: 'Điểm xếp hạng của bài viết',
        detailRankScore: {
            content1: 'Điểm',
            content2: 'xếp hạng',
            content3: 'là một số từ 0 đến 1, được sử dụng để đánh giá mức độ ưu tiên của các bài viết được chia sẻ trên fan page.',
            content4: 'Điểm đánh giá càng cao, ưu tiên càng cao.',
        },
        cancel: 'Huỷ bỏ',
        add: 'Thêm',
        updateSuccess: 'Cập nhật thành công!',
        updateError: 'Đã có lỗi xảy ra. Không thể cập nhât bài viết này',
        handleDelete: {
            title: 'Xóa',
            content: 'Xóa bài viết này?',
            success: 'Bài viết đã bị xóa khỏi danh sách!',
            error: 'Đã xảy ra lỗi. Không thể xóa bài viết này!',
        },
        shareSuccess: 'Bài viết đã được thêm vào danh sách chia sẻ lên Facebook!',
        shareError: 'Đã xảy ra lỗi. Không thể chia sẻ bài viết này.',
        shareQuestionSuccess: 'Câu hỏi đã được thêm vào danh sách chia sẻ lên Facebook!',
        shareQuestionError: 'Đã xảy ra lỗi. Không thể chia sẻ câu hỏi này.',
        shareSeriesSuccess: 'Series đã được thêm vào danh sách chia sẻ lên Facebook!',
        shareSeriesError: 'Đã xảy ra lỗi. Không thể chia sẻ series này.',
        validations: {
            requiredContent: 'Nội dung là bắt buộc',
            requiredRankScore: 'Điểm xếp hạng là bắt buộc',
        },
    },

    seeNewPosts: {
        firstContent: 'Xem',
        lastContent: 'bài viết mới',
    },

    confirmInvitation: {
        title: 'Xác nhận lời mời',
        titleAlert: 'Thông tin:',
        content1: 'Bạn đã được mời tham gia tổ chức',
        content2: 'với tư cách là',
        content3: 'bởi',
        accept: 'Chấp nhận',
        decline: 'Từ chối',
    },

    pwa: {
        offline_warning: 'Bạn đang ngoại tuyến. Một số chức năng có thể không khả dụng.',
        new_version_available: 'Đã có phiên bản mới!',
        refresh: 'Làm mới',
    },

    authorsRecommend: {
        title: 'TÁC GIẢ ĐỀ XUẤT',
        content: 'Bạn cần theo dõi nhiều tác giả hơn, chúng tôi có thể giới thiệu thêm cho bạn',
        seeMore: 'Xem thêm >>>',
    },

    tagRecommend: {
        title: 'TAG ĐỀ XUẤT',
        content: 'Bạn cần theo dõi thêm các thẻ, chúng tôi có thể giới thiệu thêm cho bạn',
    },

    userMenu: {
        edit: 'Sửa',
        signOut: 'Đăng xuất',
    },

    pinAnnouncement: {
        success: 'Đã ghim thành công bài thông báo',
        error: 'Đã có lỗi xảy ra vui lòng thử lại',
        tooltip: 'Ghim thông báo này',
        tooltipUnPin: 'Bỏ ghim thông báo này',
        unPinSuccess: 'Bỏ ghim thông báo thành công',
    },

    confirm: {
        ok: 'Đồng ý',
        cancel: 'Hủy bỏ',
    },

    deleteVideo: {
        title: 'Xóa video',
        content: 'Video đã tải lên sẽ bị xóa vĩnh viễn. Bạn có chắc không?',
    },

    deleteNotification: {
        title: 'Xóa thông báo ',
        content: 'Bạn có muốn xóa tất cả các thông báo có sẵn không?',
    },

    deleteImage: {
        title: 'Bạn có chắc chắn muốn xóa hình ảnh này không?',
        cancel: 'Hủy bỏ',
        confirm: 'Xác nhận',
    },

    copyURL: 'Sao chép URL',

    questions: {
        delete: {
            title: 'Xóa câu hỏi',
            content: 'Các câu hỏi đã xóa sẽ biến mất vĩnh viễn. Bạn có chắc không?',
        },
        unBookmark: {
            title: 'Xóa câu hỏi',
            content: 'Xóa câu hỏi này?',
        },
    },

    moreArticles: 'Các bài viết khác',
    copied: 'Đã sao chép ✔️',
    copy: 'Sao chép',
    showMore: 'Xem thêm',

    validateUploadImage: {
        invalidType: 'Loại tệp không hợp lệ',
        maxFilesize: 'Tệp quá lớn để tải lên ({imageSize}MB). Kích thước tệp cho phép tối đa là {maxFilesize}MB.',
        offline: 'Bạn đang ngoại tuyến, không thể tải ảnh lên.',
    },

    popupEvent: {
        the: 'Sự kiện',
        takingPlace: 'đang diễn ra',
        untilend: 'đến hết',
        enterForAChance: 'Tham gia để có cơ hội giành',
        addTag: 'bằng cách thêm thẻ',
        intoPost: 'vào bài viết của bạn.',
        learnMore: 'Tìm hiểu thêm.',
        reward: 'giải thưởng',
    },

    canNotTag: 'Xin lỗi, sự kiện {eventName} hiện không mở. Bạn không thể gắn thẻ này của của sự kiện "{eventName}" vào bài viết bây giờ',
    postPublicNotInEvent: 'Xin lỗi, bài viết này được publish ngoài thời gian sự kiện nên không thể gắn tag "{eventName}".',
    canNotTagContentCreator: 'Chỉ những tài khoản là Nhà sáng tạo nội dung mới có thể thêm thẻ này.',
    canNotTagContentCreatorIfNotPost: 'Chỉ những bài viết mới được thêm thẻ này',
    seriesProposal: {
        title: 'Hãy cẩn thận! Bài đăng này chỉ có thể được thêm vào một series duy nhất!',
        actions: 'Hành động',
        block: 'Chặn',
        approve: 'Chấp thuận',
        reject: 'Từ chối',
        user: 'Người dùng',
        wantToAdd: 'muốn thêm bài viết này vào series của anh ấy / cô ấy',
        success: 'Thành công!',
    },

    lastEdited: 'Sửa lần cuối',

    tags: {
        sort: {
            title: 'Sắp xếp theo',
            newestPosts: 'Bài viết mới nhất',
            mostCliped: 'Nhiều lượt bookmark nhất',
            mostViewed: 'Nhiều lượt xem nhất',
            mostVoted: 'Nhiều lượt vote nhất',
        },
    },
    Answer: 'Câu trả lời',
    Series: 'Series',
    Question: 'Câu hỏi',
    Post: 'Bài viết',
    'Poor Translation': 'Bản dịch chất lượng kém',
    'Infringes copyright': 'Vi phạm bản quyền',
    'Harassment content': 'Quấy rối, đả kích',
    'TOS Violation': 'Vi phạm điều khoản',
    'Spam content': 'Nội dung rác',
    'Viblo MayFest 2020 Badge': 'Huy hiệu cho người dùng hoàn thành thử thách trong sự kiện Viblo MayFest 2020.',
    'Viblo MayFest 2021 Badge': 'Huy hiệu cho người dùng hoàn thành thử thách trong sự kiện Viblo MayFest 2021.',
    'Viblo MayFest 2022 Badge': 'Huy hiệu cho người dùng hoàn thành thử thách trong sự kiện Viblo MayFest 2022.',
    'Viblo MayFest 2023 Badge': 'Huy hiệu cho người dùng hoàn thành thử thách trong sự kiện Viblo MayFest 2023.',
    'Viblo MayFest 2024 Badge': 'Huy hiệu cho người dùng hoàn thành thử thách trong sự kiện Viblo MayFest 2024.',

    contentCreator: {
        rank: {
            grade: 'Hạng: ',
            title: 'Tất cả',
            diamondGrade: 'Hạng kim cương',
            goldGrade: 'Hạng vàng',
            silverGrade: 'Hạng bạc',
        },
    },

    uploadAvt: {
        tip: 'Mẹo: Sử dụng con lăn để thu phóng, kéo để di chuyển ảnh.',
        title: 'Thay đổi hình ảnh đại diện',
        save: 'Lưu',
    },

    wrappedUp: {
        toolTip: 'Cùng chờ một chút để nhìn lại thành tích của bạn trong năm qua nhé!',
        lookBack: 'Cùng nhìn lại thành tích của bạn trong năm qua',
        aYear: 'một năm',
        withViblo: 'Cùng Viblo nhìn lại một năm qua',
        error: 'Đã có lỗi xảy ra vui lòng thử lại!',
        chooseImage: 'Chọn hình ảnh tải xuống',
        saveImage: 'Tải xuống',
        all: 'Tất cả',
        loading: 'đang tải ...',
        downloadMessage: 'Không thể tải ảnh',
    },

    writeUps: {
        collectionWriteupsDesc: 'Một bộ sưu tập các write-ups từ các hacker giỏi nhất cho',
        congratulationsWriteUpsDesc: 'Chúc mừng nhé! Bạn đã giải quyết được puzzle này. Hãy trở thành hacker đầu tiên',
        submitAWriteUps: 'viết một write-up',
        to: 'tới',
    },

    settings: {
        newsletter: {
            textUpdateEmailSubscription: 'Thiết lập theo dõi email',
            textSubscribeEmail: 'Để mang tới trải nghiệm thông báo nhất quán trên Viblo, chúng tôi đã tạm dừng gửi email với 2 thông báo này. Bạn có thể bật lại bên dưới để nhận cập nhật mới về',
            textNewsletter: 'Bản tin hàng tuần',
            textUnreadNotifications: 'Thông báo chưa đọc',
            textAdjustSettings: 'Bấm vào đây để xem cài đặt thông báo khác',
            messageSuccess: 'Cập nhật cài đặt thành công!',
        },
        error: 'Có lỗi xảy ra. Vui lòng thử lại sau.',
    },

    socialSharePopUp: {
        title: 'Mô tả cho bài viết khi chia sẻ công khai',
        label: 'Nội dung',
        validations: {
            contentMin: 'Mô tả phải có ít nhất 10 ký tự',
            contentMax: 'Mô tả không được dài quá 10000 kí tự',
        },
        save: 'Lưu',
        skip: 'Bỏ qua',
        description: 'Viblo Team sẽ sử dụng nội dung này khi bài viết của bạn được lựa chọn để chia sẻ trên các trang MXH của Viblo (Facebook, TikTok, Threads,...).',
        noRequire: 'Việc cung cấp nội dung này là không bắt buộc.',
    },
    allPosts: 'Tất cả bài viết',
    somePicturesAboutUs: 'Một số hình ảnh về',
    allVideos: 'Tất cả video',
    postsBookmarked: 'Bài viết đã bookmark',
    subdomain: {
        PostPin: 'Bài viết được ghim',
        videoPin: 'Video được ghim',
        growthDate: 'Từ {from} đến {to}',
        AllMembers: 'Tất cả thành viên',
        AllFollowers: 'Tất cả người theo dõi',
        PinnedPosts: ' Chọn bài viết được ghim',
        alertPostPin: 'Lưu ý: Hãy chọn 3 hoặc 6 bài viết để có thể lưu danh sách bài viết được ghim',
    },
    pinPostOrg: {
        confirmContent: 'Bạn có chắc chắn muốn ghim video này?',
        confirmContentUnPin: 'Bạn có chắc chắn muốn bỏ ghim video này?',
        confirmPostPin: 'Bạn có chắc chắn muốn ghim bài viết này?',
        confirmPostUnPin: 'Bạn có chắc chắn muốn bỏ ghim bài viết này?',
        contentPin: 'Ghim bài viết',
        contentUnPin: 'Bỏ ghim bài viết',
        pinSuccess: 'Bài viết đã được ghim!',
        pinVideoSuccess: 'Video đã được ghim!',
        unPinVideoSuccess: 'Video đã được bỏ ghim!',
        unPinSuccess: 'Bài viết đã được bỏ ghim!',
        titlePinPost: 'Chọn bài ghim',
        reset: 'Làm mới',
        confirmPinPost: {
            title: 'Xác nhận ghim',
            content: 'Bạn có chắc chắn muốn ghim những bài viết này?',
        },
    },
    navbarOrg: {
        topMembers: 'Thành viên nổi bật',
        seeAll: 'Xem tất cả >',
    },
    uploadImage: 'Thêm ảnh hoạt động',
    descDarkMode: 'Khi nhấn chuyển chế độ sáng/chế độ tối, các trang tiếp theo sẽ tự động áp dụng chế độ này cho đến khi bạn thay đổi lại. Bạn có thể tuỳ chỉnh chế độ tại trang subdomain, hoặc thay đổi màu nền tại trang chi tiết bài viết.',
    PopupDownloadApp:
    {
        title: 'Tải ngay Viblo Mobile App',
        content: 'Bạn đang tìm cách khám phá Viblo dễ dàng hơn?',
        download: 'Tải ứng dụng ngay',
    },
}
