// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { getUserPosts } from 'viblo-sdk/api/users'
import _cloneDeep from 'lodash/cloneDeep'
import {
    defaultState, getters, createActions, mutations,
} from '../__modules/post-feed'

const state = () => _cloneDeep(defaultState)

const getUserPostFeed = ({ username, params }) => getUserPosts(username, params)

const actions = {
    ...createActions({
        fetchPosts: getUserPostFeed,
    }),
    resetData: ({ commit }) => {
        commit('resetData')
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
