// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _pick from 'lodash/pick'
import _assign from 'lodash/assign'
import _isArray from 'lodash/isArray'
import _mergeWith from 'lodash/mergeWith'
import _mapValues from 'lodash/mapValues'

import { subscribe } from 'viblo-sdk/api/me'
import { deleteQuestion } from 'viblo-sdk/api/questions'
import { RateableType, SubscribableType } from 'viblo-sdk/libs/interactions'
import { voteAction } from '~/utils/store/voting'
import { getters } from '~/components/entities/common'

export const QUESTION_ENTITIES = 'entities/questions'
export const PUT_QUESTIONS_TO_STATE = 'entities/questions/put'

const pickValues = questions => _mapValues(questions, question => _pick(question, [
    'id',
    'hash_id',
    'user_id',
    'title',
    'tags',
    'seo',
    'title_slug',
    'solved',
    'accepted_answer_id',
    'points',
    'rated_value',
    'clipped',
    'clips_count',
    'views_count',
    'comments_count',
    'answers_count',
    'created_at',
    'updated_at',
    'canonical_url',
    'uploads',
    'contents',
]))

const state = () => ({
    all: {},
})

const actions = {
    vote: voteAction(
        RateableType.Question,
        (id, state) => state.all[id],
        (id, values, commit) => commit('put', { [id]: values })
    ),

    async clip({ rootGetters, commit, state }, { id, value }) {
        const question = state.all[id]
        const response = subscribe(SubscribableType.Question, question.hash_id, value)
        const isOwner = rootGetters['auth/isUser'](question.user_id)

        commit('entities/users/increment', {
            [question.user_id]: {
                reputation: isOwner ? 0 : (value ? 3 : -3), // eslint-disable-line no-nested-ternary
            },
        }, { root: true })

        commit('put', {
            [id]: {
                clipped: value,
                clips_count: question.clips_count + (value ? 1 : -1),
            },
        })

        return response
    },

    async clipSingleQuestion({ commit }, { question, value, id }) {
        const response = subscribe(SubscribableType.Question, question.hash_id, value)

        commit('put', {
            [id]: {
                clipped: value,
                clips_count: question.clips_count + (value ? 1 : -1),
            },
        })

        return response
    },

    async destroy({ commit }, question) {
        return deleteQuestion(question.hash_id)
            .then(() => {
                commit('entities/users/increment', {
                    [question.user_id]: {
                        questions_count: -1,
                    },
                }, { root: true })
            })
    },
}

const mutations = {
    put(state, questions) {
        const data = pickValues(questions)
        const updatedQuestions = _mapValues(data, (user, id) => state.all[id])

        // eslint-disable-next-line consistent-return
        const updatedSlice = _mergeWith({}, updatedQuestions, data, (current, next) => {
            if (_isArray(current) && _isArray(next)) return next
        })
        const nextState = _assign({}, state.all, updatedSlice)

        state.all = nextState
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
