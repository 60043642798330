// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _pick from 'lodash/pick'
import _assign from 'lodash/assign'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import { normalize } from 'normalizr'
import { post as postSchema } from './__utils/schemas'
import { loadOrganizations } from './__utils/entities'
import { markFeaturedPost } from '../api/editorial'
import {
    getPost, getRelated, getRelatedOrganizations, getSeries, report, getAllProposalNews,
} from '../api/posts'
import {
    getSuggestionService,
} from '~/api/placements'
import { suggestionService } from '~/constants/suggestions'

const CACHE_TIME = 5

const shouldFetchPost = (state, slug) => {
    if (!state.post || state.invalidated || !state.fetchedAt || state.post.slug !== slug) {
        return true
    }

    const minutesPassed = differenceInMinutes(new Date(), state.fetchedAt)

    return minutesPassed > CACHE_TIME
}

const state = () => ({
    post: null,
    related: null,
    relatedOrganizations: null,
    fetchedAt: null,
    invalidated: true,
    series: null,
    proposals: [],
    video: null,
    tracking: null,
    suggestionChallenges: [],
    suggestionCourses: [],
    suggestionPuzzles: [],
})

const getters = {
    post: (state, getters, rootState, rootGetters) => {
        if (!state.post) return null

        const post = rootGetters['entities/posts/get'](state.post.id)
        const user = rootGetters['entities/users/get'](post.user_id)
        const organization = rootGetters['entities/organizations/get'](_get(state.post, 'organization.data.id'))
        const isOwn = state.post.user_id === rootGetters['auth/userId']

        return _assign({}, post, state.post, {
            is_own: isOwn,
            user: { data: user },
            organization: { data: organization },
        })
    },

    author: (state, getters, rootState, rootGetters) => {
        if (!state.post) {
            return null
        }

        return rootGetters['entities/users/get'](state.post.user_id)
    },
}

const actions = {
    async fetchPost({ commit, state }, hashId, force = false) {
        if (!force && !shouldFetchPost(state, hashId)) {
            return state.post
        }

        const result = await getPost(hashId)

        const post = result.data
        const video = _get(post, 'video.data', null)
        const normalizedPost = normalize(post, postSchema)

        commit('entities/posts/put', normalizedPost.entities.posts, { root: true })
        commit('entities/users/put', normalizedPost.entities.users, { root: true })
        commit('entities/organizations/put', normalizedPost.entities.organizations, { root: true })

        commit('setPost', _pick(post, [
            'id', 'slug', 'transliterated', 'user_id', 'contents', 'moderation',
            'preview', 'current_revision', 'category_id', 'reading_time',
            'series_id', 'video_id', 'video_type', 'slide_url', 'organization', 'last_edited_at',
            'reading_time', 'puzzle_hash_id', 'license', 'seo_setting', 'tags', 'summary',
        ]))
        commit('setVideo', _isEmpty(video) ? null : video)
        commit('setRelated', null)
        commit('setRelatedOrganizations', null)
        commit('setSeries', null)

        return post
    },

    // used with InstantClick: ~/lib/instantclick.js
    async setPreloadedData({ commit }, post) {
        const video = _get(post, 'video.data', null)
        const normalizedPost = normalize(post, postSchema)

        commit('entities/posts/put', normalizedPost.entities.posts, { root: true })
        commit('entities/users/put', normalizedPost.entities.users, { root: true })
        commit('entities/organizations/put', normalizedPost.entities.organizations, { root: true })

        commit('setPost', _pick(post, [
            'id', 'slug', 'transliterated', 'user_id', 'contents', 'moderation',
            'preview', 'current_revision', 'category_id', 'reading_time',
            'series_id', 'video_id', 'video_type', 'slide_url', 'organization', 'last_edited_at',
            'reading_time', 'puzzle_hash_id', 'license', 'seo_setting', 'tags', 'summary', 'user',
        ]))
        commit('setVideo', _isEmpty(video) ? null : video)
        commit('setRelated', null)
        commit('setRelatedOrganizations', null)
        commit('setSeries', null)
    },

    async getRelated({ state, commit }) {
        if (!state.post) {
            commit('setRelated', null)
        }

        const params = {
            tags: state.post.tags ? state.post.tags.data.map(item => item.slug) : [],
        }

        // If cant find related post, set it to null
        const relatedPosts = await getRelated(state.post.slug, params)
            .then(response => response.data)
            .catch(() => null)

        commit('setRelated', relatedPosts)
    },

    async getRelatedOrganizations(store) {
        const params = {
            tags: store.state.post.tags ? store.state.post.tags.data.map(item => item.slug) : [],
        }

        const relatedOrganizations = await loadOrganizations(store,
            getRelatedOrganizations(store.state.post.slug, params)
                .then(_ => _.data.data))
            .catch(() => null)

        store.commit('setRelatedOrganizations', relatedOrganizations)
    },

    async fetchProposals({ commit, state }) {
        const proposals = await getAllProposalNews(state.post.slug).then(res => res.data.data)
        commit('setProposals', proposals)
    },

    async getSeries({ state, commit }) {
        if (!state.post) {
            commit('setSeries', null)
        }

        const relatedPosts = await getSeries(state.post.slug)
            .then(response => response.data)

        commit('setSeries', relatedPosts)
    },

    vote({ state, dispatch }, value) {
        return dispatch('entities/posts/vote', {
            id: state.post.id,
            value,
        }, { root: true })
    },

    clip({ state, dispatch }, value) {
        return dispatch('entities/posts/clip', { id: state.post.id, value }, { root: true })
    },

    async markFeatured({ state, commit }, value) {
        const response = await markFeaturedPost(state.post.slug, value)

        commit('entities/posts/put', {
            [state.post.id]: {
                promoted: value,
            },
        }, { root: true })

        return response
    },

    report({ state, commit }, { reason, source, comment }) {
        return report(state.post.slug, { reason, source, comment })
            .then((response) => {
                commit('setReported')
                return response
            })
    },

    addComment: ({ state, dispatch, commit }, values) => dispatch('comments/add', {
        hashId: state.post.slug,
        values,
    }).then((comment) => {
        commit('entities/posts/increment', {
            [state.post.id]: {
                comments_count: 1,
            },
        }, { root: true })

        return comment
    }),

    removeComment: ({ state, dispatch, commit }, comment) => dispatch('comments/remove', comment)
        .then((removed) => {
            commit('entities/posts/increment', {
                [state.post.id]: {
                    comments_count: -1 * removed,
                },
            }, { root: true })
        }),

    async fetchSuggestions({ commit }, payload) {
        const tags = _get(payload, 'tags.data').reduce((array, item) => {
            array.push(item.name)

            return array
        }, [])
        const params = {
            detail_tags: tags,
            post_slug: payload.slug,
        }
        const [suggestionChallenges, suggestionCourses, suggestionPuzzles] = await Promise.all([
            getSuggestionService(_assign({}, params, {
                type: suggestionService.CODE,
            })).then(_ => _.data),
            getSuggestionService(_assign({}, params, {
                type: suggestionService.LEARN,
            })).then(_ => _.data),
            getSuggestionService(_assign({}, params, {
                type: suggestionService.CTF,
            })).then(_ => _.data),
        ])
        commit('setSuggestionChallenges', suggestionChallenges)
        commit('setSuggestionCourses', suggestionCourses)
        commit('setSuggestionPuzzles', suggestionPuzzles)
    },
}

const mutations = {
    setPost(state, post) {
        state.post = post
        state.fetchedAt = new Date()
        state.invalidated = false
    },

    setVideo(state, video) {
        state.video = video
    },

    setProposals(state, proposals) {
        state.proposals = proposals
    },
    setSeries(state, series) {
        state.series = series
    },

    setRelated(state, related) {
        state.related = related
    },

    setRelatedOrganizations(state, relatedOrganizations) {
        state.relatedOrganizations = relatedOrganizations
    },

    invalidate(state) {
        state.invalidated = true
    },

    setPromoted(state, { promoted, promotion }) {
        state.post.promoted = promoted
        state.post.promotion = promotion
    },

    setReported(state) {
        state.post.reported = true
    },

    setSuggestionChallenges(state, suggestions) {
        state.suggestionChallenges = suggestions
    },

    setSuggestionPuzzles(state, suggestions) {
        state.suggestionPuzzles = suggestions
    },

    setSuggestionCourses(state, suggestions) {
        state.suggestionCourses = suggestions
    },
}


export default {
    state,
    actions,
    mutations,
    getters,
}
