// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
// TODO Refactor

import { subscribe } from 'viblo-sdk/api/me'
import { castVote } from 'viblo-sdk/api/voting'
import { SubscribableType, RateableType } from 'viblo-sdk/libs/interactions'
import _assign from 'lodash/assign'
import { getSeriesFeed } from 'viblo-sdk/api/series'
import { getPosts, getAllRequestNews } from '~/api/series'

import { deserializeVote } from '../lib/functions'
import { normalizeSeries } from '~/components/entities/normalize'

const state = () => ({
    series: {},
    posts: [],
    requests: [],
    seriesHome: null,
    paginationSeries: null,
})

const getters = {
    series: (state, getters, rootState, rootGetters) => {
        if (!state.series) return null
        const series = state.series
        const authUserId = rootGetters['auth/userId']

        return _assign(series, {
            is_own: series.user_id === authUserId,
        })
    },
}

const actions = {
    async getSeries({ dispatch, commit, rootGetters }, { hashId, query }) {
        const fetchedData = await getPosts(hashId, query)
            .then(data => ({
                series: data.series.data,
                posts: data.posts,
                user_id: data.series.data.user.data.id,
            }))

        const normalizedSeries = normalizeSeries(fetchedData.series)

        commit('setCurrent', fetchedData)

        const users = normalizedSeries.entities.users
        users[fetchedData.user_id] = fetchedData.series.user.data

        commit('entities/users/put', users, { root: true })

        commit('comments/set', normalizedSeries.entities.comments || {})

        if (rootGetters['auth/userId'] === fetchedData.series.user_id) {
            dispatch('getAllRequestNews')
        }

        return fetchedData.series
    },

    async getAllRequestNews({ state, commit }) {
        const requests = await getAllRequestNews(state.series.hash_id).then(res => res.data.data)
        commit('SET_REQUESTS', requests)
    },

    clip({ state, commit, rootGetters }, { hash_id: hashId, value }) {
        const series = state.series
        const user = rootGetters['entities/users/get'](series.user_id)

        return subscribe(SubscribableType.Series, hashId, value)
            .then((res) => {
                commit('update', {
                    clipped: value,
                    clips_count: series.clips_count + (value ? 1 : -1),
                })

                if (rootGetters['auth/userId'] !== series.user_id) {
                    commit('entities/users/put', {
                        [series.user_id]: {
                            reputation: user.reputation + (value ? 3 : -3),
                        },
                    }, { root: true })
                }

                return res
            })
    },

    async addComment({ dispatch, commit, state }, { values }) {
        const comment = await dispatch('comments/add', {
            commentable_type: 'series',
            commentable_id: state.series.hash_id,
            values,
        })

        const current = state.series.comments.data
        const updatedComments = [...current, comment]

        commit('update', {
            comments: {
                data: updatedComments,
            },
            comments_count: state.series.comments_count + 1,
        })
    },

    async destroyComment({ dispatch, commit, state }, comment) {
        await dispatch('comments/remove', comment)

        commit('update', {
            comments_count: state.series.comments_count - 1,
        })
    },

    async vote({ commit, state }, value) {
        const series = state.series

        const currentScore = series.rated_value
        const currentDir = deserializeVote(currentScore)
        const dir = currentDir !== value ? value : 'none'

        const reputations = { up: 4, down: 0, none: 0 }
        const nextScore = await castVote(RateableType.Series, series.hash_id, dir)
            .then(response => response.data.rated_value)
        const repChange = reputations[dir] - reputations[currentDir]

        commit('update', {
            rated_value: nextScore,
            points: series.points - currentScore + nextScore,
        })

        commit('entities/users/increment', {
            [series.user_id]: {
                reputation: repChange,
            },
        }, { root: true })
    },

    async getSeriesHomeFeed({ commit }, { params }) {
        const series = await getSeriesFeed(params)
        commit('setDataSeriesHomeFeed', series)
    },
}

const mutations = {
    setCurrent(state, { series, posts }) {
        state.series = series
        state.posts = posts
    },

    SET_REQUESTS(state, requests) {
        state.requests = requests
    },

    update(state, series) {
        const current = state.series
        state.series = _assign(current, series)
    },

    setDataSeriesHomeFeed(state, series) {
        state.seriesHome = series.data
        state.paginationSeries = series.meta.pagination
    },
}

export default {
    state,
    actions,
    getters,
    mutations,
}
