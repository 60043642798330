// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <ElPopover
        ref="popover"
        :append-to-body="false"
        transition="el-zoom-in-top"
        placement="bottom-end"
    >
        <slot slot="reference" name="reference">
            <div class="position-relative" @click="trackingGA()">
                <i class="viblo-services-toggle fa fa-th" />
                <sup class="service-new-icon el-badge__content el-badge__content--undefined is-fixed">new</sup>
            </div>
        </slot>

        <ul class="viblo-services-menu">
            <li v-for="(service, name) in vibloServices" :key="name" class="viblo-services-menu__item">
                <nuxt-link
                    v-if="service.name === 'Viblo'"
                    to="/"
                    target="_blank"
                    rel="noopener"
                >
                    <span
                        :style="`background: url(${service.icon});`"
                        class="service-icon viblo-icon"
                    />
                    <span class="service-name d-block">
                        {{ service.name }}
                    </span>
                </nuxt-link>

                <a
                    v-else
                    :href="getUTMUrl(service.src)"
                    target="_blank"
                    rel="noopener"
                    class="position-relative"
                >
                    <div class="position-relative">
                        <span
                            :style="`background: url(${service.icon});`"
                            class="service-icon"
                        />
                        <sup
                            v-if="service.name === 'Viblo Interview'"
                            class="new-service-icon__item el-badge__content el-badge__content--undefined is-fixed"
                        >
                            new
                        </sup>
                    </div>

                    <span class="service-name d-block">
                        {{ service.name }}
                    </span>
                </a>
            </li>
        </ul>
    </ElPopover>
</template>

<script>
    import { mixin as clickaway } from '@tranxuanthang/vue-clickaway'
    import { vibloServices } from '~/constants/services'

    export default {
        mixins: [clickaway],
        data: () => ({
            isShow: false,
            vibloServices,
        }),

        methods: {
            open() {
                this.$refs.popover.doShow()
            },
            close() {
                this.$refs.popover.doClose()
            },
            trackingGA() {
                this.$gtag.event('tracking_services', {
                    event_category: 'Tracking Services',
                    eventLabel: 'Dropdown Services',
                    nonInteraction: true,
                })
            },

            getUTMUrl(url) {
                return `${url}/?utm_source=Viblo_Service&utm_medium=Page&utm_campaign=DropdownServices`
            },
        },
    }
</script>

<style lang="scss" scoped>
    .viblo-services-background {
        border: 1px solid #ccc !important;
    }
    .viblo-services-toggle {
        line-height: 30px;
        font-size: 1.3rem;
        color: #7f7f7f;
        display: inline-block;
        margin-left: .8rem;
        margin-right: .8rem;
        cursor: pointer;
        transition: all 0.1s ease-in;

        &:hover {
            color: #212529;
        }
    }

    .service-new-icon {
        right: 28px;
        top: 2px;
        font-size: 10px;
        height: 16px;
        line-height: 16px;
        padding: 0 4px;

        &:hover {
            cursor: pointer;
        }
    }

    .new-service-icon__item {
        right: 42px;
        top: 4px;
        font-size: 14px;
        height: 20px;
        line-height: 19px;

        &:hover {
            cursor: pointer;
        }
    }

    .viblo-services-menu {
        padding-left: 0;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        max-width: 290px;
        margin-bottom: 0;
        .viblo-services-menu__item {
            display: block;
            width: 96.5px;
            height: 91px;
            box-sizing: border-box;
            border: solid 1px transparent;
            margin-bottom: 1rem;

            &:hover {
                border-color: #ddd;
            }
            .service-icon {
                background-color: #fff;
                background-size: contain !important;
                background-repeat: no-repeat !important;
                background-position: center !important;
                width: 46px;
                height: 46px;
                display: block;
                overflow: hidden;
                margin: 0.6rem auto;
            }
            a, .service-name {
                text-decoration: none !important;
                color: #212529;
                width: 100%;
            }

            .viblo-icon {
                width: 43px;
                height: 43px;
                margin-top: 0.9rem;
                margin-bottom: 0.4rem;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    @media screen and (max-width: 600px) {
        .viblo-services-menu {
            max-width: 260px !important;
            .viblo-services-menu__item {
                width: 84px !important;
                height: 80px !important;

                .service-icon {
                    width: 40px !important;
                    height: 40px !important;
                }
                .service-name {
                    font-size: 13px;
                    word-break: break-word;
                }
            }
        }
    }

    @media screen and (max-width: 330px) {
        .viblo-services-menu {
            max-width: 230px !important;
            .viblo-services-menu__item {
                width: 75px !important;
                height: 80px !important;
            }
        }
    }

    .theme-dark {
        .viblo-services-menu {
            .viblo-services-menu__item {
                a, .service-name {
                    color: #fff;
                }
            }
    }
    }
</style>
