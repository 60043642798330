// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { getTagPosts } from 'viblo-sdk/api/tags'
import _cloneDeep from 'lodash/cloneDeep'
import {
    defaultState, getters, createActions, mutations,
} from '../__modules/post-feed'

export const ACTION_FETCH_TAG_POST_FEED = 'tagView/posts/fetch'

const state = () => _cloneDeep(defaultState)

const getTagPostFeed = ({ tag, params }) => getTagPosts(tag, params)

const actions = createActions({
    fetchPosts: getTagPostFeed,
})

export default {
    state,
    getters,
    actions,
    mutations,
}
