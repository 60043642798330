// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="v-ctr-section">
        <slot />
    </div>
</template>

<script>
    import _merge from 'lodash/merge'
    import { mapGetters } from 'vuex'
    import { CTR_LOGS } from '~/constants/log-types'
    import { saveLog } from '~/api/collect-logs'
    import trackCTR from '~/lib/trackings/ctr'
    import { trackUserAgentInfo } from '~/lib/trackings/user-agent'

    export default {
        name: 'VCtr',

        props: {
            name: {
                type: String,
                required: true,
            },

            container: {
                type: String,
                required: false,
            },

            autoSave: {
                type: Boolean,
                default: true,
            },

            externalData: {
                type: [Object, Function],
                default: () => ({}),
            },
        },

        computed: {
            ...mapGetters('auth', ['userId']),
        },

        mounted() {
            const section = !this.container ? this.$el : this.$el.querySelector(this.container)

            this.$_ctrTracking = trackCTR(section, '[aria-ctr-enable]')
            this.$_ctrTracking.on('first-seen', this.onSeen)
            // this.$_ctrTracking.on('clicked-item', this.onClickedItem)
        },

        beforeDestroy() {
            if (this.$_ctrTracking) {
                this.$_ctrTracking.destroy()
            }
        },

        methods: {
            async onSeen(context) {
                if (this.autoSave) {
                    const response = await this.storeLog('seen', context).catch(() => {})
                    if (response && typeof response.data !== 'undefined') {
                        this.$store.commit('log/pushCTRLogs', response.data.data)
                    }
                }

                this.$emit('seen', context)
            },

            onClickedItem(context) {
                const clickedItemId = context.event.currentTarget.getAttribute('aria-ctr-enable')

                if (this.autoSave) {
                    this.storeLog('clicked', context, { clicked_item_id: clickedItemId }).catch(() => {})
                }

                context.clickedItemId = clickedItemId

                this.$emit('clicked', context)
            },

            async storeLog(action, context, data = {}) {
                const { guid } = context
                const externalData = typeof this.externalData === 'function'
                    ? this.externalData(context)
                    : this.externalData

                const visitor = await trackUserAgentInfo()

                return saveLog(CTR_LOGS, _merge({
                    guid,
                    action,
                    section: this.name,
                    user_id: this.userId,
                    ...visitor,
                }, data, externalData))
            },
        },
    }
</script>
