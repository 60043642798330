// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _get from 'lodash/get'
import _assign from 'lodash/assign'
import _indexOf from 'lodash/indexOf'
import axios from 'viblo-sdk/libs/axios'
import { getHost, getContentSubDomain } from '~/utils/pages'

import pushNewPostsPlugin from './__plugins/pushNewPosts'
import pushNotificationsPlugin from './__plugins/pushNotifications'

export const actions = {
    async nuxtServerInit({ commit }, {
        req, redirect, app, res,
    }) {
        const { data } = await axios.get('/web/init')
        const {
            auth,
            motd,
            banners,
            logo,
            preferences,
            lastSeens,
            externalSurvey,
            webSocket,
            mayFestTime,
            config,
            wrappedUpTime,
            witePostNewYear,
            reputationOrganizations,
        } = data

        if (auth) {
            const locale = _get(preferences, 'preferred_locale', 'vi')

            commit('auth/set', auth)
            commit('auth/setLastSeens', lastSeens)
            commit('i18n/setLocale', locale)
            commit('survey/setExternalUrl', externalSurvey)
            // make sure that the first HTML will contain correct language (ctr+u)
            app.i18n.locale = locale
        }

        // Set cookie value for websocket broadcaster
        res.cookie('socket_broadcaster', webSocket.broadcaster)
        if (webSocket.broadcaster === 'pusher') {
            res.cookie('socket_key', webSocket.key)
            res.cookie('auth_endpoint', webSocket.authEndpoint)
            res.cookie('socket_host', webSocket.host)
            res.cookie('wsPort', webSocket.wsPort)
            res.cookie('wssPort', webSocket.wssPort)
        }

        const isOrganizationSubDomain = _indexOf(config.special_organizations,
            getContentSubDomain(req.headers.host)) !== -1
        const isCnameOrganization = _indexOf(config.cname_organizations,
            req.headers.host) !== -1
        const isSpecialSubdomain = {
            is_special_organization: null,
            is_special_profile: null,
        }

        if (req.headers.host !== getHost(process.env.APP_URL)) {
            if (isOrganizationSubDomain || isCnameOrganization) {
                res.cookie('is_special_subdomain', 'organization')
                isSpecialSubdomain.is_special_organization = true
            } else {
                res.cookie('is_special_subdomain', 'profile')
                isSpecialSubdomain.is_special_profile = true
            }
        }

        commit('mayFestTime/setTime', mayFestTime)
        commit('wrappedUpTime/setTime', wrappedUpTime)
        commit('witePostNewYear/setTime', witePostNewYear)
        commit('config/setConfig', { name: 'banners', value: banners })
        commit('config/setConfig', { name: 'motd', value: motd })
        commit('config/setConfig', { name: 'logo', value: logo })
        commit('config/setConfig', { name: 'vars', value: _assign({}, config, isSpecialSubdomain) })
        commit('conditionCreateOrganization/setCondition', reputationOrganizations)

        const sessionSettings = _get(req, 'session.settings')
        commit('settings/setAll', _assign({}, preferences, sessionSettings))

        commit('toast/pushMessages', req.flash('messages'))

        if (auth && auth.user.first_login) {
            redirect('/get-started')
        }
    },
}

export const plugins = process.client ? [
    pushNewPostsPlugin,
    pushNotificationsPlugin,
] : []
