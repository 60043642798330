// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _pick from 'lodash/pick'
import _merge from 'lodash/merge'
import _assign from 'lodash/assign'
import { subscribe } from 'viblo-sdk/api/me'
import _mapValues from 'lodash/mapValues'
import { getters } from '~/components/entities/common'

export const PUT_ORGANIZATIONS_TO_STATE = 'entities/organizations/put'

const attributes = [
    'id',
    'slug',
    'user_id',
    'avatar',
    'name',
    'posts_count',
    'short_description',
    'full_description',
    'followers_count',
    'members_count',
    'following',
    'location',
    'website',
    'website_verified',
    'approved',
    'postviews_count',
    'level_partner',
    'google_analytics_id',
    'description_subdomain',
]

const pickValues = organizations => _mapValues(organizations, organization => _pick(organization, attributes))

const state = () => ({
    all: {},
})

const actions = {
    async subscribe({ commit }, { organization, value }) {
        await subscribe('organization', organization.slug, value)

        commit('put', {
            [organization.id]: {
                following: value,
                followers_count: organization.followers_count + (value ? 1 : -1),
            },
        })
    },
}

const mutations = {
    put(state, organizations) {
        const data = pickValues(organizations)
        const updatedOrganizations = _mapValues(data, (organization, id) => state.all[id])
        const slice = _merge({}, updatedOrganizations, data)
        // Set next state:
        state.all = _assign({}, state.all, slice)
    },
}

export {
    state, getters, actions, mutations,
}
