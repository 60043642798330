// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import axios from 'viblo-sdk/libs/axios'

if (process.client) {
    axios.defaults.baseURL = '/api'
}

export default ({ req }) => {
    if (process.server && req) {
        axios.defaults.headers.common.cookie = req.headers.cookie || ''
    }
}
