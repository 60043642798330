// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { getNotifications, clearNotifications } from 'viblo-sdk/api/me'
import { updateData } from '~/utils/data'

const state = () => ({
    data: [],
    batch: 0,
    lastBatch: 1,
    unread: 0,
    loadNoti: true,
})

const hasMore = state => state.batch < state.lastBatch

const getters = {
    hasMore,

    withUnreadCount: state => title => (state.unread > 0
        ? `(${state.unread}) ${title}`
        : title
    ),
}

const fetchData = async (page, locale) => {
    const data = await getNotifications({ page, locale })

    const unread = data.counter
    const notifications = data.notifications
    const pagination = notifications.meta.pagination

    return { notifications: notifications.data, unread, pagination }
}

const mutate = (commit, mutation, { notifications, unread, pagination }) => {
    commit(mutation, notifications)
    commit('setUnreadCount', unread)
    commit('setPagination', {
        current: pagination.current_page,
        last: pagination.total_pages,
    })
}

const actions = {
    async fetchMore({ state, commit }) {
        if (!hasMore(state)) {
            return
        }

        const data = await fetchData(state.batch + 1, this.state.i18n.locale)
        mutate(commit, 'push', data)
    },

    async fetch({ commit }) {
        commit('setLoad', true)
        try {
            const data = await fetchData(1, this.state.i18n.locale)
            mutate(commit, 'set', data)
            commit('setLoad', false)
        } catch (error) {
            throw error
        }
    },

    clear({ commit }) {
        clearNotifications().then(() => {
            commit('setUnreadCount', 0)
        })
    },

    deleteAll({ commit }) {
        clearNotifications(true).then(() => {
            commit('clear')
        })
    },
}

const mutations = {
    shift(state, notification) {
        state.data = [notification, ...state.data]
    },

    push(state, notifications) {
        state.data = [...state.data, ...notifications]
    },

    set(state, notifications) {
        state.data = notifications
    },

    setPagination(state, { current, last }) {
        state.batch = current
        state.lastBatch = last
    },

    setRead(state, id) {
        state.data = updateData(state.data, 'id', id, { is_read: true })
    },

    setUnreadCount(state, unread) {
        state.unread = unread
    },

    clear(state) {
        state.data = []
        state.batch = 0
        state.lastBatch = 1
        state.unread = 0
    },

    setLoad(state, data) {
        state.loadNoti = data
    },
}

export {
    state,
    getters,
    actions,
    mutations,
}
