// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _mapValues from 'lodash/mapValues'

function collectServerError(errors) {
    return _mapValues(errors, '0')
}

export default {
    data: () => ({
        processing: false,
        serverErrors: {},
        serverErrorMessage: null,
    }),

    methods: {
        submit(form, send) {
            this.serverErrors = {}
            if (form.model) {
                form.validate((valid) => {
                    if (valid) {
                        send()
                    }
                })
            } else {
                send()
            }
        },

        handleError(error) {
            if (error.response) {
                const data = error.response.data
                if (error.response.status === 422) {
                    this.serverErrors = collectServerError(data.errors)
                } else if (data.message) {
                    this.serverErrorMessage = data.message
                }
            } else {
                this.$message.error('Something went wrong. Please try again later.')
                throw error
            }
        },
    },
}
