// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <transition
        v-if="!lazy || rendered"
        :name="`dialog-${animation}`"
        @before-enter="lockBodyScroll"
        @after-leave="restoreScroll"
    >
        <div
            v-show="show"
            :style="wrapperStyle"
            class="dialog__wrapper"
            @click="onBackdropClicked"
        >
            <div
                :class="['dialog', `dialog--${size}`]"
                role="dialog"
                @click.stop
            >
                <!-- Close -->
                <button type="button" class="dialog__close" @click="requestClose">
                    <i class="el-icon-close" aria-hidden="true" />
                </button>

                <!-- Header -->
                <div v-if="showHeader" class="dialog__header">
                    <slot name="header">
                        <h3 class="dialog__title">
                            <slot name="title">
                                {{ title }}
                            </slot>
                        </h3>
                    </slot>
                </div>

                <!-- Body -->
                <div class="dialog__body">
                    <slot />
                </div>

                <!-- Footer -->
                <div v-if="$slots.footer" class="dialog__footer">
                    <slot name="footer" />
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import _includes from 'lodash/includes'
    import { getScrollbarWidth } from '~/utils/dom'

    export default {
        props: {
            show: Boolean,
            title: String,
            viewport: Boolean,
            animation: {
                type: String,
                default: 'zoom-in',
            },
            bgColor: String,
            backdrop: {
                type: Boolean,
                default: true,
            },
            appendToBody: Boolean,
            lazy: {
                type: Boolean,
                default: true,
            },
            size: {
                type: String,
                default: 'default',
                validator: val => _includes(['default', 'small', 'large', 'fit-content', 'full'], val),
            },
        },

        data: () => ({
            rendered: false,
        }),

        computed: {
            showHeader() {
                return this.title || this.$slots.header || this.$slots.title
            },

            wrapperStyle() {
                return {
                    'background-color': this.bgColor,
                }
            },
        },

        watch: {
            show(value) {
                this.$emit(value ? 'open' : 'close')
                if (value || !this.rendered) {
                    this.rendered = true
                }
            },
        },

        mounted() {
            window.addEventListener('keydown', this.onEsc)
            if (this.appendToBody) {
                document.body.appendChild(this.$el)
            }
        },

        beforeDestroy() {
            window.removeEventListener('keydown', this.onEsc)
            if (this.appendToBody && this.$el && this.$el.parentNode) {
                this.$el.parentNode.removeChild(this.$el)
            }

            if (this.show) {
                this.restoreScroll()
            }
        },

        methods: {
            onBackdropClicked() {
                if (this.backdrop) {
                    this.requestClose()
                }
            },

            onEsc(event) {
                if (this.show && event.keyCode === 27) {
                    this.requestClose()
                }
            },

            lockBodyScroll() {
                if (document.body.scrollHeight > window.innerHeight) {
                    document.body.style.overflowY = 'hidden'
                    document.body.style.paddingRight = `${getScrollbarWidth()}px`
                }
            },

            restoreScroll() {
                // TODO: should restore original style. We surely don't have any so just `null` is enough
                document.body.style.overflowY = null
                document.body.style.paddingRight = null
            },

            requestClose() {
                this.$emit('update:show', false)
            },
        },
    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/colors";
    @import "~assets/sass/bootstrap/mixins";

    .dialog {
        margin: 0 auto 50px;
        position: relative;
        min-width: 450px;
        margin-top: 15vh;
        background: #fff;
        border-radius: 2px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
        transition: transform .3s ease, opacity .3s ease;

        &--default {
            width: 40%;
        }

        &--small {
            width: 35%;
            max-width: 480px;
        }

        &--large {
            width: 55%;
        }

        &--fit-content {
            width: fit-content;
        }

        &--full {
            width: 100%;
        }

        &__wrapper {
            z-index: 2000;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transition: opacity .3s ease;
            background-color: rgba(0, 0, 0, .5);
            overflow: auto;
        }

        &__close {
            position: absolute;
            top: 18px;
            right: 15px;
            padding: 0;
            background: transparent;
            border: none;
            outline: none;
            cursor: pointer;
            font-size: 16px;
            line-height: normal;
            z-index: 999;

            i {
                color: #909399;
            }

            &:hover i {
                color: #676767;
            }
        }

        &__header {
            padding: 15px 15px 10px;
            display: flex;
            justify-content: space-between;
            align-items: baseline;

            h2, h3, h4 {
                margin: 0;
            }
        }

        &__title {
            line-height: 24px;
            font-size: 18px;
            color: #303133;
        }

        &__body {
            padding: 15px;
            color: #606266;
            line-height: 24px;
            font-size: 14px;
        }

        &__footer {
            padding: 15px 15px 10px;
            text-align: right;
        }

        &__viewport {
            overflow: hidden;
        }

        &__viewport &__wrapper {
            overflow: auto;
        }
    }

    .theme-dark {
        .dialog {
            background: $dark-bg;

            &__title {
                color: $dark-text-secondary;
            }
        }
    }

    @mixin animation($name, $transform) {
        .dialog-#{$name} {
            &-enter, &-leave-active {
                &.dialog__wrapper {
                    opacity: 0;
                }

                .dialog {
                    opacity: 0;
                    transform: $transform;
                }
            }
        }
    }

    @include animation(zoom-in, scale(.9));
    @include animation(zoom-out, scale(1.1));
    @include animation(slide, translateY(-25px));

    @include media-breakpoint-down(sm) {
        .dialog {
            min-width: 75%;
        }
    }

    @include media-breakpoint-down(xs) {
        .dialog {
            min-width: 90%;
        }
    }
</style>
