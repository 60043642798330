// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<script>
    import _isEmpty from 'lodash/isEmpty'
    import TagItem from './items/TagItem.vue'
    import PostItem from './items/PostItem.vue'
    import UserItem from './items/UserItem.vue'
    import QuestionItem from './items/QuestionItem.vue'

    export default {
        props: {
            items: Object,
            highlight: {
                type: Number,
                default: -1,
            },
        },

        watch: {
            highlight(value) {
                if (value !== -1) {
                    const item = this.$el.querySelectorAll('li.sb__suggestions-item')[value]
                    if (item) {
                        item.firstChild.focus()
                    }
                }
            },
        },

        methods: {
            renderGroup(name, Component, data) {
                if (_isEmpty(data)) {
                    return null
                }

                const items = data.map((item, index) => <li class='sb__suggestions-item'>
                        <Component item={item} nativeOnClick={this.$emit.bind(this, 'suggestion-picked', index, data.length, item)}/>
                    </li>)

                return (
                    <div class='sb__suggestions-group'>
                        <header class='sb__suggestions-group__name'>{ name }</header>
                        <ul class="sb__suggestions-list">
                            { items }
                        </ul>
                    </div>
                )
            },
        },

        render() {
            const {
                posts, questions, users, tags,
            } = this.items || {}

            return (
                <div>
                    { this.renderGroup(this.$t('search.posts'), PostItem, posts) }
                    { this.renderGroup(this.$t('search.questions'), QuestionItem, questions) }
                    { this.renderGroup(this.$t('search.authors'), UserItem, users) }
                    { this.renderGroup(this.$t('search.tags'), TagItem, tags) }
                </div>
            )
        },
    }
</script>

<style lang="scss">
    @import "../../assets/sass/bootstrap/colors";
    @import "../../assets/sass/bootstrap/borders";

    .sb__suggestions {
        overflow-y: auto;
        max-height: calc(100vh - 80px);

        &-group {
            &:not(:last-child) {
                margin-bottom: 1rem;
            }

            &__name {
                color: #FFF;
                padding: 10px;
                font-weight: bold;
                text-transform: uppercase;
                background-color: $primary;
            }
        }

        &-list {
            padding: 0;
            list-style: none;
        }

        &-item {
            padding: 10px;

            &:not(:last-child) {
                border-bottom: 1px dashed $base-border-color;
            }

            &:hover {
                background-color: #f0f0f0;
            }

            b {
                color: $primary !important;
            }

            &:not(:last-child) {
                border-bottom: 1px dashed $gray-lighter;
            }

            & > * {
                margin: -10px;
                padding: 10px;
            }

            .suggestion-has-image {
                display: flex;
                align-items: center;
            }

            .suggestion-stats {
                display: flex;
                font-size: .8rem;
            }

            .suggestion-image {
                margin-right: 1rem;
            }

            .suggestion-identifier {
                font-size: .9rem;
                color: $dark;
            }
        }
    }
</style>
