// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <el-button
        v-if="user === null"
        type="text"
        icon="fa fa-sign-in"
        @click="showAuthDialog"
    >
        {{ $t('mainNavbar.userMenu.login') }}
    </el-button>

    <UserMenu
        v-else
        ref="menu"
        :profile="user"
        service="viblo"
    >
        <template slot="menu">
            <div class="user-menu__menu-item">
                <nuxt-link :to="toUser(user)" class="link link--plain" @click.native="$refs.menu.close()">
                    <i class="fa fa-user" /> {{ $t('mainNavbar.userMenu.profile') }}
                </nuxt-link>
            </div>

            <div class="user-menu__menu-item">
                <nuxt-link to="/me/posts/drafts" class="link link--plain" @click.native="$refs.menu.close()">
                    <i class="fa fa-file-text-o" /> {{ $t('mainNavbar.userMenu.myContents') }}
                </nuxt-link>
            </div>

            <div v-if="permissions.canModeration || isSocialMediaEditor || isAdmin" class="user-menu__menu-item">
                <nuxt-link
                    :to="isSocialMediaEditor ? '/review-contents' : '/review-contents/posts-spam'"
                    class="link link--plain"
                    @click.native="$refs.menu.close()"
                >
                    <i class="fa fa-handshake-o" /> {{ $t('mainNavbar.userMenu.reviewContents') }}
                </nuxt-link>
            </div>

            <div class="user-menu__menu-item">
                <nuxt-link to="/me/activities" class="link link--plain" @click.native="$refs.menu.close()">
                    <i class="fa fa-history" /> {{ $t('mainNavbar.userMenu.myActivities') }}
                </nuxt-link>
            </div>

            <div class="user-menu__menu-item">
                <nuxt-link to="/me/organizations" class="link link--plain" @click.native="$refs.menu.close()">
                    <i class="fa fa-building" /> {{ $t('mainNavbar.userMenu.organizations') }}
                </nuxt-link>
            </div>

            <div id="v-step-0" class="user-menu__menu-item" :class="{'show-tour-step0' : step0}">
                <nuxt-link
                    to="/settings/appearances"
                    class="link link--plain"
                    :class="{'pulse' : step0}"
                    @click.native="$refs.menu.close()"
                >
                    <i class="fa fa-cog" /> {{ $t('mainNavbar.userMenu.preferences') }}
                </nuxt-link>
            </div>

            <div v-if="user.is_admin" class="user-menu__menu-item">
                <a
                    href="/admin"
                    target="_blank"
                    rel="noopener"
                    class="link link--plain"
                    @click="$refs.menu.close()"
                >
                    <i class="fa fa-tachometer" /> {{ $t('mainNavbar.userMenu.adminDashboard') }}
                </a>
            </div>

            <div v-if="step0" class="background-menu" />
        </template>
    </UserMenu>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex'
    import UserMenu from '@viblo-asia/viblo-accounts-user-menu/UserMenu.vue'
    import { user as toUser } from '~/utils/url'

    export default {
        components: {
            UserMenu,
        },

        computed: {
            ...mapState('auth', ['user']),
            ...mapGetters('auth', ['hasRole', 'authenticated', 'isAdmin', 'isUser', 'isSocialMediaEditor', 'isSpamReviewer']),
            ...mapState('vueTour', ['step0']),

            permissions() {
                const isAdmin = this.isAdmin
                const isSpamReviewer = this.isSpamReviewer
                const isCollaborator = this.hasRole('collaborator')
                const isEditor = this.hasRole('editor')

                return {
                    canModeration: isCollaborator || isAdmin || isEditor || isSpamReviewer,
                }
            },
        },

        methods: {
            ...mapActions('auth', ['showAuthDialog']),
            toUser,

            openMenu() {
                this.$refs.menu.open()
            },

            closeMenu() {
                this.$refs.menu.close()
            },
        },
    }
</script>

<style lang="scss">
    @import '~assets/sass/bootstrap/colors';

    .user-menu__menu-item .fa {
        font-size: 1rem;
        width: 1rem;
    }
    .user-menu__menu-item .fa-sign-out-alt:before {
        content: '\f08b';
        font-size: 1.1rem;
    }
    .show-tour-step0{
        width: 260px;
        height: 70px;
        padding: 8px 50px;
        position: relative;
        z-index: 1;
        background-color: white;
    }

    .pulse {
        border-radius: 50%;
        border: 2px solid rgb(175, 175, 175);
        text-align: center;
        position: relative;
        animation: anim-glow 1s ease infinite;
        padding: 5px;
    }

    @keyframes anim-glow {
        0% {
            box-shadow: 0 0 rgba(rgb(175, 175, 175), 1);
        }
        100% {
            box-shadow: 0 0 10px 8px transparent;
            border-width: 2px;
        }
    }
    .background-menu {
        top: -1px;
        bottom: -1px;
        right: -1px;
        left: -1px;
        position: absolute;
        background-color: #000000cf;
    }

    .theme-dark {
        .user-menu__top {
            background: $dark-bg;
        }
        .user-menu__menu-item:hover {
            background: #fff;
            color: #000;
        }
    }
</style>
