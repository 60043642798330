// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
export const baseUrl = process.client ? `${window.location.protocol}//${window.location.host}` : process.env.APP_URL

export function image(imageItem, size) {
    return size
        ? `${process.env.IMAGES_URL}/${size}/${imageItem}`
        : `${process.env.IMAGES_URL}/${imageItem}`
}

export function answer(answerItem) {
    return `/a/${answerItem.hash_id}`
}

export function comment(commentItem) {
    return `/c/${commentItem.hash_id}`
}

export function user(userAccount, tab) {
    if (!userAccount || !userAccount.username) {
        return '/users'
    }

    tab = (typeof tab === 'undefined') ? '' : `/${tab}`
    return `/u/${userAccount.username}${tab}`
}

export function post(postItem) {
    if (!postItem.system) {
        return postItem.transliterated ? `/p/${postItem.transliterated}-${postItem.slug}` : `/p/${postItem.slug}`
    }

    if (postItem.system === 'faqs' || postItem.system === 'faq') {
        return '/faq'
    }

    if (postItem.system === 'vi_terms') {
        return '/terms/vi_term'
    }

    if (postItem.system === 'en_terms') {
        return '/terms/en_term'
    }

    if (postItem.system === 'jp_terms') {
        return '/terms/jp_term'
    }

    const system = postItem.system === 'announcement' ? 'announcements' : postItem.system

    return `/${system}/${postItem.transliterated}-${postItem.slug}`
}

export function series(seriesItem) {
    return `/s/${seriesItem.hash_id}`
}

export function question(questionItem) {
    return `/q/${questionItem.hash_id}`
}

export function organization(organizationItem, tab = '') {
    return tab ? `/o/${organizationItem.slug}/${tab}` : `/o/${organizationItem.slug}`
}

export function tag(tagItem, tab = null) {
    if (tagItem.slug === 'editors-choice') {
        return '/editors-choice'
    }

    if (tagItem.system) {
        return tagItem.slug === 'announcement' || tagItem.slug === 'help' ? `/${tagItem.slug}s` : `/${tagItem.slug}`
    }

    return tab ? `/tags/${tagItem.slug}/${tab}` : `/tags/${tagItem.slug}`
}

export function feed(feedType) {
    return feedType === 'homepage' ? '/' : `/${feedType}`
}

export function personalStats(username) {
    return `/embed/users/${username}`
}

export function absolute(path = '/') {
    if (path.match(/^http(s?):\/\//)) {
        return path
    }

    return `${baseUrl}/${path.replace(/^\/+|\/+$/, '')}`
}

export function toPuzzle(puzzleHashId) {
    return `https://ctf.viblo.asia/puzzles/${puzzleHashId}`
}

export function toFacebook(id) {
    return `https://www.facebook.com/${id}`
}

export function seoSetting(slug) {
    return `/posts/${slug}/settings/seo`
}


export function getDomain(hostname) {
    const hostParts = hostname.split('.')
    if (hostParts.length > 2) {
        return hostParts.slice(-2).join('.')
    }

    return hostname
}

/**
 * @param {string} page
 */
export function toAppUrl(page) {
    return `${process.env.APP_URL}${page}`
}
