// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _indexOf from 'lodash/indexOf'
import { getContentRouteParams, handlePageError, getSlugSubdomain } from '~/utils/pages'

export default async function ({
    route, redirect, store, error, req,
}) {
    const hostName = process.server ? req.headers.host : window.location.host

    const isCNameOrganization = isCNameSubdomain(hostName, store, true)
    const isCNameProfile = isCNameSubdomain(hostName, store, false)

    const isSpecialOrganization = store.state.config.vars.is_special_organization
    const isSpecialProfile = store.state.config.vars.is_special_profile

    const { fullPath } = route

    try {
        const { hashId } = getContentRouteParams(route.params.post)
        const post = await store.dispatch('postView/fetchPost', hashId)
        const isVideoPage = post && !!post.video
        store.dispatch('organizationsUser/getOrganizations')
        store.dispatch('config/changeBanner', { isVideoPage })

        const subdomain = getSlugSubdomain(hostName)

        const isNotProfileIncludePost = (isSpecialProfile || isCNameProfile)
            && isNotAuthorInProfileSubdomainList(post, subdomain)

        const isNotOrganizationIncludePost = (isSpecialOrganization || isCNameOrganization)
            && isNotAuthorInOrganizationSubdomainList(post, subdomain)

        // redirect to app url if author doesn't subdomain
        if (isNotProfileIncludePost || isNotOrganizationIncludePost) {
            redirect(`${process.env.APP_URL}${fullPath !== '/' ? fullPath : ''}`)
        }
    } catch (err) {
        handlePageError(error, err)
    }
}

function isNotAuthorInProfileSubdomainList(post, subdomain) {
    return post && post.user.data.username !== subdomain
}

function isNotAuthorInOrganizationSubdomainList(post, subdomain) {
    return (post.organization && post.organization.data.slug !== subdomain)
        || (post.organization === undefined)
}

function isCNameSubdomain(host, store, isOrg) {
    const listCName = isOrg
        ? store.state.config.vars.cname_organizations
        : store.state.config.vars.cname_profiles

    return _indexOf(listCName, host) !== -1
}
