// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { getUserQuestions } from 'viblo-sdk/api/users'
import {
    state, mutations, getters, createFetchAction,
} from '../__modules/question-feed'

const fetch = ({ username, params }) => getUserQuestions(username, params)

const actions = {
    fetch: createFetchAction(fetch),

    resetData: ({ commit }) => {
        commit('resetData')
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
