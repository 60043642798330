// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import Vue from 'vue'
import pluralize from 'pluralize'
import formatNumber from '~/filters/formatNumber'
import signedNumber from '~/filters/signedNumber'
import { formatTime, humanizeTime, formatDate } from '~/filters/dates'

export default () => {
    Vue.filter('pluralize', pluralize)
    Vue.filter('formatNumber', formatNumber)
    Vue.filter('signed', signedNumber)
    Vue.filter('formatTime', formatTime)
    Vue.filter('humanizeTime', humanizeTime)
    Vue.filter('formatDate', formatDate)
}
