export default {
    categoriesLabel: 'Chuyên mục',
    emojiUnsupportedMessage: 'Trình duyệt của bạn không hỗ trợ emoji màu.',
    favoritesLabel: 'Yêu thích',
    loadingMessage: 'Đang tải…',
    networkErrorMessage: 'Không thể tải emoji.',
    regionLabel: 'Chọn emoji',
    searchDescription: 'Khi có kết quả tìm kiếm, nhấn phím mũi tên lên hoặc xuống để lựa chọn và nhấn enter để sử dụng.',
    searchLabel: 'Tìm kiếm',
    searchResultsLabel: 'Kết quả tìm kiếm',
    skinToneDescription: 'Khi lựa chọn hiện lên, nhấn phím mũi tên lên hoặc xuống để lựa chọn và nhấn enter để sử dụng.',
    skinToneLabel: 'Chọn loại màu da (hiện tại là {skinTone})',
    skinTonesLabel: 'Màu da',
    skinTones: [
        'Mặc định',
        'Sáng',
        'Trung bình-sáng',
        'Trung bình',
        'Trung bình-tối',
        'Tối',
    ],
    categories: {
        custom: 'Tùy chỉnh',
        'smileys-emotion': 'Mặt cười và biểu tượng cảm xúc',
        'people-body': 'Con người và cơ thể',
        'animals-nature': 'Động vật và thiên nhiên',
        'food-drink': 'Thức ăn và đồ uống',
        'travel-places': 'Du lịch và địa điểm',
        activities: 'Hoạt động',
        objects: 'Đồ vật',
        symbols: 'Ký hiệu',
        flags: 'Quốc kỳ',
    },
}
