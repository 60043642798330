// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <nuxt-link :to="userPage">
        <Avatar class="level-default avatar-event" v-bind="$props" :image="image || imagePolyfill" />
    </nuxt-link>
</template>

<script>
    import { user as toUser } from '~/utils/url'
    import avatarLink from '~/components/mixins/avatarLink'
    import avatarLazyLoad from '~/components/mixins/avatarLazyLoad'

    export default {
        mixins: [avatarLink, avatarLazyLoad],

        props: {
            username: {
                type: String,
                required: true,
            },

            size: {
                type: String,
                default: 'md',
            },

            levelPartner: {
                type: String,
                default: null,
            },
        },

        computed: {
            userPage() {
                return toUser({ username: this.username })
            },
        },
    }
</script>

<style>
    .avatar-loading {
        border-radius: 100%;
        background-color: #eaeaea;
    }

    .level-default {
        border-radius: 50px !important;
    }
    #avatarLeft {
        margin: 0 10px 0 0 !important;
    }
</style>
