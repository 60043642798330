import loadScript from 'load-script'

const getGoogleLoginUri = () => {
    const returnUrl = window.location.href
    const state = encodeURIComponent(returnUrl)

    return `${process.env.SSO_APP_URL}/auth/one-tap/callback?redirect_uri=${state}`
}

const createElementForOneTap = ({ clientId, googleLoginUri, monmentCallback }) => {
    const element = document.createElement('div')

    element.setAttribute('id', 'g_id_onload')
    element.setAttribute('data-client_id', clientId)
    element.setAttribute('data-login_uri', googleLoginUri)
    element.setAttribute('data-cancel_on_tap_outside', 'false')
    element.setAttribute('data-prompt_parent_id', 'g_id_onload')
    element.setAttribute('data-moment_callback', `${monmentCallback}`)

    element.style.position = 'fixed'
    element.style.top = '45px'
    element.style.right = 0
    element.style.zIndex = 1040

    return element
}

export default ({ store }) => {
    if (!process.env.GOOGLE_CLIENT_ID || store.getters['auth/authenticated']) {
        return
    }

    document.body.appendChild(createElementForOneTap({
        clientId: process.env.GOOGLE_CLIENT_ID,
        googleLoginUri: getGoogleLoginUri(),
        monmentCallback: '__onOneTapContinueNextIdp',
    }))

    window.__onOneTapContinueNextIdp = (data) => {
        if (data.isNotDisplayed() || data.isSkippedMoment()) {
            store.commit('oneTap/setIsDisplayed', false)
        } else {
            store.commit('oneTap/setIsDisplayed', true)
        }
    }

    loadScript('https://accounts.google.com/gsi/client', { async: true, attrs: { defer: '' } })
}
