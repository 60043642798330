// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <nuxt-link :to="profilePage" class="link d-block">
        <div class="suggestion-has-image">
            <div class="suggestion-image">
                <avatar :image="item.avatar" :username="item.username" size="md" />
            </div>
            <div>
                <h6 class="font-weight-bold mb-0 suggestion-identifier">
                    {{ item.name }} <span class="text-muted">@{{ item.username }}</span>
                </h6>
                <div class="suggestion-stats text-muted">
                    <p class="mr-1 mb-0">
                        {{ $t('search.suggestions.users.followers') }} <strong>{{ item.followers_count }}</strong>
                    </p>
                    <p class="mb-0">
                        {{ $t('search.suggestions.users.posts') }} <strong>{{ item.posts_count }}</strong>
                    </p>
                </div>
            </div>
        </div>
    </nuxt-link>
</template>

<script>
    import { routeToUser } from '~/lib/functions'
    import Avatar from '~/components/shared/user/Avatar.vue'

    export default {
        components: {
            Avatar,
        },

        props: {
            item: Object,
        },

        computed: {
            profilePage() {
                return routeToUser(this.item)
            },
        },
    }
</script>
