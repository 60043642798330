// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _assign from 'lodash/assign'
import _isEmpty from 'lodash/isEmpty'
import { getDomain } from '~/utils/url'

const params = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']

export default ({ req, res }) => {
    if (process.server) {
        if (!req.session) {
            return
        }

        const utm = params.reduce((acc, paramName) => (typeof req.query[paramName] === 'string' && req.query[paramName] !== ''
            ? _assign(acc, { [paramName]: req.query[paramName] })
            : acc),
        {})

        if (!_isEmpty(utm)) {
            // TODO save and read utm params from cookie only?
            req.session.utm = utm
            res.clearCookie('utm')
            res.cookie('utm', utm, { domain: `.${getDomain(req.hostname)}` })
        }
    }
}
