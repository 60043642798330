// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
export const state = () => ({
    startTime: '',
    endTime: '',
    lastImage: '',
})

export const mutations = {
    setTime(state, data) {
        state.startTime = data.startTime
        state.endTime = data.endTime
        state.lastImage = data.lastImage
    },
}
