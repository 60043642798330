// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div v-show="active" class="tab-pane" role="tabpanel">
        <slot />
    </div>
</template>

<script>
    export default {
        componentName: 'TabPane',

        props: {
            name: String,
            label: String,
        },

        computed: {
            active() {
                return this.$parent.activePane === this.name
            },
        },

        mounted() {
            this.$parent.addPane(this)
        },

        destroyed() {
            this.$parent.removePane(this)
        },
    }
</script>
