import JSZip from 'jszip'
import { saveAs } from 'file-saver'

// Function to fetch image and convert to base64
async function loadImageAsBase64(url) {
    const response = await fetch(url)
    const blob = await response.blob()
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = function () { // eslint-disable-line
            resolve(reader.result)
        }
        reader.onerror = reject
        reader.readAsDataURL(blob)
    })
}

// Plugin to create and download zip file
export default ({}, inject) => { // eslint-disable-line
    inject('zipper', {
        async createAndDownloadZip(imagesList, zipFileName, imagePrefix) {
            const zip = new JSZip()

            const promises = imagesList.map(async (imageURL, index) => {
                try {
                    const base64Image = await loadImageAsBase64(imageURL)
                    const imageName = `${imagePrefix}_${index}.png`
                    zip.file(imageName, base64Image.split(',')[1], { base64: true })
                } catch (error) {
                    console.error(`Error loading image ${index}:`, error)  // eslint-disable-line
                }
            })

            try {
                await Promise.all(promises)
                const content = await zip.generateAsync({ type: 'blob' })
                saveAs(content, zipFileName)
            } catch (error) {
                console.error('Error processing images:', error) // eslint-disable-line
            }
        },
    })
}
