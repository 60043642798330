// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _get from 'lodash/get'
import _assign from 'lodash/assign'
import _includes from 'lodash/includes'

import axios from 'viblo-sdk/libs/axios'
import { login } from '~/utils/login'

export const state = () => ({
    user: null,
    roles: null,
    lastSeens: null,
    guest: true,
    showAuthDialog: false,
    showLetRegister: true,
    reminderLogin: false,
    avtUser: null,
    is_partner: null,
})

export const getters = {
    authenticated: state => state.user != null,
    roles: state => (state.user ? state.roles || [] : []),
    hasRole: state => role => (state.roles ? _includes(state.roles, role) : false),
    isEditor: state => state.user && _includes(state.roles, 'editor'),
    userId: state => (state.user ? state.user.id : null),
    canRateComment: state => (state.user ? (state.user.reputation + state.user.qa_reputation) >= 50 : false),
    isUser: state => id => state.user && state.user.id === id,
    isAdmin: state => state.user && state.user.is_admin === true,
    isPartner: state => state.user && state.is_partner != null,
    isCollaborator: state => state.user && _includes(state.roles, 'collaborator'),
    isSocialMediaEditor: state => state.user && _includes(state.roles, 'social-media-editor'),
    isSpamReviewer: state => state.user && _includes(state.roles, 'spam-reviewer'),
    lastSeen: state => (page) => {
        if (state.user) {
            return state.lastSeens[page] || null
        }

        return null
    },
}

export const actions = {
    fetchLastSeen({ state, commit }) {
        if (!state.guest) {
            return axios.get('/me/activity/lastVisit')
                .then(({ data }) => commit('setLastSeens', data))
        }

        return Promise.resolve()
    },

    showAuthDialog() {
        // TODO directly use this function instead of dispatching this action
        // Put it here just for backward compatibility
        login()
    },
}

export const mutations = {
    set(state, auth) {
        const user = _get(auth, 'user', null)
        const isAdmin = _get(auth, 'is_admin', false)
        const roles = _get(auth, 'roles', [])
        const isPartner = _get(auth, 'is_partner', null)

        state.guest = false
        state.user = isAdmin
            ? _assign({}, user, { is_admin: isAdmin })
            : user
        state.roles = roles
        state.is_partner = isPartner
    },

    SET_POSTS_COUNT(state, postsCount) {
        if (state.user.posts_count !== postsCount) {
            state.user.posts_count = postsCount
        }
    },

    setShow(state, showLetRegister) {
        state.showLetRegister = showLetRegister
    },

    setLastSeens(state, data) {
        state.lastSeens = _assign({}, state.lastSeens, data)
    },

    setFollowingTechnicalsCount(state, value) {
        state.user.following_technicals_count += value
    },

    setReminderLogin(state, reminderLogin) {
        state.reminderLogin = reminderLogin
    },

    setAvtUser(state, value) {
        state.avtUser = value
    },

    updateLayout(state, newLayout) {
        state.user.layout = newLayout
    },
}
