// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<script>
    import { full, lite } from '../../lib/markdown'

    export default {
        functional: true,

        props: {
            contents: {
                type: String,
                required: true,
            },

            renderer: {
                type: String,
                default: 'lute',
            },
        },

        render: (h, { props, data }) => {
            const className = ['md-contents', data.staticClass]
            const md = props.renderer === 'lite' ? lite() : full()
            const html = md.render(props.contents)

            return <div class={className} domPropsInnerHTML={html}></div>
        },
    }
</script>
