// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _isEmpty from 'lodash/isEmpty'

export function accepted(rule, value, callback) {
    if (value === true) {
        callback()
    } else {
        callback(new Error(rule.message))
    }
}

export function confirmed(model, prop) {
    return (rule, value, callback) => {
        if (value === model[prop]) {
            callback()
        } else {
            callback(new Error(rule.message))
        }
    }
}

export function requiredIf({ condition, message }, value, callback) {
    if (condition() && _isEmpty(value)) {
        callback(new Error(message))
    } else {
        callback()
    }
}
