// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX

export const state = () => ({
    startTime: '',
    endTime: '',
    active: false,
    happening: false,
    link_annc: '',
    tag_event: '',
})

export const mutations = {
    setTime(state, data) {
        state.startTime = data.startTime
        state.endTime = data.endTime
        state.active = data.active
        state.happening = data.happening
        state.link_annc = data.link_annc
        state.tag_event = data.tag
    },
}
