// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
export default (value) => {
    const intValue = parseInt(value, 10)
    if (Number.isNaN(intValue)) {
        return value
    }

    const sign = intValue > 0 ? '+' : ''
    return `${sign}${intValue}`
}
