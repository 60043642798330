// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { normalize } from 'normalizr'
import _get from 'lodash/get'
import { userArray } from '~/utils/store/schemas'
import { PUT_USERS_TO_STATE } from '../entities/users'

export const state = () => ({
    current: [],
    pagination: {},
})

/**
 * @param {Promise} fetch
 */
export const createFetchAction = fetch => ({ commit }, payload) => fetch(payload).then((users) => {
    const normalizedData = normalize(users.data, userArray)
    const isLoadMore = payload?.params?.isLoadMore

    commit(PUT_USERS_TO_STATE, normalizedData.entities.users, { root: true })

    if (isLoadMore) {
        commit('setDataLoreMore', normalizedData.result)
    } else {
        commit('setCurrent', normalizedData.result)
    }
    commit('setPagination', _get(users, 'meta.pagination'))
})

export const mutations = {
    setCurrent(state, current) {
        state.current = current
    },

    setDataLoreMore(state, current) {
        state.current = [...state.current, ...current]
    },

    setPagination(state, pagination) {
        state.pagination = pagination
    },

    resetData(state) {
        state.current = []
        state.pagination = {}
    },
}
