// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <a
        id="top-btn"
        v-scroll-to="'body'"
        href="javascript:void(0);"
        class="hidden-sm-down"
    >
        <i class="fa fa-arrow-up" aria-hidden="true" />
    </a>
</template>

<script>
    const showButton = (button, offsetTop) => {
        if (window.scrollY > offsetTop) {
            button.classList.add('top-btn-is-visible')
        } else {
            button.classList.remove('top-btn-is-visible')
        }
    }

    export default {
        props: {
            offset: {
                type: Number,
                default: 500,
            },
        },

        mounted() {
            window.addEventListener('scroll', this.checkShowButton)
        },

        destroyed() {
            window.removeEventListener('scroll', this.checkShowButton)
        },

        methods: {
            checkShowButton() {
                showButton(this.$el, this.offset)
            },
        },
    }
</script>

<style lang="css">
    #top-btn {
        opacity: 0;
        z-index: 99;
        right: 1.250rem;
        width: 2.813rem;
        bottom: 1.250rem;
        height: 2.813rem;
        display: flex;
        display: -webkit-box;
        display: -ms-flexbox;
        -webkit-box-align: center;
        -webkit-box-pack: center;
        -ms-flex-align: center;
        -ms-flex-pack: center;
        position: fixed;
        overflow: hidden;
        visibility: hidden;
        align-items: center;
        text-decoration: none;
        justify-content: center;
        background-color: #5488c7;
        border-radius: 0.188rem;
        line-height: 1.6;
        font-size: 0.875rem;
    }

    #top-btn .fa {
        font-size: 1.313rem;
        color: #ffffff;
    }

    #top-btn.top-btn-is-visible {
        visibility: visible;
        opacity: 1;
    }

    @media only screen and (max-width: 765px)  {
        #top-btn {
            bottom: 4.250rem;
        }
    }
</style>
