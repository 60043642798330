// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { getPostsFeed, PostFeedType } from 'viblo-sdk/api/posts'
import { getQuestionsFeed, QuestionFeedType } from 'viblo-sdk/api/questions'
import {
    getTopUsers, getOrganizationPartners, getUserPartners, getSuggestionService,
} from '~/api/placements'
import { loadUsers } from './__utils/entities'

import {
    state as defaultState,
    getters as defaultGetters,
    createFetchAction,
    mutations as defaultMutations,
} from './__modules/question-feed'
import { suggestionService } from '~/constants/suggestions'

const state = () => ({
    ...defaultState,

    sidebar: {
        users: [],
        posts: [],
        organizationPartners: [],
        userPartners: [],
        interviewQuestions: [],
    },
})

const getters = {
    ...defaultGetters,

    getUserPartners: (state, getters, rootState, rootGetters) => {
        if (!state.sidebar.userPartners) return null
        const users = state.sidebar.userPartners

        return users.map(userObj => ({ ...userObj, is_self: userObj.id === rootGetters['auth/userId'] }))
    },
}

const fetchQuestions = ({ feed, params }) => getQuestionsFeed(feed || QuestionFeedType.Newest, params)

const actions = {
    fetch: createFetchAction(fetchQuestions),

    async fetchSidebar(store) {
        const [users, posts, organizationPartners, userPartners, interviewQuestions] = await Promise.all([
            loadUsers(store, getTopUsers('qa_sidebar').then(_ => _.data.data)),
            getPostsFeed(PostFeedType.Newest, { limit: 5, category: 'normal' }).then(_ => _.data),
            getOrganizationPartners().then(_ => _.data.data),
            getUserPartners().then(_ => _.data.data),
            getSuggestionService({ type: suggestionService.INTERVIEW }).then(_ => _.data),
        ])
        store.commit('setSidebar', {
            users, posts, organizationPartners, userPartners, interviewQuestions,
        })
    },
}

const mutations = {
    ...defaultMutations,

    setSidebar(state, data) {
        state.sidebar = data
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
