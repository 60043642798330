import _round from 'lodash/round'

export const state = () => ({
    fontSize: 18,
    lineHeight: 1.75,
    fullScreen: false,
    modeTheme: 'default',
})

export const getters = {
    fontSize(state) {
        return `${state.fontSize}px`
    },

    lineHeight(state) {
        return state.lineHeight
    },

    fullScreen(state) {
        return state.fullScreen
    },
}

export const mutations = {
    increaseFontSize(state) {
        state.fontSize += 1
    },

    decreaseFontSize(state) {
        state.fontSize -= 1
    },

    increaseLineHeight(state) {
        state.lineHeight = _round(state.lineHeight + 0.1, 2)
    },

    decreaseLineHeight(state) {
        state.lineHeight = _round(state.lineHeight - 0.1, 2)
    },

    resetFontSizeAndLineHeight(state) {
        state.fontSize = 18
        state.lineHeight = 1.75
        state.fullScreen = false
        state.modeTheme = 'default'
    },

    showFullScreen(state) {
        state.fullScreen = !state.fullScreen
    },

    switchMode(state, mode) {
        state.modeTheme = mode
    },
}
