// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
const POST_CLIPPED = 'POST_CLIPPED'
const SERIES_CLIPPED = 'SERIES_CLIPPED'
const QUESTION_CLIPPING = 'QUESTION_CLIPPING'
const USER_FOLLOWING = 'USER_FOLLOWING'
const QUESTION_VOTED = 'QUESTION_VOTED'
const ANSWER_VOTED = 'ANSWER_VOTED'
const COMMENT_VOTED = 'COMMENT_VOTED'
const ANSWER_ACCEPTED = 'ANSWER_ACCEPTED'
const POST_VOTED = 'POST_VOTED'

const reputationChange = (reason, status = 0) => {
    switch (reason) {
        case POST_CLIPPED:
        case QUESTION_CLIPPING:
        case SERIES_CLIPPED:
        case ANSWER_ACCEPTED:
            return status ? 3 : -3
        case USER_FOLLOWING:
            return status ? 7 : -7
        case POST_VOTED:
            if (status.newValue === 1) return 4
            return status.oldValue === 1 ? -4 : 0
        case QUESTION_VOTED:
        case COMMENT_VOTED:
            if (status.newValue === 1) return 2
            return status.oldValue === 1 ? -2 : 0
        case ANSWER_VOTED:
            if (status.newValue === 1) return 1
            return status.oldValue === 1 ? -1 : 0
        default:
            return 0
    }
}

export {
    POST_CLIPPED,
    QUESTION_CLIPPING,
    POST_VOTED,
    QUESTION_VOTED,
    ANSWER_VOTED,
    COMMENT_VOTED,
    ANSWER_ACCEPTED,
    USER_FOLLOWING,
    reputationChange,
}
