// TO-DO: get array from database
export const cnameSubdomains = [
    {
        slug: 'sun-ai',
        host: 'blog.sun-asterisk.ai',
    },
    {
        slug: 'sun-ai',
        host: 'sun-ai.sun-asterisk.ai',
    },
]
