// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="registration-form">
        <div class="auth-brand text-center mt-1 mb-1">
            <h1 class="top-header">
                <span>Free</span>&nbsp;service for<br>technical knowledge <span>sharing</span>
            </h1>
            <p class="hidden-sm-down">
                Join us to find useful information required to improve your skills
            </p>
        </div>

        <el-form
            ref="form"
            :model="form"
            :rules="validations"
            :status-icon="true"
        >
            <el-form-item :error="serverErrors.email" prop="email">
                <el-input
                    v-model="form.email"
                    :disabled="!_isEmpty(values.social)"
                    name="email"
                    placeholder="Your E-mail address"
                />
            </el-form-item>

            <div class="d-flex justify-content-between">
                <el-form-item :error="serverErrors.name" prop="name" class="mr-05">
                    <el-input
                        v-model="form.name"
                        name="name"
                        placeholder="Your name"
                    />
                </el-form-item>

                <el-form-item :error="serverErrors.username" prop="username" class="ml-05">
                    <el-input
                        v-model="form.username"
                        name="username"
                        placeholder="Your username"
                    />
                </el-form-item>
            </div>

            <el-form-item :error="serverErrors.password" prop="password">
                <el-input
                    v-model="form.password"
                    name="password"
                    type="password"
                    placeholder="Password"
                />
            </el-form-item>

            <el-form-item :error="serverErrors.password_confirmation" prop="password_confirmation">
                <el-input
                    v-model="form.password_confirmation"
                    name="password_confirmation"
                    type="password"
                    placeholder="Confirm your password"
                />
            </el-form-item>

            <el-form-item :error="serverErrors.terms" prop="terms" class="registration-form__terms">
                <el-checkbox v-model="form.terms">
                    I agree to <a href="/terms" target="_blank" rel="noopener">Viblo Terms of Service</a>
                </el-checkbox>
            </el-form-item>
        </el-form>

        <el-button
            :loading="processing"
            type="primary"
            class="full-width text-uppercase"
            @click="submit($refs.form, register)"
        >
            Sign Up
        </el-button>
    </div>
</template>

<script>
    import _assign from 'lodash/assign'
    import _isEmpty from 'lodash/isEmpty'
    import { axios } from 'viblo-sdk'
    import formMixin from '~/utils/form/mixin'
    import { accepted, confirmed } from '~/utils/form/validations'

    const defaultFormValues = {
        name: '',
        email: '',
        terms: false,
        username: '',
        password: '',
        password_confirmation: '',
    }

    export default {
        mixins: [formMixin],

        props: {
            values: {
                type: Object,
                default: () => ({}),
            },
        },

        data() {
            const form = _assign({}, defaultFormValues, this.values)

            const validations = {
                email: [
                    { required: true, message: 'Email is required' },
                    { type: 'email', message: 'Must be a valid email' },
                    { max: 255, message: 'Email must not be longer than 255 characters' },
                ],
                name: [
                    { required: true, message: 'Name is required' },
                    { max: 50, message: 'Must be less than 50 characters' },
                ],
                username: [
                    { required: true, message: 'Username is required' },
                    { max: 20, message: 'Must be less than 20 characters' },
                ],
                password: [
                    { required: true, message: 'Password is required' },
                    { min: 6, message: 'Password must has at least 6 characters' },
                ],
                password_confirmation: [
                    { required: true, message: 'Password confirmation is required' },
                    { validator: confirmed(form, 'password'), message: 'Password confirmation does not match' },
                ],
                terms: [
                    { validator: accepted, message: 'Please agree to our Terms of service' },
                ],
            }

            return {
                form,
                validations,
                processing: false,
            }
        },

        methods: {
            _isEmpty,

            async register() {
                this.processing = true

                try {
                    const { data: response } = await axios.post('/register', this.form, {
                        baseURL: '/',
                    })

                    this.sendGaEvent()

                    if (response.must_verify_email) {
                        this.$message({
                            message: "You've been signed up. Please check your E-mail address to confirm your new account.",
                            showClose: true,
                            duration: 0,
                        })

                        this.$emit('sent')
                    } else {
                        window.location.href = '/'
                    }
                } catch (error) {
                    this.handleError(error)
                } finally {
                    this.processing = false
                }
            },

            sendGaEvent() {
                this.$gtag.event('authentication', {
                    eventCategory: 'Authentication',
                    eventAction: this.values.social ? 'Sign up|Social' : 'Sign up',
                    eventLabel: 'Regular',
                })
            },

            reset() {
                this.serverErrors = {}
                this.$refs.form.clearValidate()
                this.$refs.form.resetFields()
            },
        },
    }
</script>

<style lang="scss">
    .registration-form {
        &__terms {
            margin-bottom: 30px;

            .el-form-item__content {
                line-height: normal;

                .el-checkbox {
                    margin-bottom: 0;
                }
            }
        }
    }
</style>
