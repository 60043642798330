// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
const state = () => ({
    messages: [],
    newPosts: 0,
})

const mutations = {
    pushMessages(state, messages) {
        state.messages = [...state.messages, ...messages]
    },

    popMessage(state) {
        state.messages = state.messages.slice(0, state.messages.length - 1)
    },

    pushNewPost(state) {
        state.newPosts += 1
    },

    clearNewPost(state) {
        state.newPosts = 0
    },
}

export default {
    state,
    mutations,
}
