// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _get from 'lodash/get'
import _mapValues from 'lodash/mapValues'
import { normalize } from 'normalizr'
import { questionArray } from '~/utils/store/schemas'
import { PUT_USERS_TO_STATE } from '../entities/users'
import { PUT_QUESTIONS_TO_STATE } from '../entities/questions'

export const state = () => ({
    byId: {},
    current: [],
    pagination: {},
})

export const getters = {
    get: state => id => state.byId[id],
}

/**
 * @param {Promise} fetch
 */
export const createFetchAction = fetch => ({ commit, rootState }, payload) => fetch(payload).then((questions) => {
    const normalizedData = normalize(questions.data, questionArray)
    const entities = normalizedData.entities
    const isLoadMore = payload?.params?.isLoadMore

    commit(PUT_QUESTIONS_TO_STATE, entities.questions, { root: true })
    commit(PUT_USERS_TO_STATE, entities.users, { root: true })

    if (isLoadMore) {
        commit('setDataLoreMore', normalizedData.result)
    } else {
        commit('setCurrent', normalizedData.result)
    }

    commit('setPagination', questions.meta.pagination)

    const authUserId = _get(rootState, 'auth.user.id')
    const extra = _mapValues(entities.questions, question => ({
        is_own: question.user_id === authUserId,
        contributors: question.contributors,
    }))
    commit('setData', extra)
})

export const mutations = {
    setData(state, data) {
        state.byId = data
    },

    setCurrent(state, current) {
        state.current = current
    },

    setPagination(state, pagination) {
        state.pagination = pagination
    },

    setDataLoreMore(state, current) {
        state.current = [...state.current, ...current]
    },

    resetData(state) {
        state.current = []
        state.pagination = {}
    },
}
