// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div :class="{ 'is-new': !notification.is_read }" class="notification-item word-break">
        <div class="notification-item__avatar">
            <Avatar
                v-if="sender"
                :image="sender.avatar"
                :username="sender.username"
            />
        </div>

        <!-- Legacy notification only -->
        <div v-if="notification.data.message" class="notification-item__message">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span class="mb-0" v-html="notification.data.message" />
            <br>
            <small>{{ notification.created_at | humanize-time }}</small>
        </div>

        <a
            v-else
            :href="targetUrl"
            class="notification-item__message link"
            @click.prevent="onClickUrl"
        >
            <div>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span class="mb-0" v-html="notification.data.title.html" />
                <br>
                <small>{{ notification.created_at | humanize-time }}</small>
            </div>
        </a>
    </div>
</template>

<script>
    import _get from 'lodash/get'
    import { baseUrl } from '~/utils/url'
    import Avatar from '~/components/shared/user/Avatar.vue'

    export default {
        components: {
            Avatar,
        },

        props: {
            notification: {
                type: Object,
                required: true,
            },
        },

        computed: {
            sender() {
                return _get(this.notification, 'sender.data')
            },

            answerUrl() {
                const commentMatch = /\/a\/[a-zA-Z0-9]+#comment-([a-zA-Z0-9]+)/.exec(this.notification.data.url)
                const answerMatch = /^\/(a\/[a-zA-Z0-9]+)/.exec(this.notification.data.url)
                if (commentMatch) {
                    return `${baseUrl}/c/${commentMatch[1]}`
                }
                return answerMatch ? `${baseUrl}/${answerMatch[1]}` : null
            },

            commentUrl() {
                const commentMatch = /^\/(c\/[a-zA-Z0-9]+)/.exec(this.notification.data.url)
                return commentMatch ? `${baseUrl}/${commentMatch[1]}` : null
            },

            targetUrl() {
                return !this.answerUrl ? this.notification.data.url : this.answerUrl
            },

            facebookUrl() {
                return /^(http[s]):\/\/(www\.)?facebook.com(\/.*)?/.test(this.notification.data.url)
            },
        },

        methods: {
            onClickUrl() {
                if (this.answerUrl) {
                    window.location = this.answerUrl
                } else if (this.commentUrl) {
                    window.location = this.commentUrl
                } else if (this.facebookUrl) {
                    window.open(this.notification.data.url)
                } else {
                    this.$router.push(this.notification.data.url)
                }
            },
        },
    }
</script>

<style lang="scss">
    @import '../../assets/sass/bootstrap/fonts';
    @import '../../assets/sass/bootstrap/colors';
    @import '../../assets/sass/bootstrap/borders';

    .notification-item {
        display: flex;
        white-space: normal;
        align-items: center;
        border-bottom: 1px solid $base-border-color;
        cursor: pointer;
        word-break: break-word;

        background-color: #fff;

        &:hover {
            background-color: $gray-100;
        }

        &.is-new {
            background-color: $highlighted-item-bg-color;

            &:hover {
                background-color: $highlighted-item-hover-bg-color;
            }
        }

        &__avatar {
            min-width: 2.5rem;
            margin: .5rem .7rem;

            .avatar {
                width: 40px;
                height: 40px;
            }
        }

        &__message {
            font-size: $font-size-xs;
            word-spacing: 1px;
            display: block;
            width: 100%;
            padding: .5rem 1rem .5rem 0;

            b {
                color: $blue;
            }

            a {
                font-weight: bold;
            }
        }
    }
    .theme-dark {
        .notification-item {
            background: $black;
            color: $white;
            border-color: $dark-border;

            &:hover {
            background-color: $dark-highlight;
        }
        }
    }

</style>
