// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _get from 'lodash/get'
import _find from 'lodash/find'
import { getOrganizations } from '../api/organizations'

const state = () => ({
    organizations: [],
})

const actions = {
    async getOrganizations({ rootState, commit }) {
        const authUser = _get(rootState, 'auth.user')
        const fetchOrganizations = () => getOrganizations()
            .then(({ data }) => data.data)
            .catch(() => [])

        const organizations = authUser ? await fetchOrganizations() : []

        commit('set_organizations', organizations)
    },
}

const getters = {
    byId: state => id => _find(state.organizations, organization => organization.id === id),
}

const mutations = {
    set_organizations(state, organizations) {
        state.organizations = organizations
    },
}

export {
    state, actions, mutations, getters,
}
