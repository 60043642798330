// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { getTagFollowers } from 'viblo-sdk/api/tags'
import { state, mutations, createFetchAction } from '../__modules/user-list'

const actions = {
    fetch: createFetchAction(({ tag, params }) => getTagFollowers(tag, params)),
}

export default {
    state,
    actions,
    mutations,
}
