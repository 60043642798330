// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import axios from 'viblo-sdk/libs/axios'

export const fetchAllOrganizations = params => axios.get('/organizations', { params })
export const getOrganizations = params => axios.get('/me/organizations', { params })
export const getUserOrganizations = user => axios.get(`/users/${user}/organizations`)
export const getOrganizationEdit = slug => axios.get(`/me/organizations/${slug}/edit`)
export const getVerifyToken = (slug, params) => axios.get(`/me/organizations/${slug}/get-token`, { params })
export const websiteVerify = (slug, data) => axios.post(`/me/organizations/${slug}/verify`, data)
export const sendVerificationMail = (slug, data) => axios.post(`/me/organizations/${slug}/send-mail`, data)
export const create = data => axios.post('/me/organizations', data)
export const update = (slug, data) => axios.put(`/me/organizations/${slug}`, data)
export const destroy = slug => axios.delete(`/me/organizations/${slug}`)
export const leave = slug => axios.delete(`/organizations/${slug}/leave`)

export const getExistingMembers = (slug, params) => axios.get(`/organizations/${slug}/members`, { params })
export const getTopMembers = (slug, params) => axios.get(`/organizations/${slug}/top-members`, { params })
export const getFollowers = (slug, params) => axios.get(`/organizations/${slug}/followers`, { params }).then(({ data }) => data)

export const removeMember = (slug, data) => axios.delete(`/organizations/${slug}/members`, { data })
export const getOrganizationRequests = slug => axios.get(`/organizations/${slug}/member-requests`)
export const changeRoleMember = (slug, username, data) => axios.patch(`/organizations/${slug}/users/${username}/change-roles`, data)
export const fetchSubmittedDrafts = (slug, params) => axios.get(`/organizations/${slug}/submitted-drafts`, { params })

export const subscribe = slug => axios.put(`/me/subscriptions/organization/${slug}`)
export const unsubscribe = slug => axios.delete(`/me/subscriptions/organization/${slug}`)

export const getFollowings = params => axios.get('/me/subscribed/organizations', { params }).then(data => data.data)
export const getFollowingsWithUser = (username, params) => axios.get(`/users/${username}/following-organizations`, { params }).then(data => data.data)

export const fetchOrganization = slug => axios.get(`/organizations/${slug}`).then(data => data.data)
export const fetchPostOrganization = (slug, params) => axios.get(`/organizations/${slug}/posts`, { params })
export const fetchVideoOrganization = (slug, params) => axios.get(`/organizations/${slug}/videos`, { params })
export const fetchVideoOrganizationSubdomain = (slug, params) => axios.get(`/organizations/${slug}/videos-subdomain`, { params })
export const fetchVideoPinOrganizationSubdomain = (slug, params) => axios.get(`/organizations/${slug}/videos-pin-subdomain`, { params })
export const getImages = slug => axios.get(`/organizations/${slug}/images`).then(data => data.data)

export const getTechnologyStacks = slug => axios.get(`/o/${slug}/technologies`)
export const updateTechnology = (slug, data) => axios.post(`/o/${slug}/technologies`, data)

export const getOrganizationStats = (slug, params) => axios.get(`/organizations/${slug}/stats`, { params })

export const getOrganizationGeneralStats = (slug, params) => axios.get(`/organizations/${slug}/stats/general`, { params })

export const getAllOrganizationWithCheck = params => axios.get('/organizations-with-check', { params })

export const getListPostPinOrganization = (slug, params) => axios.get(`/organizations/${slug}/pinned-posts`, { params })
export const pinPostsOrganization = (slug, data) => axios.post(`/pin-posts-organization/${slug}`, data)
export const pinVideoOrganization = (slug, orgSlug) => axios.put(`/pin-video-organization/${slug}/${orgSlug}`)
export const unPinVideoOrganization = slug => axios.put(`/un-pin-video-organization/${slug}`)
