// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _isEmpty from 'lodash/isEmpty'
import _assign from 'lodash/assign'
import _mapValues from 'lodash/mapValues'
import _merge from 'lodash/merge'

import { subscribe } from 'viblo-sdk/api/me'
import { SubscribableType } from 'viblo-sdk/libs/interactions'
import { getHoverCard } from '~/api/users'

const state = () => ({
    all: {},
})

const getters = {
    get: state => (username) => {
        const user = state.all[username]

        return !_isEmpty(user) ? user : null
    },
}

const actions = {
    async follow({ commit, state }, { username, value }) {
        await subscribe(SubscribableType.User, username, value)
        const user = state.all[username]
        commit('updateHovercard', {
            [user.username]: {
                following: value,
                followers_count: user.followers_count + (value ? 1 : -1),
                reputation: user.reputation + (value ? 7 : -7),
            },
        })
    },

    async getUser({ state, commit }, username) {
        if (_isEmpty(state.all[username])) {
            const newuser = await getHoverCard(username).then(({ data }) => data)
            commit('putToHovercards', newuser)
        }
    },
}


const mutations = {
    putToHovercards(state, user) {
        state.all = _assign({}, state.all, { [user.username]: user })
    },

    updateHovercard(state, user) {
        const updateUser = _mapValues(user, (_user, username) => state.all[username])
        const slice = _merge({}, updateUser, user)

        state.all = _assign({}, state.all, slice)
    },
}

export default {
    state,
    actions,
    getters,
    mutations,
}
