// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _curry from 'lodash/curry'

/**
 * @param {string} defaultValue
 * @param {Event} event
 */
const useDefaultAvatar = _curry((defaultValue, event) => {
    event.target.setAttribute('src', defaultValue)
    event.target.setAttribute('srcset', `${defaultValue} 1x`)
})

/** @param {Element} el */
export default function (el, { value }) {
    const defaultAvatar = typeof value !== 'string'
        ? `${process.env.APP_URL}/images/mm.png`
        : `https://www.gravatar.com/avatar/${value}?s=80&d=mm`

    el.addEventListener('error', useDefaultAvatar(defaultAvatar), {
        once: true,
    })
}
