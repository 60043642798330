import axios from 'viblo-sdk/libs/axios'
import Vue from 'vue'

export const getHost = () => {
    const { vars } = Vue.prototype.$_store.state.config
    return vars.search_host !== 'undefined'
        && vars.search_host
        ? vars.search_host
        : ''
}

export const search = (type, params) => axios.get(`${getHost()}/search/${type}`, { params }).then(_ => _.data)

export const multisearch = (searchQuery, params) => axios.get(`${getHost()}/search/multi`, {
    params: {
        q: searchQuery,
        ...params,
    },
}).then(_ => _.data)
