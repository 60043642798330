// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <el-popover
        ref="popover"
        :append-to-body="false"
        placement="bottom-end"
        transition="el-zoom-in-top"
        class="flyout"
        popper-class="flyout__popper"
        v-on="$listeners"
    >
        <div slot="reference" class="el-badge">
            <el-button :icon="icon" type="text" class="text-muted" />
            <sup v-if="badge > 0 && badge <= 99" class="el-badge__content is-fixed">
                {{ badge }}
            </sup>
            <sup v-if="badge > 99" class="el-badge__content is-fixed">
                99+
            </sup>
        </div>

        <div v-if="$slots.header" class="flyout__header">
            <slot name="header" />
        </div>

        <div class="flyout__body">
            <el-scrollbar class="flyout__scroll" wrap-class="flyout__scroll-wrap">
                <slot />
            </el-scrollbar>
        </div>

        <div v-if="$slots.footer" class="flyout__footer">
            <slot name="footer" />
        </div>
    </el-popover>
</template>

<script>
    export default {
        props: {
            icon: String,
            badge: [String, Number],
        },

        methods: {
            toggleMenu() {
                this.$refs.popover.doToggle()
            },

            open() {
                this.$refs.popover.doShow()
            },

            close() {
                this.$refs.popover.doClose()
            },
        },
    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/colors";
    @import "~assets/sass/bootstrap/mixins";

    .flyout {
        .el-button {
            padding: 0;
            font-size: 1.3em;

            &:focus {
                outline: none
            }
        }

        .el-badge__content {
            right: 50%;
        }

        &__header, &__footer {
            padding: .5rem .8rem;
            font-size: 1rem;
        }

        &__header {
            border-bottom: 1px solid $gray-300;
        }

        &__body {
            text-align: left;
        }

        &__footer {
            border-top: 1px solid $gray-300;
        }

        &__popper {
            padding: 0;
            border-color: $gray-300;
            max-width: 500px;
            min-width: 240px;
        }

        &__scroll {
            &-wrap {
                max-height: 380px;
            }
        }
    }

    @media screen and (max-width: 410px) {
        // Workaround for weird popper.js issue
        .flyout {
            &__popper {
                left: 5px !important;
                right: 5px !important;
            }
        }
    }

    .theme-dark {
        .flyout {
            &__popper {
                background: $black;
                color: $white;
                border-color: $dark-border;

                .el-popper[x-placement^="bottom"] .popper__arrow {
                    border-bottom-color: $dark-border !important;

                    &::after {
                        border: none !important;
                    }
                }
            }

            &__header {
                border-bottom: 1px solid $dark-border;
            }

            &__footer {
                border-top: 1px solid $dark-border;
            }
        }
    }
</style>
