import FingerprintJS from '@fingerprintjs/fingerprintjs'

export const trackVisitorInfo = () => FingerprintJS.load()
    .then(fp => fp.get())
    .then(result => result)

export const trackUserAgentInfo = async () => {
    const visitor = await trackVisitorInfo()
    const { visitorId } = visitor
    let userAgentObj = {}
    if (typeof window !== 'undefined') {
        const { navigator } = window
        const { platform, userAgent } = navigator
        userAgentObj = {
            platform,
            userAgent,
        }
    }

    return {
        visitor: {
            id: visitorId,
            ...userAgentObj,
        },
    }
}
