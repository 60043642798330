// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { fetchBadges } from '~/api/stats'

export const state = () => ({
    items: {},
})

export const actions = {
    async fetch({ commit, rootGetters }) {
        const authenticated = rootGetters['auth/authenticated']

        if (!authenticated) return commit('setItems', {})

        const badges = await fetchBadges().then(({ data }) => data)
        return commit('setItems', badges)
    },
}

export const mutations = {
    setItems(state, badges) {
        state.items = badges
    },
}
