// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _get from 'lodash/get'
import _has from 'lodash/has'
import _pick from 'lodash/pick'
import _assign from 'lodash/assign'
import _each from 'lodash/each'
import _indexOf from 'lodash/indexOf'
import _flow from 'lodash/flow'
import _fpMap from 'lodash/fp/map'
import _fpGroupBy from 'lodash/fp/groupBy'
import _fpMapValues from 'lodash/fp/mapValues'

import { normalize } from 'normalizr'
import { postComment, updateComment, deleteComment } from 'viblo-sdk/api/comments'
import { RateableType } from 'viblo-sdk/libs/interactions'
import { comment as commentScheamas } from '~/utils/store/schemas'
import { voteAction } from '~/utils/store/voting'

import { reportComment } from '~/api/comments'

import { PUT_USERS_TO_STATE } from '../entities/users'
import { PUT_POST_INCREMENT } from '../entities/posts'

export const defaultState = {
    byId: {},
    threads: {},
}

export const getters = {
    get: state => id => state.byId[id],
    thread: state => threadId => state.threads[threadId],
}

export const actions = {
    add: async ({ commit }, { commentable, values }) => {
        const response = await postComment(commentable.type, commentable.hashId, values)
        const newComment = response.data.comment.data

        const { entities, result } = normalize(newComment, commentScheamas)

        commit('add', entities.comments[result])
        commit(PUT_USERS_TO_STATE, entities.users, { root: true })
        commit(PUT_POST_INCREMENT, { [commentable.id]: { comments_count: 1 } }, { root: true })

        return newComment
    },

    remove: async ({ state, commit }, comment) => {
        if (!_has(state.byId, comment.id)) {
            const error = new Error('Comment is not in this store')
            return Promise.reject(error)
        }

        const response = await deleteComment(comment.hash_id)

        commit('remove', comment)
        commit(PUT_POST_INCREMENT, { [comment.commentable_id]: { comments_count: -1 } }, { root: true })

        return _get(response.data, 'deleted.length')
    },

    update: async ({ commit }, { id, values }) => {
        const { comment } = await updateComment(id, values)
            .then(response => response.data)

        const updated = _pick(comment.data, ['id', 'contents', 'updated_at'])

        commit('update', updated)
    },

    report(_, { id, values }) {
        return reportComment(id, values)
    },

    vote: voteAction(
        RateableType.Comment,
        (id, state) => state.byId[id],
        (id, values, commit) => commit('update', { id, ...values })
    ),
}

export const createMutations = (options = {}) => {
    const {
        threadKeyName = null,
    } = options

    const getThreadKey = (comment) => {
        if (typeof threadKeyName === 'function') {
            return threadKeyName(comment)
        }

        if (!threadKeyName) {
            return 'root'
        }

        return comment[threadKeyName] || 'root'
    }

    return {
        set(state, data) {
            if (data && data.threads) {
                state.byId = data.comments
                state.threads = data.threads
            } else if (data) {
                state.byId = data
                state.threads = _flow(
                    _fpGroupBy(getThreadKey),
                    _fpMapValues(_fpMap('id'))
                )(data)
            } else {
                state.byId = {}
                state.threads = {}
            }
        },

        add(state, newComment) {
            state.byId = _assign({}, state.byId, {
                [newComment.id]: newComment,
            })

            let parentThread = getThreadKey(newComment)

            if (_indexOf(parentThread, state.threads.root) < 0) {
                _each(state.threads, (thread, index) => {
                    if (_indexOf(thread, parentThread) >= 0 && index !== 'root') {
                        parentThread = index
                    }
                })
            }

            const threadCommentIds = state.threads[parentThread] || []

            state.threads = _assign({}, state.threads, {
                [parentThread]: [...threadCommentIds, newComment.id],
            })

            return true
        },

        update(state, comment) {
            const updatedComment = _assign({}, state.byId[comment.id], comment)

            state.byId = _assign({}, state.byId, {
                [comment.id]: updatedComment,
            })
        },

        remove(state, removedComment) {
            const com = _get(state.byId, removedComment.id)

            com.deleted_at = Date.now()
        },
    }
}
