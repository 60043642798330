// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
const tag = [
    'followers_count',
    'image',
    'name',
    'posts_count',
    'primary',
    'questions_count',
    'slug',
    'subscribe_url',
    'subscribed',
    'system',
    'url',
]

const user = [
    'id',
    'username',
    'name',
    'avatar',
    'following',
    'reputation',
    'followers_count',
    'posts_count',
    'questions_count',
    'answers_count',
    'total_post_views',
    'banned_at',
    'level_partner',
]

const question = [
    'id',
    'hash_id',
    'user_id',
    'title',
    'tags',
    'seo',
    'title_slug',
    'solved',
    'accepted_answer_id',
    'points',
    'rated_value',
    'clipped',
    'clips_count',
    'views_count',
    'comments_count',
    'answers_count',
    'created_at',
    'updated_at',
    'canonical_url',
]

export { tag, user, question }
