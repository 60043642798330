// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
let ready = false
let queue = []

const noTracking = ({
    page: () => {},
    event: () => {},
    time: () => {},
    social: () => {},
})

const tracker = name => ({
    page: (...args) => gaCall(`${name}.send`, 'pageview', ...args),
    event: (...args) => gaCall(`${name}.send`, 'event', ...args),
    time: (...args) => gaCall(`${name}.send`, 'timing', ...args),
    social: (...args) => gaCall(`${name}.send`, 'social', ...args),
})

const features = {
    tracker: (uaID, name) => {
        if (!uaID) {
            /* eslint-disable-next-line no-console */
            console.warn('No Tracker ID provided')
            return noTracking
        }

        create(uaID, name)
        return tracker(name)
    },
    page: (...args) => gaCall('send', 'pageview', ...args),
    event: (...args) => gaCall('send', 'event', ...args),
    time: (...args) => gaCall('send', 'timing', ...args),
    social: (...args) => gaCall('send', 'social', ...args),
    ready(cb) {
        if (ready) {
            cb(this)
        } else {
            queue.push(cb)
        }
    },
}

function create(uaId, trackerName = null) {
    if (!uaId) {
        /* eslint-disable no-console */
        console.warn('No Id provided')
    }

    gaCall('create', uaId, 'auto', trackerName)
    ready = true
    queue.forEach(item => item(features))
    queue = []
}

function setUp(...args) {
    window.GoogleAnalyticsObject = 'ga'
    window.ga = window.ga || function ga() {
        (window.ga.q = window.ga.q || []).push(args)
    }
    window.ga.l = 1 * new Date()
}

function gaCall(action, ...args) {
    const ga = window.ga
    if (!ga) {
        /* eslint-disable no-console */
        console.warn('Google analytics is not available')
        return
    }

    ga(action, ...args)
}

export default { create, setUp, features }
