// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { normalize } from 'normalizr'
import { userArray, organizationArray } from '~/utils/store/schemas'

export async function loadUsers({ commit }, get) {
    const users = await get
    const normalizedUsers = normalize(users, userArray)

    commit('entities/users/put', normalizedUsers.entities.users, { root: true })

    return normalizedUsers.result
}

export async function loadOrganizations({ commit }, get) {
    const organizations = await get
    const normalizedOrganizations = normalize(organizations, organizationArray)

    commit('entities/organizations/put', normalizedOrganizations.entities.organizations, { root: true })

    return normalizedOrganizations.result
}
