// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _indexOf from 'lodash/indexOf'
import { handlePageError, getContentRouteParams } from '~/utils/pages'
import { ACTION_FETCH_QUESTION } from '~/store/questionView'

export default async function ({
    route, redirect, store, error,
}) {
    const isSpecialProfile = store.state.config.vars.is_special_profile
    const profileList = store.state.config.vars.special_profiles

    const { fullPath } = route

    try {
        const { hashId } = getContentRouteParams(route.params.question)
        const question = await store.dispatch(ACTION_FETCH_QUESTION, hashId)

        // redirect to app url if author doesn't subdomain
        if (isSpecialProfile && isAuthorInSubdomainList(profileList, question)) {
            redirect(`${process.env.APP_URL}${fullPath !== '/' ? fullPath : ''}`)
        }
    } catch (err) {
        handlePageError(error, err)
    }
}

function isAuthorInSubdomainList(profileList, question) {
    return question && _indexOf(profileList, question.user.data.username) === -1
}
