<template>
    <div class="box-last">
        <div
            class="box-last__content position-relative
            d-flex flex-column align-items-center justify-content-center text-white"
        >
            <div class="box-last__bg-up position-absolute" />
            <div class="box-last__popup">
                <div class="position-relative box-last__popup__header">
                    <div class="title text-uppercase font-italic font-weight-bold">
                        {{ $t('wrappedUp.chooseImage') }}
                    </div>
                    <img
                        class="position-absolute"
                        src="/images/wrapped-up/close-around.png"
                        width="32"
                        @click="$emit('display', false)"
                    >
                </div>
                <div class="grid-container">
                    <div v-for="(image, index) in imagesList" :key="index" class="grid-item">
                        <el-checkbox
                            class="mb-1 d-flex flex-column justity-content-center align-items-center"
                            :value="image.selected"
                            @change="(value) => onSelectImage(index, value)"
                        >
                            <img
                                :src="image.src"
                                width="110"
                                alt=""
                            >
                        </el-checkbox>
                    </div>
                </div>
                <div class="btn-content d-flex">
                    <div
                        :class="`btn-content__left d-flex justify-content-center align-items-center
                        ${downloadDisabled ? 'download-disabled' : ''}`"
                        @click="downloadImages"
                    >
                        <img src="/images/wrapped-up/download.svg" width="20">
                        <div class="text text-uppercase font-italic font-weight-bold ml-2">
                            {{ $t('wrappedUp.saveImage') }}
                        </div>
                    </div>
                    <div class="btn-content__right d-flex justify-content-center align-items-center">
                        <el-checkbox v-model="checked" @change="onCheckImages">
                            <div class="text text-uppercase font-italic font-weight-bold">
                                {{ $t('wrappedUp.all') }}
                            </div>
                        </el-checkbox>
                    </div>
                </div>
            </div>
            <div class="box-last__bg-down" />
        </div>
    </div>
</template>

<script>
    import _map from 'lodash/map'
    import _each from 'lodash/each'
    import { downloadFile } from '~/utils/helpers'

    export default {
        props: {
            images: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                checked: true,
                imagesList: this.images,
                imageIndex: 1,
            }
        },

        computed: {
            downloadDisabled() {
                const imagesDownload = []
                this.imagesList.forEach((image) => {
                    if (image.selected) {
                        imagesDownload.push(image)
                    }
                })

                return imagesDownload.length <= 0
            },
        },

        mounted() {
            this.renderImageList(true)
        },

        methods: {
            onSelectImage(position, value) {
                this.imagesList = _map(this.imagesList, (item, index) => {
                    if (position === index) {
                        item.selected = value
                    }
                    return item
                })
                this.checked = this.imagesList.every(image => image.selected)
            },

            onCheckImages(e) {
                this.renderImageList(e)
            },

            renderImageList(value) {
                this.imagesList = _map(this.imagesList, (item) => {
                    item.selected = value
                    return item
                })
            },

            onClose() {
                this.$emit('changeDisplayLastItem', false)
            },

            async downloadImages() {
                if (!this.downloadDisabled) {
                    const selectedImages = this.imagesList.filter(image => image.selected)
                    if (selectedImages.length > 1) {
                        const imageUrls = selectedImages.map(image => image.src)

                        try {
                            // create object URLSearchParams and add image to params
                            const params = new URLSearchParams()
                            imageUrls.forEach((value) => {
                                params.append('images[]', value)
                            })

                            // get params string
                            const paramString = params.toString()

                            const linkElement = document.createElement('a')
                            linkElement.href = `${process.env.APP_URL}/api/me/wrapped-up/download-images?${paramString}`
                            linkElement.target = '_blank'

                            linkElement.click()
                            linkElement.remove()
                        } catch (e) {
                            this.$message.error(this.$t('wrappedUp.downloadMessage'))
                        }
                    } else {
                        _each(this.imagesList, (image) => {
                            if (image.selected && image.src) {
                                downloadFile(image.src, `viblo-wrapped-up-2023-${this.imageIndex}.png`)
                                this.imageIndex += 1
                            } else {
                                this.$message.error(this.$t('wrappedUp.downloadMessage'))
                            }
                        })
                    }
                    this.renderImageList(false)
                    this.checked = false
                }
            },
        },
    }
</script>

<style lang="scss">
    .box-last {
        background-color: rgba(0,0,0,.9);
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        text-align: center;
        top: 0;
        z-index: 1040;
        &__content {
            height: 100%;
            width: 100%;
            .grid-container {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                background-color: #fff;
                margin: 0px 20px 40px 20px;
                padding: 10px 15px;
                border-radius: 10px;
                max-height: 280px;
                overflow: auto;
            }
            .grid-item {
                background-color: rgba(255, 255, 255, 0.8);
                text-align: center;
                img {
                    cursor: pointer;
                    &:hover {
                        opacity: 0.8;
                    }
                }
                .el-checkbox {
                    &__input {
                        margin-bottom: 10px;
                    }
                    &__label {
                        padding: 0px 10px !important;
                    }
                }
            }
        }
        &__popup {
            height: 450px;
            max-width: 800px;
            width: auto;
            background-image: linear-gradient(#529FBF, #D966CD);
            opacity: 1;
            border-radius: 15px;
            z-index: 1040;
            &__header {
                .title {
                    font-family: 'Montserrat ExtraBold';
                    font-size: 12px;
                    padding: 12px;
                }
                img {
                    top: 0.25rem;
                    right: 0.25rem;
                    cursor: pointer;
                }
            }
            .el-checkbox__inner {
                border-radius: 50%;
            }
            .el-checkbox__input.is-checked .el-checkbox__inner {
                background-color: #14CE5E !important;
            }
            .btn-content {
                height: 50px;
                background: #0F59AA;
                margin: 0px 20px;
                border-radius: 10px;
                .download-disabled {
                    cursor: not-allowed;
                    opacity: 0.8;
                }
                &__left {
                    width: 70%;
                    cursor: pointer;
                    .text {
                        font-family: 'Montserrat ExtraBold';
                        font-size: 16px;
                    }
                }
                &__right {
                    width: 30%;
                    cursor: pointer;
                    background-color: #fff;
                    border-radius: 0 10px 10px 0;
                    .text {
                        color: #000 !important;
                    }
                }
            }
        }
        &__bg-up {
            top: 60px;
            width: 360px;
            height: 225px;
            z-index: 1039;
            background-image: url('/images/wrapped-up/bg-up.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
        &__bg-down {
            width: 360px;
            height: 215px;
            background-image: url('/images/wrapped-up/bg-down.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
    }

    @media only screen and (min-width: 875px) {
        .box-last {
            &__content {
                .grid-container {
                    grid-template-columns: repeat(6, minmax(0, 1fr));
                }
            }
            &__popup {
                &__header {
                    .title {
                        font-size: 18px;
                    }
                }
                .btn-content {
                    &__left {
                        font-size: 24px;
                        width: 80%;
                    }
                    &__right {
                        width: 20%;
                    }
                }
            }
        }
    }
</style>
