// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import Vue from 'vue'
import _get from 'lodash/get'
import loadScript from 'load-script'
import analytics from '../lib/ga'

export default ({ app: { router }, store }) => {
    // Inject
    Vue.$ga = analytics.features
    Vue.prototype.$ga = analytics.features
    loadScript('https://www.google-analytics.com/analytics.js', { async: true }, () => {
        analytics.setUp()
        analytics.create(_get(store, 'state.config.analytics_track_id'))

        // First page
        sendPage(router.currentRoute)
        // After route change
        router.afterEach(sendPage)
    })
}

function sendPage(route) {
    const analyticsOption = getAnalyticsOption(route)

    if (analyticsOption.autoTrack !== false) {
        Vue.nextTick(() => {
            Vue.$ga.page({
                ...analyticsOption.analyticsFields,
                title: _get(analyticsOption, 'title', route.name),
                page: _get(analyticsOption, 'page', route.fullPath),
                location: window.location.href,
            })
        })
    }
}

function getAnalyticsOption(route) {
    const matchedComponent = _get(route.matched[0], 'components.default')
    const analyticsOption = _get(matchedComponent, 'options.analytics', {})

    return typeof analyticsOption === 'function'
        ? analyticsOption(route)
        : analyticsOption
}
