// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { RateableType, VoteDir } from 'viblo-sdk/libs/interactions'

/**
 * @param {Number} value
 */
export const deserializeVote = (value) => {
    if (!value) {
        return VoteDir.None
    }

    return value > 0 ? VoteDir.Up : VoteDir.Down
}

/**
 * @param {string} value
 */
export const serializeVote = value => ({
    [VoteDir.Up]: 1,
    [VoteDir.Down]: -1,
    [VoteDir.None]: 0,
}[value])

const reputationValues = {
    [RateableType.Post]: { up: 4, down: 0, none: 0 },
    [RateableType.Series]: { up: 4, down: 0, none: 0 },
    [RateableType.Question]: { up: 2, down: 0, none: 0 },
    [RateableType.Answer]: { up: 1, down: 0, none: 0 },
    [RateableType.Comment]: { up: 2, down: 0, none: 0 },
}

/**
 * @param {Number} score
 * @param {Number} currentScore
 * @param {String} type
 */
export const effectsOnReputation = (score, currentScore, type) => {
    const vote = deserializeVote(score)
    const currentVote = deserializeVote(currentScore)

    const repPoints = reputationValues[type]

    return repPoints
        ? repPoints[vote] - repPoints[currentVote]
        : null
}
