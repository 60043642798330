export const profilePath = [
    '/',
    '/series',
    '/questions',
    '/answers',
    '/clips/posts',
    '/clips/series',
    '/clips/questions',
    '/following',
    '/following/organizations',
    '/followers',
    '/following-tags',
    '/reputations',
    '/contact',
    '/p/',
    '/s/',
    '/q/',
]
