// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
export const vibloServices = [
    {
        name: 'Viblo',
        src: process.env.APP_URL,
        icon: '/favicon.ico',
    },
    {
        name: 'Viblo Code',
        src: process.env.CODE_URL,
        icon: '/images/viblo-code.png',
    },
    {
        name: 'Viblo CTF',
        src: process.env.CTF_URL,
        icon: '/images/viblo-ctf.png',
    },
    {
        name: 'Viblo CV',
        src: process.env.CV_URL,
        icon: '/images/viblo-cv.png',
    },
    {
        name: 'Viblo Learning',
        src: process.env.LEARN_URL,
        icon: '/images/viblo-learn.png',
    },
    {
        name: 'Viblo Partner',
        src: process.env.PARTNER_URL,
        icon: '/images/viblo-partner.png',
    },
    {
        name: 'Viblo Battle',
        src: process.env.BATTLE_URL,
        icon: '/images/viblo-battle.png',
    },
    {
        name: 'Viblo Interview',
        src: process.env.INTERVIEW_URL,
        icon: '/images/viblo-interview.ico',
    },
]
