// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <ul class="main-menu list-unstyled">
        <li class="main-menu__item">
            <nuxt-link
                v-if="guest"
                to="/newest"
                class="link"
                exact
            >
                {{ $t('mainNavbar.mainMenu.posts') }}
            </nuxt-link>
            <span v-else>
                <a
                    v-if="isSubdomain"
                    :href="toAppUrl('/')"
                    class="link"
                    exact
                >
                    {{ $t('mainNavbar.mainMenu.posts') }}
                </a>
                <nuxt-link
                    v-else
                    to="/"
                    class="link"
                    exact
                >
                    {{ $t('mainNavbar.mainMenu.posts') }}
                </nuxt-link>
            </span>
        </li>
        <li id="v-step-2" class="main-menu__item" :class="{'border-tour': step2}">
            <div :class="[step2 ? 'pulse main-menu__vue-tour' : '']">
                <a
                    v-if="isSubdomain"
                    :href="toAppUrl('/questions')"
                    class="link"
                    exact
                >
                    <el-badge :is-dot="!!newQuestionsCount" class="badge-has-new">
                        {{ $t('mainNavbar.mainMenu.questions') }}
                    </el-badge>
                </a>
                <nuxt-link
                    v-else
                    to="/questions"
                    class="link"
                    exact
                >
                    <el-badge :is-dot="!!newQuestionsCount" class="badge-has-new">
                        {{ $t('mainNavbar.mainMenu.questions') }}
                    </el-badge>
                </nuxt-link>
            </div>
        </li>
        <li class="main-menu__item">
            <nuxt-link to="/discussion" class="link" exact>
                {{ $t('mainNavbar.mainMenu.discussions') }}
            </nuxt-link>
        </li>
    </ul>
</template>

<script>
    import _get from 'lodash/get'
    import { mapState } from 'vuex'
    import { toAppUrl } from '~/utils/url'

    export default {
        computed: {
            ...mapState('auth', ['guest']),
            ...mapState({
                newQuestionsCount: state => _get(state, 'badges.items.questions-newest', 0),
            }),
            ...mapState('vueTour', ['step2']),
            ...mapState('config', ['vars']),

            isSubdomain() {
                return this.vars.is_special_organization || this.vars.is_special_profile
            },
        },

        methods: {
            toAppUrl,
        },
    }
</script>

<style lang="scss">
    @import '~assets/sass/bootstrap/colors';

    .main-menu {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        .badge-has-new .el-badge__content {
            right: 0 !important;
            top: 5px;
        }

        &__item {
            margin-right: 2.5rem;

            .link {
                font-weight: bold;
                color: $gray-600;

                &:hover, &.active {
                    color: $dark;
                }
            }
        }

        &__vue-tour {
            padding: 5px;
            margin: 5px;
        }

        .border-tour {
            background-color: white;
            z-index: 1;
            border-radius: 3px;
        }
    }

    .theme-dark {
        .main-menu {
            &__item {
            .link {
                &:hover, &.active {
                    color: $white;
                }
            }
        }
        }
    }

</style>
