// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div :class="{ 'container-fluid': fluid }" class="container">
        <slot />
    </div>
</template>

<script>
    export default {
        props: {
            fluid: Boolean,
        },
    }
</script>
