// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _indexOf from 'lodash/indexOf'
import { handlePageError } from '~/utils/pages'

export default async function ({
    route, redirect, store, error, query,
}) {
    const isSpecialProfile = store.state.config.vars.is_special_profile
    const profileList = store.state.config.vars.special_profiles

    const { fullPath } = route

    try {
        const getSeriesHashId = urlParam => urlParam.split('-').pop()
        const hashId = getSeriesHashId(route.params.hashId)
        const series = await store.dispatch('seriesView/getSeries', { hashId, query })

        // redirect to app url if author doesn't subdomain
        if (isSpecialProfile && isAuthorInSubdomainList(profileList, series)) {
            redirect(`${process.env.APP_URL}${fullPath !== '/' ? fullPath : ''}`)
        }
    } catch (err) {
        handlePageError(error, err)
    }
}

function isAuthorInSubdomainList(profileList, series) {
    return series && _indexOf(profileList, series.user.data.username) === -1
}
