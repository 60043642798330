// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _map from 'lodash/map'
import _assign from 'lodash/assign'
import _get from 'lodash/get'
import _find from 'lodash/find'
import format from 'date-fns/format'
import { updateData } from '~/utils/data'
import {
    getAnnouncementsPin, loadAnnouncements, markAllAsRead,
} from '../api/support'

export const state = () => ({
    indicator: 0,
    items: [],
    processing: false,
    pinnedItem: [],
    post: {},
    related: [],
})

export const actions = {
    loadAnnouncements({ commit }) {
        return loadAnnouncements({ src: 'popover' }).then(({ data }) => {
            commit('setAnnouncements', {
                items: data.posts,
                unread: data.unread_count,
            })
        })
    },

    getPinnedItem({ commit }) {
        return getAnnouncementsPin().then(({ data: { data } }) => {
            if (data.length > 0) {
                commit('setPinnedItem', data)
            }
        })
    },

    markAsRead({ commit, rootState }, slug) {
        const createdAt = _get(rootState, 'auth.user.created_at')
        commit('markAsRead', {
            slug,
            createdAt,
        })
    },

    markAllAsRead({ commit, state }) {
        if (state.indicator > 0) {
            markAllAsRead().then(() => commit('markAllAsRead'))
        }
    },
}

export const mutations = {
    markAsRead(state, { slug, createdAt }) {
        const now = format(new Date(), 'yyyy-MM-dd hh:mm:ss')
        const post = _find(state.items, item => item.slug === slug)
        state.items = updateData(state.items, 'slug', slug, { read_at: now })
        if (post && post.read_at === null && post.published_at > createdAt) {
            state.indicator -= 1
        }
    },

    markAllAsRead(state) {
        const now = format(new Date(), 'yyyy-MM-dd hh:mm:ss')
        state.items = _map(state.items, announcement => _assign({}, announcement, { read_at: now }))
        state.indicator = 0
    },

    setAnnouncements(state, { items, unread }) {
        state.items = items || []
        state.indicator = unread
    },

    setPinnedItem(state, item) {
        state.pinnedItem = item
    },
}
