// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import axios from 'viblo-sdk/libs/axios'

export const getRelatedPosts = slug => axios.get(`/posts/${slug}/related`)
export const getPopularTags = scope => axios.get(`/tags/${scope}/popular`)
export const getTopUsers = context => axios.get('/user-placements', { params: { context } })
export const getTopOrganizations = context => axios.get('/organization-placements', { params: { context } })
export const getSubscribers = (subscribableType, id) => axios.get(`/${subscribableType}/${id}/subscribers`)
export const getRelatedTags = params => axios.get('/related-tags', { params })
export const getRelatedAuthors = params => axios.get('/related-authors', { params })
export const getOrganizationPartners = () => axios.get('/organization-partners')
export const getUserPartners = () => axios.get('/user-partners')
export const getSuggestionInterviewQuestions = () => axios.get('/suggestion/interview-questions')
export const getSuggestionService = params => axios.get('/suggestion/service', { params }).then(_ => _.data)
