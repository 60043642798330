// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div id="app-container" :class="[theme, modeThemeSubdomain]">
        <MainNavbar />
        <div id="main-content">
            <nuxt />
        </div>
        <MainFooter />
        <AuthDialog />
        <ScrollToTop />
        <PopupDownloadApp />
        <!-- <ReminderWhenNotLoginPopup v-if="!authenticated" /> -->
        <FixedBottomBar v-if="!authenticated" />
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations } from 'vuex'

    import offlineAlert from '~/components/mixins/offlineAlert'
    import MainNavbar from '~/components/subdomains/navbar/MainNavbar.vue'
    import MainFooter from '~/components/subdomains/footer/Footer.vue'
    import AuthDialog from '~/components/auth/Popup.vue'
    import ScrollToTop from '~/components/ScrollToTop.vue'

    // const ReminderWhenNotLoginPopup = () => import('~/components/dialogs/ReminderWhenNotLogin.vue')
    const FixedBottomBar = () => import('~/components/posts/FixedBottomBar.vue')
    const PopupDownloadApp = () => import('~/components/dialogs/PopupDownloadApp.vue')

    export default {
        components: {
            MainNavbar,
            AuthDialog,
            MainFooter,
            ScrollToTop,
            // ReminderWhenNotLoginPopup,
            FixedBottomBar,
            PopupDownloadApp,
        },

        mixins: [offlineAlert],

        middleware: ['root-domain-redirect'],

        computed: {
            ...mapState('settings', ['theme']),
            ...mapState('typeControl', ['modeTheme']),
            ...mapGetters('auth', ['authenticated']),
            showPopupNewsletter() {
                return !this.$store.state.settings.viewed_popup_newsletter
            },

            modeThemeSubdomain() {
                return this.$store.state.typeControl.modeTheme
            },
        },

        mounted() {
            const theme = this.$cookies.get('modeTheme') || 'default'
            this.switchMode(theme)
        },

        methods: {
            ...mapMutations({
                switchMode: 'typeControl/switchMode',
            }),
        },
    }
</script>
<style lang="scss">
    @import '~assets/sass/bootstrap/colors';

    .el-popover {
        .suggestion-item-tags {
            padding: 3px 10px;
            background-color: rgb(244, 244, 245);
            color: rgb(144, 147, 153) !important;
            border-radius: 5px;
            margin-right:5px;
            margin-bottom:5px;

            &:hover {
                text-decoration: none;
                background-color: #eaeaea;
                color: #909399;
            }
        }
    }

    .theme-dark {
        .el-popover {
            background-color: #000;
            color: #fff;
            border-color: $dark-gray;

            &__item {
                &--active {
                    background-color: #353535;
                    color: #fff;
                }
            }
        }
    }
</style>
