// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _find from 'lodash/find'
import _pick from 'lodash/pick'
import _merge from 'lodash/merge'
import _mergeWith from 'lodash/mergeWith'
import _mapValues from 'lodash/mapValues'
import _assign from 'lodash/assign'
import _set from 'lodash/set'
import _isEmpty from 'lodash/isEmpty'
import _flow from 'lodash/flow'
import _fpMap from 'lodash/fp/map'
import _fpFilter from 'lodash/fp/filter'

import { subscribe } from 'viblo-sdk/api/me'
import { SubscribableType } from 'viblo-sdk/libs/interactions'
import { reputationChange } from '~/components/reputation'
import { user as attributes } from '~/components/entities/attributes'

export const USER_ENTITIES = 'entities/users'
export const PUT_USERS_TO_STATE = 'entities/users/put'
export const ACTION_FOLLOW_USER = 'entities/users/follow'
export const CHANGE_REPUTATION = 'entities/users/changeReputation'

const pickValues = users => _mapValues(users, user => _pick(user, attributes))

const state = () => ({
    all: {},
})

const getters = {
    get: (state, getters, rootState, rootGetters) => (id) => {
        const user = state.all[id]
        if (_isEmpty(user)) {
            return null
        }

        return _assign({}, user, {
            is_self: user.id === rootGetters['auth/userId'],
        })
    },

    byId: state => ids => _pick(state.all, ids),

    mapId: (state, getters) => ids => _flow(
        _fpFilter(id => state.all[id] != null),
        _fpMap(getters.get)
    )(ids),
}

const actions = {
    async follow({ commit, state }, { username, value }) {
        await subscribe(SubscribableType.User, username, value)
        const user = _find(state.all, { username })
        commit('put', {
            [user.id]: {
                following: value,
                followers_count: user.followers_count + (value ? 1 : -1),
                reputation: user.reputation + (value ? 7 : -7),
            },
        })

        commit('hovercard/updateHovercard', {
            [user.username]: {
                following: value,
                followers_count: user.followers_count + (value ? 1 : -1),
                reputation: user.reputation + (value ? 7 : -7),
            },
        }, { root: true })
    },

    changeReputation({ commit, state, rootState: { auth } }, { reason, status, userId }) {
        const user = state.all[userId]

        if (!auth.user || !user) {
            return
        }

        if (userId !== auth.user.id) {
            user.reputation += reputationChange(reason, status)
            _set(state, `all.${userId}`, user)
            commit('put', state.all)
        }
    },

    changeFollowing({ commit, state }, { userId, following }) {
        const user = state.all[userId]
        if (user) {
            user.following = following
            user.followers_count += following ? 1 : -1
            _set(state, `all.${userId}`, user)
            commit('put', state.all)
        }
    },

    setUser({ commit, state }, user) {
        const userId = user.id
        const oldUserData = state.all[userId]
        const dataList = {}

        // Merge old user data if found user in store:
        user = oldUserData ? _merge({}, oldUserData, user) : user

        _set(dataList, userId, user)

        commit('put', dataList)
    },
}

const mutations = {
    put(state, users) {
        const data = pickValues(users)
        const updatedUsers = _mapValues(data, (user, id) => state.all[id])
        const slice = _merge({}, updatedUsers, data)
        // Set next state:
        state.all = _assign({}, state.all, slice)
    },

    increment(state, users) {
        // eslint-disable-next-line consistent-return
        const increment = (current, next) => {
            if (typeof current === 'number' && typeof next === 'number') {
                return current + next
            }
        }

        const toBeUpdated = _mapValues(users, (user, id) => state.all[id])
        const updatedSlice = _mergeWith({}, toBeUpdated, users, increment)
        const next = _assign({}, state.all, updatedSlice)

        state.all = next
    },
}

export default {
    state,
    actions,
    getters,
    mutations,
}
