// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _pick from 'lodash/pick'
import _assign from 'lodash/assign'
import _isArray from 'lodash/isArray'
import _mapValues from 'lodash/mapValues'
import _mergeWith from 'lodash/mergeWith'

import { subscribe } from 'viblo-sdk/api/me'
import { RateableType, SubscribableType } from 'viblo-sdk/libs/interactions'
import { voteAction } from '~/utils/store/voting'

export const PUT_POST_INCREMENT = 'entities/posts/increment'

const pickValues = posts => _mapValues(posts, post => _pick(post, [
    'id', 'slug', 'title', 'transliterated', 'tags', 'system_tag', 'user_id', 'path', 'canonical_url', 'uploads',
    'status', 'is_published', 'is_shared', 'is_public', 'promoted', 'trending', 'system',
    'thumbnail_url', 'is_video', 'video', 'organization',
    'views_count', 'clips_count', 'comments_count', 'clipped', 'points', 'rated_value', 'scheduled_publish_at',
    'created_at', 'updated_at', 'published_at', 'promoted_at', 'trend_at', 'reading_time', 'can_share_social', 'submit_status', 'profile_pin_position',
]))

const state = () => ({
    all: {},
})

const getters = {
    get: state => id => state.all[id],
    byId: state => ids => _pick(state.all, ids),
    mapId: state => ids => ids.map(id => state.all[id]).filter(item => item),
}

const actions = {
    vote: voteAction(
        RateableType.Post,
        (id, state) => state.all[id],
        (id, values, commit) => commit('put', { [id]: values }),
        'slug'
    ),

    async clip({ state, commit, rootGetters }, { id, value }) {
        const post = state.all[id]

        const response = await subscribe(SubscribableType.Post, post.slug, value)
        const isOwner = rootGetters['auth/userId'] === post.user_id

        if (!isOwner) {
            commit('entities/users/increment', {
                [post.user_id]: {
                    reputation: value ? 3 : -3,
                },
            }, { root: true })
        }

        commit('put', {
            [id]: {
                clipped: value,
                clips_count: post.clips_count + (value ? 1 : -1),
            },
        })

        return response
    },

    async clipSinglePost({ commit }, { post, value, id }) {
        const response = await subscribe(SubscribableType.Post, post.slug, value)

        commit('put', {
            [id]: {
                clipped: value,
                clips_count: post.clips_count + (value ? 1 : -1),
            },
        })

        return response
    },
}

const mutations = {
    put(state, posts) {
        const data = pickValues(posts)

        const updatedPosts = _mapValues(data, (_, id) => state.all[id])
        const performMerge = (current, next) => (_isArray(current) && _isArray(next) ? next : undefined)
        const updatedSlice = _mergeWith({}, updatedPosts, data, performMerge)
        const nextState = _assign({}, state.all, updatedSlice)

        state.all = nextState
    },

    increment(state, posts) {
        const increment = (current, next) => (
            typeof current === 'number' && typeof next === 'number'
                ? current + next
                : undefined
        )

        const toBeUpdated = _mapValues(posts, (_, id) => state.all[id])
        const updatedSlice = _mergeWith({}, toBeUpdated, posts, increment)
        const next = _assign({}, state.all, updatedSlice)

        state.all = next
    },

    updatePinPosition(state, { id, position }) {
        const post = state.all[id]
        if (post) {
            post.profile_pin_position = position
        }
    },
}

export default {
    state,
    actions,
    getters,
    mutations,
}
