<template>
    <v-tour
        name="myTour"
        class="vue-tour-style"
        :steps="steps"
        :callbacks="callbacks"
    >
        <template slot-scope="tour">
            <transition name="fade">
                <v-step
                    v-if="tour.steps[tour.currentStep]"
                    :key="tour.currentStep"
                    :step="tour.steps[tour.currentStep]"
                    :previous-step="tour.previousStep"
                    :next-step="tour.nextStep"
                    :stop="tour.stop"
                    :skip="tour.skip"
                    :is-first="tour.isFirst"
                    :is-last="tour.isLast"
                    :labels="tour.labels"
                >
                    <template>
                        <div slot="actions">
                            <button v-if="!tour.isLast" class="btn btn-primary" @click="closeVueTour(tour)">
                                {{ $t('vueTour.skip') }}
                            </button>
                            <button v-if="!tour.isFirst" class="btn btn-primary" @click="tour.previousStep">
                                {{ $t('vueTour.previous') }}
                            </button>
                            <button v-if="!tour.isLast" class="btn btn-primary" @click="tour.nextStep">
                                {{ $t('vueTour.next') }}
                            </button>
                            <button
                                v-if="tour.isLast"
                                v-scroll-to="'body'"
                                class="btn btn-primary"
                                @click="closeVueTourWhenFinish(tour)"
                            >
                                {{ $t('vueTour.finish') }}
                            </button>
                        </div>
                    </template>
                </v-step>
            </transition>
        </template>
    </v-tour>
</template>
<script>
    export default {
        props: {
            steps: {
                type: Array,
                default: () => [],
            },
            callbacks: {
                type: Object,
                default: () => {},
            },
        },

        methods: {
            closeVueTour(value) {
                return this.$emit('closeVueTour', value)
            },

            closeVueTourWhenFinish(value) {
                return this.$emit('closeVueTourWhenFinish', value)
            },
        },
    }
</script>
