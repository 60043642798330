// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <section v-if="isWrapped || isWrappedWithAdmin" class="section-wrapped-up">
        <div v-if="showWrappedUp" class="v-wrapped-up">
            <div class="btn-wrapped-up-close">
                <i class="el-icon-close" @click="close()" />
            </div>
            <img
                v-tooltip.left
                src="/images/btn-wrapped2023-up.png"
                :title="$t('wrappedUp.lookBack')"
                alt=""
                @click="onDisplay"
            >
        </div>
        <Loading v-if="loading" @skip="skipLoading" />
        <client-only v-if="displayImage && !loading">
            <Tinybox
                v-model="index"
                class="tinybox-custom"
                :images="images"
                :is-loading-image="isLoading"
                loop
                no-thumbs
                is-close-outsite-box
                @close="onCloseBox"
                @prev="onPrevious"
                @next="onNext"
                @change="changeImage"
            />
            <LastItem
                v-if="showLastItem"
                :images="images"
                @display="changeDisplayLastItem"
            />
        </client-only>
        <BackgroundEffect v-if="showEffect" />
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import _each from 'lodash/each'
    import _map from 'lodash/map'
    import _assign from 'lodash/assign'
    import Tinybox from './TinyBox.vue'
    import { get } from '~/api/wrapped-up'
    import BackgroundEffect from '~/components/events/BackgroundEffect.vue'
    import LastItem from '~/components/events/LastItem.vue'
    import Loading from '~/components/events/Loading.vue'

    export default {
        components: {
            Tinybox,
            BackgroundEffect,
            LastItem,
            Loading,
        },

        data() {
            return {
                showWrappedUp: false,
                displayImage: false,
                showEffect: false,
                showLastItem: false,
                loading: false,
                imageIndex: 0,
                index: 0,
                images: [],
                refTinyBox: undefined,
                statusTimeDone: 0,
                isLoading: false,
            }
        },

        computed: {
            ...mapGetters('auth', ['isAdmin']),
            isWrapped() {
                return (
                    new Date(this.$store.state.wrappedUpTime.startTime) <= Date.now()
                    && new Date(this.$store.state.wrappedUpTime.endTime) >= Date.now()
                )
            },

            isWrappedWithAdmin() {
                return (
                    new Date(this.$store.state.wrappedUpTime.endTime) >= Date.now() && this.isAdmin
                )
            },

            user() {
                return this.$route.query.user
            },
        },

        mounted() {
            const value = localStorage.getItem('show-wrapped-up')
            localStorage.setItem('show-wrapped-up', value !== null ? value : true)
            this.showWrappedUp = value !== null ? JSON.parse(value) : true
            if (this.$route.hash === '#wrappedup2023' && (this.isWrapped || this.isAdmin)) {
                this.onDisplay()
            }

            window.addEventListener('keyup', this.keyup)
        },

        beforeDestroy() {
            window.removeEventListener('keyup', this.keyup)
        },

        methods: {
            close() {
                localStorage.setItem('show-wrapped-up', false)
                this.showWrappedUp = false
            },

            onCloseBox() {
                this.displayImage = false
                this.showEffect = false
                this.images = []
            },

            skipLoading() {
                this.loading = false
                this.displayImage = false
                this.showEffect = false
            },

            /**
             * Handles the `keyup` event
             *
             * @param {KeyboardEvent} e event
             */
            keyup(e) {
                if (
                    e.code === 'ArrowRight'
                    || e.key === 'ArrowRight'
                    || e.key === 'Right'
                    || e.keyCode === 39
                ) {
                    if (this.index <= this.images.length - 1) {
                        this.index += 1
                    }
                    if (this.index === this.images.length) {
                        this.index = 0
                    }
                    this.onNext(this.index)
                    this.changeImage(this.index)
                } else if (
                    e.code === 'ArrowLeft'
                    || e.key === 'ArrowLeft'
                    || e.key === 'Left'
                    || e.keyCode === 37
                ) {
                    if (this.index === 0) {
                        this.index = this.images.length - 1
                    } else {
                        this.index -= 1
                    }
                    this.onPrevious(this.index)
                    this.changeImage(this.index)
                } else if (
                    e.code === 'Escape'
                    || e.key === 'Escape'
                    || e.key === 'Esc'
                    || e.keyCode === 27
                ) {
                    this.onCloseBox()
                }
            },

            async onDisplay() {
                try {
                    this.loading = true
                    let params = {}
                    if (this.user) {
                        params = {
                            user: this.user,
                        }
                    }
                    const response = await get(params)
                    const { images } = response
                    const url = process.env.IMAGES_URL

                    images.push({
                        imageLink: `${url}/full/${this.$store.state.wrappedUpTime.lastImage}`,
                        template: null,
                    })
                    _each(images, (image) => {
                        if (image.template || image.imageLink) {
                            this.images.push({
                                src: image.imageLink,
                                caption: this.$t('wrappedUp.withViblo'),
                                template: image.template || null,
                                selected: true,
                            })
                        }
                    })

                    Promise.all(
                        _each(images, async (image) => {
                            if (!image.src && image.template) {
                                await this.updateImageData(image.template)
                                this.statusTimeDone += 1
                                // Wait for the emission of the last image, then display the slide
                                if (this.statusTimeDone === (this.images.length - 1)) {
                                    this.loading = false
                                    this.showEffect = true
                                    this.displayImage = true
                                    this.index = 0
                                    this.statusTimeDone = 0
                                }
                            }
                        })
                    )

                    if (this.images.length <= 2) {
                        this.loading = false
                        this.showEffect = true
                        this.displayImage = true
                        this.index = 0
                        this.statusTimeDone = 0
                    }
                } catch (e) {
                    this.$message.error(this.$t('wrappedUp.error'))
                }
            },

            async updateImageData(template, action = 'next') {
                let params = { template, action }
                if (this.user) {
                    params = _assign({}, params, { user: this.user })
                }
                const data = await get(params)
                this.images = _map(this.images, (image) => {
                    if (image.template === template && image.src !== data.imageLink) {
                        image.src = data.imageLink
                    }

                    return image
                })
            },

            onPrevious(index) {
                this.isLoading = true
                const image = this.images[index - 1]
                if (typeof image !== 'undefined' && image.template) {
                    this.updateImageData(image.template, 'prev')
                }
                if (index === 0 && this.images[0] !== 'undefined' && this.images[0].template) {
                    this.updateImageData(this.images[0].template, 'prev')
                }
                this.isLoading = false
            },

            onNext(index) {
                this.isLoading = true
                const image = this.images[index + 1]
                if (typeof image !== 'undefined' && image.template && index > 0) {
                    this.updateImageData(image.template)
                }
                if (index === 0 && this.images[0] !== 'undefined' && this.images[0].template) {
                    this.updateImageData(this.images[0].template)
                }
                this.isLoading = false
            },

            changeImage(index) {
                this.showEffect = false
                if (index === 0 && index < this.imageIndex && this.imageIndex === this.images.length - 1) {
                    this.showLastItem = true
                }
                this.imageIndex = index
            },

            changeDisplayLastItem(value) {
                this.showLastItem = value
            },
        },
    }
</script>

<style lang="scss" scoped>
    // @keyframes headShake {
    //     0% {
    //         -webkit-transform: translateX(0);
    //     }

    //     6.5% {
    //         -webkit-transform: translateX(-6px) rotateY(-9deg);
    //     }

    //     18.5% {
    //         -webkit-transform: translateX(5px) rotateY(7deg);
    //     }

    //     31.5% {
    //         -webkit-transform: translateX(-3px) rotateY(-5deg);
    //     }

    //     43.5% {
    //         -webkit-transform: translateX(2px) rotateY(3deg);
    //     }

    //     50% {
    //         -webkit-transform: translateX(0);
    //     }
    // }

    // .section-wrapped-up {
    //     .v-wrapped-up {
    //         -webkit-animation: headShake 1.2s ease-in-out infinite;
    //         animation: headShake 1.2s ease-in-out infinite;
    //     }
    // }
</style>

<style lang="scss">
    .section-wrapped-up {
        .v-wrapped-up {
            z-index: 99;
            width: 2.813rem;
            left: 1.25rem;
            bottom: 2.5rem;
            height: 2.813rem;
            display: flex;
            display: -webkit-box;
            display: -ms-flexbox;
            -webkit-box-align: center;
            -webkit-box-pack: center;
            -ms-flex-align: center;
            -ms-flex-pack: center;
            position: fixed;
            align-items: center;
            text-decoration: none;
            justify-content: center;
            border-radius: 0.188rem;
            line-height: 1.6;
            font-size: 0.875rem;
            cursor: pointer;

            img {
                width: 80px;
            }
            .btn-wrapped-up-close {
                position: absolute;
                top: -30px;
                right: -30px;
                visibility: hidden;
                i {
                    font-weight: bold;
                }
            }
            &:hover {
                .btn-wrapped-up-close {
                    visibility: visible;
                }
            }
        }

        .tinybox {
            &__content {
                &__image__caption {
                    font-family: 'Montserrat ExtraBold' !important;
                    background: none !important;
                    text-transform: uppercase;
                    width: 410px;
                    top: 1rem;
                    opacity: 1 !important;
                    font-weight: bold;
                    z-index: 1042 !important;
                }
                &__image {
                    top: auto;
                    border-radius: 10px;
                }
                &__control {
                    &--close {
                        background: url('/images/wrapped-up/close.svg') no-repeat center/24px !important;
                        z-index: 1043 !important;
                    }
                    &--prev {
                        background: url('/images/wrapped-up/arrow-right.svg') no-repeat center/50px !important;
                        transform: rotate(180deg);
                        z-index: 1043 !important;
                    }
                    &--next {
                        background: url('/images/wrapped-up/arrow-right.svg') no-repeat center/50px !important;
                        z-index: 1043 !important;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 319px) {
        .tinybox__content {
            &__image__caption {
                font-size: 12px !important;
            }
        }
    }

    @media only screen and (min-width: 320px) {
        .tinybox__content {
            img {
                max-height: 70% !important;
                top: 5rem;
            }
            &__image__caption {
                font-size: 12px !important;
                line-height: 20px !important;
                width: 100% !important;
            }
        }
    }

    @media only screen and (min-width: 525px) {
        .tinybox__content {
            &__image__caption {
                font-size: 16px !important;
            }
        }
    }

    @media only screen and (min-width: 1024px) {
        .section-wrapped-up {
            .v-wrapped-up {
                left: 3.5rem;
                bottom: 3.5rem;
                img {
                    width: 130px;
                }
            }
        }
    }

    @media only screen and (min-width: 1024px) {
        .tinybox__content {
            img {
                top: 7rem;
            }
            &__image__caption {
                top: 4rem;
                font-size: 32px !important;
                line-height: 39px !important;
                margin-top: 1rem;
            }
        }
    }
</style>
