// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { normalize } from 'normalizr'
import { getComments } from 'viblo-sdk/api/comments'
import { CommentableType } from 'viblo-sdk/libs/interactions'
import { commentArray } from '~/utils/store/schemas'
import * as commentsModule from '../__modules/comments'
import { PUT_USERS_TO_STATE } from '../entities/users'

const state = () => ({
    ...commentsModule.defaultState,
    loading: true,
})

const getters = commentsModule.getters

const actions = {
    ...commentsModule.actions,

    async fetch({ commit }, hashId) {
        commit('setLoading', true)

        const { comments } = await getComments(CommentableType.User, hashId)

        const { entities } = normalize(comments.data, commentArray)

        commit('set', entities.comments)
        commit(PUT_USERS_TO_STATE, entities.users, { root: true })
        commit('setLoading', false)
    },
}

const mutations = {
    ...commentsModule.createMutations({
        threadKeyName: 'in_reply_to_comment',
    }),

    setLoading: (state, value) => {
        state.loading = value
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
