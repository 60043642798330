import lozad from 'lozad'

export default {
    mounted() {
        const observer = lozad(this.$el.querySelector('.avatar-event'), {
            threshold: 0.1,
        })
        observer.observe()
    },
}
