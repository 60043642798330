import _escapeRegExp from 'lodash/escapeRegExp'
import _find from 'lodash/find'

export default class AddImgDimensions {
    constructor(uploads) {
        this.uploads = uploads
    }

    call(domTree) {
        const imgs = domTree.querySelectorAll('.article-img')

        imgs.forEach((img) => {
            const src = img.getAttribute('src')
            if (!src) {
                return
            }

            const matches = src.match(new RegExp(String.raw`^${_escapeRegExp(process.env.IMAGES_URL)}\/(\w{8}-\w{4}-\w{4}-\w{4}-\w{12})\.([a-z]+)$`), 'i')
            if (!matches) {
                return
            }

            const uuid = matches[1]
            const extension = matches[2]

            const upload = _find(this.uploads, { uuid })
            if (!upload || !upload.width || !upload.height) {
                return
            }

            const width = upload.width
            const height = upload.height

            const tinySrc = `${process.env.IMAGES_URL}/60/${uuid}.${extension}`
            const srcset = `${process.env.IMAGES_URL}/retina/${uuid}.${extension} 2x`
            const zoomSrc = `${process.env.IMAGES_URL}/full/${uuid}.${extension}`

            img.setAttribute('width', width)
            img.setAttribute('height', height)
            img.setAttribute('data-tiny-src', tinySrc)
            img.setAttribute('data-zoom-src', zoomSrc)
            img.setAttribute('data-srcset', srcset)
            img.setAttribute('data-wrapper-class', 'article-img-wrapper')
            img.setAttribute('data-tiny-class', 'article-img-tiny')
            img.setAttribute('data-full-class', 'article-img')
            img.classList.add('pil-img')
        })
    }
}
