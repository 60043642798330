// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div id="app-container" :class="theme">
        <WritingNavbar />

        <nuxt class="container-fluid bg-light" />

        <AuthDialog />
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import offlineAlert from '~/components/mixins/offlineAlert'
    import WritingNavbar from '~/components/navbar/WritingNavbar.vue'
    import AuthDialog from '~/components/auth/Popup.vue'

    export default {
        components: {
            WritingNavbar,
            AuthDialog,
        },

        mixins: [offlineAlert],

        computed: {
            ...mapState('settings', ['theme']),
        },
    }
</script>
