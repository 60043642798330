// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _get from 'lodash/get'

export default {
    inserted(el, binding, vnode) {
        const shouldFocus = typeof binding.value === 'undefined' || binding.value === true
        if (!shouldFocus) {
            return
        }

        const componentFocus = _get(vnode, 'componentInstance.focus')

        if (typeof componentFocus === 'function') {
            componentFocus()
        } else {
            el.focus()
        }
    },
}
