// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import WebAuth from '@viblo-asia/viblo-accounts-auth-web'

export const webAuth = new WebAuth({
    domain: process.env.SSO_APP_URL,
    service: process.env.SSO_SERVICE_CODE || 'viblo',
})

export function login() {
    webAuth.login({
        continue: window.location.href,
    })
}

export function logout() {
    webAuth.logout({
        continue: window.location.href,
    })
}

export function loginWithSSR(req, redirect) {
    const continueUrl = process.server
        ? `${req.protocol}://${req.get('host')}${req.originalUrl}`
        : window.location.href

    webAuth.login({
        redirect,
        continue: continueUrl,
    })
}
