// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _cloneDeep from 'lodash/cloneDeep'
import * as commentsModule from '../__modules/comments'

const state = () => _cloneDeep(commentsModule.defaultState)

const getters = commentsModule.getters

const actions = commentsModule.actions

const mutations = commentsModule.createMutations()

export default {
    state,
    getters,
    actions,
    mutations,
}
