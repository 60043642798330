// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <header class="main-navbar main-navbar__group py-1">
        <div class="main-navbar__container container-fluid">
            <!-- Left -->
            <div class="main-navbar__left">
                <!-- Logo -->
                <nuxt-link class="main-navbar__logo d-block mr-lg-5" to="/" exact>
                    <img
                        src="/logo_full.svg"
                        alt="Viblo"
                        width="62"
                        height="21"
                    >
                </nuxt-link>

                <!-- Main navigation -->
                <MainMenu class="hidden-md-down" />
            </div>

            <!-- Right -->
            <div class="main-navbar__right">
                <!-- Flyouts -->
                <div class="main-navbar__group">
                    <Announcements class="mr-1" />
                    <Notifications v-if="!guest" class="mr-1" />
                </div>

                <!-- Menus -->
                <div class="main-navbar__group">
                    <WriteMenu v-if="!guest" class="mr-1" />
                    <UserMenu />
                </div>
            </div>
        </div>
        <div v-if="showBackgroud" class="backgroud-vue-tour" />
    </header>
</template>

<script>
    import { mapState } from 'vuex'
    import MainMenu from './items/MainMenu.vue'
    import UserMenu from './items/UserMenu.vue'
    import WriteMenu from './items/WriteMenu.vue'
    import Announcements from './items/Announcements.vue'
    import Notifications from './items/Notifications.vue'

    export default {
        components: {
            MainMenu,
            UserMenu,
            WriteMenu,
            Announcements,
            Notifications,
        },

        computed: {
            ...mapState('auth', ['guest']),
            ...mapState('vueTour', ['showBackgroud']),
        },
    }
</script>

<style lang="scss" src="./navbar.scss"/>
