// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <el-dropdown trigger="click" size="medium">
        <el-button
            v-tooltip
            :title="$t('mainNavbar.writeMenu.title')"
            type="text"
            icon="el-icon-edit"
            class="text-muted py-0"
            style="font-size:1.2rem"
        />

        <el-dropdown-menu slot="dropdown" class="publish-dropdown el-dropdown-menu--wide">
            <el-dropdown-item>
                <nuxt-link to="/publish/post" class="link link--plain">
                    <i class="fa fa-pencil mr-05" /> {{ $t('mainNavbar.writeMenu.writePost') }}
                </nuxt-link>
            </el-dropdown-item>


            <el-dropdown-item divided>
                <nuxt-link to="/publish/series" class="link link--plain">
                    <i class="fa fa-list-ul mr-05" /> {{ $t('mainNavbar.writeMenu.newSeries') }}
                </nuxt-link>
            </el-dropdown-item>


            <el-dropdown-item divided>
                <nuxt-link to="/questions/ask" class="link link--plain">
                    <i class="fa fa-question-circle mr-05" /> {{ $t('mainNavbar.writeMenu.askQuestion') }}
                </nuxt-link>
            </el-dropdown-item>


            <template v-if="canCreateVideo">
                <el-dropdown-item divided>
                    <nuxt-link to="/publish/video" class="link link--plain">
                        <i class="fa fa-play-circle mr-05" /> {{ $t('mainNavbar.writeMenu.newVideo') }}
                    </nuxt-link>
                </el-dropdown-item>
            </template>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
    import _includes from 'lodash/includes'
    import { mapState } from 'vuex'

    export default {
        computed: {
            ...mapState('auth', ['user', 'roles']),

            canCreateVideo() {
                return this.user && (this.user.is_admin || _includes(this.roles, 'video-editor'))
            },
        },
    }
</script>
