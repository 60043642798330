// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { createRenderer } from 'viblo-sdk/markdown'
import { transText } from '~/utils/lang'

const katex = {
    maxSize: 500,
    maxExpand: 100,
    maxCharacter: 1000,
}

const clipboard = {
    successText: transText('copied'),
    titleButton: transText('copy'),
    showMoreText: transText('showMore'),
}

const lite = () => {
    const md = createRenderer({
        baseURL: process.env.APP_URL,
        embed: false,
        absoluteURL: false,
        katex,
        clipboard,
    })

    return md
}

const full = () => {
    const md = createRenderer({
        baseURL: process.env.APP_URL,
        absoluteURL: false,
        embed: {
            wrapperClass: 'embed-responsive embed-responsive-16by9',
            iframeClass: 'embed-responsive-item',
        },
        katex,
        clipboard,
    })

    return md
}

export {
    lite,
    full,
}
