// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import Tippy from 'tippy.js'
import _isEmpty from 'lodash/isEmpty'

const positions = [
    'top', 'top-start', 'top-end',
    'right', 'right-start', 'right-end',
    'bottom', 'bottom-start', 'bottom-end',
    'left', 'left-start', 'left-end',
]

const defaultOptions = {
    position: 'bottom',
}

const getPosition = modifiers => (_isEmpty(modifiers)
    ? defaultOptions.position
    : positions.find(pos => modifiers[pos] === true) || defaultOptions.position)

export default {
    /** @param {Element} el */
    bind: (el, { value, modifiers }) => {
        const hasValue = typeof value === 'string' && value

        if (!hasValue && !el.getAttribute('title')) return
        if (hasValue) el.setAttribute('title', value)

        Tippy(el, {
            placement: el.getAttribute('tooltip-placement') || getPosition(modifiers),
            animation: 'fade',
            size: 'regular',
            arrow: true,
            arrowTransform: 'scale(0.75)',
            performance: true,
            dynamicTitle: true,
        })
    },

    /** @param {Element} el */
    update: (el, { value, oldValue }) => {
        if (value !== oldValue) {
            el.setAttribute('title', value)
        }
    },

    /** @param {Element} el */
    unbind: (el) => {
        if (el._tippy) {
            el._tippy.destroy()
        }
    },
}
