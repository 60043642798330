// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX

import _findIndex from 'lodash/findIndex'

export const state = () => ({
    searchLog: null,
    CTRLogs: [],
    clickedLog: null,
})

export const getters = {
    getSearchLog: state => state.log,
    getCTRLogs: state => state.CTRLogs,
    getClickedLog: state => state.clickedLog,
}

export const mutations = {
    setSearchLog(state, data) {
        state.log = data
    },

    setClickedLog(state, data) {
        state.clickedLog = data
    },

    pushCTRLogs(state, data) {
        const index = _findIndex(state.CTRLogs, { section: data.section })
        const CTRLogs = state.CTRLogs
        if (index !== -1) {
            CTRLogs[index] = data
            state.CTRLogs = CTRLogs
        } else {
            state.CTRLogs.push(data)
        }
    },

    clearCTRLogs(state) {
        state.CTRLogs = []
    },
}
