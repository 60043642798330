// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { joinNewPostsChannel } from 'viblo-sdk/echo'

export default (store) => {
    const connection = require('~/lib/echoConnection').default
    const newPostsChannel = joinNewPostsChannel(connection)

    newPostsChannel.onNewPostPublished(() => {
        store.commit('toast/pushNewPost')
    })
}
