// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<script>
    import _flow from 'lodash/flow'
    import _join from 'lodash/fp/join'
    import _slice from 'lodash/fp/slice'
    import { image as imageUrl } from '~/utils/url'

    const _map = require('lodash/fp/map').convert({ cap: false })

    const _imageSize = (imageSize) => {
        if (typeof imageSize === 'string') {
            return [imageSize, `${imageSize}-retina`]
        }

        return typeof imageSize === 'number' ? [imageSize, imageSize * 2] : ['avatar', 'avatar-retina']
    }

    function getImages({ image, imageSize, defaultImage }) {
        if (!image) {
            return { src: defaultImage }
        }

        const sizes = _imageSize(imageSize)
        const images = sizes.map(size => imageUrl(image, size))

        const src = images[0]
        const srcset = _flow(
            _slice(1, 2),
            _map((img, index) => `${img} ${index + 2}x`),
            _join(', ')
        )(images)

        return { src, srcset }
    }

    const getAvatarClasses = ({ size, rectangle, organization }) => ({
        [`avatar--${size}`]: Boolean(size),
        'avatar--rect': rectangle,
        'avatar--ognzt': organization,
    })

    export default {
        name: 'Avatar',

        functional: true,

        props: {
            image: String,
            imageSize: [Number, String],
            size: String,
            rectangle: Boolean,
            organization: Boolean,
            defaultImage: {
                type: String,
                default: '/images/mm.png',
            },
        },

        render: (h, { props, data }) => {
            const imageSrcs = getImages(props)
            const avatarClasses = getAvatarClasses(props)
            const className = ['avatar', avatarClasses, data.class, data.staticClass]

            return <img v-avatar {...{ attrs: imageSrcs }} alt="Avatar" class={className}/>
        },
    }
</script>

<style lang="scss" scoped>
    @import '~assets/sass/variables';

    .avatar {
        display: block;
        width: $avatar-size-xl;
        height: auto;
        margin: auto;
        cursor: pointer;
        border-radius: 100%;

        &--rect {
            border-radius: 3px;
        }

        &--ognzt {
            object-fit:contain;
            width: 100%;
            display: block;
            overflow: hidden;
        }

        &--xs {
            width: $avatar-size-xs;
            max-height: $avatar-size-xs;
        }

        &--sm {
            width: $avatar-size-sm;
            max-height: $avatar-size-sm;
        }

        &--md {
            width: $avatar-size-md;
            max-height: $avatar-size-md;
        }

        &--md-mini {
            width: $avatar-size-md-mini;
            max-height: $avatar-size-md-mini;
        }

        &--lg {
            width: $avatar-size-lg;
            max-height: $avatar-size-lg;
        }

        &--lg-mini {
            width: $avatar-size-lg-mini;
            max-height: $avatar-size-lg-mini;
        }

        &--xl {
            width: $avatar-size-xl;
            max-height: $avatar-size-xl !important;
        }

        &--xl-mini {
            width: $avatar-size-xl-mini;
            max-height: $avatar-size-xl-mini !important;
        }

        &--xxl {
            width: $avatar-size-xxl;
            max-height: $avatar-size-xxl;
        }
    }

    .discussion-avatar img {
        display: inherit;
    }
</style>
