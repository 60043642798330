// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div id="app-container" :class="theme">
        <MainNavbar />
        <div id="main-content">
            <nuxt />
        </div>
        <MainFooter />
        <AuthDialog />
        <WrappedUp v-if="authenticated" />
        <!-- <WrappedUp v-if="authenticated && !showPopupNewsletter" /> -->
        <ScrollToTop />
        <!-- <ReminderWhenNotLoginPopup v-if="!authenticated" /> -->
        <FixedBottomBar v-if="!authenticated" />
        <PopupDownloadApp />
        <!-- <SubscribeMailNewsLetter v-if="authenticated && showPopupNewsletter" /> -->
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import offlineAlert from '~/components/mixins/offlineAlert'
    import MainNavbar from '~/components/navbar/MainNavbar.vue'
    import MainFooter from '~/components/footer/Footer.vue'
    import AuthDialog from '~/components/auth/Popup.vue'
    import WrappedUp from '~/components/events/WrappedUp.vue'
    import ScrollToTop from '~/components/ScrollToTop.vue'

    // const ReminderWhenNotLoginPopup = () => import('~/components/dialogs/ReminderWhenNotLogin.vue')
    const FixedBottomBar = () => import('~/components/posts/FixedBottomBar.vue')
    // const SubscribeMailNewsLetter = () => import('~/components/dialogs/SubscribeMailNewsLetter.vue')
    const PopupDownloadApp = () => import('~/components/dialogs/PopupDownloadApp.vue')

    export default {
        components: {
            MainNavbar,
            AuthDialog,
            MainFooter,
            WrappedUp,
            ScrollToTop,
            // ReminderWhenNotLoginPopup,
            FixedBottomBar,
            PopupDownloadApp,
            // SubscribeMailNewsLetter,
        },

        mixins: [offlineAlert],

        middleware: ['root-domain-redirect'],

        computed: {
            ...mapState('settings', ['theme', 'viewed_popup_newsletter', 'complete_tour']),
            ...mapGetters('auth', ['authenticated']),
            // showPopupNewsletter() {
            //     const completeTour = this.complete_tour
            //     const showIntroTour = !completeTour || completeTour === 'writePost'
            //     return !this.viewed_popup_newsletter && !showIntroTour
            // },
        },
    }
</script>
<style lang="scss">
    .el-popover {
        .suggestion-item-tags {
            padding: 3px 10px;
            background-color: rgb(244, 244, 245);
            color: rgb(144, 147, 153) !important;
            border-radius: 5px;
            margin-right:5px;
            margin-bottom:5px;

            &:hover {
                text-decoration: none;
                background-color: #eaeaea;
                color: #909399;
            }
        }
    }
</style>
