// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { getExistingMembers } from '~/api/organizations'
import { state, mutations, createFetchAction } from '../__modules/user-list'

const actions = {
    fetch: createFetchAction(({ slug, params }) => getExistingMembers(slug, params).then(({ data }) => data)),
    resetData: ({ commit }) => {
        commit('resetData')
    },
}

export default {
    state,
    actions,
    mutations,
}
