// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _findIndex from 'lodash/findIndex'
import _assign from 'lodash/assign'
import { normalizeCommentList } from '~/components/entities/normalize'
import { fetchAllComments } from '../api/comments'

const state = () => ({
    comments: {},
    pagination: {},
})

const actions = {
    async fetch({ commit }, params) {
        const { comments, pagination } = await fetchAllComments(params)
            .then(response => ({
                comments: response.data.data,
                pagination: response.data.meta.pagination,
            }))

        const normalizedComment = normalizeCommentList(comments)

        commit('entities/users/put', normalizedComment.entities.users, { root: true })
        commit('setPagination', pagination)
        commit('setData', comments)
    },
    rated({ commit }, { comment, rated_value: ratedValue }) {
        commit('UPDATE_COMMENT', {
            id: comment.id,
            points: comment.points - comment.rated_value + ratedValue,
            rated_value: ratedValue,
        })
    },
}

const mutations = {
    setData(state, data) {
        state.comments = data
    },

    setPagination(state, pagination) {
        state.pagination = pagination
    },

    UPDATE_COMMENT(state, newComment) {
        const index = _findIndex(state.comments, c => c.id === newComment.id)
        const comment = _assign({}, state.comments[index], newComment)
        state.comments = _assign([], state.comments, {
            [index]: comment,
        })
    },
}

export default {
    state,
    actions,
    mutations,
}
