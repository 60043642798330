// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <header class="main-navbar main-navbar__group py-1">
        <div class="main-navbar__container container px-md-0">
            <!-- Left -->
            <div class="d-flex main-navbar__grow">
                <!-- Logo -->
                <nuxt-link
                    v-if="guest"
                    class="main-navbar__logo d-block mr-lg-5"
                    to="/newest"
                    exact
                >
                    <progressive-img
                        v-if="logo"
                        :tiny-src="tinyUrl"
                        :src="logo.image"
                        :width="62"
                        :height="21"
                        alt=""
                    />
                    <img
                        v-else
                        src="/logo_full.svg"
                        alt="Viblo"
                        width="62"
                        height="21"
                    >
                </nuxt-link>
                <span v-else>
                    <a
                        v-if="isSubdomain"
                        class="main-navbar__logo d-block mr-lg-5"
                        :href="toAppUrl('/')"
                        exact
                    >
                        <progressive-img
                            v-if="logo"
                            :tiny-src="tinyUrl"
                            :src="logo.image"
                            :width="62"
                            :height="21"
                            alt=""
                        />
                        <img
                            v-else
                            src="/logo_full.svg"
                            alt="Viblo"
                            width="62"
                            height="21"
                        >
                    </a>

                    <nuxt-link
                        v-else
                        class="main-navbar__logo d-block mr-lg-5"
                        to="/"
                        exact
                    >
                        <progressive-img
                            v-if="logo"
                            :tiny-src="tinyUrl"
                            :src="logo.image"
                            :width="62"
                            :height="21"
                            alt=""
                        />
                        <img
                            v-else
                            src="/logo_full.svg"
                            alt="Viblo"
                            width="62"
                            height="21"
                        >
                    </nuxt-link>
                </span>

                <!-- Main navigation -->
                <MainMenu class="hidden-md-down" />
            </div>

            <!-- Right -->
            <div class="main-navbar__right">
                <!-- Search -->
                <nuxt-link to="/search" class="link text-muted hidden-md-up mr-1">
                    <i class="fa fa-search" aria-hidden="true" />
                </nuxt-link>

                <quicksearch class="hidden-sm-down flex-fill mr-1" />

                <!-- Flyouts -->
                <div class="main-navbar__group">
                    <Announcements class="mr-1" />
                    <Notifications v-if="!guest" class="mr-1" />
                </div>

                <!-- Menus -->
                <div class="main-navbar__group">
                    <div class="mr-05" :class="{ 'hightlight-vue-tour' :step1 }">
                        <WriteMenu
                            v-if="!guest"
                            id="v-step-1"
                            class="main-navbar__writer"
                            :class="{'pulse': step1}"
                        />
                    </div>

                    <div class="main-navbar__group">
                        <LanguageSwitcher class="sm-response" />
                    </div>

                    <Services />

                    <UserMenu ref="menuUser" />
                    <div v-if="showBackgroud" class="backgroud-vue-tour" />
                </div>

                <VueTour
                    v-if="showVueTour && user"
                    :steps="steps"
                    :callbacks="Mycallbacks"
                    @closeVueTour="closeVueTour"
                    @closeVueTourWhenFinish="closeVueTourWhenFinish"
                />
            </div>
        </div>
        <feedback-popup ref="feedbackDialog" />
    </header>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import { updateCompleteTour } from '~/api/users'
    import MainMenu from './items/MainMenu.vue'
    import UserMenu from './items/UserMenu.vue'
    import WriteMenu from './items/WriteMenu.vue'
    import Quicksearch from './items/Quicksearch.vue'
    import Announcements from './items/Announcements.vue'
    import Notifications from './items/Notifications.vue'
    import FeedbackPopup from '../feedback/Popup.vue'
    import VueTour from '~/components/VueTour.vue'
    import LanguageSwitcher from '~/components/footer/LanguageSwitcher.vue'
    import Services from '~/components/navbar/items/Services.vue'
    import { toAppUrl } from '~/utils/url'

    const contentSteps = [
        'vueTour.gotoSetting',
        'vueTour.createContent',
        'vueTour.seeQuestions',
        'vueTour.previewMode',
        'vueTour.sendFeebback',
    ]

    export default {
        components: {
            Quicksearch,
            MainMenu,
            UserMenu,
            WriteMenu,
            Announcements,
            Notifications,
            FeedbackPopup,
            VueTour,
            LanguageSwitcher,
            Services,
        },

        data() {
            return {
                showBackgroud: false,
                contentSteps,
                tinyUrl: null,
                Mycallbacks: {
                    onPreviousStep: this.myCustomPreviousStepCallback,
                    onNextStep: this.myCustomNextStepCallback,
                },
                steps: [
                    {
                        target: '#v-step-0',
                        content: this.$t('vueTour.gotoSetting'),
                        before: type => new Promise((resolve) => { // eslint-disable-line
                            this.$store.commit('vueTour/setDataStep0', true)
                            resolve('foo')
                        }),
                    },
                    {
                        target: '#v-step-1',
                        content: this.$t('vueTour.createContent'),
                        before: type => new Promise((resolve) => { // eslint-disable-line
                            this.$store.commit('vueTour/setDataStep0', false)
                            this.$store.commit('vueTour/setDataStep1', true)
                            this.$refs.menuUser.closeMenu()
                            resolve('foo')
                        }),
                    },
                    {
                        target: '#v-step-2',
                        content: this.$t('vueTour.seeQuestions'),
                        before: type => new Promise((resolve) => { // eslint-disable-line
                            this.$store.commit('vueTour/setDataStep1', false)
                            this.$store.commit('vueTour/setDataStep2', true)
                            resolve('foo')
                        }),
                    },
                    {
                        target: '#v-step-3',
                        content: this.$t('vueTour.previewMode'),
                        params: {
                            enableScrolling: false,
                        },
                        before: type => new Promise((resolve) => { // eslint-disable-line
                            window.scrollTo(0, 0)
                            this.$store.commit('vueTour/setDataStep2', false)
                            this.$store.commit('vueTour/setDataStep3', true)
                            resolve('foo')
                        }),
                    },
                    {
                        target: '#v-step-4',
                        content: this.$t('vueTour.sendFeebback'),
                        before: type => new Promise((resolve) => { // eslint-disable-line
                            this.$store.commit('vueTour/setDataStep3', false)
                            this.$store.commit('vueTour/setDataStep4', true)
                            resolve('foo')
                        }),
                    },
                ],
            }
        },

        computed: {
            ...mapState('auth', ['guest', 'user']),
            ...mapGetters('auth', ['authenticated']),
            ...mapState('vueTour', ['step1', 'triggerUserMenu', 'doneFetchData']),
            ...mapGetters('config', { logo: 'currentLogo' }),
            ...mapState('config', ['vars']),

            getLocale() {
                return this.$i18n.locale
            },

            showVueTour() {
                return this.$route.path === '/newest'
            },

            isSubdomain() {
                return this.vars.is_special_organization || this.vars.is_special_profile
            },
        },

        watch: {
            getLocale() {
                for (let i = 0; i < this.steps.length; i += 1) {
                    this.steps[i].content = this.$t(contentSteps[i])
                }
            },

            doneFetchData() {
                const route = this.$route.path === '/newest'
                const doneFetchData = this.$store.state.vueTour.doneFetchData
                if (this.authenticated && route && doneFetchData) {
                    const completeTour = this.$store.state.settings.complete_tour
                    if (!completeTour || completeTour === 'writePost') {
                        this.$store.commit('vueTour/setTriggerUserMenu', 'manual')
                        this.$root.$emit('changleTriggerUserMenu', this.triggerUserMenu)
                        this.showBackgroud = true
                        this.doneUpdate()
                        setTimeout(() => {
                            this.$tours.myTour.start()
                        }, 150)
                        this.disableEventBody()
                    }
                }
            },
        },

        beforeDestroy() {
            document.body.style.pointerEvents = 'unset'
        },

        methods: {
            toAppUrl,

            doneUpdate() {
                setTimeout(() => {
                    this.$refs.menuUser.openMenu()
                }, 1)
            },

            myCustomPreviousStepCallback(currentStep) {
                const completeTour = this.$store.state.settings.complete_tour
                const showVueTour = !completeTour || completeTour === 'writePost'
                if (currentStep === 1 && showVueTour) {
                    this.$refs.menuUser.openMenu()
                    setTimeout(() => {
                        window.scroll(0, 20)
                    }, 200)
                    this.$store.commit('vueTour/setDataStep1', false)
                }

                if (currentStep === 2 && showVueTour) {
                    this.$store.commit('vueTour/setDataStep1', true)
                    this.$store.commit('vueTour/setDataStep2', false)
                }

                if (currentStep === 3 && showVueTour) {
                    this.$store.commit('vueTour/setDataStep2', true)
                    this.$store.commit('vueTour/setDataStep3', false)
                }

                if (currentStep === 4 && showVueTour) {
                    window.scrollTo(0, 0)
                    this.$store.commit('vueTour/setDataStep3', true)
                    this.$store.commit('vueTour/setDataStep4', false)
                }
            },

            myCustomNextStepCallback() {
            },

            closeVueTour(value) {
                const completeTour = this.$store.state.settings.complete_tour

                value.skip()
                updateCompleteTour(this.user.id, {
                    type: 'intro',
                })
                this.showBackgroud = false
                this.$store.commit('vueTour/doneOrSkipTour')
                this.$store.commit('vueTour/setTriggerUserMenu', 'click')
                this.$root.$emit('doneTour', this.triggerUserMenu)
                this.enableEventBody()
                !completeTour // eslint-disable-line
                    ? this.$store.commit('settings/setValueTour', 'intro')
                    : this.$store.commit('settings/setValueTour', 'all')
            },

            closeVueTourWhenFinish(value) {
                const completeTour = this.$store.state.settings.complete_tour

                value.stop()
                updateCompleteTour(this.user.id, {
                    type: 'intro',
                })
                this.showBackgroud = false
                this.$store.commit('vueTour/doneOrSkipTour')
                this.$store.commit('vueTour/setTriggerUserMenu', 'click')
                this.$root.$emit('doneTour', this.triggerUserMenu)
                this.enableEventBody()
                !completeTour // eslint-disable-line
                    ? this.$store.commit('settings/setValueTour', 'intro')
                    : this.$store.commit('settings/setValueTour', 'all')
            },

            disableEventBody() {
                const bodyElement = document.getElementsByTagName('body')[0]
                bodyElement.style.pointerEvents = 'none'
            },

            enableEventBody() {
                const bodyElement = document.getElementsByTagName('body')[0]
                bodyElement.style.pointerEvents = 'auto'
            },
        },
    }
</script>

<style lang="scss" src="./navbar.scss"/>
