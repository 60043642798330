// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX

const state = () => ({
    step0: false,
    step1: false,
    step2: false,
    step3: false,
    step4: false,
    writePostStep0: false,
    writePostStep1: false,
    writePostStep2: false,
    writePostStep3: false,
    showBackgroud: false,
    triggerUserMenu: 'click',
    doneFetchData: false,
})

const mutations = {
    setDataStep0(state, data) {
        state.step0 = data
    },

    setDataStep1(state, data) {
        state.step1 = data
    },

    setDataStep2(state, data) {
        state.step2 = data
    },

    setDataStep3(state, data) {
        state.step3 = data
    },

    setDataStep4(state, data) {
        state.step4 = data
    },

    setDataWritePostStep0(state, data) {
        state.writePostStep0 = data
    },

    setDataWritePostStep1(state, data) {
        state.writePostStep1 = data
    },

    setDataWritePostStep2(state, data) {
        state.writePostStep2 = data
    },

    setDataWritePostStep3(state, data) {
        state.writePostStep3 = data
    },

    setDataWritePostStep4(state, data) {
        state.writePostStep4 = data
    },

    setShowBackgroud(state, data) {
        state.showBackgroud = data
    },

    doneOrSkipTour(state) {
        state.step0 = false
        state.step1 = false
        state.step2 = false
        state.step3 = false
        state.step4 = false
    },

    doneOrSkipTourWritePost(state) {
        state.writePostStep0 = false
        state.writePostStep1 = false
        state.writePostStep2 = false
        state.writePostStep3 = false
        state.writePostStep4 = false
    },

    setTriggerUserMenu(state, data) {
        state.triggerUserMenu = data
    },

    setDoneFetchData(state, data) {
        state.doneFetchData = data
    },
}

export default {
    state,
    mutations,
}
