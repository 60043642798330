export default {
    mainNavbar: {
        mainMenu: {
            posts: 'Posts',
            questions: 'Questions',
            discussions: 'Discussions',
        },

        quickSearch: {
            placeholder: 'Search Viblo',
            notFound: "We couldn't find anything. Try an",
            advancedSearch: 'advanced search',
        },

        announcements: {
            announcements: 'Announcements',
            allRead: 'Mark all as read',
            noAnnouncement: 'No announcement yet.',
            allAnnouncements: 'All Announcements',
        },

        notifications: {
            notifications: 'Notifications',
            NotificationSettings: 'Notification settings',
            notificationsList: {
                new: 'New',
                earlier: 'Earlier',
            },
            seeAll: 'See All',
        },

        writeMenu: {
            title: 'Write',
            writePost: 'Write post',
            newSeries: 'New series',
            askQuestion: 'Ask question',
            newVideo: 'New video',
        },

        userMenu: {
            login: 'Sign In/Sign up',
            profile: 'Profile',
            myContents: 'My Contents',
            myActivities: 'My Activities',
            reviewContents: 'Review Contents',
            organizations: 'Organizations',
            preferences: 'Preferences',
            adminDashboard: 'Admin Dashboard',
        },

        setting: {
            appearances: {
                title: 'Appearances',
                postLayout: {
                    title: 'Post layout',
                    detail: 'You may choose to display posts with simple layout - only title and metadata or with a teaser for preview layout.',
                    simple: 'Simple',
                    preview: 'Preview',
                },
                itemsPerPage: {
                    title: 'Items per page',
                    detail: 'You may customize the number of posts to display in a single page for a paginated post list (e.g. homepage).',
                },
                highlightThemes: {
                    title: 'Highlight Themes',
                    detail: 'Theme used changes code blocks',
                    dark: 'Dark',
                    light: 'Light',
                },
                homePage: {
                    title: 'Homepage',
                    detail: 'You may choose your suitable feed to display what interested you the most.',
                    followings: 'Followings',
                    newest: 'Newest',
                },
                locale: {
                    title: 'Preferred Language',
                    detail: 'Website, emails and notifications will appear in the selected language.',
                },
                layout: {
                    title: 'Layout',
                    detail: 'You can choose a layout template to apply on your subdomain page. ',
                    placeholder: 'Select Display Layout',
                    options: {
                        default: 'Layout 1',
                        layoutSubdomain2: 'Layout 2',
                    },
                },
                success: 'Settings updated',
            },
            notifications: {
                title: 'Notifications',
                content: {
                    title: 'Contents for you',
                    contentPeople: {
                        content: 'Contents from people you followed',
                        detail: 'We\'ll notify you when people you are following published a new post.',
                    },
                    question: {
                        content: 'Questions you asked or followed',
                        detail: "We'll notify you when there are new answers to questions you asked or followed.",
                    },
                },
                comment: {
                    title: 'Comments & mentions',
                    comments: {
                        title: 'Comments',
                        detail: 'We\'ll notify you when someone comments on your posts, questions and answers.',
                    },
                    mentions: {
                        title: 'Mentions',
                        detail: 'We\'ll notify you when someone mentions you in their posts, questions or answer.',
                    },
                },
                reputations: {
                    title: 'Your reputations',
                    voteAndclip: {
                        title: 'Votes & Bookmarks',
                        detail: 'We\'ll notify you when someone votes or bookmarks your posts, questions or answers.',
                    },
                    acceptedAnswers: {
                        title: 'Accepted answers',
                        detail: 'We\'ll notify you when your answers are accepted.',
                    },
                    newFollowers: {
                        title: 'New followers',
                        detail: 'We\'ll notify you when a new person starts following you.',
                    },
                    vibloFanpage: {
                        title: 'Viblo Fanpage',
                        detail: 'We\'ll notify you when your post have been shared into Viblo Fanpage.',
                    },
                },
                email: {
                    title: 'Email',
                    unread: {
                        title: 'Unread notifications',
                        detail: 'We\'ll email you when you have new unread notifications.',
                    },
                    newsletter: {
                        title: ' Weekly newsletter',
                        detail: 'We\'ll send you newsletter every Friday.',
                    },
                },
            },
            webhooks: {
                title: 'Webhooks',
                newPost: 'New post',
                firstDetail: 'We will send',
                link: 'JSON payload',
                lastDetail: 'when the authors you are following publish a new post.',
                form: {
                    targetURL: 'Target URL',
                    secret: 'Secret',
                    token: 'Secure token',
                    tokenHeader: 'Secure token header',
                    basicAuth: 'Use basic auth',
                    username: 'Username',
                    password: 'Password',
                    update: 'Update',
                    webhookUpdated: 'Webhook updated',
                    checkWebhook: 'Sent a request to {url}. Please check it!',
                },
                popup: {
                    title: 'PayLoad Structure',
                    copy: 'Copy',
                },
                validations: {
                    url: {
                        required: 'Payload url is required',
                        type: 'Must be a valid url',
                        max: 'Url must not be longer than 255 characters',
                    },
                    secureTokenHeader: {
                        max: 'Secure token header must not be longer than 255 characters',
                    },
                    secureToken: {
                        max: 'Secure token must not be longer than 255 characters',
                    },
                    secret: {
                        max: 'Secure token must not be longer than 255 characters',
                    },
                },
                test: 'Test',
                updateAndTest: 'Update & Test',
            },
        },
    },

    welcomeHero: {
        guest: {
            welcome: 'Welcome to Viblo',
            free: 'Free',
            service: 'service for technical knowledge',
            sharing: 'sharing',
            join: 'Join us to find useful information required to perfect your skill',
            start: 'Get started',
        },

        user: {
            welcome: 'Welcome back,',
            writings: 'Feel like starting you writings today?',
            start: 'Get started now!',
        },
    },

    feedBar: {
        mayfestTitle: 'Mayfest',
        followings: 'Followings',
        newest: 'Newest',
        series: 'Series',
        editorsChoice: 'Editors\' Choice',
        trending: 'Trending',
        videos: 'Videos',
        clip: 'My Bookmarks',
        createPost: 'Create Post',
        mayfest: {
            starts: 'Until Viblo May Fest starts. Join now to receive attractive gifts!',
            happenning: 'Until Viblo May Fest ends. Share new Posts now to receive attractive gifts!',
            end: 'Viblo May Fest ended. Thanks everyone for contributing to the success of the event',
        },
        contentCreator: 'Content Creator',
    },

    alAlert: {
        followingTagsLessThanFive: {
            title: 'Hi there! We make an effort to help you improve your feed by',
            machineLearing: 'Viblo Machine Learning.',
            follow: 'So, let\'s follow at least',
            tag: '5 technical tags',
            reading: 'that you\'re interested in reading!',
            interest: 'What\'s your interest?',
        },
        termWarningVisible: {
            update: 'September 2020, we have been updated our',
            termsOfService: 'Terms of Service',
            detail: 'for the "Our Ownership" section. If you don\'t have any feedback, we assume that you have agreed to this new term.',
        },
    },

    sidebar: {
        question: 'Newest questions',
        organization: 'Top Organizations',
        author: 'Top authors',
        partners: 'Content creator',
        organizationPartners: 'Organizations',
        userPartners: 'Authors',
        suggestion: {
            question: {
                title: 'Question suggestions',
            },
            challenge: {
                title: 'Challenge suggestions',
            },
            course: {
                title: 'Course suggestions',
                level: {
                    beginning: 'Beginner',
                    intermediate: 'Intermediate',
                    advanced: 'Advanced',
                },
            },
            puzzle: {
                title: 'Puzzle suggestions',
            },
            post: 'Post Suggestions',
            profile: {
                empty: 'This user hasn’t posted yet.',
            },
        },
    },

    getStarted: {
        title: 'Tags',
        tag: 'Technical Tags',
        sort: {
            title: 'Sort by',
            nameASC: 'Name: A to Z',
            nameDESC: 'Name: Z to A',
            followers: 'Followers',
            posts: 'Number of Posts',
            question: 'Number of Questions',
        },
        type: {
            title: 'Type of',
            all: 'All of types',
            technical: 'Technical Tag',
            others: 'Other types',
        },
        search: 'Search Tags',
        continueAble: {
            follow: 'Follow 5 or more',
            continue: 'Continue',
            followed: 'Followed',
        },
        tagItem: {
            posts: 'posts',
            questions: 'questions',
            followers: 'followers',
        },
        follow: {
            following: 'Following',
            follow: 'Follow',
        },
        beforeRouteLeave: {
            content: 'Please follow at least 5 technical tags to move on to the next step.',
            title: 'You have not follow enough 5 technical tags',
        },
        start: 'Get started with Viblo',
    },

    profile: {
        posts: 'Posts',
        series: 'Series',
        questions: 'Questions',
        answers: 'Answers',
        clips: 'Bookmarks',
        followings: 'Followings',
        followers: 'Followers',
        tags: 'Tags',
        reputations: 'Reputations',
        communication: 'Communication',
        follow: 'Follow',
        following: 'Following',
        edit: 'Edit',
        layoutSwitcher: {
            simple: 'Only title',
            preview: 'With preview contents',
        },
        embed: {
            title: 'Embed Profile Stats',
            close: 'Close',
        },
        profileStats: {
            postViews: 'Total post views',
            reputations: 'Reputations',
            followingTags: 'Following tags',
            followingUsers: 'Following users',
            followers: 'Followers',
            posts: 'Posts',
            Clips: 'Bookmarks',
            totalQuestions: 'Total questions',
            totalAnswers: 'Total answers',
        },
        statsHeader: {
            postViews: 'Post views',
            reputations: 'Reputations',
            followers: 'Followers',
            posts: 'Posts',
        },
        badges: 'Badges',
        technicalSkills: {
            title: 'Technical skills',
            noSkill: 'Have no skills yet.',
            update: 'Update now!',
            updateSkill: 'Update technical skills',
        },
        organizations: {
            title: 'Organizations',
            noOrganization: 'Have no organizations yet.',
            createOrgaization: 'Create your organization!',
            posts: 'Posts: ',
            members: 'Members',
            follow: 'Followers',
            view: 'Post view',
            totalPost: 'Total posts',
            newPost: 'New posts',
            createOrgaizationCondition: 'You don\'t have enough 100 reputations to create an organization',
            description: 'This organization has subdomain at',
        },
        report: 'Report',
        reportDialog: {
            title: 'Report content',
            label: 'Reason for reporting this content',
            spam: 'Spam',
            rulesViolation: 'Rules Violation',
            harassment: 'Harassment',
            terms: 'Viblo Terms of Service',
            comment: 'Comment',
            reported: 'Reported',
            reportBtn: 'Report',
            validations: {
                reason: 'Please choose a reason.',
            },
        },
        postsTendency: 'Posts tendency',
        answered: 'Answered',
        featured: 'Featured',
        trending: 'Trending',
        reputationHistory: 'Reported History',
        reason: 'Reason:',
        reportAt: 'Report at:',
        deleted: 'Your {type} has been deleted.',
    },

    userCard: {
        edit: 'Edit',
        following: 'Following',
        follow: 'Follow',
    },

    contentMenu: {
        minRead: ' min read',
        posts: {
            title: 'Posts',
            drafts: 'Drafts',
            public: 'Public',
            unlisted: 'Anyone with the link',
            spam: 'Spam',
            notReady: 'Needs to be edited',
            scheduled: 'Scheduled',
            writeups: 'Writeups',
        },
        minRLastead: 'min read',
        socialShare: {
            title: 'Social Shares',
            drafts: 'Drafts',
            scheduled: 'Scheduled',
            published: 'Published',
        },
        series: 'Series',
        answers: 'Answers',
        questions: 'Questions',
        postsSpam: 'Posts Spam',
        clips: {
            title: 'My Bookmarks',
            posts: 'Posts',
            series: 'Series',
            questions: 'Questions',
        },
        answerRequests: {
            title: 'Answer Requests',
            receivedQuestions: 'Received Questions',
            sentHistory: 'Sent history',
        },
        gallery: 'Gallery',
        technicalSkill: 'Technical Skills',
        violations: 'Violations',
    },

    myContent: {
        search: {
            startDate: 'Start date',
            endDate: 'End date',
            apply: 'Apply',
            tag: 'Tag',
            search: 'Search',
        },
        title: {
            drafts: 'Drafts',
            publicPosts: 'Public Posts',
            unlistedPosts: 'Anyone with the link',
            sharesDrafts: 'Social Shares - Drafts',
            sharesScheduled: 'Social Shares - Scheduled',
            sharesPublished: 'Social Shares - Published',
            series: 'Series',
            answers: 'Answers',
            questions: 'Questions',
            clipsPosts: 'My Bookmarks - Posts',
            clipsSeries: 'My Bookmarks - Series',
            clipsQuestions: 'My Bookmarks - Questions',
            receivedQuestions: 'Received Questions',
            sentHistory: 'Sent History',
            gallery: 'Gallery',
            technicalSkills: 'Technical Skills',
            violations: 'Violations',
            postsSpam: 'Posts Spam',
            notReady: 'Post Needs To Be Edited',
            scheduledPosts: 'Scheduled Posts',
        },
        updateSkills: 'Update skills',
        noSkill: 'You have no skills yet.',
        technicalSkillsDialog: {
            title: 'Update Technical Skills',
            placeholder: 'Select technical skills',
        },
        sentHistory: {
            title: 'Do you know?',
            firstContent: 'For any question, you can request answer to the people who you think they are knowledgeable. Let click on',
            betweenContent: '"Request answer" in Question Menu',
            lastContent: 'choose an user then send your request.',
        },
        action: {
            addToSeries: {
                title: 'Add to my series',
                empty: 'You don\'t have any series.',
                create: 'Create a new one!',
                titlePopup: 'What series you want to add this post to?',
                add: 'Add',
                sendProposal: 'Send proposal',
                success: 'Add post to series successfully!',
                proposalSuccess: 'Your proposal has been sent. Please wait for owner\'s response!',
                error: 'There was a problem when adding this post to your series.',
            },
            edit: 'Edit',
            delete: {
                title: 'Delete',
                titleDelete: 'Delete post',
                contentDelete: 'Delete this post?',
            },
            seoSetting: 'SEO Settings',
        },
        series: {
            stats: {
                views: 'Views',
                votes: 'Votes',
                comments: 'Comments',
                clips: 'Bookmarks',
                posts: 'Posts',
            },
            status: {
                draft: 'Draft - Last edited: ',
                unlisted: 'Anyone with the link - Last edited: ',
                published: 'Published: ',
                edit: 'Last edit: ',
            },
            action: {
                add: 'Add post',
                edit: 'Edit',
                delete: 'Delete',
                remove: 'Remove',
                contentDelete: 'Do you want to delete this answer?',
                titleRemovePosts: 'Remove posts',
                contentRemovePosts: 'Remove this posts?',
            },
        },
        answer: {
            yourAnswer: 'Your answer:',
            vote: 'Vote:',
            answered: 'Answered',
        },
        sendRequestions: {
            status: 'Status',
            recipient: 'Recipient',
            question: 'Question',
            requestedAt: 'Requested at',
            requestedBy: 'Requested by',
            writeAnswer: 'Write answer',
            answered: 'Answered',
            noAnswerYet: 'No answer yet',
            accepted: 'Accepted',
        },
        violations: {
            type: 'Type',
            title: 'Title',
            reason: 'Reason',
            deleteAt: 'Deleted at',
            comment: 'Comment: ',
            entityTypes: {
                post: 'Post',
                question: 'Question',
                series: 'Series',
                answer: 'Answer',
                comment: 'Comment',
            },
            reasonTypes: {
                spam: 'Spam',
                poor_translation: 'Poor Translation',
                infringes_copyright: 'Infringes Copyright',
                harashment: 'Harashment',
                tos_violation: 'Rules Violation',
            },
        },
        socialShare: {
            title: 'Title',
            creator: 'Creator',
            type: 'Type',
            rankScore: 'Rank score',
            createdAt: 'Created at',
            actions: 'Actions',
            message: 'Message',
            publishedAt: 'Published at: ',
            publishAt: 'Expected publish time: ',
        },
    },

    footer: {
        resources: {
            title: 'Resources',
            posts: 'Posts',
            organizations: 'Organizations',
            questions: 'Questions',
            tags: 'Tags',
            videos: 'Videos',
            authors: 'Authors',
            discussions: 'Discussions',
            recommendSystem: 'Recommend System',
            tools: 'Tools',
            machineLearning: 'Machine Learning',
            systemStatus: 'System Status',
            services: 'Services',
            mobileApp: 'Mobile App',
            links: 'Links',
            rightsReserved: '. All rights reserved.',
        },
        about: 'About Us',
        help: 'Help',
        fAQs: 'FAQs',
        rSS: 'RSS',
        terms: 'Terms',
        feedback: {
            title: 'Feedback',
            titleForm: 'Viblo - Free service for technical knowledge sharing',
            lable: 'Your feedback',
            description: 'Your feedback will be shared with our product delivery teams, and taken into consideration for future development.',
            lableName: 'Name',
            lableEmail: 'Email',
            send: 'Send',
            cancel: 'Cancel',
            survey: 'Take a survey',
            required: 'The feedback content is required',
            min: 'The feedback content must be at least 10 characters',
            max: 'The feedback content is too long',
            success: 'Thank you for your feedback!',
            error: 'Something went wrong while trying to send your feedback. Please try again later.',
            validations: {
                requireContent: 'The feedback content is required',
                contentMin: 'The feedback content must be at least 10 characters',
                contentMax: 'The feedback content is too long',
                typeEmail: 'Must be a valid email',
                requireName: 'Name is required',
                maxName: 'Name is too long',
            },
        },
    },

    question: {
        newest: 'Newest',
        unsolved: 'Unsolved',
        followings: 'Followings',
        myClips: 'My Bookmarks',
        askQuestion: 'Ask Question',
        newestPost: 'Newest posts',
        topAuthors: 'Top authors',
        views: 'Views: ',
        comments: 'Comments: ',
        cliped: 'Bookmarked: ',
        score: 'Score',
        answer: 'Answers',
        view: 'Views',
        comment: 'Comments',
        clips: 'Bookmarks',
        posts: 'Posts',
        clip: 'Bookmark this question',
        unClip: 'Delete bookmark',
        asked: 'Asked',
        unclip: 'Delete bookmark this question',
        isOwn: 'You cannot bookmark your own question',
        recommend: 'Maybe you are interested',
        interview_question: 'Interview questions',
        votes: 'Vote: ',
        noQuestion: 'No question',
        answers: 'answers',
    },

    userInline: {
        reputations: 'Reputations: ',
        posts: 'Posts: ',
        followers: 'Followers: ',
        totalPostViews: 'Total post views: ',
        following: 'Following',
        follow: 'Follow',
        edit: 'Edit',
        questions: 'Questions: ',
        answers: 'Answers: ',
        views: 'Views: ',
        comments: 'Comments: ',
        clips: 'Bookmarks: ',
    },

    myClips: {
        type: 'Type: ',
        posts: 'Posts',
        series: 'Series',
        questions: 'Questions',
        sort: 'Sort by: ',
        published: 'Published date',
        clipAt: 'Bookmarked at',
    },

    myFollowing: {
        type: 'Type: ',
        users: 'Authors',
        organizations: 'Organizations',
    },

    cancelScheduled: {
        title: 'Cancel scheduled sharing',
        confirm: 'Confirm',
        content: 'Are you sure cancel share post?',
        success: 'Post has been removed from list!',
        error: 'Something went wrong. Cannot delete this post!',
    },

    postBody: {
        postInOgranziation: {
            pendingIn: '| Pending in',
            in: 'in',
            followedBy: 'Followed by',
            people: 'people.',
            following: 'Following',
            follow: 'Follow',
        },
        postActions: {
            readingTool: 'Reading tool',
            clip: 'Bookmark this post',
            unclip: 'Unbookmark this post',
            shareLinkFB: 'Share a link to this page on Facebook',
            shareLinkTwitter: 'Share a link to this page on Twitter',
            editorsChoice: 'Mark as Editors\' choice',
            shareToFanpage: {
                title: {
                    notYetShare: 'Share to Viblo Fanpage',
                    hasBeenShare: 'This post has been add to Facebook share list',
                },
            },
            isOwner: 'You cannot bookmark your own post',
            error: 'Something went wrong. Please try again later.',
        },
        postContent: {
            statusPost: {
                published: 'Published',
                unlisted: 'Anyone with the link',
                draft: 'Draft',
                lastEdited: '- Last edited',
                scheduled: 'Scheduled publish at',
            },
            postAuthor: {
                following: 'Following',
                follow: 'Follow',
                edit: 'Edit',
                reputations: 'Reputations: ',
                followers: 'Followers: ',
                posts: 'Posts: ',
                views: 'Views: ',
                scroll: 'Scroll to Comments',
                showClips: 'Show all Bookmarks',
                clipEmpty: 'No bookmark, yet.',
                peopleClipped: ' people bookmarked',
                notFollowing: 'This user haven\'t followed anyone, yet.',
                notFollowed: 'This user doesn\'t have any followers.',
            },
            solveSolution: 'This is a solution to solve',
            puzzle: 'the puzzle',
            ctf: 'in Viblo CTF.',
            helpfulLinks: 'Helpful links:',
            backToList: 'Back to the write-ups list.',
            goToCTF: 'Go to the puzzle in Viblo CTF.',
            spam: 'Spam Alert:',
            firstContent: 'This post is marked as spam by',
            betweenContent: 'our system',
            lastContent: '. So it can\'t be shown in homepage! Administrators will review as soon as possible. If you have any problems, please send us your feedback.',
            notReady: {
                withAuthor: 'This post is not ready to publish. You may have accidentally made it public during writing. Therefore, it was spam. Please change it\'s status to Draft to remove this warning.',
                withUser: 'This post is not ready to publish. The author may have accidentally made it public during writing. Therefore, you should ponder before reading this post.',
            },
            notUpdate: {
                content: 'This post hasn\'t been updated for {year} years',
            },
            postMenu: {
                title: 'Show more actions',
                edit: 'Edit',
                report: 'Report',
                addToSeries: 'Add to my series',
                removeOrganization: 'Remove from organization',
                submitOrganization: 'Submit to organization',
                convertQuestion: 'Convert to question',
                deletePost: 'Delete this post',
                markSpam: 'Mark as spam',
                reportNotSpam: 'Report not spam',
                reportNotReady: 'Report not ready',
                postModeration: 'Post Moderation',
                codeBlocks: 'Highlighting code blocks',
            },
            dialogReport: {
                title: 'Report content',
                titleForUser: 'Reason for delete this content',
                titleForm: 'Reason for reporting this content',
                spam: 'Spam',
                rulesViolation: 'Rules Violation',
                harassment: 'Harassment',
                infringesCopyright: 'Infringes copyright',
                poorTranslation: 'Poor Translation',
                placeholder: 'Comment (max 255 characters)',
                placeholderUser: 'Comment',
                vibloTerms: 'Viblo Terms of Service',
                requireReason: 'Please choose a reason',
                report: 'Report',
                delete: 'Delete',
                success: 'Content successfully reported',
                successDeleted: 'Content successfully deleted',
                error: 'There was a problem reporting this content. Please try again later',
                errorDeleted: 'There was a problem deleting this content. Please try again later',
            },
            dialogAddOrganization: {
                title: 'Which organization would you like to add this post to?',
                submit: 'Submit to an organization',
                notOrganization: 'You don\'t have any organization.',
                createOrganization: 'Create a new organization',
                success: 'This post has been submitted to the organization {name}',
                error: 'Something went wrong. Can not submit this post to the organization. Please try again later.',
            },
            removeOrganization: {
                content: 'Do you want remove to organization?',
                title: 'Remove to organization',
                success: 'This post has been removed from organization',
            },
            convertToQuestion: {
                title: 'Are you sure?',
                content: 'Convert post to question, the post will be deleted when completed. Are you sure to continue?',
                success: 'The post is converted to question successfully.',
                error: 'There was something wrong!',
            },
            deletePost: {
                title: 'Delete this post',
                content: 'Do you want to delete this post?',
                success: 'Post has been deleted',
                error: 'There was something wrong!',
            },
            markSpam: {
                title: 'Mark as spam',
                content: 'Do you want to mark this post as Spam?',
                success: 'Post has been marked as Spam. It\'s will be reviewed by administrator.',
                error: 'There was something wrong!',
                noRight: 'This post cannot be marked as spam because the admin has marked it as not spam',
            },
            notSpam: {
                title: 'Report not spam',
                content: 'Are you sure this post is not a spam?',
                success: 'Post unmarked as spam.',
                error: 'There was something wrong!',
                tooltipNotSpam: 'Mark not spam',
                tooltipLink: 'Go to post',
            },
            reportNotReady: {
                title: 'Report not ready',
                content: 'Are you sure this post is not ready for publish?',
                success: 'Post marked as not ready for publish.',
                error: 'The was something wrong!',
            },
            highlightingCode: {
                title: 'Highlighting code blocks',
                content: 'Do you want to highlight code blocks in this post?',
                success: 'Update highlighting code blocks successfully!',
                error: 'There was something wrong!',
            },
            postModeration: {
                title: 'Post Moderation',
                save: 'Save',
                placeholder: 'Please fill suitable tag for this post. Maximum 5 tags',
            },
        },
        postSidebar: {
            contentsTree: {
                title: 'Table of contents',
                empty: 'Have no Table of contents',
            },
            series: 'Series\'s Post',
            organization: 'Suggested Organizations',
        },
        recommendations: {
            related: 'Related',
            moreFrom: 'More from',
        },
    },

    detailQuestion: {
        stats: {
            views: 'Views',
            clips: 'Bookmarks',
            answers: 'Answers',
        },
        postAnswer: 'Post answer',
        questionMenu: {
            title: 'Show more actions',
            edit: 'Edit',
            report: 'Report',
            requestAnswer: 'Request answer',
            deleteQuestion: 'Delete this question',
            shareToFanpage: 'Share to Fanpage',
            questionModeration: 'Question moderation',
            highlighting: 'Highlighting code blocks',
        },
        dialogRequestAnswer: {
            title: 'Request Answer',
            content: 'Please search and select recipient who you want to send an answer request for question',
            placeholder: 'Search recipient for question',
            note: 'Note: You only can send 3 answer requests per day.',
            cancel: 'Cancel',
            send: 'Send',
            confirmRequestAnswer: {
                title: 'Confirm Send Request',
                content: 'Are you sure? Send an answer request to user',
            },
            sendRequest: 'Request has been sent to',
        },
        confirmDelete: {
            title: 'Delete this question',
            content: 'Do you want to delete this question?',
            success: 'Question has been deleted successfully!',
        },
        dialogQuestionModeration: {
            title: 'Question Moderation',
            placeholder: 'Please fill suitable tag for this question. Maximum 5 tags',
            save: 'Save',
            error: 'Something went wrong while trying to update this question',
        },
        highlightCodeAnswer: {
            title: 'Highlighting code blocks',
            content: 'Do you want to highlight code blocks in this answer?',
            success: 'Success! It maybe take a few minutes to complete.',
        },
        comment: {
            title: 'Add a comment',
            answers: 'ANSWERS',
            commentForm: {
                write: 'Write',
                preview: 'Preview',
                writeAResponse: 'Write a response...',
                discard: 'Discard',
                save: 'Save',
                comment: 'Comment',
                empty: 'Nothing to preview!',
                comfirmDiscard: {
                    title: 'Are you sure?',
                    content: 'Changes you made may not be saved. Are you sure?',
                },
                emoji: 'Emoji Suggestion',
                image: 'Drag image or click to attach',
                markdown: 'Markdown Help',
                answer: 'Answer',
                answering: 'You are answering as:',
                saveComment: 'Save Comment',
                saveAnswer: 'Save',
            },
            answerForm: {
                answered: 'Answered',
                accepted: 'Accepted',
                acceptAnswer: 'Accept this answer',
                approveAnswer: 'Approve to ',
                reject: 'Reject',
            },
            socialShare: {
                title: 'Share a link to this answer',
                button: 'Share',
                close: 'Close',
            },
            menuAction: {
                title: 'Show more actions',
                edit: 'Edit',
                report: 'Report',
                deleteAnswer: 'Delete this answer',
                highlighting: 'Highlighting code blocks',
            },
        },
        deleteConfirm: {
            title: 'Delete answer',
            content: 'Deleted answers are gone forever. Are you sure?',
            success: 'This answer have been removed.',
            error: 'Something went wrong. Can not remove this answer.',
        },
        confirmHighlight: {
            title: 'Highlighting code blocks',
            content: 'Do you want to highlight code blocks in this answer?',
            success: 'Success! It maybe take a few minutes to complete.',
        },
        commentHasNotAccepted: {
            reply: 'Reply',
            actionComment: {
                title: 'Show more actions',
                edit: 'Edit',
                report: 'Report',
                deleteComment: 'Delete this comment',
                convertToAnswer: 'Convert to Answer',
                highlighting: 'Highlighting code blocks',
            },
            confirmDelete: {
                title: 'Delete comment',
                content: 'Deleted comments are gone forever. Are you sure?',
            },
            confirmConvert: {
                title: 'Convert this to answer',
                content: 'This comment will be deleted then posted as an Answer. Are you sure?',
                success: 'Converted to answer successfully!',
                commentDeleted: 'This comment has been deleted',
            },
            confirmHighlight: {
                title: 'Highlighting code blocks',
                content: 'Do you want to highlight code blocks in this comment?',
                success: 'Success! It maybe take a few minutes to complete.',
            },
        },
    },

    commentPost: {
        comments: 'Comments',
        loading: 'Loading comments...',
        login: 'Login to comment',
        noComent: 'No comments, yet.',
        postComment: 'Post Comment',
        viewMore: 'View more',
    },

    activities: {
        title: 'My activities',
        filterAction: {
            all: 'All activities',
            clip: 'Bookmark',
            unclip: 'Delete bookmark',
            upVote: 'Upvote',
            downVote: 'Downvote',
            unVote: 'Unvote',
            accept: 'Accept',
            delete: 'Delete',
            upVoteUndo: 'Upvote/Undo',
            downVoteUndo: 'Downvote/Undo',
        },
        filterType: {
            all: 'All types',
            post: 'Post',
            question: 'Question',
            series: 'Series',
            organization: 'Organization',
            answer: 'Answer',
            tag: 'Tag',
            comment: 'Comment',
            user: 'User',
        },
        search: 'Search',
        table: {
            date: 'Date',
            type: 'Type',
            interactableTypes: {
                Post: 'Post',
                User: 'User',
                Tag: 'Tag',
                Question: 'Question',
                Organization: 'Organization',
                Series: 'Series',
                Answer: 'Answer',
                Comment: 'Comment',
            },
            activity: {
                title: 'Activity',
                clip: 'Bookmark',
                unclip: 'Delete Bookmark',
                follow: 'Follow',
                unfollow: 'Unfollow',
                upvoteUndo: 'Upvote/Undo',
                downvoteUndo: 'Downvote/Undo',
                delete: 'Delete',
                accept: 'Accept',
                unvote: 'Unvote',
                upvote: 'Upvote',
                downvote: 'Downvote',
                acceptUndo: 'Accept/Undo',
            },
            title: 'Title',
            actions: 'Actions',
        },
        vote: {
            comment: {
                cannotVote: 'You cannot vote your own comment',
            },
            content: {
                cannotVote: 'You cannot vote your own content',
            },
            needReputationToVote: 'You need 50 reputation to vote',
            upvote: 'Upvote',
            downVote: 'Downvote',
        },
        comment: {
            reply: 'Reply',
            shareLinkComment: 'Share a link to this comment',
            commentAnswer: 'Comment on answer',
        },
    },

    createSeries: {
        title: 'Title',
        save: 'Save',
        publishPopover: {
            publishSeries: 'Publish your series',
            startPublish: 'Add a title and start writing something to publish.',
            visibility: 'Visibility:',
            public: 'Public',
            unlisted: 'Anyone with the link',
            draft: 'Private draft',
            type: 'series',
            descriptionPublic: 'Everyone can see your ',
            descriptionSchedule: 'Schedule publish ',
            descriptionUnlistFirst: 'Only those with the link to this ',
            descriptionUnlistLast: ' can see it.',
            descriptionDraftFirst: 'Only you can see this ',
            descriptionDraftLast: '. Your draft is already saved automatically as you type.',
            saveDraft: 'Save',
            publish: 'Publish',
            schedule: 'Schedule',
            selectDateTime: 'Select date and time',
        },
        saving: 'Saving',
        cannotSave: 'Cannot save your series',
        saved: 'Saved',
        addPosts: {
            title: 'SERIES CONTENTS',
            noPostYet: 'There\'s no post yet. Please add posts to this series to make it displayed on the homepage!',
            addPost: 'Add post',
            titleDialog: 'Add posts',
            buttonAdd: 'Add posts',
            cancel: 'Cancel',
            notPost: 'You have no post.',
            write: 'Go write one',
            edit: 'Edit',
            remove: 'Remove',
            titleRemove: 'Remove post',
            contentRemove: 'Remove this post from series?',
        },
        validations: {
            title: 'The series title is required',
            contents: 'The series content is required',
        },
    },

    series: {
        draft: 'Draft - Last edited',
        unlisted: 'Anyone with the link - Last edited',
        published: 'Published at',
        views: 'Views',
        clips: 'Bookmarks',
        comments: 'Comments',
        posts: 'Posts',
        menuAction: {
            title: 'Show more actions',
            edit: 'Edit',
            report: 'Report',
            delete: 'Delete this series',
            share: 'Share to Fanpage',
            highlight: 'Highlighting code blocks',
        },
        lableShare: 'Share a link to series',
        bottom: {
            contents: 'CONTENTS',
            postsDraft: 'This draft post is hidden in the series. Please make this post public!',
            noPostsYet: 'This series doesn\'t have any posts',
        },
        titleComment: 'COMMENTS',
        unclip: 'Delete bookmark',
        clip: 'Bookmark this series',
        unclipTooltip: 'Delete bookmark this series',
        isOwn: 'You cannot bookmark your own series',
        author: 'AUTHORS',
        comfirmDelete: {
            title: 'Delete series',
            content: 'Deleted series are gone forever. Are you sure?',
            success: 'Series has been deleted',
        },
        hinghlightCode: {
            title: 'Highlighting code blocks',
            content: 'Do you want to highlight code blocks in this series?',
            success: 'Success! It maybe take a few minutes to complete.',
        },
        createButton: 'Create new series',
    },

    createQuestion: {
        title: 'Title',
        contentTag: 'Tag your question. Maximum 5 tags.',
        create: 'Create',
        edit: 'Edit',
        question: 'Question',
        discard: 'Discard',
        errorUpdate: 'An error happened while trying to update your question. Please try again!',
        errorAdd: 'An error happened while trying to post your question. Please try again!',
        validateTitle: 'The question title is required',
        validateTag: 'The question tag is required',
        validateContent: 'The question content is required',
    },

    seoSetting: {
        title: 'SEO Settings',
        titleForm: 'Title',
        placeholderTitle: 'Enter title...',
        description: 'Description',
        placeholderDescription: 'Enter description...',
        preview: 'Preview',
        desktop: 'Desktop preview',
        mobile: 'Mobile preview',
        save: 'Save Settings',
        titleEmpty: 'Title field is empty',
        titleShort: 'Your title is probably too short',
        titlePerfect: 'Congrats! Your title has an ideal length for search engines.',
        titleLong: 'Your title is too long and will be cut off some words in Google results',
        descriptionEmpty: 'Description field is empty',
        descriptionShort: 'Your description is probably too short',
        descriptionPerfect: 'Congrats! Your description has an ideal length for search engines.',
        descriptionLong: 'Your description is too long and will be cut off some words in Google results',
        success: 'SEO settings has been updated successfully!',
        error: 'Something is wrong, please try again!',
        validateTitle: 'Title must not be empty.',
        validateDescription: 'Description must not be empty.',
        validateDescriptionMin: 'The description must be at least 150 characters',
        validateDescriptionMax: 'The description is too long',
    },

    createPost: {
        missingTags: 'Please tag at least 1 tag to automatically save a public post.',
        isTagDifferentFromEvents: 'You need to add at least 1 tag, maximum of 5 tags, apart from the Event tag to save this post.',
        lastSaved: 'Last saved: ',
        save: 'Saved',
        title: 'Title',
        tootipTitle: 'Open this post in the new tab',
        lableCategories: 'Categories',
        Normal: 'Normal',
        Help: 'Help',
        Announcement: 'Announcement',
        FAQ: 'FAQ',
        lableSystem: 'System categories',
        Vi_term: 'Terms of Service (Vietnamese)',
        En_term: 'Terms of Service (English)',
        Jp_term: 'Terms of Service (Japanese)',
        placeholdertag: 'Tag your post. Maximum 5 tags. At least 1 tag!',
        seoSetting: 'SEO Settings',
        changeImage: {
            title: 'Change thumbnail',
            detailChangeImage: 'Using a high-quality image will attract more people to your article. By default, the thumbnail of Viblo will be used as the thumbnail for your article.',
            upload: 'Upload',
            enter: 'Enter thumbnail url',
            remove: ' Remove thumbnail',
            titleChange: 'Set Thumbnail URL',
            contentChange: 'Please enter a direct link to your external thumbnail file:',
            error: 'Invalid URL',
            uploadSuccess: 'The feature image have been updated successfully!',
            errorUpload: 'The width and height of the feature image must be at least ',
            errorChage: 'Thumbnail change failed',
            titleConfirmRemove: 'Are you sure?',
            contentConfirmRemove: 'If you remove this image, Viblo\'s default thumbnail would be applied. Are you sure to continue removing the current thumbnail?',
            removeSuccess: 'The feature image have been removed successfully!',
            cancel: 'Cancel',
            confirm: 'OK',
        },
        leaveTitle: 'Are you sure?',
        leaveContent: 'Your post content is not saved. Do you want to leave?',
        publishPopover: {
            title: 'Add a title and start writing something to publish.',
            addIn: 'Added in',
        },
        writeUpTitle: 'Permission denied!',
        writeUpContent: 'Please solve the puzzle first. If you gave up, you also can not submit a write-up.',
        saving: 'Saving',
        cannotSave: 'Cannot save your post',
        errorAutoSave: {
            title: 'Autosave Failed',
            content: 'Couldn\'t autosave this post. Please save manually edited content!',
            confirm: 'OK, I\'ll save it manually',
        },
        submitOrganization: {
            success: 'Submitted to ',
            error: 'Something went wrong. Can not submit to organization.',
        },
        removeOrganization: {
            title: 'Cancel submission',
            content: 'This post will be removed from the organization. Are you sure?',
            success: 'Removed from organization',
        },
        licence: {
            title: 'License:',
            selectLicense: 'Please select the appropriate license for your work:',
            someRights: 'Some rights reserved',
            noRights: 'No rights reserved',
            saveLicence: 'Save license',
            cancel: 'Cancel',
            warning: 'Autosaving is running... Please wait and try again!',
            success: 'The license is updated!',
            error: 'Please save the post first!',
            allRightReserved: 'All Rights Reserved',
            noRightsReserved: 'No Rights Reserved - Public Domain',
            thisWork: 'This work',
            by: 'by',
            isLicensedUnder: 'is licensed under',
            allRight: {
                lable: 'All rights reserved',
                shortName: 'All Rights Reserved',
            },
            ccBy: {
                lable: 'Attribution',
                shortName: 'CC BY 4.0',
            },
            ccBySA: {
                lable: 'Attribution, share alike',
                shortName: 'CC BY-SA 4.0',
            },
            ccByND: {
                lable: 'Attribution, no derivatives',
                shortName: 'CC BY-ND 4.0',
            },
            ccByNC: {
                lable: 'Attribution, non-commercial',
                shortName: 'CC BY-NC 4.0',
            },
            ccByNCSA: {
                lable: 'Attribution, non-commercial, share alike',
                shortName: 'CC BY-NC-SA 4.0',
            },
            ccByNCND: {
                lable: 'Attribution, non-commercial, no derivatives',
                shortName: 'CC BY-NC-ND 4.0',
            },
            ccCCO: {
                lable: 'Copyright waiver',
                shortName: 'CC0 1.0',
            },
            publicDomain: {
                lable: 'Public domain',
                shortName: 'Public Domain',
            },
            rules: {
                all: 'Others cannot distribute, remix, adapt, and build upon your work without your permission (or as permitted by fair use).',
                by: 'Others can distribute, remix and build upon your work as long as they credit you for the original creation.',
                sa: 'Others must license the derivatives, the modified material under the same terms.',
                nd: 'Others can copy and distribute your work as long as they do not change it or create derivative works.',
                nc: 'Others can only use your work for non-commercial purpose.',
                cc0: 'You waive all copyright and similar rights in your work and dedicating those rights to the public domain.',
                pd: 'This work is already in the public domain and free of copyright restrictions.',
            },
        },
        puplishPopup: {
            title: 'Add a title, pick up at least a tag and start writing something to publish.',
            publishPost: 'Publish your post',
            contentLicensing: 'Content Licensing',
            submitOrganization: 'Submit to organization',
            removeOrganization: 'Remove organization',
            organizationSelecter: {
                title: 'Select a organization which will be added',
                cancel: 'Cancel',
                submit: 'Submit',
            },
            confirmSubmit: {
                title: 'Ready to submit in the organization?',
                content: 'This will submit post to the organization. Are you sure this post is ready to publish in the organization?',
                content1: 'Are you sure to submit this ',
                content2: ' in the organization?',
            },
        },
        buttonSave: 'Save',
        buttonPublish: 'Publish',
        post: 'post',
        errorMessage: {
            scheduleTimeAfter: 'Scheduled posts need to be shared between 20 minutes and 75 days from when you create them.',
        },
    },

    createVideo: {
        title: 'Title',
        addVideo: 'Add video',
        changeVideo: 'Change this video',
        slideUrl: 'Presentation slide URL',
        placeholdertag: 'Tag your post.',
        publishPopover: {
            title: 'Add a title, choose a video and start writing something to publish.',
        },
        noteCanAddVideo: 'Add a title and start writing something to save post after add a video.',
        saving: 'Saving',
        saved: 'Saved',
        canNotSave: 'Cannot save your post',
        addByUrl: 'Thêm video bằng đường dẫn URL',
        addVideoLabel: 'You can add video by the link of media on Youtube Or Vimeo:',
        endterYourLink: 'Enter your link (full URL of media)',
        addURL: 'Add URL',
        noteDirectVideo: 'Direct public Youtube, Vimeo.',
        mesageErrorLink: 'Please enter correct link of media on Youtube Or Vimeo',
    },

    organization: {
        title: 'Organizations',
        sort: {
            label: 'Sort by',
            posts: 'Number of Posts',
            users: 'Number of Users',
            followers: 'Followers',
        },
        headingTabs: {
            myOrganizations: 'My Organizations',
            followings: 'Followings',
        },
        listJoined: {
            first: 'You don\'t follow any organizations yet. Let take a look at',
            organization: 'organizations',
            last: 'in Viblo!',
        },
        create: 'Create organization',
        companyItem: {
            stast: {
                posts: 'Posts: ',
                followers: 'Followers: ',
                member: 'Member: ',
                location: 'Location',
            },
            menuAction: {
                writePost: 'Write post',
                submissions: 'Submissions',
                manageMembers: ' Manage members',
                editOrganization: 'Edit organization',
                deleteOrganization: 'Delete organization',
                leaveOrganization: 'Leave organization',
            },
            confirmDelete: {
                title: 'Delete organization',
                content: 'Are you sure delete this organization?',
            },
            confirmLeave: {
                title: 'Leave organization',
                content: 'Are you sure to leave this organization?',
            },
        },
        head: 'My Organizations - ',
        followings: 'Following',
        follow: 'Follow',
        noFollowers: 'This company have no followers, yet.',
        invitations: {
            title: 'Invitations',
            accept: 'Accept',
            decline: 'Decline',
            invitedBy: 'invited by',
            invited: 'Invited',
        },
        stats: {
            postView: 'Post views',
            followers: 'Followers',
            posts: 'Posts',
            members: 'Members',
            numberOf: 'Number of',
            from: 'acquired from',
            to: 'to',
        },
        level: {
            gold: 'Gold',
            silver: 'Silver',
            diamond: 'Diamond',
            diamondDescription: 'Diamond Content Creator',
            goldDescription: 'Gold Content Creator',
            silverDescription: 'Silver Content Creator',
        },
    },

    detailOrgaization: {
        menu: {
            posts: 'Posts',
            submissions: 'Submissions',
            members: 'Members',
            followers: 'Followers',
            aboutUs: 'About Us',
            stats: 'Stats',
            invitations: 'Invitations',
        },
        edit: 'Edit',
        following: 'Following',
        follow: 'Follow',
        stast: {
            newPosts: 'New Posts',
            postViews: 'Views',
            posts: 'Posts',
            followers: 'Followers',
            members: 'Members',
        },
        notApproved: 'The organization is not approved by Administrator yet. After approved, you can write post, invite member, etc. If rejected, it will be also deleted.',
        noMember: 'This company have no members, yet.',
        membersList: 'Members List',
        technology: 'Technology stacks',
        noTechnology: 'Have no technology yet.',
        wiewAdminNoTechnology: 'Update now!',
        dialogTag: {
            title: 'Update Technical Stacks',
            placeholder: 'Select technical stacks',
            save: 'Save',
            success: 'Success',
            error: 'Something went wrong',
        },
        about: {
            fullDescription: 'This organization hasn\'t updated full description!',
            edit: 'Edit',
        },
        darkMode: 'Dark mode',
        lightMode: 'Light mode',
    },

    inviteInOrgaization: {
        title: 'Invite Member',
        fistContent: 'You can invite members to the',
        lastContent: 'organization.',
        formSearch: 'Search user for invite',
        formRole: 'Set role for user',
        invite: 'Invite',
        success: 'Invited!',
        error: 'The user is already invited to the organization.',
        valdateError1: 'Please fill the input to invite users to this organization.',
        valdateError2: 'The user is already invited to the organization.',
        invitations: 'Invitations',
        cancel: 'Cancel',
        writer: 'Writer',
        editor: 'Editor',
        admin: 'Admin',
        owner: 'Owner',
        allRole: 'All Role',
        confirmCancel: {
            title: 'Are you sure?',
            content: 'Cancel invitation',
            success: 'Invitation cancelled!',
        },
        confirmRemove: {
            title: 'Are you sure',
            content: 'Remove member',
        },
        confirmChangeRole: {
            title: 'Change role',
            success: 'Member role changed!',
            setOwner: 'If you set ',
            as: ' as ',
            roleOwner: ' you will be set as admin and you are not owner of this organization. Are you sure?',
            set: 'Set',
        },
        removeMember: {
            succes: 'This member has been removed.',
            error: 'Sorry. Something went wrong.',
        },
        existingMember: {
            title: 'Existing Members',
            search: 'Search member',
            table: {
                name: 'Name',
                role: 'Role',
                action: 'Action',
            },
        },
        invited: 'Invited',
    },

    detailTag: {
        following: 'Following',
        follow: 'Follow',
        posts: 'Posts',
        series: 'Series',
        questions: 'Questions',
        followers: 'Followers',
        topTag: 'Top QA Tags',
        popularTag: 'Popular Tags',
        allTag: 'All tags',
    },

    createOrganization: {
        title: 'New Organization',
        alert: 'The new organization is created that must be approved by Administrator. After that, you can write post, invite member, etc. If rejected, It will be also deleted.',
        inputName: {
            title: 'Name',
            required: 'Name is required',
            max: 'Name must not be longer than 255 characters',
        },
        inputTrackingID: {
            title: 'Tracking ID (Google Analytics)',
            max: 'Tracking ID must not be longer than 20 characters',
        },
        googleAnalyticHelp: 'You can use google analytics track id v3 or v4',
        inputURL: {
            title: 'URL',
            required: 'URL is required',
            max: 'URL must not be longer than 255 characters',
        },
        inputWebsite: {
            title: 'Website',
            max: 'Website must not be longer than 253 characters',
            type: 'Must be a valid URL',
        },
        inputLocation: {
            title: 'Location',
            required: 'Location is required',
            max: 'Location must not be longer than 255 characters',
        },
        inputShotDescription: {
            title: 'Short Description',
            required: 'Short description is required',
            max: 'Description must not be longer than 500 characters',
            placeholder: 'Short description about your organization...',
        },
        inputFullDescription: {
            title: 'About This Organization',
            required: 'This article is required',
            min: 'This article must has at least 200 characters',
            max: 'This article must not be longer than 5000 characters',
            placeholder: 'Markdown syntax is supported! For example:\n'
            + 'To next line, using HTML <br> tag or Enter Twice!\n'
            + 'Heading h1~h6, using #, ##, ...!\n'
            + 'Bold a text, using **text**\n',
        },
        verify: {
            first: 'You need to',
            between: 'verify your website ownership',
            last: 'before your website shows up to everyone.',
        },
        save: 'Save',
        create: 'Create',
        update: 'Update',
        requiredAvt: 'Organization logo is required',
        confirmEditSlug: {
            title: 'Be careful. Renaming an organization\'s url can have unintended side effects. The people cannot access to your organization by the old URL. Are you sure to continue?',
            content: 'Are you sure?',
        },
        updatesuccess: 'Success',
        layout: {
            title: 'Layout',
            detail: 'You can choose a layout template to apply on your organization’s subdomain page.',
            placeholder: 'Select Display Layout',
            options: {
                default: 'Layout 1',
                layoutSubdomain2: 'Layout 2',
            },
        },
    },

    verifyOrganization: {
        verified: 'This organization has been verified by Viblo.',
        noVerify: 'Verify ownership',
        firstTitle: 'Follow the instructions below to verify your website',
        lastTitle: 'ownership.',
        strategies: 'Strategies',
        htmlFile: {
            title: 'HTML File',
            here: 'here',
            step1: 'Step 1: Download HTML file',
            step2: ' Step 2: Upload above HTML file to your domain’s web host.',
            step3: 'Step 3: Click the verify button below.',
            button: 'Verify',
        },
        htmlTag: {
            title: 'HTML <meta> Tag',
            step1: 'Step 1: Copy the code below.',
            step2: 'Step 2: Paste the above code inside the <head> section of your website\'s homepage.',
            step3: 'Step 3: Click the verify button below.',
        },
        dnsRecord: {
            title: 'DNS record',
            step1: 'Step 1: Go to your domain’s DNS Management page.',
            step2: 'Step 2: Select the option to add a new record.',
            step3: 'Step 3: Add new record TXT as follows:',
            step4: 'Step 4: Click the verify button below.',
        },
        email: {
            title: 'Mail',
            step1: 'We support website verification by sending an activation email to some restricted email addresses.',
            step2: 'Please choose an email address below to continue:',
            sent: {
                firstTitle: 'Verification email has been sent to your selected mail address. Still haven\'t receiving any email?',
                betweenTitle: 'Click here',
                lastTitle: 'to resend the verification email.',
            },
            success: 'Email sent! Please follow the instruction in email to proceed verification.',
            error: 'Something goes wrong and email couldn\'t be sent. Please try again later.',
        },
        success: 'Verification success!',
        warning: 'We couldn\'t verify your website\'s ownership. Please recheck your verification steps.',
        error: 'We cannot proceed verification right now. Please try again later.',
    },

    statsOrganization: {
        title: 'Stats for',
        overview: {
            title: 'Overview',
        },
        contribution: {
            title: 'Contribution',
            search: 'Search member',
            table: {
                stt: 'STT',
                name: 'Name',
                reputations: 'Reputations',
                tootipReputations: 'Reputations: ',
                publish: 'Published Posts',
                inOrganization: ' In Organization',
                organizationPosts: 'Organization Posts: ',
                allIn: 'All In Viblo',
                toolTipAllIn: 'Posts: ',
                postViews: 'Postviews',
                tootipPostView: 'Views: ',
                answers: 'Answers',
                tootipAnswers: 'Answers: ',
            },
        },
        date: {
            thisMonth: 'This month',
            lastMonth: 'Last month',
            twoMonths: '2 months ago',
        },
    },

    discussions: {
        title: 'DISCUSSIONS',
        noDiscussions: 'No discussion yet.',
        answer: {
            isOwner: 'commented to your answer in question',
            default: 'commented to answer in the question',
        },
        question: {
            isOwner: 'commented to your question',
            default: 'commented to the question',
        },
        post: {
            isOwner: 'commented to your post',
            default: 'commented to the post',
        },
        series: {
            isOwner: 'commented to your series',
            default: 'commented to the series',
        },
        default: 'commented on',
        of: 'of',
    },

    dialogRegister: {
        title: 'Let\'s register a Viblo Account to get more interesting posts.',
        register: 'Register',
        login: 'Login',
        createAccount: 'Create a new account',
        seeMore: 'Learn & sharing with',
        forgotPassword: 'Forgot password ?',
        or: 'or',
        skip: 'Skip',
    },

    dialogNoAvt: {
        title: 'You have no avatar yet!',
        content: 'It’s time to have a new awesome look on your profile. Only 1 step to easily create a stunning avatar from a photo in Viblo Accounts.',
        button: 'OK, I got it!',
    },

    dialogExternalSurvey: {
        title: 'User Survey',
        content: "Hi, currently Viblo team is consulting users to improve service quality. Let's take a moment to survey with us ^^!",
        confirmBtn: "Let's go",
        cancelBtn: 'Cancel',
    },

    dialogUpload: {
        title: 'Insert thumbnails',
        dropzone: 'Drag and drop files here or click to upload',
        watermark: 'Enable Watermark',
        yourPhoto: 'Your photos',
        cancel: 'Cancel',
        updateProfilePicture: 'Update profile picture',
    },

    dialogThumbnail: {
        thumbnailTitle: 'Viblo templates',
        thumbnailContent: 'Please choose a thumbnail for your post:',
        empty: 'No templates to preview',
    },

    dialogDetect: {
        title: 'Convert image to LaTex',
        dropzone: 'Hold and drag, or paste (Ctr/Command + V) an image file here, or click to select a file.',
        result: 'Result:',
        done: 'Done',
        notDetectFomulas: 'No result found. Please try again!',
        note: '(*) Please note that the current version works best for single-line formulas; more complicated ones will be supported in the next versions.',
        description: 'An easy-to-use tool that converts images of math formulas to LaTeX.',
    },

    dialogReadingTool: {
        tooltip: 'Reading tool',
        fontSize: 'Font size',
        lineHeight: 'Line height',
        reset: 'Reset',
        default: 'Default',
        fullScreen: 'Full Screen',
        colorMode: 'Color Mode',
    },

    topAuthors: {
        title: 'Top authors',
        sortBy: 'Sort by',
        sortOptions: {
            reputation: 'Reputation',
            followers: 'Followers',
            posts: 'Posts',
        },
        rangeFilter: {
            week: 'Week',
            month: 'Month',
            all: 'All',
        },
    },

    editor: {
        placeholder: 'Markdown syntax is supported. Click ？ for Help\n'
            + 'To next line, using HTML <br> tag or Enter Twice\n'
            + 'Click 👁 or press "Ctrl + P" to toggle Preview mode\n'
            + 'Click ▯▯ or press "F9" to toggle Side by Side Preview mode\n'
            + 'Click 🕂 or press "F11" to toggle Fullscreen mode \n'
            + 'To highlight codes, please add the programming lowercase language name after the opening triple backticks (for example: ```ruby)',
        lines: 'Lines',
        words: 'Words',
        upload: {
            uploading: 'Uploading',
            fileUploading: 'Uploading your files',
        },
        emoji: {
            title: 'Emoji Suggestion',
            categories: {
                search: 'Search Results',
                recent: 'Frequently Used',
                people: 'Smileys & People',
                nature: 'Animals & Nature',
                foods: 'Food & Drink',
                activity: 'Activity',
                places: 'Travel & Places',
                objects: 'Objects',
                symbols: 'Symbols',
                flags: 'Flags',
                custom: 'Custom',
            },
            notFound: 'No Emoji Found',
            search: 'Search',
            pick: 'Pick your emoji',
        },
        actions: {
            link: 'Insert Link (Ctrl-K)',
            image: 'Insert Image (Ctrl-Alt-I)',
            embed: 'Add an embed',
            help: 'Markdown Help',
            shortcut: 'Shortcut Cheatsheet',
            emoji: 'Emoji Suggestion',
            alignCenter: 'Center the content (Ctrl-Shift-E)',
            alignLeft: 'Left align content (Ctrl-Shift-L)',
            alignRight: 'Right align content (Ctrl-Shift-R)',
            fullscreen: 'Fullscreen mode (F11)',
            sideBySide: 'Side by side mode (F9)',
            embedPopup: {
                title: 'Add an embed',
                label: 'Please enter embed URL:',
                ok: 'OK',
                cancel: 'Cancel',
                invalid: 'Invalid URL. Accepted URL: Youtube, Vimeo, Slideshare, Codepen, Gist, JsFiddle, GoogleSlide, Soundcloud.',
            },
            linkPopup: {
                title: 'Insert link',
                label: 'Please enter your link:',
                ok: 'OK',
                cancel: 'Cancel',
                invalid: 'Doesn\'t look like a valid URL.',
                link: 'Link',
                placeholder: 'Please insert a link or search from your posts',
            },
            detectMatex: 'Image to LaTeX Converter',
            invalidImage: 'Invalid image format please try again',
        },
    },

    dialogMarkdownHelp: {
        title: 'Markdown Help',
        linkFull: 'Link Full: ',
        clickto: 'Click to see more',
        row1: {
            component: 'Bold',
            markdown: '**Bold** (No Space after opening and before closing)',
        },
        row2: {
            component: 'Next Line',
            markdown: 'Using HTML <br> tag or Enter Twice',
        },
        row3: {
            component: 'Italic',
            markdown: '*Italic* (No Space after opening and before closing)',
        },
        row4: {
            component: 'Inline code',
            markdown: 'Inline code',
        },
        row5: {
            component1: 'var',
            component2: ' s = \'Syntax Highlighting\';',
            markdown1: '```javascript',
            markdown2: 'var s = \'Syntax Highlighting\';',
            markdown3: '```',
            markdown4: 'If you want to include both language and filename',
            markdown5: '```javascript:app/index.js',
            markdown6: 'var s = \'Syntax Highlighting\';',
            markdown7: 'We supported 64 languages such as PHP, JavaScript, XML, YAML...',
            markdown8: 'Read more.',
        },
        row6: {
            component: 'Link',
            markdown: '[Link](http://~)',
        },
        row7: {
            component: 'Image',
        },
        row8: {
            component: 'Headings (h1~h6)',
            markdown1: '# h1 (A space after #)',
            markdown2: '## h2 (A space after ##)',
            markdown3: '### h3 (A space after ###)',
        },
        row10: {
            component: 'Unordered list',
            markdown: '* Item (A space after *)',
        },
        row11: {
            component: 'Ordered list 1, 2, 3',
        },
        row12: {
            component: 'Horizontal rules',
        },
        row13: {
            component: 'Blockquotest',
            markdown: '> Blockquotes',
        },
        row14: {
            component: 'Table',
            markdown1: '| Column 1 | Column 2 |',
            markdown2: '| - | - |',
            markdown3: '| Text | Text |',
        },
        row15: {
            component: 'Escape markdown',
        },
        row16: {
            component: 'Embed syntax',
            markdown1: '{@embed: Your embed URL}',
            markdown2: '(Supported embed URL: Youtube, Vimeo, Slideshare, Codepen, Gist, JsFiddle, Google Slide, Soundcloud)',
        },
        row17: {
            component: 'Math formula',
            markdown1: 'Inline: $f(x) = x$',
            markdown2: '(No spaces after opening or before closing)',
            markdown3: 'Block:',
            markdown4: '(See more)',
        },
        row18: {
            component: 'Aligning syntax',
            markdown1: 'Swap your content inside a HTML tag (div, p) with ',
            markdown2: 'align',
            markdown3: 'attribute. Example:',
            markdown4: '<div align="left|right|center|justify">',
            markdown5: '  [Your content here]',
            markdown6: '</div>',
            markdown7: '(Some Markdown syntaxes may not work inside HTML tag when aligning.)',
        },
    },

    shortcutCheatsheet: {
        title: 'Shortcut Cheatsheet',
        shortcut: 'Shortcut',
        description: 'Description',
        row1: {
            description: 'Toggle Blockquote',
        },
        row2: {
            description: 'Toggle Bold',
        },
        row3: {
            description: 'Clean Block',
        },
        row4: {
            description: 'Toggle Heading Smaller',
        },
        row5: {
            description: 'Toggle Italic',
        },
        row6: {
            description: 'Draw Link',
        },
        row7: {
            description: 'Toggle Unordered List',
        },
        row8: {
            description: 'Preview Mode',
        },
        row9: {
            description: 'Save',
        },
        row10: {
            description: 'Toggle CodeBlock',
        },
        row11: {
            description: 'Draw Image',
        },
        row12: {
            description: 'Toggle Ordered List',
        },
        row13: {
            description: 'Toggle Heading Bigger',
        },
        row14: {
            description: 'Toggle Side BySide',
        },
        row15: {
            description: 'Toggle Full Screen',
        },
        row16: {
            description: 'Left Align Content',
        },
        row17: {
            description: 'Right Align Content',
        },
        row18: {
            description: 'Center The Content',
        },
    },

    stats: {
        answers: 'Answers',
        comments: 'Comments',
        clips: 'Bookmarks',
        posts: 'Posts',
        views: 'Views',
        votes: 'Votes',
        suggestion: {
            question: {
                vote: 'Vote: ',
                bookmark: 'Bookmark: ',
                comment: 'Comment: ',
                view: 'View: ',
            },
            challenge: {
                score: 'Score: ',
                completed_times: 'Completion rate: ',
                trophied_count: 'Achievement rate: ',
            },
            course: {
                view: 'View: ',
                user_count: 'Enrolled student: ',
                question_count: 'Total question: ',
                test_count: 'Total test: ',
            },
            puzzle: {
                score: 'Score: ',
                completed_times: 'Solver: ',
            },
        },
    },

    search: {
        title: 'Search',
        results: 'results',
        posts: 'Posts',
        questions: 'Questions',
        authors: 'Authors',
        tags: 'Tags',
        suggestions: {
            tags: {
                followers: 'Followers',
                posts: 'Posts',
            },
            posts: {
                posted: 'posted',
            },
            users: {
                followers: 'Followers',
                posts: 'Posts',
            },
            questions: {
                asked: 'asked',
            },
        },
        searching: 'Searching',
        not_found: 'We couldn\'t find anything',
        sort: {
            label: 'Sort by',
            best_match: 'Best match',
            clipped: 'Most clipped',
            viewed: 'Most viewed',
            rated: 'Most rated',
            commented: 'Most commented',
            newest: 'Newest',
            oldest: 'Oldest',
        },
        guide: {
            label: 'Search syntax',
            title: 'Containing "Git" in title',
            body: 'Containing "Ruby" in body',
            code: 'Containing "function" in code',
            tag: 'Tagged with "Rails"',
            not_tag: 'Not containing tags "Ruby" and "Rails"',
            user: 'Created by user "name"',
            clip: 'Clipped more than 3 times',
            views: 'Viewed more or equal than 10 times',
            rate: 'Post that have a rating greater than or equal 10',
            created: 'Created after 2016-09-30',
            updated: 'Updated in the range between 2016-09-01 and 2016-09-30',
            contain: 'Containing "Ruby" or "Rails"',
            not_contain: 'Must not contain "php"',
            title_or_body: 'Containing phrase "Js performance" in title or body',
            language: 'Filter posts by language (en, vi, ja)',
        },
    },

    notification: {
        label: 'Your Notifications',
        noNew: 'No new notifications',
        settingsUpdated: 'Settings updated',
        options: {
            label: 'Options',
            markAsRead: 'Mark all as read',
            deleteAll: 'Delete all',
            settings: 'Notification settings',
        },
    },

    rss: {
        title: 'Viblo RSS Channels',
        description: 'RSS (Really Simple Syndication) is a type of XML file that helps create information channels (feeds). The principle of RSS is minimalism, shortened content only contains important items such as title, author, summary content, date, ... so users can update information quickly without needing to directly access the site. Users can synthesize information from many different websites through specialized RSS reader.',
        seeAll: 'See all',
        posts: {
            title: 'Posts',
            newest: 'Newest',
            trending: 'Trending',
            editorsChoice: 'Editors\' Choice',
            video: 'Videos',
            mayfest: 'Viblo May Fest',
        },
        questions: {
            title: 'Questions',
            newest: 'Newest',
            unsolved: 'Unsolved',
        },
        topic: 'Topic',
        authors: 'Authors',
        organizations: 'Organizations',
    },

    headSeo: {
        following: 'Following post - Viblo',
        followingDefault: 'Followings',
        newest: 'Newest post - Viblo',
        newestDefault: 'Newest Posts',
        series: 'Post Series',
        announcements: 'Announcements',
        clipPost: 'My Post Bookmarks',
        clipSeries: 'My Series Bookmarks',
        clipQuestion: 'My Question Bookmarks',
        questionsNewest: 'Newest Questions',
        questionsUnsolved: 'Unsolved Questions',
        questionsFollowings: 'Following\'s Questions',
        questionsAsk: 'Ask Question',
        editorsChoice: 'Editor\'s Choice',
        trending: 'Trending Posts',
        discussion: 'Discussions',
        translation: 'Translation Posts',
        homePageTitle: 'Viblo | Free service for technical knowledge sharing',
        defaultDescription: 'Technology Articles Platform from Asia, filled with latest information on Programming Languages and Frameworks. Ruby on Rails / PHP / Swift / Unity / Java /.Net',
        contentCreatorPost: 'Content creator',
    },

    titleEmoji: 'Emoji Suggestion',
    vueTour: {
        createContent: 'Click here to create a new post, question or series',
        seeQuestions: 'Click here to see the latest questions',
        previewMode: 'You can customize the display posts with titles only or display posts with content preview',
        sendFeebback: 'Click here to give us feedback',
        gotoSetting: 'Click here to go to settings',
        skip: 'Skip',
        previous: 'Previous step',
        next: 'Next step',
        finish: 'Finish',
        enterTitle: 'Enter title here',
        enterTag: 'Add tags related to this post here',
        changeImage: 'Click vào đây để chọn ảnh hiển thị mặc định',
        selectlicense: 'Click here to select the appropriate license and publish the post',
        markdownHelp: 'Click here to learn about markdown syntax',
        enterContent: 'Enter content here',
    },

    pageError: {
        error404: {
            title: 'IS ENCOUNTERING SOME PROBLEMS.',
            status: '404 - Not Found.',
        },
        error403: {
            status: '403 - Forbidden.',
            content1: 'Please solve (or give up)',
            content2: 'this puzzle',
            content3: 'in Viblo CTF first to read this post!',
        },
        error503: 'IS CURRENTLY IN THE MAINTENANCE MODE.',
    },

    titleSeries: 'Series with zero posts will only be displayed on users profile page, and will not be listed here.',
    empty: 'There is nothing here.',
    common: {
        alert: {
            warning: 'Warning:',
        },
        actions: {
            remove: 'Remove',
        },
    },

    reviewContents: {
        postsSpam: {
            title: 'Title',
            author: 'Author',
            published: 'Published At',
        },
    },

    popUpSocialShare: {
        content: 'Content:',
        titleDefault: 'Share to Viblo Fanpage',
        rankingScore: 'Ranking score',
        placeholderContent: 'Content of the post',
        placeholderRankScore: 'Ranking score of the post',
        detailRankScore: {
            content1: 'The',
            content2: 'ranking score',
            content3: 'is a number between 0 and 1, which is used to evaluate the priority of the posts shared on the fan page.',
            content4: 'The higher the rating score, the higher the priority.',
        },
        cancel: 'Cancel',
        add: 'Add',
        updateSuccess: 'Update successfully!',
        updateError: 'Something went wrong. Cannot update this post!',
        handleDelete: {
            title: 'Delete',
            content: 'Delete this sharing?',
            success: 'Post has been removed from list!',
            error: 'Something went wrong. Cannot delete this post!',
        },
        shareSuccess: 'Post have been add to Facebook share list!',
        shareError: 'Something went wrong. Can not share this post.',
        shareQuestionSuccess: 'Question has been add to social share list!',
        shareQuestionError: 'Something went wrong. Can not share this post.',
        shareSeriesSuccess: 'Series has been add to social share list!',
        shareSeriesError: 'Something went wrong. Can not share this post.',
        validations: {
            requiredContent: 'Content is required',
            requiredRankScore: 'RankScore is required',
        },
    },

    seeNewPosts: {
        firstContent: 'See',
        lastContent: 'new post(s)',
    },

    confirmInvitation: {
        title: 'Confirm Invitation',
        titleAlert: 'Info:',
        content1: 'You have been invited to the',
        content2: 'organization as',
        content3: 'by',
        accept: 'Accept',
        decline: 'Decline',
    },

    pwa: {
        offline_warning: 'You are offline. Some functionality may be unavailable.',
        new_version_available: 'A new version is available!',
        refresh: 'Refresh',
    },

    authorsRecommend: {
        title: 'AUTHORS RECOMMENDATION',
        content: 'You need follow more authors that we can recommend more for you',
        seeMore: 'See more >>>',
    },

    tagRecommend: {
        title: 'TAGS RECOMMENDATION',
        content: 'You need follow more tags that we can recommend more for you',
    },

    userMenu: {
        edit: 'Edit',
        signOut: 'Sign out',
    },

    pinAnnouncement: {
        success: 'Successfully pinned the announcement',
        error: 'An error occurred, please try again',
        tooltip: 'Pin this Announcement',
        tooltipUnPin: 'Unpin this Announcement',
        unPinSuccess: 'Unpin success announcement',
    },

    confirm: {
        ok: 'OK',
        cancel: 'Cancel',
    },

    deleteVideo: {
        title: 'Delete video',
        content: 'Uploaded video file will be deleted forever. Are you sure?',
    },

    deleteNotification: {
        title: 'Clear notification',
        content: 'Do you want to delete all available notifications?',
    },

    deleteImage: {
        title: 'Are you sure that you want to delete this image?',
        cancel: 'Cancel',
        confirm: 'Confirm',
    },

    copyURL: 'Copy URL',

    questions: {
        delete: {
            title: 'Delete question',
            content: 'Deleted questions are gone forever. Are you sure?',
        },
        unBookmark: {
            title: 'Remove question',
            content: 'Remove this questions?',
        },
    },

    moreArticles: 'More articles',
    copied: 'Copied ✔️',
    copy: 'Copy',
    showMore: 'Show more',

    validateUploadImage: {
        invalidType: 'Invalid file type',
        maxFilesize: 'File is too large for upload ({imageSize}MB). Maximum allowed file size is {maxFilesize}MB.',
        offline: 'You are offline, cannot upload image.',
    },

    popupEvent: {
        the: 'The',
        takingPlace: 'event is taking place',
        untilend: 'until the end of',
        enterForAChance: ' Enter for a chance to win',
        addTag: ' by adding the',
        intoPost: 'tag into your post.',
        learnMore: 'Learn more.',
        reward: 'rewards',
    },

    canNotTag: 'Sorry, {eventName} is not opening. You can not tag this post into "{eventName}" now.',
    postPublicNotInEvent: 'Sorry, this post has been published outside the period of {eventName} event. Therefore, it cannot be tagged with "{eventName}" tag',
    canNotTagContentCreator: 'Only accounts that are Content Creator can add this tag.',
    canNotTagContentCreatorIfNotPost: 'Only Post can be added this tag.',
    seriesProposal: {
        title: 'Be careful! This post can only be added to one series only!',
        actions: 'Actions',
        block: 'Block',
        approve: 'Approve',
        reject: 'Reject',
        user: 'User',
        wantToAdd: 'want to add this post to his/her series',
        success: 'Action successfully!',
    },

    lastEdited: 'Last edited',

    tags: {
        sort: {
            title: 'Sort by',
            newestPosts: 'Newest posts',
            mostCliped: 'Most bookmarked',
            mostViewed: 'Most viewed',
            mostVoted: 'Most voted',
        },
    },
    Answer: 'Answer',
    Series: 'Series',
    Question: 'Question',
    Post: 'Post',
    'Poor Translation': 'Poor Translation',
    'Infringes copyright': 'Infringes copyright',
    'Harassment content': 'Harassment content',
    'TOS Violation': 'TOS Violation',
    'Spam content': 'Spam content',
    'Viblo MayFest 2020 Badge': 'Badge for succeeded users in Viblo MayFest 2020 event.',
    'Viblo MayFest 2021 Badge': 'Badge for succeeded users in Viblo MayFest 2021 event.',
    'Viblo MayFest 2022 Badge': 'Badge for succeeded users in Viblo MayFest 2022 event.',
    'Viblo MayFest 2023 Badge': 'Badge for succeeded users in Viblo MayFest 2023 event.',
    'Viblo MayFest 2024 Badge': 'Badge for succeeded users in Viblo MayFest 2024 event.',

    contentCreator: {
        rank: {
            grade: 'Grade: ',
            title: 'All',
            diamondGrade: 'Diamond grade',
            goldGrade: 'Gold grade',
            silverGrade: 'Silver grade',
        },
    },

    uploadAvt: {
        tip: 'Tips: Use scroll wheel to zoom, drag to move profile picture.',
        title: 'Change Profile Picture',
        save: 'Save',
    },

    wrappedUp: {
        toolTip: 'Please wait a few minutes to look back at your achievements last year!',
        lookBack: "Let's look back at your achievements last year",
        aYear: 'a year with Viblo',
        withViblo: "Let's look back on the past year with Viblo",
        error: 'An error occurred, please try again!',
        chooseImage: 'Select images to download',
        saveImage: 'Download',
        all: 'All',
        loading: 'loading ...',
        downloadMessage: 'Can\'t download images',
    },

    writeUps: {
        collectionWriteupsDesc: 'A collection of write-ups from the best hackers for',
        congratulationsWriteUpsDesc: 'Congratulations! You have solved this puzzle. Let become the first hacker',
        submitAWriteUps: 'submit a write-up',
        to: 'to',
    },

    settings: {
        newsletter: {
            textUpdateEmailSubscription: 'Set up your email subscription',
            textSubscribeEmail: 'In order to create consistent notification experience on Viblo, we’ve turned off email subscriptions for these two items. You can turn on below to receive new updates on',
            textNewsletter: 'Weekly newsletter',
            textUnreadNotifications: 'Unread notifications',
            accessLink: 'Adjust notification settings:',
            textAdjustSettings: 'Click here for further notification settings',
            messageSuccess: 'Update settings successfully!',
        },
        error: 'Something went wrong. Please try again later.',
    },

    socialSharePopUp: {
        title: 'Description for the social share of your post',
        label: 'Contents',
        validations: {
            contentMin: 'The description must be at least 10 characters',
            contentMax: 'The description can\'t be more than 10000 characters',
        },
        save: 'Save',
        skip: 'Skip',
        description: 'Viblo Team will use this content when your post is selected to share on Viblo\'s social media pages (Facebook, TikTok, Threads,...).',
        noRequire: 'Providing this content is optional.',
    },
    allPosts: 'All posts',
    somePicturesAboutUs: 'Some pictures about',
    allVideos: 'All videos',
    postsBookmarked: 'The post has been bookmarked',
    subdomain: {
        PostPin: 'Pinned Posts',
        videoPin: 'Pinned Video',
        growthDate: 'From {from} to {to}',
        AllMembers: 'Members list',
        AllFollowers: 'Followers list',
        PinnedPosts: 'Select pinned posts',
        alertPostPin: 'Note: Please select 3 or 6 posts to save the list of pinned posts',
    },
    pinPostOrg: {
        confirmContent: 'Are you sure to pin this video?',
        confirmContentUnPin: 'Are you sure to unpin this video?',
        confirmPostPin: 'Are you sure to pin this post?',
        confirmPostUnPin: 'Are you sure to unpin this post?',
        contentPin: 'Pin post',
        contentUnPin: 'Un pin post',
        pinSuccess: 'Post has been pinned!',
        unPinSuccess: 'Post has been un pinned!',
        pinVideoSuccess: 'Video has been pinned!',
        unPinVideoSuccess: 'Video has been un pinned!',
        titlePinPost: 'Select post pin',
        reset: 'Reset',
        confirmPinPost: {
            title: 'Confirm',
            content: 'Are you sure to pin these posts?',
        },
    },
    navbarOrg: {
        topMembers: 'Featured member',
        seeAll: 'See all >',
    },
    uploadImage: 'Add activity photos',
    descDarkMode: 'When you switch between light mode and dark mode, subsequent pages will automatically apply the chosen mode until you make another change. You can customize the mode on the subdomain page or adjust the background color on detail post pages.',
    PopupDownloadApp:
    {
        title: 'Download Mobile App',
        content: 'Looking for an easier way to explore Viblo?',
        download: 'Download Now',
    },
}
