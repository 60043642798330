import { parse } from 'node-html-parser'

export default class ContentPipeline {
    constructor(textContent, markdownRenderer) {
        this.textContent = textContent
        this.markdownRenderer = markdownRenderer
        this.htmlFilters = []
    }

    appendHtmlFilter(filter) {
        this.htmlFilters.push(filter)
    }

    process() {
        const markdownRenderResult = this.markdownRenderer.render(this.textContent)

        // Parse html-rendered markdown to DOM tree with node-html-parser
        const domTree = parse(markdownRenderResult)

        // Apply filters to DOM tree
        this.htmlFilters.forEach(filter => filter.call(domTree, { parse }))

        return domTree
    }
}
