// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _assign from 'lodash/assign'
import _partial from 'lodash/partial'
import _includes from 'lodash/includes'
import * as Echo from 'viblo-sdk/echo'
import echoConnection from '~/lib/echoConnection'

const pushNewNotification = ({ state, commit }, event) => {
    const newNotification = _assign({}, event.notification, {
        id: event.id,
        is_read: false,
        created_at: new Date(),
    })

    commit('notifications/setUnreadCount', state.notifications.unread + 1)
    commit('notifications/shift', newNotification)
}

const clearNotifications = ({ state, commit }, event) => {
    const unread = state.notifications.unread - event.ids.length

    commit('notifications/setUnreadCount', unread < 0 ? 0 : unread)

    if (event.deleted) {
        const current = state.notifications.data
        const withoutCleared = current.filter(item => !_includes(event.ids, item.id))
        commit('notifications/set', withoutCleared)
    }
}

export default ({ store }) => {
    const authUser = store.state.auth.user
    if (!authUser) {
        return
    }

    const privateChannel = Echo.joinPrivateChannel(authUser.id, echoConnection)

    const pushNewNotificationToStore = _partial(pushNewNotification, store)
    const clearNotificationsInStore = _partial(clearNotifications, store)

    privateChannel.onNewNotification(pushNewNotificationToStore)
    privateChannel.onNotificationCleared(clearNotificationsInStore)
}
