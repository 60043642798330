// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { getTechnologyStacks, updateTechnology } from '~/api/organizations'

const state = () => ({
    technologies: [],
})

const actions = {
    fetch: ({ commit }, slug) => getTechnologyStacks(slug).then(({ data }) => commit('setTechnologies', data.data)),
    update: ({ dispatch }, { slug, technologies }) => updateTechnology(slug, { technologies }).then(() => dispatch('fetch', slug)),
}

const mutations = {
    setTechnologies(state, technologies) {
        state.technologies = technologies
    },
}

export default {
    state,
    actions,
    mutations,
}
