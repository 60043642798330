// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _pick from 'lodash/pick'
import _merge from 'lodash/merge'
import _assign from 'lodash/assign'
import _mapValues from 'lodash/mapValues'

import { subscribe } from 'viblo-sdk/api/me'
import { SubscribableType } from 'viblo-sdk/libs/interactions'
import { getters } from '~/components/entities/common'
import { markTechnical } from '~/api/tags'

const attributes = [
    'slug', 'image', 'name', 'url', 'posts_count', 'primary', 'technology',
    'questions_count', 'followers_count', 'following', 'system',
]

const pickValues = tags => _mapValues(tags, tag => _pick(tag, attributes))

const state = () => ({
    all: {},
})

const actions = {
    async subscribe({ commit }, { tag, value }) {
        await subscribe(SubscribableType.Tag, tag.slug, value)

        commit('put', {
            [tag.slug]: {
                following: value,
                followers_count: tag.followers_count + (value ? 1 : -1),
            },
        })

        commit('auth/setFollowingTechnicalsCount', value ? 1 : -1, { root: true })
    },

    async markTechnical({ commit }, { tag, value }) {
        await markTechnical(tag, { technical: value })

        commit('put', {
            [tag.slug]: {
                technology: value,
            },
        })
    },
}

const mutations = {
    put(state, users) {
        const data = pickValues(users)
        const updatedUsers = _mapValues(data, (user, slug) => state.all[slug])
        const slice = _merge({}, updatedUsers, data)
        // Set next state:
        state.all = _assign({}, state.all, slice)
    },
}

export {
    state, getters, actions, mutations,
}
