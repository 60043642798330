// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="tabs">
        <ul class="tabs__list" role="tablist">
            <li
                v-for="(pane, index) in panes"
                :key="index"
                class="tabs__item"
                @click.prevent="setActivePane(pane.name)"
            >
                <div :class="{ active: activePane === pane.name }" class="tabs__link">
                    {{ pane.label }}
                </div>
            </li>
        </ul>

        <div class="tabs__content">
            <slot />
        </div>
    </div>
</template>

<script>
    import _flow from 'lodash/flow'
    import _filter from 'lodash/fp/filter'
    import _indexOf from 'lodash/fp/indexOf'

    export default {
        props: {
            initialActivePane: String,
        },

        data() {
            return {
                panes: [],
                activePane: this.initialActivePane,
            }
        },

        methods: {
            setActivePane(name) {
                this.activePane = name
                this.$emit('active-pane', name)
            },

            addPane(pane) {
                const index = this.getPaneIndex(pane)

                this.panes = [
                    ...this.panes.slice(0, index),
                    pane,
                    ...this.panes.slice(index + 1),
                ]
            },

            removePane(pane) {
                const index = this.getPaneIndex(pane)

                this.panes = [...this.panes.slice(0, index), ...this.panes.slice(index + 1)]
            },

            getPaneIndex(pane) {
                return _flow(
                    _filter(child => child.$options.componentName === 'TabPane'),
                    _indexOf(pane)
                )(this.$children)
            },
        },
    }
</script>

<style lang="scss">
    @import "../../assets/sass/bootstrap/colors";

    .tabs {
        &__list {
            display: flex;
            align-items: center;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
            border-bottom: 1px solid $gray-lighter;
        }

        &__item {
            margin-bottom: 0;
            text-align: center;
        }

        &__link {
            border-radius: 0;
            cursor: pointer;
            position: relative;
            color: $gray-light;
            padding: .5rem 1rem;

            &:after {
                left: 0;
                width: 0;
                content: '';
                height: 2px;
                bottom: -1px;
                position: absolute;
                transition: width .3s ease;
                background-color: $primary;
            }

            &.active {
                color: $gray-dark;

                &:after {
                    width: 100%;
                }
            }

            &:not(.active):hover:after {
                width: 100%;
                background-color: $gray-light;
            }
        }

        &__content {
            padding: .6rem 0;
        }
    }
</style>
