// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
/**
 * @param {Element} el
 * @param {Element} relativeTo
 */
export const calculateElementOffset = (el, relativeTo) => {
    const rect = el.getBoundingClientRect()
    const relativeElRect = relativeTo.getBoundingClientRect()

    return {
        top: rect.top - relativeElRect.top,
        left: rect.left - relativeElRect.left,
    }
}

/**
 * @param {Element} el
 */
export const isVisibleInViewport = (el) => {
    const rect = el.getBoundingClientRect()

    return rect.top >= 0
        && rect.left >= 0
        && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
}
