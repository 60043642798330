// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
/* eslint-disable no-console */
<template>
    <div class="container">
        <div class="panel">
            <div class="panel-heading">
                <nuxt-link class="logo" to="/">
                    {{ appName }}
                </nuxt-link>
                {{ subTitle }}
            </div>
            <div class="panel-body">
                <div class="title">
                    {{ title }}
                </div>
            </div>
            <div v-if="isForbiddenReadWriteup" class="panel-footering">
                <div class="title-down">
                    {{ $t('pageError.error403.content1') }}
                    <a :href="linkPuzzleCTF" target="_blank"> {{ $t('pageError.error403.content2') }} </a>
                    {{ $t('pageError.error403.content3') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _get from 'lodash/get'
    import _has from 'lodash/has'
    import { toPuzzle } from '~/utils/url'

    const component = {
        name: 'error',
        props: ['error'],

        computed: {
            appName() {
                return 'Viblo'
            },

            title() {
                if (this.error.customMessage) {
                    return `${this.error.statusCode} - ${this.error.customMessage}`
                }

                switch (this.error.statusCode) {
                    case 403: return this.$t('pageError.error403.status')
                    // case 500: return '500 - Internal Server Error.'
                    // case 503: return '503 - Be Right Back.'
                    // default: return 'An error occured'
                    default: return this.$t('pageError.error404.status')
                }
            },

            subTitle() {
                return this.error.statusCode === 503
                    ? this.$t('pageError.error503')
                    : this.$t('pageError.error404.title')
            },

            isForbiddenReadWriteup() {
                if (_has(this.error.response, 'data.puzzle_hash_id')) {
                    return true
                }

                return false
            },

            linkPuzzleCTF() {
                const puzzleHashId = _get(this.error.response, 'data.puzzle_hash_id')

                return toPuzzle(puzzleHashId)
            },

            exceptionName() {
                const { response, name } = this.error

                if (!response) {
                    return `JavaScript::${name || 'Error'}`
                }

                const nuxtError = _get(response, 'data.name')
                if (nuxtError) {
                    return `SPA::${nuxtError}`
                }

                const apiError = _get(response, 'data.error.type')

                return `API::${apiError || 'Exception'}`
            },
        },

        mounted() {
            if (this.error.statusCode === 500) {
                this.$gtag.event('server_error', {
                    eventCategory: 'Server Error',
                    eventLabel: this.$route.fullPath,
                    nonInteraction: true,
                })
            }
        },
    }

    if (process.env.APP_ENV !== 'production') {
        Object.assign(component, {
            created() {
                // eslint-disable-next-line no-console
                console.error(this.error)
            },

            watch: {
                error(newErr) {
                    if (newErr) {
                        // eslint-disable-next-line no-console
                        console.error(newErr)
                    }
                },
            },
        })
    }

    export default component
</script>

<style scoped>
    body, html {
        margin: 0;
        padding: 0;
        font-family: 'Roboto', sans-serif;
    }

    .container {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .title {
        color: #5488c7;
        font-size: 2.5rem;
        text-align: center;
    }

    .panel-heading {
        font-size: 1rem;
        text-align: center;
    }

    .logo {
        color: #5488c7;
        text-decoration: none;
        text-transform: uppercase;
    }

    .panel {
        border-color: #DDD;
        margin-bottom: 20px;
        background-color: #FFF;
    }

    .panel-heading {
        color: #333;
        padding: 10px 15px;
        border-bottom: 1px dashed #DDD;
    }

    .panel-footering{
        color: #333;
        padding: 10px 15px;
    }

    .panel-body {
        padding: 15px;
    }
</style>
