// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <Flyout
        ref="flyout"
        :badge="unreadCounter"
        icon="fa fa-bell-o"
        class="notifications-flyout"
        @show="onShow"
    >
        <div slot="header" class="d-flex align-items-center justify-content-between">
            <span>{{ $t('mainNavbar.notifications.notifications') }}</span>
            <nuxt-link
                v-tooltip
                to="/settings/notifications"
                :title="$t('mainNavbar.notifications.NotificationSettings')"
                class="link"
                @click.native="hide()"
            >
                <i class="el-icon-setting" aria-hidden="true" />
            </nuxt-link>
        </div>

        <NotificationPlaceholder v-if="loadNoti" />

        <div v-else>
            <NotificationsList
                :open-time="openTime"
                :notifications="notifications"
                class="notifications-flyout__list"
                @item-click="onItemClick($event)"
            />

            <client-only>
                <InfiniteLoading
                    ref="loader"
                    :distance="50"
                    spinner="spiral"
                    @infinite="onInfinite"
                >
                    <span slot="no-uploadResults" />
                    <span slot="no-more" />
                    <span slot="no-results">{{ $t('empty') }}</span>
                </InfiniteLoading>
            </client-only>
        </div>

        <div slot="footer" class="notifications-flyout__all">
            <nuxt-link to="/notifications" @click.native="hide()">
                {{ $t('mainNavbar.notifications.seeAll') }}
            </nuxt-link>
        </div>
    </Flyout>
</template>

<script>
    import { mapState } from 'vuex'
    import InfiniteLoading from 'vue-infinite-loading'
    import Flyout from '~/components/shared/Flyout.vue'
    import ContainerMixin from '~/components/notifications/Container'
    import NotificationsList from '~/components/notifications/List.vue'
    import NotificationPlaceholder from '~/containers/notifications/index.vue'

    export default {
        components: {
            Flyout,
            InfiniteLoading,
            NotificationsList,
            NotificationPlaceholder,
        },

        mixins: [ContainerMixin],

        data() {
            return {
                openTime: new Date().getTime(),
                loadNoti: true,
            }
        },

        computed: {
            ...mapState('i18n', ['locale']),
        },

        watch: {
            locale() {
                this.loadNoti = true
                this.fetchNotifications().then(() => {
                    this.loadNoti = false
                    if (!this.hasMore() && this.$refs.loader) {
                        this.$refs.loader.stateChanger.complete()
                    }
                })
            },
        },

        mounted() {
            this.fetchNotifications().then(() => {
                this.loadNoti = false
                if (!this.hasMore() && this.$refs.loader) {
                    this.$refs.loader.stateChanger.complete()
                }
            })
        },

        methods: {
            onInfinite($state) {
                this.fetchMore().then(() => {
                    if (this.hasMore()) {
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                })
            },

            onItemClick(id) {
                this.setRead(id)
                this.hide()
            },

            hide() {
                this.$refs.flyout.close()
            },

            onShow() {
                this.clear()
                this.openTime = new Date().getTime()
            },
        },
    }
</script>

<style lang="scss">
    .notifications-flyout {
        &__all {
            text-align: center;
            font-size: .9rem;
        }

        &__list {
            width: 400px;
            max-width: 100%; // Can never be larger than popper itself
        }
    }
</style>
