// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _pick from 'lodash/pick'
import _curry from 'lodash/curry'
import _get from 'lodash/get'
import _last from 'lodash/last'
import _keys from 'lodash/keys'
import _find from 'lodash/find'

import { cnameSubdomains } from '~/constants/subdomain'

export const doNothing = () => {}

/**
 * @param {Error} error
 * @param {Function} showErrorPage
 */
export const handlePageError = _curry((showErrorPage, error) => {
    if (error.response) {
        // `response` has circular reference at `request`. Must exclude it.
        const response = _pick(error.response, ['status', 'statusText', 'data'])

        showErrorPage({
            response,
            statusCode: response.status,
        })
    } else {
        throw error
    }
})

export const displayError = _curry((showError, error) => {
    if (error.response) {
        const statusText = _get(error, 'response.statusText')
        const message = _get(error, 'response.data.message')
        const errors = _get(error, 'response.data.errors')
        const flatErrors = errors ? _last(errors[_last(_keys(errors))]) : null

        showError(flatErrors || message || statusText)
    } else {
        showError('Something went wrong!')
    }
})

/**
 * @param {string} param
 */
export function getContentRouteParams(param, routeKeyName = 'hashId') {
    const matches = param.match(/^([^/]+(?=-))?-?([^/-]+)$/)

    return matches
        ? { slug: matches[1], [routeKeyName]: matches[2] }
        : {}
}

/**
 * @param {string} routeHash
 */
export function getHighlightFromRouteHash(routeHash) {
    const regexp = /^#(answer|comment)-([a-zA-Z0-9]{11})$/

    return regexp.test(routeHash)
        ? routeHash.replace(regexp, '[data-$1id=$2]')
        : null
}

/**
 * @param {string} appUrl
 */
export function getHost(appUrl) {
    return process.env.APP_ENV === 'local' ? appUrl.replace('http://', '') : appUrl.replace('https://', '')
}

/**
 * @param {string} host
 */
export function getContentSubDomain(host) {
    return host?.split('.')[0]
}

/**
 * @param {string} cookies
 * @param {string} name
 */
export function getCookieData(cookies, name) {
    const cookie = {}
    if (!cookies || typeof cookies === 'undefined') {
        return null
    }

    cookies.split(';').forEach((el) => {
        const [k, v] = el.split('=')
        cookie[k.trim()] = v
    })

    return cookie[name]
}


export function getSlugSubdomain(hostname) {
    const result = _find(cnameSubdomains, { host: hostname })
    return typeof result !== 'undefined' && result.slug ? result.slug : hostname.split('.')[0]
}
