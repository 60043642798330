// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <nuxt-link :to="routeToPost(item)" class="link d-block">
        <div>
            <h6 class="font-weight-bold mb-0 suggestion-identifier">
                <!-- eslint-disable -->
                <div
                    v-if="hasHighlight(item.highlights, 'title')"
                    v-html="item.highlights.title[0]"
                />
                <!-- eslint-enable -->
                <div v-else>
                    {{ item.title }}
                </div>
            </h6>

            <div>
                <span class="text-primary">{{ item.user.data.name }} </span>
                <span class="text-muted">
                    {{ $t('search.suggestions.posts.posted') }}
                    {{ item.published_at | formatTime }}
                </span>
            </div>

            <div class="text-dark">
                <!-- eslint-disable -->
                <div
                    v-if="hasHighlight(item.highlights, 'contents')"
                    v-html="`...${item.highlights.contents[0]}...`"
                />
                <!-- eslint-enable -->
                <div v-else>
                    {{ previewContents(item.teaser) }}
                </div>
            </div>
        </div>
    </nuxt-link>
</template>

<script>
    import isEmpty from 'lodash/isEmpty'
    import { routeToPost } from '~/lib/functions'

    export default {
        props: {
            item: Object,
        },

        methods: {
            routeToPost,
            hasHighlight: (obj, type) => obj && !isEmpty(obj[type]),
            previewContents: contents => `${contents.substr(0, 120)}...`,
        },
    }
</script>
