// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _pick from 'lodash/pick'

const getters = {
    get: state => id => state.all[id],
    byId: state => ids => _pick(state.all, ids),
    mapId: state => ids => ids.map(id => state.all[id]).filter(item => item),
}

export { getters }
