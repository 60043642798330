// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <nuxt-link
        :to="url"
        :class="{
            [className]: className,
            'el-tag--info': !primary && !className,
            'primary-tag': primary,
            [sizeTag]: true,
        }"
        class="el-tag tag"
    >
        <template v-if="name">
            {{ name }}
        </template>
        <slot v-else />
    </nuxt-link>
</template>

<script>
    export default {
        props: {
            name: String,
            url: String,
            primary: Boolean,
            size: {
                type: String,
                default: 'default',
            },
            className: {
                type: String,
                default: '',
            },
        },

        computed: {
            sizeTag() {
                return `el-tag--${this.size}`
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "~assets/sass/bootstrap/colors";

    .tag {
        margin-right: .5rem;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
            text-decoration: none;
            background-color: #eaeaea;
            color: #909399;
        }
    }

    .primary-tag {
        background-color: $primary;
        border-color: $primary;
        color: white;

        &:hover {
            color: white;
            background-color: #396eaf;
            border-color: #396eaf;
        }
    }

    .mayfest2022-tag {
        color: white;
        background-color: hsl(248.32, 41.22%, 48.04%);
        border-color: hsl(248.32, 41.22%, 48.04%);

        &:hover {
            color: white;
            background-color: hsl(248.32, 41.22%, 58.04%);
        }
    }

    .mayfest2024-tag {
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 4px;
        color: #fff;
        background: linear-gradient(90deg, #B335EF, #2DC1F0);
        font-family: 'Origin Tech Demo';
        font-weight: 400;
        font-size: 16px;

        &:hover {
            color: #fff;
        }
    }

    .new-year-event-tag {
        color: #fdd746;
        background-color: #f8221b;
        background: linear-gradient(20deg, #980326, #ee5f1c);
        border-color: #f8221b;

        &:hover {
            color: #fdd746;
            background-color: #f8221b;
            background: linear-gradient(20deg, #840a27, #d5581d);
        }
    }

    .new-year-writing-tag {
        color: #fd4e73;
        background-color: #fea3b8;
        background: linear-gradient(20deg, #fea3b8, #fff4e8);

        &:hover {
            color: #e9607e;
            background: linear-gradient(20deg, #fea3b8, #fff4e8);
        }
    }

    .reconnection-tag {
        color: #fff;
        background-color: #354691;
        background: linear-gradient(20deg, #C165DD, #5C27FE);

        &:hover {
            color: #fff;
            background: linear-gradient(20deg, #7118a3, #20085d);
        }
    }

    .contentcreator-tag {
            background: linear-gradient(to right, #38A2D7, #561139);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-size: 200% auto;
            animation: textShine 2s linear infinite;
    }
    .javashow2022-tag {
        color: white;
        font-weight: bold;
        background: rgb(37,24,164);
        background: linear-gradient(148deg, rgba(35,19,151,1) 20%, rgba(51,26,169,1) 30%, rgba(168,55,199,1) 100%);
    }
</style>
