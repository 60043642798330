// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template functional>
    <div :class="{ 'shadow-sm': props.shadow }" class="card">
        <div v-if="slots().image" class="card-img-top">
            <slot name="image" />
        </div>
        <div class="card-body">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        functional: true,

        props: {
            shadow: Boolean,
        },
    }
</script>
