// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { normalize } from 'normalizr'
import { userArray } from '~/utils/store/schemas'
import { getTopMembers } from '~/api/organizations'
import { PUT_USERS_TO_STATE } from '../entities/users'

const state = () => ({
    topMembers: [],
})

const actions = {
    fetch: async ({ commit }, slug) => {
        const members = await getTopMembers(slug).then(response => response.data)
        const normalizedData = normalize(members.data, userArray)
        const userEntities = normalizedData.entities.users

        commit('topMembers', members.data)
        commit(PUT_USERS_TO_STATE, userEntities, { root: true })
    },

    resetData: ({ commit }) => {
        commit('resetData')
    },
}

const mutations = {
    topMembers(state, topMembers) {
        state.topMembers = topMembers
    },

    resetData(state) {
        state.topMembers = []
    },
}

export default {
    state,
    actions,
    mutations,
}
