// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import axios from 'viblo-sdk/libs/axios'

export const getPost = hashId => axios.get(`/p/${hashId}`)
export const getSeries = hashId => axios.get(`/p/${hashId}/series`)
export const getRelated = (hashId, params) => axios.get(`/posts/${hashId}/related`, { params })
export const getRelatedOrganizations = (hashId, params) => axios.get(`/posts/${hashId}/related-organizations`, { params })

export const fetchPostsFeed = (feed, params) => axios.get(`/${feed}`, { params })

export const report = (post, data) => axios.post(`/posts/${post}/report`, data)
export const markAsReview = hashId => axios.put(`/posts/${hashId}/markAsReview`)
export const reportNotSpam = hashId => axios.post(`/posts/${hashId}/mark-not-spam`)
export const reportNotReady = hashId => axios.post(`/posts/${hashId}/mark-not-ready`)

export const getAllProposalNews = hashId => axios.get(`/posts/${hashId}/proposals`)
export const convertToQuestion = hashId => axios.post(`/admin/posts/${hashId}/convert`)

export const getPostsMarkSpam = params => axios.get('/posts/mark-spam-as-by-sytem', { params })

export const getPostInOrgByLevel = params => axios.get('/content-creator', { params })
export const getNewPost = params => axios.get('/posts/editors-choice', { params })
