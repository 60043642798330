// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _memoize from 'lodash/memoize'

/**
 * @param {Element} el
 * @param {Element} relativeTo
 */
export function calculateElementOffset(el, relativeTo) {
    const rect = el.getBoundingClientRect()
    const relativeElRect = relativeTo.getBoundingClientRect()

    return {
        top: rect.top - relativeElRect.top,
        left: rect.left - relativeElRect.left,
    }
}

/**
 * @param {Element} el
 */
export function isVisibleInViewport(el) {
    const rect = el.getBoundingClientRect()

    return rect.top >= 0
        && rect.left >= 0
        && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
}

export const getScrollbarWidth = _memoize(() => {
    const scrollDiv = document.createElement('div')
    scrollDiv.style.visibility = 'hidden'
    scrollDiv.style.overflow = 'scroll'

    document.body.appendChild(scrollDiv)
    const scrollbarWidth = scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth
    document.body.removeChild(scrollDiv)

    return scrollbarWidth
})
