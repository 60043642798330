// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import axios from 'viblo-sdk/libs/axios'

export const getAllAnnouncements = params => axios.get('/announcements', { params })
export const getAnnouncementsPin = params => axios.get('/announcements-pin', { params })
export const getAnnouncementPost = slug => axios.get(`/announcements/${slug}`)
export const loadAnnouncements = params => axios.get('/announcements', { params })
export const getListHelps = params => axios.get('/helps', { params })
export const getHelpPost = slug => axios.get(slug ? `/help/${slug}` : '/help')
export const getFAQArticle = () => axios.get('/faq')
export const markAllAsRead = () => axios.delete('/me/announcements')
export const getAttachments = params => axios.get('/me/attachments', { params })

export const highlightCodeBlocks = (type, hashId) => axios.put(`/${type}/${hashId}/highlighting-code`)
export const convertToQuestion = slug => axios.post(`/admin/${slug}/convert`).then()

export const pinAnnouncement = slug => axios.put(`/pin-announcement/${slug}`)
export const unPinAnnouncement = slug => axios.put(`/un-pin-announcement/${slug}`)
export const getTermsPost = locale => axios.get(`/terms/${locale}`)
