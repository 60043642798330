export const organizationPath = [
    '/',
    '/videos',
    '/submissions',
    '/members',
    '/followers',
    '/info',
    '/info/edit',
    '/stats',
    '/stats/',
    '/stats/contribution',
    '/invitations',
    '/p/',
]
