// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _each from 'lodash/each'
import _flow from 'lodash/fp/flow'
import _mapValues from 'lodash/fp/mapValues'
import _assign from 'lodash/fp/assign'
import axios from 'viblo-sdk/libs/axios'
import { getServiceSettings } from 'viblo-sdk/api/me'

const state = () => ({
    perPage: 20,
    layout: 'simple',
    theme: 'default',
    homepage: 'default',
    complete_survey: false,
    complete_tour: '',
    viewed_popup_newsletter: true,
})

const settingsMap = {
    preferred_post_layout: 'layout',
    post_per_page: 'perPage',
    highlight_theme: 'theme',
    homepage: 'homepage',
}

const actions = {
    setLayout({ commit }, value) {
        commit('setLayout', value)
        return axios.put('/sessionSettings', {
            setting: 'preferred_post_layout',
            value,
        }, {
            baseURL: '/',
        })
    },

    setValue({ commit }, { name, value }) {
        const setting = settingsMap[name]
        return axios.put('/sessionSettings', { setting, value }, { baseURL: '/' }).then(() => {
            commit('setValue', { setting, value })
        })
    },

    updateServiceSettings({ commit }, { sessionSettings, userSettings }) {
        const settings = _flow(
            _mapValues(setting => setting.status),
            _assign(sessionSettings)
        )(userSettings)

        commit('setAll', settings)
    },

    async getSettingUser({ commit }) {
        try {
            const { data: settings } = await getServiceSettings()
            commit('setValueSetting', { settings })
        } catch (e) {
            commit('setValueSetting', {
                settings: {
                    complete_survey: true,
                    complete_tour: 'all',
                    viewed_popup_newsletter: true,
                },
            })
        }
    },
}

const mutations = {
    setAll(state, settings) {
        _each(settings, (value, name) => {
            name = settingsMap[name] || name
            if (value && state[name]) {
                state[name] = value
            }
        })
    },

    setLayout(state, value) {
        state.layout = value
    },

    setValue(state, { setting, value }) {
        state[setting] = value
    },

    reset(state) {
        state.perPage = 20
        state.layout = 'simple'
        state.theme = 'default'
        state.homepage = 'default'
    },

    setValueSetting(state, value) {
        state.complete_survey = value.settings.complete_survey
        state.complete_tour = value.settings.complete_tour
        state.viewed_popup_newsletter = value.settings.viewed_popup_newsletter
    },

    setValueTour(state, value) {
        state.complete_tour = value
    },

    setValueSurvey(state) {
        state.complete_survey = true
    },
}

export default {
    state,
    actions,
    mutations,
}
