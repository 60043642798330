// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _map from 'lodash/map'
import _pick from 'lodash/pick'
import _merge from 'lodash/merge'
import _flow from 'lodash/fp/flow'
import _filter from 'lodash/filter'
import _assign from 'lodash/assign'
import _isEmpty from 'lodash/isEmpty'
import { getQuestion, acceptAnswer } from 'viblo-sdk/api/questions'
import { normalizeQuestion, normalizeAnswersList } from '~/components/entities/normalize'
import { report } from '../api/questions'
import { getSubscribers } from '../api/placements'
import { USER_ENTITIES, PUT_USERS_TO_STATE } from './entities/users'
import { QUESTION_ENTITIES, PUT_QUESTIONS_TO_STATE } from './entities/questions'
import { updateTagsQuestion } from '~/api/collaborator'

export const ACTION_FETCH_QUESTION = 'questionView/fetchQuestion'

const state = () => ({
    question: null,
    answers: {
        byId: {},
        all: [],
    },
    subscribers: [],
    invalidated: true,
})

const getters = {
    current: (state, getters, rootState, rootGetters) => {
        if (!state.question) return null

        const question = rootGetters[`${QUESTION_ENTITIES}/get`](state.question.id)
        const user = rootGetters[`${USER_ENTITIES}/get`](question.user_id)
        const authUserId = rootGetters['auth/userId']

        const questionView = state.question

        return _assign({}, question, questionView, {
            is_own: question.user_id === authUserId,
            user: _assign({}, { data: user }),
        })
    },

    answers: (state, getters, rootState, rootGetters) => {
        if (!state.question) return null

        const all = state.answers.all
        const byId = state.answers.byId
        const answers = _map(all, id => byId[id])
        const authUserId = rootGetters['auth/userId']

        return _map(answers, a => _assign({}, a, {
            is_own: a.user_id === authUserId,
            accepted: getters.current.accepted_answer_id === a.id,
        }))
    },

    canAcceptAnswer: (state, getters) => {
        const current = getters.current

        return current && current.is_own
    },
}

const updateAnswersCount = (commit, current, change) => {
    commit(PUT_QUESTIONS_TO_STATE, {
        [current.id]: {
            answers_count: current.answers_count + change,
        },
    }, { root: true })
}

const actions = {
    async fetchQuestion({ commit }, hashId) {
        const response = await getQuestion(hashId)

        const question = response.question.data
        const answers = response.answers.data

        // question
        const normalizedQuestion = normalizeQuestion(question)
        const questionId = normalizedQuestion.result
        const questionEntities = normalizedQuestion.entities.questions
        commit(PUT_QUESTIONS_TO_STATE, questionEntities, { root: true })
        commit('setQuestion', questionEntities[questionId])

        // answers
        const normalizedAnswers = normalizeAnswersList(answers)
        commit('answers/set', {
            all: normalizedAnswers.result,
            byId: normalizedAnswers.entities.answers,
        })

        // all comments
        const allComments = _assign({}, normalizedQuestion.entities.comments, normalizedAnswers.entities.comments)
        commit('comments/set', allComments)

        // all users
        const allUsers = _merge({}, normalizedQuestion.entities.users, normalizedAnswers.entities.users)
        commit(PUT_USERS_TO_STATE, allUsers, { root: true })

        return question
    },

    addAnswer({ dispatch, commit, getters }, { values }) {
        const current = getters.current

        return dispatch('answers/store', { question: current.hash_id, values })
            .then((result) => {
                updateAnswersCount(commit, current, 1)

                return result
            })
    },

    removeAnswer({ dispatch, commit, getters }, hashId) {
        const current = getters.current

        return dispatch('answers/destroy', hashId)
            .then((result) => {
                updateAnswersCount(commit, current, -1)

                return result
            })
    },

    async addComment({
        dispatch, commit, state, getters,
    }, { values }) {
        const comment = await dispatch('comments/add', {
            hashId: getters.current.hash_id,
            values,
        })

        const current = state.question.comments.data
        const updatedComments = [...current, comment.id]

        commit('update', {
            comments: {
                data: updatedComments,
            },
        })
    },

    async acceptAnswer({ commit, state, getters }, { answer, value }) {
        await acceptAnswer(answer.hash_id, value)

        const question = getters.current
        const lastAcceptedAnswer = question.accepted_answer_id
            ? state.answers.byId[question.accepted_answer_id]
            : null

        commit(PUT_QUESTIONS_TO_STATE, {
            [question.id]: {
                accepted_answer_id: value ? answer.id : null,
            },
        }, { root: true })

        // Still the same guy who get the reputation
        const reputationNotChanged = lastAcceptedAnswer
            && lastAcceptedAnswer.user_id === answer.user_id
            && value === true
        if (reputationNotChanged) {
            return
        }

        // Not self-answering
        const shouldAwardUser = question.user_id !== answer.user_id
        // Accepted answer changed and was not self-answering
        const shouldRevokePoints = lastAcceptedAnswer
            && lastAcceptedAnswer.user_id !== answer.user_id
            && question.user_id !== lastAcceptedAnswer.user_id

        const payload = _flow(
            // eslint-disable-next-line max-len, no-shadow
            payload => (shouldAwardUser ? _assign(payload, { [answer.user_id]: { reputation: value ? 3 : -3 } }) : payload),
            // eslint-disable-next-line max-len, no-shadow
            payload => (shouldRevokePoints ? _assign(payload, { [lastAcceptedAnswer.user_id]: { reputation: -3 } }) : payload)
        )({})

        if (!_isEmpty(payload)) {
            commit('entities/users/increment', payload, { root: true })
        }
    },

    voteQuestion({ state, dispatch }, value) {
        return dispatch('entities/questions/vote', {
            id: state.question.id,
            value,
        }, { root: true })
    },

    fetchSubscribers({ commit }, id) {
        commit('SET_SUBSCRIBERS', [])
        return getSubscribers('questions', id)
            .then(({ data: { data } }) => {
                commit('SET_SUBSCRIBERS', data)
                return data
            })
    },

    async clip({
        dispatch, state, commit, rootState: { auth: { user } },
    }, value) {
        const response = await dispatch('entities/questions/clip', {
            id: state.question.id,
            value,
        }, { root: true })

        commit('updateSubscribers', { value, clipper: user })

        return response
    },

    report({ state, commit }, { reason, source }) {
        return report(state.question.hash_id, { reason, source })
            .then((response) => {
                commit('SET_REPORTED')
                return response
            })
    },

    async updateTags({ state, commit }, { data }) {
        const question = state.question

        const tags = await updateTagsQuestion(question.hash_id, data)

        commit(PUT_QUESTIONS_TO_STATE, {
            [question.id]: {
                tags,
            },
        }, { root: true })
    },
}

const mutations = {
    setQuestion(state, question) {
        state.question = _pick(question, ['id', 'contents', 'comments', 'hash_id', 'user_id'])
    },

    update(state, question) {
        const current = state.question
        state.question = _assign(current, question)
    },

    updateSubscribers(state, { value, clipper }) {
        if (clipper) {
            state.subscribers = value
                ? [clipper, ...state.subscribers]
                : _filter(state.subscribers, subscriber => subscriber.username !== clipper.username)
        }
    },

    SET_REPORTED(state) {
        state.question.reported = true
    },

    SET_SUBSCRIBERS(state, subscribers = []) {
        if (subscribers.length >= 0) {
            state.subscribers = subscribers
        }
    },
}

export default {
    state,
    actions,
    getters,
    mutations,
}
