// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <nuxt-link :to="to" class="link d-block">
        <div class="suggestion-has-image">
            <div class="suggestion-image">
                <img :alt="item.name" :src="item.image">
            </div>
            <div>
                <h6 class="font-weight-bold mb-0 suggestion-identifier">
                    {{ item.name }}
                </h6>
                <div class="suggestion-stats text-muted">
                    <p class="mr-1 mb-0">
                        {{ $t('search.suggestions.tags.followers') }}: <strong>{{ item.followers_count }}</strong>
                    </p>
                    <p class="mb-0">
                        {{ $t('search.suggestions.tags.posts') }}: <strong>{{ item.posts_count }}</strong>
                    </p>
                </div>
            </div>
        </div>
    </nuxt-link>
</template>

<script>
    import { routeToTag as toTag } from '~/lib/functions'

    export default {
        props: {
            item: Object,
        },
        computed: {
            to() {
                return toTag(this.item)
            },
        },
    }
</script>

<style lang="scss">
    .suggestion-has-image {
        .suggestion-image {
            max-width: 45px;
            overflow: hidden;
        }
    }
</style>
