// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <header class="main-navbar main-navbar__group py-1">
        <div class="main-navbar__container container px-md-0">
            <!-- Left -->
            <div class="d-flex main-navbar__grow">
                <!-- Logo -->
                <nuxt-link
                    v-if="guest"
                    class="main-navbar__logo d-block mr-lg-5"
                    to="/newest"
                    exact
                >
                    <progressive-img
                        v-if="logo"
                        :tiny-src="tinyUrl"
                        :src="logo.image"
                        :width="62"
                        :height="21"
                        alt=""
                    />
                    <img
                        v-else
                        src="/logo_full.svg"
                        alt="Viblo"
                        width="62"
                        height="21"
                    >
                </nuxt-link>
                <span v-else>
                    <a
                        v-if="isSubdomain"
                        class="main-navbar__logo d-block mr-lg-5"
                        :href="toAppUrl('/')"
                        exact
                    >
                        <progressive-img
                            v-if="logo"
                            :tiny-src="tinyUrl"
                            :src="logo.image"
                            :width="62"
                            :height="21"
                            alt=""
                        />
                        <img
                            v-else
                            src="/logo_full.svg"
                            alt="Viblo"
                            width="62"
                            height="21"
                        >
                    </a>

                    <nuxt-link
                        v-else
                        class="main-navbar__logo d-block mr-lg-5"
                        to="/"
                        exact
                    >
                        <progressive-img
                            v-if="logo"
                            :tiny-src="tinyUrl"
                            :src="logo.image"
                            :width="62"
                            :height="21"
                            alt=""
                        />
                        <img
                            v-else
                            src="/logo_full.svg"
                            alt="Viblo"
                            width="62"
                            height="21"
                        >
                    </nuxt-link>
                </span>

                <!-- Main navigation -->
                <MainMenu class="hidden-md-down" />
            </div>

            <!-- Right -->
            <div class="main-navbar__right">
                <!-- Search -->
                <nuxt-link to="/search" class="link text-muted hidden-md-up mr-1">
                    <i class="fa fa-search" aria-hidden="true" />
                </nuxt-link>

                <quicksearch class="hidden-sm-down flex-fill mr-1" />

                <!-- Flyouts -->
                <div class="main-navbar__group">
                    <Announcements class="mr-1" />
                    <Notifications v-if="!guest" class="mr-1" />
                </div>

                <!-- Menus -->
                <div class="main-navbar__group">
                    <div class="mr-05">
                        <WriteMenu
                            v-if="!guest"
                            id="v-step-1"
                            class="main-navbar__writer"
                        />
                    </div>

                    <div class="main-navbar__group">
                        <LanguageSwitcher class="sm-response" />
                    </div>

                    <Services />

                    <UserMenu ref="menuUser" />
                    <div v-if="showBackgroud" class="backgroud-vue-tour" />
                </div>
            </div>
        </div>
        <feedback-popup ref="feedbackDialog" />
    </header>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import MainMenu from '~/components/navbar/items/MainMenu.vue'
    import UserMenu from '~/components/navbar/items/UserMenu.vue'
    import WriteMenu from '~/components/navbar/items/WriteMenu.vue'
    import Quicksearch from '~/components/navbar/items/Quicksearch.vue'
    import Announcements from '~/components/navbar/items/Announcements.vue'
    import Notifications from '~/components/navbar/items/Notifications.vue'
    import FeedbackPopup from '~/components/feedback/Popup.vue'
    import LanguageSwitcher from '~/components/footer/LanguageSwitcher.vue'
    import Services from '~/components/navbar/items/Services.vue'
    import { toAppUrl } from '~/utils/url'

    export default {
        components: {
            Quicksearch,
            MainMenu,
            UserMenu,
            WriteMenu,
            Announcements,
            Notifications,
            FeedbackPopup,
            LanguageSwitcher,
            Services,
        },

        data() {
            return {
                showBackgroud: false,
            }
        },

        computed: {
            ...mapState('auth', ['guest', 'user']),
            ...mapGetters('auth', ['authenticated']),
            ...mapGetters('config', { logo: 'currentLogo' }),
            ...mapState('config', ['vars']),

            getLocale() {
                return this.$i18n.locale
            },

            isSubdomain() {
                return this.vars.is_special_organization || this.vars.is_special_profile
            },
        },

        beforeDestroy() {
            document.body.style.pointerEvents = 'unset'
        },

        methods: {
            toAppUrl,

            doneUpdate() {
                setTimeout(() => {
                    this.$refs.menuUser.openMenu()
                }, 1)
            },

            disableEventBody() {
                const bodyElement = document.getElementsByTagName('body')[0]
                bodyElement.style.pointerEvents = 'none'
            },

            enableEventBody() {
                const bodyElement = document.getElementsByTagName('body')[0]
                bodyElement.style.pointerEvents = 'auto'
            },
        },
    }
</script>

<style lang="scss" src="../../navbar/navbar.scss"/>
