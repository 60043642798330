// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <v-dialog
        :show.sync="show"
        animation="zoom-out"
        bg-color="rgba(0,0,0,.9)"
        @close="onClose"
    >
        <FeedbackForm ref="form" @sent="close" />
    </v-dialog>
</template>

<script>
    import FeedbackForm from './Form.vue'

    export default {
        components: {
            FeedbackForm,
        },

        data: () => ({
            show: false,
        }),

        methods: {
            open() {
                this.show = true
            },

            close() {
                this.show = false
            },

            onClose() {
                this.$refs.form.reset()
            },
        },
    }
</script>
