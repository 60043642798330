// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _get from 'lodash/get'
import _mapValues from 'lodash/mapValues'
import _indexOf from 'lodash/indexOf'

import { normalize } from 'normalizr'
import { postArray } from '../__utils/schemas'

export const defaultState = {
    byId: {},
    all: [],
    pagination: {},
    current: [],
}

export const getters = {
    get: state => id => state.byId[id],
    isEmpty: state => !state.all.length,
}

export const createActions = ({ fetchPosts }) => ({
    fetch: (actionContext, payload) => fetchPosts(payload).then(setStoreState(actionContext, payload)),
})

function setStoreState({ commit, rootState }, payload) {
    return (posts) => {
        const { entities, result } = normalize(posts.data, postArray)
        const isLoadMore = payload?.params?.isLoadMore

        commit('entities/posts/put', entities.posts, { root: true })
        commit('entities/users/put', entities.users, { root: true })
        commit('entities/organizations/put', entities.organizations, { root: true })
        commit('setAllIds', result)
        commit('setPagination', posts.meta.pagination)
        commit('vueTour/setDoneFetchData', true, { root: true })

        const authUserId = _get(rootState, 'auth.user.id')
        const byId = _mapValues(entities.posts, post => ({
            is_own: post.user_id === authUserId,
            commentators: post.commentators,
            contents_short: post.contents_short,
            organization: post.organization ? entities.organizations[post.organization.data] : null,
            social_shares: post.social_shares ? post.social_shares.data : null,
        }))
        if (isLoadMore) {
            commit('setMorePosts', result)
        }

        commit('setPosts', byId)
    }
}

export const mutations = {
    setPosts(state, data) {
        state.byId = data
    },

    setCurrent(state, data) {
        state.current = data
    },

    setMorePosts(state, data) {
        state.current = [...state.current, ...data]
    },

    setAllIds(state, allIds) {
        state.all = allIds
    },

    setPagination(state, pagination) {
        state.pagination = pagination
    },

    destroyId(state, id) {
        state.all.splice(_indexOf(state.all, id), 1)
    },

    resetData(state) {
        state.current = []
        state.pagination = {}
    },
}
