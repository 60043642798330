<template>
    <el-dropdown @command="updateSettings">
        <span class="el-dropdown-link uppercase switch-language font-bold text-sm">
            <img
                slot="prefix"
                class="imageLocale"
                :src="srcImage(locale)"
                width="20"
                height="20"
            >
            <span class="leading-none block hiden-sm-screen">{{ locale }}</span>
        </span>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
                v-for="locale in $i18n.locales"
                :key="locale.code"
                :command="locale.code"
            >
                <a class="dropdown-item px-4">
                    {{ locale.name }}
                </a>
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import { updateSettings } from 'viblo-sdk/api/me'
    import { changeLangTooltipClipboard, changeLangTitleClipboard, changeLangTextShowMore } from '~/utils/lang'
    import { changeLocaleElementUI } from '~/utils/element-ui'

    export default {
        computed: {
            ...mapState('i18n', ['locale']),
            ...mapGetters('auth', ['authenticated']),
        },

        mounted() {
            if (!this.authenticated) {
                const locale = localStorage.getItem('preferred_locale') || 'vi'
                this.updateSettings(locale)
            }
        },

        methods: {
            updateSettings(value) {
                this.$i18n.locale = value
                this.$store.dispatch('i18n/setLocale', value)
                changeLangTooltipClipboard(this.$t('copied'))
                changeLangTitleClipboard(this.$t('copy'))
                changeLangTextShowMore(this.$t('showMore'))
                changeLocaleElementUI(value)
                if (this.authenticated) {
                    updateSettings('preferred_locale', value)
                } else {
                    localStorage.setItem('preferred_locale', value)
                }
            },

            srcImage(locale) {
                const src = `/images/${locale}-flag-32x48.png`
                return src
            },
        },
    }
</script>

<style lang="scss">
    .el-dropdown-link {
        padding: 5px 6px;
        border-radius: 5px;
        color: #9b9b9b;
        text-transform: uppercase;
        font-weight: 700;
        font-size: .875rem;

        .fa {
            line-height: 2rem;
            margin-left: 4px;
        }

        .imageLocale {
            border-radius: 50%;
            height: 20px;
            width: 20px;
        }
    }
    a.dropdown-item.px-4 {
            color: gray;
            text-decoration: none;
        }

    a.dropdown-item.px-4:hover {
        background-color: #ecf5ff;
        color: #66b1ff;
    }
    .switch-language {
        display: flex;
        align-items: center;

        span {
            margin-left: 6px;
        }
    }
    @media screen and (max-width: 370px) {
        .hiden-sm-screen {
            display: none;
        }
    }
</style>
