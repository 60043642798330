// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import Axios from 'axios'

const axios = Axios.create({
    baseURL: process.env.STATS_API_URL,
    timeout: 5000, // ms
    headers: {
        'Accept': 'application/json', // eslint-disable-line
    },
})

export const saveLog = (logType, data) => axios.post(`/${logType}`, data)
export const updateLog = (logType, id, data) => axios.put(`/${logType}/${id}`, data)
