// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { schema } from 'normalizr'

export const user = new schema.Entity('users')
export const userArray = new schema.Array(user)

export const organization = new schema.Entity('organizations')
export const organizationArray = new schema.Array(organization)

const userResource = new schema.Object({ data: user })

export const post = new schema.Entity('posts', { user: userResource })
export const postArray = new schema.Array(post)

export const question = new schema.Entity('questions', { user: userResource })
export const questionArray = new schema.Array(question)

export const answer = new schema.Entity('answers', { user: userResource })
export const answerArray = new schema.Array(answer)

export const comment = new schema.Entity('comments', { user: userResource })
export const commentArray = new schema.Array(comment)
