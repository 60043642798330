// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { normalize, schema } from 'normalizr'
import { postArray } from '~/store/__utils/schemas'

const user = new schema.Entity('users')
const userData = new schema.Object({ data: user })
const comment = new schema.Entity('comments', { user: userData })
const comments = new schema.Array(comment)
const commentsData = new schema.Object({ data: comments })
const question = new schema.Entity('questions', { user: userData, comments: commentsData })
const series = new schema.Entity('series', { user: userData, comments: commentsData })
const answer = new schema.Entity('answers', { user: userData, comments: commentsData })
const tag = new schema.Entity('tags', {}, { idAttribute: 'slug' })

// Normalize functions:
const normalizeUserList = data => normalize(data, new schema.Array(user))
const normalizeUser = data => normalize(data, user)
const normalizeQuestion = data => normalize(data, question)
const normalizeSeries = data => normalize(data, series)
const normalizeSeriesList = data => normalize(data, new schema.Array(series))
const normalizeAnswersList = data => normalize(data, new schema.Array(answer))
const normalizeTagList = data => normalize(data, new schema.Array(tag))
const normalizeTag = data => normalize(data, tag)
const normalizeCommentList = data => normalize(data, new schema.Array(comment))
const normalizePostList = data => normalize(data, postArray)


export {
    normalizeUser,
    normalizeUserList,
    normalizeQuestion,
    normalizeSeries,
    normalizeAnswersList,
    normalizeTagList,
    normalizeTag,
    normalizeCommentList,
    normalizeSeriesList,
    normalizePostList,
}
