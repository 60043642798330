// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { getTechnicalSkills, updateSkills } from '~/api/users'

const state = () => ({
    skills: [],
})

const actions = {
    fetch: ({ commit }, username) => getTechnicalSkills(username)
        .then(({ data }) => commit('setSkills', data.data)),
    update: ({ dispatch }, { username, skills }) => updateSkills(username, { skills })
        .then(() => dispatch('fetch', username)),
}

const mutations = {
    setSkills(state, skills) {
        state.skills = skills
    },
}

export default {
    state,
    actions,
    mutations,
}
