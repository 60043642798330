/*
 * Add .article-img class to images inserted with raw HTML (not using markdown syntax)
 */

export default class TransformImgTags {
    constructor(selector = 'img:not(.emoji)') {
        this.selector = selector
    }

    call(domTree) {
        const imgs = domTree.querySelectorAll(this.selector)

        imgs.forEach((img) => {
            img.classList.add('article-img')
        })
    }
}
