// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <p class="title font-weight-bold">
            {{ $t('footer.feedback.titleForm') }}
        </p>

        <el-form ref="form" :model="form" :rules="validations">
            <el-form-item :error="serverErrors.content" prop="content" :label="$t('footer.feedback.lable')">
                <el-input
                    v-model="form.content"
                    :rows="4"
                    type="textarea"
                />
            </el-form-item>

            <p class="mt-1">
                {{ $t('footer.feedback.description') }}
            </p>

            <div class="d-flex justify-content-between">
                <el-form-item
                    :error="serverErrors.name"
                    prop="name"
                    :label="$t('footer.feedback.lableName')"
                    class="flex-grow-1 mr-05"
                >
                    <el-input v-model="form.name" />
                </el-form-item>

                <el-form-item
                    :error="serverErrors.email"
                    prop="email"
                    :label="$t('footer.feedback.lableEmail')"
                    class="flex-grow-1 ml-05"
                >
                    <el-input v-model="form.email" />
                </el-form-item>
            </div>
            <div v-if="externalUrl" class="text-right">
                <a
                    :href="externalUrl"
                    target="_blank"
                    @click="surveyOpened"
                >
                    {{ $t('footer.feedback.survey') }}
                </a>
            </div>
        </el-form>

        <div class="d-flex justify-content-center">
            <el-button
                :loading="sending"
                type="primary"
                @click="submit"
            >
                {{ $t('footer.feedback.send') }}
            </el-button>
        </div>
    </div>
</template>
<script>
    import _get from 'lodash/get'
    import { mapState } from 'vuex'
    import { axios } from 'viblo-sdk'
    import { collectServerError } from '~/utils/form'

    export default {
        data() {
            const authUser = this.$store.state.auth.user
            const validations = {
                content: [
                    { required: true, message: this.$t('footer.feedback.validations.requireContent') },
                    { min: 10, message: this.$t('footer.feedback.validations.contentMin') },
                    { max: 10000, message: this.$t('footer.feedback.validations.contentMax') },
                ],
                email: [
                    { type: 'email', message: this.$t('footer.feedback.validations.typeEmail') },
                ],
                name: [
                    { required: true, message: this.$t('footer.feedback.validations.requireName') },
                    { max: 255, message: this.$t('footer.feedback.validations.maxName') },
                ],
            }

            return {
                sending: false,
                validations,
                serverErrors: {},
                form: {
                    name: _get(authUser, 'name', ''),
                    email: _get(authUser, 'email', ''),
                    content: '',
                },
            }
        },

        computed: {
            ...mapState('auth', { authUser: 'user', guest: 'guest' }),
            ...mapState('survey', ['externalUrl']),
        },

        methods: {
            submit() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        this.sendFeedback()
                    }
                })
            },

            surveyOpened() {
                localStorage.setItem(`showSurvey-${this.externalUrl}`, true)
            },

            async sendFeedback() {
                try {
                    this.sending = true
                    await axios.post('/feedback', this.form)
                    this.$message.success({
                        message: this.$t('footer.feedback.success'),
                        duration: 5000,
                        showClose: true,
                    })
                    this.$emit('sent')
                } catch (error) {
                    if (error.response) {
                        this.serverErrors = collectServerError(error.response.data.errors)
                    } else {
                        this.$message.error(this.$t('footer.feedback.error'))
                        throw error
                    }
                } finally {
                    this.sending = false
                }
            },

            reset() {
                this.serverErrors = {}
                this.$refs.form.clearValidate()
                this.$refs.form.resetFields()
            },
        },
    }
</script>
