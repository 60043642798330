// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { getTagQuestions } from 'viblo-sdk/api/tags'
import {
    state, mutations, getters, createFetchAction,
} from '../__modules/question-feed'

const getQuestionsTagged = ({ tag, params }) => getTagQuestions(tag, params)

const actions = {
    fetch: createFetchAction(getQuestionsTagged),
}

export default {
    state,
    getters,
    actions,
    mutations,
}
