// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import axios from 'viblo-sdk/libs/axios'

export const get = (hashId, params) => axios.get(`/series/${hashId}`, { params })

export const getPostsForSeries = params => axios.get('/series/myPosts', { params })
export const report = (series, { reason, source }) => axios.post(`/series/${series}/report`, { reason, source })
export const fetchSubscribers = hashId => axios.get(`/series/${hashId}/subscribers`)

export const getAllSeriesWithCheck = params => axios.get('/series/series-with-check', { params })
export const getAllPostsWithCheck = params => axios.get('/series/posts-with-check', { params })

export const proposeAddPost = (postId, hashId) => axios.put(`/series/${hashId}/propose-add-post`, { post_id: postId })
export const requestAddPost = (postId, hashId) => axios.put(`/series/${hashId}/request-add-post`, { post_id: postId })

export const getAllRequestNews = hashId => axios.get(`/series/${hashId}/requests`)

export const deleteModification = id => axios.delete(`/series-modification/${id}`)
export const updateModification = (id, params) => axios.put(`/series-modification/${id}`, params)

export const getSeries = params => axios.get('/me/contents/series', { params })
export const getPosts = (hashId, params) => axios.get(`/series/${hashId}`, { params }).then(_ => _.data)
