import locale from 'element-ui/lib/locale'
import langEn from 'element-ui/lib/locale/lang/en'
import langVi from 'element-ui/lib/locale/lang/vi'

export function changeLocaleElementUI(value) {
    switch (value) {
        case 'en':
            locale.use(langEn)
            break
        case 'vi':
            locale.use(langVi)
            break
        default:
            locale.use(langVi)
            break
    }
}
