// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _indexOf from 'lodash/indexOf'
import { getCookieData } from '~/utils/pages'
import { profilePath } from '~/constants/profile-path'
import { organizationPath } from '~/constants/organization-path'

export default function ({
    route, redirect, req,
}) {
    const cookie = process.server ? req.headers.cookie : window.document.cookie
    const isSpecialSubdomain = getCookieData(cookie, 'is_special_subdomain')

    const { fullPath } = route

    // redirect to app url if not contains organization path
    if (isSpecialSubdomain === 'organization'
        && _indexOf(organizationPath, fullPath.split('?')[0]) === -1
        && isRedirectToAppURL(fullPath, route.params)
    ) {
        redirect(`${process.env.APP_URL}${fullPath !== '/' ? fullPath : ''}`)
    }

    // redirect to app url if not contains profile path
    if (isSpecialSubdomain === 'profile'
        && _indexOf(profilePath, fullPath.split('?')[0]) === -1
        && isRedirectToAppURL(fullPath, route.params)
    ) {
        redirect(`${process.env.APP_URL}${fullPath !== '/' ? fullPath : ''}`)
    }
}

function isRedirectToAppURL(fullPath, params) {
    if (Object.keys(params).length > 0) {
        const key = Object.values(params)[0]

        return _indexOf(profilePath, fullPath.split(key)[0]) === -1
    }

    return true
}
