// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <v-dialog
        :show.sync="show"
        animation="zoom-out"
        bg-color="rgba(0,0,0,.9)"
        size="small"
        class="login-popup"
        @close="reset"
    >
        <Tabs initial-active-pane="login">
            <TabPane label="Login" name="login">
                <LoginForm ref="loginForm" @loggedIn="reloadPage" />
            </TabPane>

            <TabPane label="Register" name="register">
                <RegistrationForm ref="registrationForm" @sent="close" />
            </TabPane>
        </Tabs>

        <SocialLogin />
    </v-dialog>
</template>

<script>
    import LoginForm from './Login.vue'
    import SocialLogin from './Social.vue'
    import RegistrationForm from './Registration.vue'

    export default {
        components: {
            LoginForm,
            SocialLogin,
            RegistrationForm,
        },

        computed: {
            show: {
                get() {
                    return this.$store.state.auth.showAuthDialog
                },

                set(value) {
                    this.$store.commit('auth/setShowAuthDialog', value)
                },
            },

        },


        methods: {
            open() {
                this.show = true
            },

            close() {
                this.show = false
            },

            reset() {
                this.$refs.loginForm.reset()
                this.$refs.registrationForm.reset()
            },

            reloadPage() {
                window.location.reload()
            },
        },
    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/mixins";

    .login-popup {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .dialog {
            margin: 0 auto;

            &__body {
                padding: 30px;
            }
        }

        .tabs {
            .tabs__item {
                flex: 1;
            }

            .tabs__link {
                text-transform: uppercase;
            }
        }
    }
</style>
