// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
export const state = () => ({
    current: null,
    previous: null,
})

export const mutations = {
    setCurrentPage(state, current = null) {
        state.previous = state.current
        state.current = current
    },
}
