// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { axios } from 'viblo-sdk'

export const ViewType = {
    Post: 'posts',
    Question: 'questions',
    Series: 'series',
    Announcements: 'announcements',
}

export const updateVisitTime = feedType => axios.put(`/me/activity/visit/${feedType}`)
export const fetchBadges = () => axios.get('/me/feed/badges')

export function sendView(type, hashId) {
    return axios.post(`/${type}/${hashId}/increment`, null, {
        baseURL: '/',
    })
}
