// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _get from 'lodash/get'
import Avatar from '~/components/shared/Avatar.vue'

export default {
    components: {
        Avatar,
    },

    props: {
        image: String,
        imageSize: [Number, String],
        size: String,
        images: Array,
    },

    computed: {
        imagePolyfill() {
            const imageUrl = this.images ? _get(this.images, 0) : null

            return imageUrl && !imageUrl.endsWith('mm.png')
                ? imageUrl.replace(/^.+\//, '')
                : null
        },
    },
}
