// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <footer id="footer" class="footer footer-subdomain">
        <v-container class="pt-2">
            <v-row>
                <v-col :md="4" class="mb-1 mb-md-0">
                    <h4 class="footer__header">
                        {{ $t('footer.resources.title') }}
                    </h4>
                    <ul class="list-unstyled footer__links resources">
                        <li>
                            <a v-if="isSubdomain" :href="toAppUrl('/')" class="link">
                                {{ $t('footer.resources.posts') }}
                            </a>
                            <nuxt-link v-else to="/" class="link">
                                {{ $t('footer.resources.posts') }}
                            </nuxt-link>
                        </li>
                        <li>
                            <nuxt-link to="/organizations" class="link">
                                {{ $t('footer.resources.organizations') }}
                            </nuxt-link>
                        </li>
                        <li>
                            <a v-if="isSubdomain" :href="toAppUrl('/questions')" class="link">
                                {{ $t('footer.resources.questions') }}
                            </a>
                            <nuxt-link v-else to="/questions" class="link">
                                {{ $t('footer.resources.questions') }}
                            </nuxt-link>
                        </li>
                        <li>
                            <nuxt-link to="/tags" class="link">
                                {{ $t('footer.resources.tags') }}
                            </nuxt-link>
                        </li>
                        <li>
                            <a v-if="isSubdomain" :href="toAppUrl('/videos')" class="link">
                                {{ $t('footer.resources.videos') }}
                            </a>
                            <nuxt-link v-else to="/videos" class="link">
                                {{ $t('footer.resources.videos') }}
                            </nuxt-link>
                        </li>
                        <li>
                            <nuxt-link to="/authors" class="link">
                                {{ $t('footer.resources.authors') }}
                            </nuxt-link>
                        </li>
                        <li>
                            <nuxt-link to="/discussion" class="link">
                                {{ $t('footer.resources.discussions') }}
                            </nuxt-link>
                        </li>
                        <li>
                            <nuxt-link to="/explore" class="link">
                                {{ $t('footer.resources.recommendSystem') }}
                            </nuxt-link>
                        </li>
                        <li>
                            <a
                                href="https://about.viblo.asia/tools/"
                                target="_blank"
                                rel="noopener"
                                class="link"
                            >{{ $t('footer.resources.tools') }}</a>
                        </li>
                        <li>
                            <a
                                href="https://machine-learning.viblo.asia"
                                target="_blank"
                                rel="noopener"
                                class="link"
                            >{{ $t('footer.resources.machineLearning') }}</a>
                        </li>
                        <li>
                            <a
                                href="https://status.viblo.asia"
                                target="_blank"
                                rel="noopener"
                                class="link"
                            >{{ $t('footer.resources.systemStatus') }}</a>
                        </li>
                    </ul>
                </v-col>
                <v-col :md="3" :xl="{ offset: 1 }" class="mb-1 mb-md-0">
                    <h4 class="footer__header">
                        {{ $t('footer.resources.services') }}
                    </h4>
                    <ul class="list-unstyled footer__links service-badges">
                        <v-row>
                            <v-col
                                v-for="service in vibloServices"
                                :key="service.name"
                                :xs="12"
                                :sm="3"
                                :md="12"
                                :lg="12"
                                :xl="12"
                            >
                                <li class="footer__links__external-link">
                                    <a
                                        :href="service.src"
                                        target="_blank"
                                        rel="noopener"
                                        class="link"
                                    >
                                        <img
                                            :src="service.icon"
                                            :alt="service.name"
                                            class="link-icon"
                                            width="24"
                                            height="24"
                                        >
                                        {{ service.name }}
                                    </a>
                                </li>
                            </v-col>
                        </v-row>
                    </ul>
                </v-col>
                <v-col :md="{ span: 3, offset: 1 }" class="mb-1 mb-md-0">
                    <div class="d-flex align-items-baseline">
                        <h4 class="footer__header mr-1">
                            {{ $t('footer.resources.mobileApp') }}
                        </h4>
                    </div>

                    <MobileApp class="mb-05" />

                    <h4 class="footer__header">
                        {{ $t('footer.resources.links') }}
                    </h4>
                    <ul class="list-unstyled footer__social-links">
                        <li class="footer__socail-links__external-link">
                            <a
                                href="https://www.facebook.com/viblo.asia/"
                                target="_blank"
                                rel="noopener"
                                class="link"
                            >
                                <i class="fa fa-facebook link-icon" />
                            </a>
                        </li>

                        <li class="footer__socail-links__external-link">
                            <a
                                href="https://github.com/viblo-asia/"
                                target="_blank"
                                rel="noopener"
                                class="link"
                            >
                                <i class="fa fa-github link-icon" />
                            </a>
                        </li>

                        <li class="footer__socail-links__external-link">
                            <a
                                href="https://chrome.google.com/webstore/detail/viblos-news-feed/mliahmjgdpkkicelofhbhgiidgljijmj"
                                target="_blank"
                                rel="noopener"
                                class="link"
                            >
                                <i class="fa fa-chrome link-icon" />
                            </a>
                        </li>

                        <li class="footer__socail-links__external-link">
                            <a
                                href="https://atom.io/packages/viblo"
                                target="_blank"
                                rel="noopener"
                                class="link"
                            >
                                <img
                                    src="/images/atom-editor-subdomain.svg"
                                    alt="Atom Icon"
                                    class="link-icon"
                                    width="24"
                                    height="24"
                                >
                            </a>
                        </li>
                    </ul>
                </v-col>
            </v-row>

            <hr class="footer__divider">

            <v-row class="py-1">
                <v-col
                    :xs="12"
                    :sm="4"
                    :lg="5"
                    :xl="6"
                >
                    <p class="copyright">
                        &copy; {{ (new Date()).getFullYear() }}
                        <b>Viblo</b>{{ $t('footer.resources.rightsReserved') }}
                    </p>
                </v-col>
                <v-col
                    :xs="12"
                    :sm="8"
                    :lg="7"
                    :xl="6"
                >
                    <!-- eslint-disable -->
                    <ul class="list-unstyled d-flex justify-content-between flex-wrap">
                        <li class="mb-05">
                            <a
                                href="https://about.viblo.asia/"
                                target="_blank"
                                rel="noopener"
                                class="link"
                            >{{ ($t('footer.about')) }}</a>
                        </li>
                        <li id="v-step-4" class="mb-05 mx-05" :class="{'hightlight-step4' : step4}">
                            <a
                                href="/feedback"
                                class="link"
                                :class="{'pulse' : step4}"
                                @click.prevent="$refs.feedbackDialog.open"
                            >{{ ($t('footer.feedback.title')) }}</a>
                        </li>
                        <li class="mb-05 mx-05">
                            <nuxt-link to="/helps" class="link">
                                {{ ($t('footer.help')) }}
                            </nuxt-link>
                        </li>
                        <li class="mb-05 mx-05">
                            <nuxt-link to="/faq" class="link">
                                {{ ($t('footer.fAQs')) }}
                            </nuxt-link>
                        </li>
                        <li class="mb-05 mx-05">
                            <nuxt-link to="/rss-channels" class="link">
                                {{ ($t('footer.rSS')) }}
                            </nuxt-link>
                        </li>
                        <li class="mb-05 mx-05">
                            <nuxt-link :to="termPath" class="link">
                                {{ ($t('footer.terms')) }}
                            </nuxt-link>
                        </li>
                        <li class="mb-05 mx-05">
                            <a
                                href="//www.dmca.com/Protection/Status.aspx?ID=41818fcd-5a60-4504-867a-38fde606354e"
                                title="DMCA.com Protection Status"
                                class="link dmca-badge"
                                target="_blank"
                                rel="noopener"
                            >
                                <img
                                    src="https://images.dmca.com/Badges/dmca-badge-w100-5x1-07.png?ID=41818fcd-5a60-4504-867a-38fde606354e"
                                    alt="DMCA.com Protection Status"
                                    width="100"
                                    height="20"
                                >
                            </a>
                            <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js" />
                        </li>
                    </ul>
                </v-col>
            </v-row>
        </v-container>

        <feedback-popup ref="feedbackDialog" />
    </footer>
</template>

<script>
    import { mapState } from 'vuex'
    import FeedbackPopup from '~/components/feedback/Popup.vue'
    import MobileApp from '~/components/footer/MobileApp.vue'
    import { vibloServices } from '~/constants/services'
    import { toAppUrl } from '~/utils/url'

    export default {
        components: {
            MobileApp,
            FeedbackPopup,
        },

        data() {
            return {
                vibloServices,
            }
        },

        computed: {
            ...mapState('auth', ['guest']),
            ...mapState('vueTour', ['step4']),
            ...mapState('config', ['vars']),

            termPath() {
                return this.$i18n.locale === 'en' ? '/terms/en_term' : '/terms/vi_term'
            },

            isSubdomain() {
                return this.vars.is_special_organization || this.vars.is_special_profile
            },
        },

        methods: {
            toAppUrl,
        },
    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/colors";

    .footer {
        color: $gray-300;
        width: 100%;
        align-self: flex-end;

        @media only screen and (max-width: 768px) {
            margin-bottom: 20px;
        }

        &__header {
            font-weight: bold;
            margin-bottom: 1.5rem;
            text-transform: uppercase;
        }

        .link:hover {
            color: $gray-100;
        }

        .service-badges {
            display: flex;
            flex-direction: column;
            @media only screen and (max-width: 767px) {
                flex-direction: row;
                li {
                    flex-basis: 50%;
                }
            }
        }

        &__links {
            li {
                margin-bottom: 0.75rem;
            }

            &__external-link {
                .link {
                    display: flex;
                    align-items: center;
                }

                .link-icon {
                    width: 1.5rem;
                    height: auto;
                    margin-right: 0.5rem;
                }
            }
        }

        &__social-links {
            li {
                display: inline-block;
                width: 1.5rem;
                height: 1.5rem;
                margin-right: 0.5rem;
            }

            .link {
                align-items: center;
                margin: auto;

                img {
                    margin-bottom: 5px;
                }
            }

            .link-icon {
                width: 1.4rem;
            }

            .fa {
                font-size: 1.3rem;
            }
        }

        .resources {
            display: flex;
            flex-wrap: wrap;

            li {
                flex-basis: 50%;
            }
        }

        &__divider {
            border-color: rgba(255, 255, 255, 0.2) !important;
        }

        .hightlight-step4 {
            width: 80px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: black;
            border-radius: 5px;
            z-index: 3000;
            background-color: white;
        }
    }

    .footer-subdomain {
        border-top: 3px solid #EDEDED;
        color: #808080;

        .link:hover {
            color: black;
        }
    }

    .theme-dark {
        .footer-subdomain {
            background-color: #252525;
            color: $dark-text-secondary;
            border: none;

            .link:hover {
                color: $gray-100;
            }
        }
    }
</style>
