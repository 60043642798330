// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import Vue from 'vue'

import avatar from '~/directives/avatar'
import focus from '~/directives/focus'
import scrollspy from '~/directives/scrollspy'
import tooltip from '~/directives/tooltip'
import jumpToRouteHash from '~/directives/jumpToRouteHash'
import scrollTo from '~/directives/scroll-to'

export default () => {
    Vue.directive('avatar', avatar)
    Vue.directive('focus', focus)
    Vue.directive('scrollspy', scrollspy)
    Vue.directive('tooltip', tooltip)
    Vue.directive('jump-to-route-hash', jumpToRouteHash)
    Vue.directive('scroll-to', scrollTo)
}
