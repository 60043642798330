// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
    computed: {
        ...mapState('notifications', {
            notifications: 'data',
            unreadCounter: 'unread',
        }),
    },

    methods: {
        ...mapActions('notifications', {
            fetchNotifications: 'fetch',
            fetchMore: 'fetchMore',
            clearUnread: 'clear',
        }),
        ...mapGetters('notifications', ['hasMore']),

        clear() {
            if (this.unreadCounter > 0) {
                this.clearUnread()
            }
        },

        setRead(id) {
            this.$store.commit('notifications/setRead', id)
        },
    },
}
