// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <Flyout
        ref="flyout"
        :badge="indicator"
        icon="fa fa-info"
        class="announcement-flyout"
    >
        <div slot="header" class="d-flex align-items-center justify-content-between">
            <span class="announcement-flyout__heading">{{ $t('mainNavbar.announcements.announcements') }}</span>
            <MarkAllAsRead />
        </div>

        <div v-if="items.length === 0" class="py-05 text-center">
            <span>
                <i class="fa fa-times" aria-hidden="true" /> {{ $t('mainNavbar.announcements.noAnnouncement') }}
            </span>
        </div>

        <div v-else class="announcement-flyout__list">
            <nuxt-link
                v-for="(article, index) in items"
                :key="index"
                :to="routeToPost(article)"
                :class="announcementClasses(article)"
                class="announcement-flyout__item d-block link"
                @click.native="hide"
            >
                <span style="word-break: break-word">{{ article.title }}</span>
                <br>
                <small class="subtext">{{ article.published_at | humanize-time }}</small>
            </nuxt-link>
        </div>

        <div slot="footer" class="text-right">
            <nuxt-link to="/announcements" class="announcement-flyout__all" @click.native="hide()">
                {{ $t('mainNavbar.announcements.allAnnouncements') }}
            </nuxt-link>
        </div>
    </Flyout>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { routeToPost } from '~/lib/functions'
    import { doNothing } from '~/utils/pages'
    import Flyout from '~/components/shared/Flyout.vue'
    import MarkAllAsRead from '~/components/announcements/MarkAllAsRead.vue'

    export default {
        components: {
            Flyout,
            MarkAllAsRead,
        },

        props: {
            open: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                state: {
                    open: this.open || false,
                },
            }
        },

        computed: {
            ...mapState('auth', [
                'user',
            ]),
            ...mapState('announcements', [
                'items',
                'indicator',
                'processing',
            ]),
        },

        watch: {
            user: 'loadData',
        },

        mounted() {
            this.loadData()
        },

        methods: {
            routeToPost,

            ...mapActions('announcements', [
                'loadAnnouncements',
            ]),

            loadData() {
                return this.loadAnnouncements().catch(doNothing)
            },

            hide() {
                this.$refs.flyout.close()
            },

            announcementClasses(article) {
                if (this.user && !article.read_at && article.published_at > this.user.created_at) {
                    return 'announcement-flyout__item--highlight'
                }

                return null
            },
        },
    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/colors";

    .announcement-flyout {
        &__heading {
            color: $dark;
        }

        &__list {
            width: 400px;
            max-width: 100%;
        }

        &__item {
            padding: .6rem 1.5rem;

            &:hover {
                background-color: $gray-100;
            }
        }

        &__item--highlight {
            background-color: $highlighted-item-bg-color;

            &:hover {
                background-color: $highlighted-item-hover-bg-color;
            }
        }

        &__all {
            font-size: .8rem;
        }
    }

    .theme-dark {
        .announcement-flyout {
            &__heading {
                color: $white;
            }
        }
    }
</style>
