// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
export const state = () => ({
    conditionReputations: 0,
})

export const mutations = {
    setCondition(state, reputations) {
        state.conditionReputations = reputations
    },
}
