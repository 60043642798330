<template>
    <div class="social-login">
        <div class="social-login__divider d-flex align-items-center justify-content-between my-1">
            <span />
            <span>or</span>
            <span />
        </div>
        <ul class="social-login__buttons d-flex align-items-center justify-content-around pl-0">
            <li>
                <a
                    href="#"
                    class="social-btn-circle facebook-btn icon-btn"
                    @click.prevent="redirectToService('facebook')"
                >
                    <i class="fa fa-facebook" aria-hidden="true" />
                </a>
            </li>

            <li>
                <a href="#" class="social-btn-circle google-btn icon-btn" @click.prevent="redirectToService('google')">
                    <i class="fa fa-google-plus" aria-hidden="true" />
                </a>
            </li>

            <li>
                <a href="#" class="social-btn-circle github-btn icon-btn" @click.prevent="redirectToService('github')">
                    <i class="fa fa-github" aria-hidden="true" />
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        methods: {
            redirectToService(service) {
                const provider = service.trim().toLowerCase()
                const returnUrl = window.location.href
                const state = encodeURIComponent(returnUrl)
                this.$root.$loading.start()
                window.location.href = `/auth/${provider}?state=${state}`
            },
        },
    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/borders";

    .social-login {
        &__divider {
            span {
                &:first-child, &:last-child {
                flex: 1;
                height: 1px;
                background-color: $base-border-color;
                }

                &:nth-child(2) {
                padding: 0 15px;
                }
            }
        }

        &__buttons {
            max-width: 50%;
            margin: 0 auto;

            li {
                list-style: none;
                margin-right: 1.5em;

                &:last-child {
                margin-right: 0;
                }

                & > a {
                text-decoration: none;
                }
            }
        }
    }
</style>
