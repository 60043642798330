<template>
    <div class="wrapped-up-loading">
        <div
            class="wrapped-up-loading__contents position-relative
            d-flex flex-column align-items-center justify-content-center text-white"
        >
            <div class="text">
                {{ this.$t('wrappedUp.toolTip') }}
                <i class="el-icon-loading" />
            </div>
            <img
                class="position-absolute"
                src="/images/wrapped-up/close.svg"
                width="24"
                @click="$emit('skip')"
            >
        </div>
    </div>
</template>

<script>
    export default {
    }
</script>

<style lang="scss">
    .wrapped-up-loading {
        background-color: rgba(0,0,0,.9);
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        text-align: center;
        top: 0;
        z-index: 1040;
        &__contents {
            height: 100%;
        }
        .text {
            font-size: 24px;
            font-family: 'Montserrat ExtraBold';
        }
        img {
            top: 0.5em;
            right: 1em;
            cursor: pointer;
            opacity: 0.6;
            &:hover {
                opacity: 1;
            }
        }
    }
</style>
