// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
export default function ({ store, route, redirect }) {
    if (route.path !== '/') return undefined

    const { fullPath } = route
    if (!store.state.auth.guest && store.state.settings.homepage === 'default') {
        return redirect(`/followings${fullPath !== '/' ? fullPath : ''}`)
    }

    return redirect(`/newest${fullPath !== '/' ? fullPath : ''}`)
}
