// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _get from 'lodash/get'
import _set from 'lodash/set'
import _pick from 'lodash/pick'
import _merge from 'lodash/merge'
import _assign from 'lodash/assign'
import { getProfile } from 'viblo-sdk/api/users'

const state = () => ({
    profile: null,
})

const actions = {
    async getProfile({ commit }, username) {
        const { data: profile } = await getProfile(username)

        commit('setProfile', profile)
        commit('entities/users/put', { [profile.id]: profile }, { root: true })
    },

    follow({ state, dispatch }, value) {
        return state.profile ? dispatch('entities/users/follow', {
            username: state.profile.username,
            value,
        }, { root: true }) : Promise.resolve()
    },
}

const mutations = {
    setProfile(state, profile) {
        state.profile = _pick(profile, [
            'id', 'username', 'internal_name',
            'is_internal_member', 'accepted_answers_count', 'answers_count',
            'posts_stats', 'posts_stats_tags', 'subscriptions_count', 'banned_at', 'total_post_views',
            'badges',
        ])
    },

    put(state, data) {
        state.profile = _merge(state.profile, data)
    },

    UPDATE_COUNTER(state, { key, value }) {
        if (!state.profile) {
            return
        }

        const counter = _get(state, key)
        const count = value ? 1 : -1
        _set(state, key, counter + count)
    },
}

const getters = {
    current: (state, getters, rootState, rootGetters) => {
        if (!state.profile) {
            return {}
        }

        const authUserId = rootGetters['auth/userId']
        const user = rootGetters['entities/users/get'](state.profile.id)

        return _assign({}, user, state.profile, {
            is_self: state.profile.id === authUserId,
        })
    },
}

export {
    state,
    actions,
    getters,
    mutations,
}
