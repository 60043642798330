// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div
        v-on-clickaway="hideSuggestions"
        class="sb"
        @keydown.up="onUp"
        @keydown.down="onDown"
        @keyup.esc="hideSuggestions"
    >
        <input
            ref="input"
            v-model="state.input"
            class="sb__input"
            :placeholder="$t('mainNavbar.quickSearch.placeholder')"
            @input="debouncedFetch(state.input)"
            @keyup.enter="toSearchPage(state.input)"
        >

        <button v-show="!state.loading" class="btn btn-primary" @click.prevent="toSearchPage(state.input)">
            <i class="fa fa-search" />
        </button>

        <i v-show="state.loading" class="fa fa-circle-o-notch fa-spin sb-icon" aria-hidden="true" />
        <i
            v-show="state.input"
            class="fa fa-times sb-icon clr text-muted"
            aria-hidden="true"
            @click="state.input = ''"
        />

        <div v-show="state.show" class="sb__suggestions">
            <suggestions
                v-if="!noResults"
                :items="items"
                :highlight="state.highlight"
                @suggestion-picked="onSuggestionPicked"
            />

            <div v-else class="text-center">
                {{ $t('mainNavbar.quickSearch.notFound') }} <nuxt-link to="/search">
                    {{ $t('mainNavbar.quickSearch.advancedSearch') }}
                </nuxt-link>.
            </div>
        </div>
    </div>
</template>

<script>
    import { mixin as clickaway } from '@tranxuanthang/vue-clickaway'

    import _debounce from 'lodash/debounce'
    import _mapValues from 'lodash/mapValues'

    import _flow from 'lodash/flow'
    import _map from 'lodash/fp/map'
    import _sum from 'lodash/fp/sum'
    import _size from 'lodash/fp/size'
    import _get from 'lodash/get'

    import { mapGetters, mapState } from 'vuex'
    import { multisearch } from '~/api/search'
    import { rotateIndex } from '~/utils/helpers'
    import SearchTracking from '~/lib/trackings/search'
    import Suggestions from '~/components/search/Suggestions.vue'

    // import { trackUserAgentInfo } from '~/lib/trackings/user-agent'

    export default {

        components: {
            Suggestions,
        },
        mixins: [clickaway],

        data: () => ({
            state: {
                input: '',
                show: false,
                loading: false,
                highlight: -1,
            },
            items: null,
        }),

        computed: {
            ...mapGetters('auth', ['userId']),

            ...mapState({
                postIds(state) {
                    return _get(state, ['postHomeFeed', 'all'])
                },
            }),

            noResults() {
                return this.state.input && this.resultsCount === 0
            },

            resultsCount() {
                return _flow(
                    _map(_size),
                    _sum
                )(this.items)
            },

            debouncedFetch() {
                return _debounce(this.fetchResults, 800)
            },
        },

        mounted() {
            this.$_searchTracking = SearchTracking('quick_search', this.userId)
        },

        methods: {
            async fetchResults(searchQuery) {
                if (!searchQuery) {
                    this.cancelFetch()
                    return
                }

                try {
                    this.state.loading = true
                    const response = await multisearch(searchQuery)

                    this.items = _mapValues(response.results, group => group.data)
                    // skip call api search tracking
                    // const visitor = await trackUserAgentInfo()
                    // const logInput = {
                    //     search: { q: searchQuery },
                    //     ...visitor,
                    //     postsFeed: this.postIds,
                    // }

                    // const { data } = await this.$_searchTracking.saveLog(logInput).catch(() => {})
                    // this.$store.commit('log/setSearchLog', data.data)
                } catch (e) {
                    this.items = null
                } finally {
                    this.state.show = true
                    this.state.loading = false
                }
            },

            toSearchPage(q) {
                this.cancelFetch()
                if (this.$route.path !== '/search' || this.$route.query.q !== q) {
                    this.$router.push({
                        path: '/search',
                        query: { q },
                    })
                }
            },

            cancelFetch() {
                this.debouncedFetch.cancel()
                this.hideSuggestions()
                this.state.loading = false
                this.items = null
            },

            onUp(e) {
                if (!this.noResults && this.state.show) {
                    e.preventDefault()
                    const next = rotateIndex(this.state.highlight, -1, this.resultsCount - 1, 'prev')
                    this.setHighlight(next)
                }
            },

            onDown(e) {
                if (!this.state.show) {
                    e.preventDefault()
                    this.requestData()
                } else if (!this.noResults) {
                    e.preventDefault()
                    const next = rotateIndex(this.state.highlight, -1, this.resultsCount - 1, 'next')
                    this.setHighlight(next)
                }
            },

            setHighlight(value) {
                this.state.highlight = value

                if (value === -1) {
                    this.$refs.input.focus()
                }
            },

            requestData() {
                if (this.items && !this.state.show) {
                    this.state.show = true
                } else if (this.state.input && !this.items) {
                    this.fetchResults(this.state.input)
                }
            },

            hideSuggestions() {
                this.state.highlight = -1
                this.state.show = false
            },

            onSuggestionPicked(index, total, item) {
                const input = {
                    clicked_item: {
                        type: item.type,
                        id: item.id,
                        position_search_results: index + 1,
                    },
                    total_search_results: total,
                }
                const log = this.$store.getters['log/getSearchLog']
                this.$_searchTracking.updateLog(log, input).catch(() => {})
                this.hideSuggestions()
            },
        },
    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/colors";
    @import "~assets/sass/bootstrap/borders";

    .sb {
        position: relative;

        &-icon {
            top: 13px;
            right: 13px;
            position: absolute;
            color: $gray-600;

            &.clr {
                right: 55px;
                cursor: pointer;
            }
        }

        &__input {
            outline: none;
            line-height: 1;
            width: 100%;
            padding: .65rem calc(45px + 1.5rem) .65rem 1.5rem;
            border-radius: $base-border-radius;
            border: 2px solid $base-border-color;

            &:focus {
                border-color: $primary;
            }

            & + .btn {
                top: 0;
                right: 0;
                height: 100%;
                position: absolute;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &__suggestions {
            right: 0;
            width: 100%;
            z-index: 99;
            margin-top: 0;
            position: absolute;
            padding: 10px;
            border: 1px solid $gray-300;
            border-top: none;
            background: #fff;
        }
    }

    .theme-dark {
        .sb {
            color: $white;

            &__input {
                color: $white;
                background-color: $black;
                border: 2px solid $dark-border;
            }

            &__suggestions {
                background: $black;
                border: none;
            }
        }
    }

</style>
