// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div :class="classContent">
        <!-- eslint-disable vue/no-v-html -->
        <div
            ref="article"
            v-progressive-img="'.pil-img'"
            class="md-contents"
            :style="{
                fontSize,
                lineHeight,
            }"
            v-html="html"
        />
        <!--eslint-enable-->

        <client-only>
            <Tinybox
                v-model="index"
                class="tinybox-custom"
                :images="images"
            />
        </client-only>
    </div>
</template>

<script>
    import _escapeRegExp from 'lodash/escapeRegExp'
    import { mapGetters, mapState } from 'vuex'
    import { ProgressiveImgPrepare } from '@tranxuanthang/vue-progressive-img'
    import Tinybox from 'vue-tinybox'
    import { full, lite } from '../../lib/markdown'
    import { image as toImage } from '~/utils/url'
    import { changeLangTooltipClipboard, changeLangTitleClipboard, changeLangTextShowMore } from '~/utils/lang'
    import TransformImgTags from '~/utils/transform-img-tags'
    import AddImgDimensions from '~/utils/add-img-dimensions'
    import ContentPipeline from '~/utils/content-pipeline'

    export default {
        components: { Tinybox },

        props: {
            contents: {
                type: String,
                default: '',
            },

            uploads: {
                type: Array,
                default: () => [],
            },

            renderer: {
                type: String,
                default: 'full',
            },

            classContent: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                html: '',
                index: null,
                images: [],
            }
        },

        computed: {
            ...mapState('typeControl', ['modeTheme']),
            ...mapGetters('typeControl', [
                'fontSize',
                'lineHeight',
            ]),
        },

        watch: {
            contents() {
                this.getHtml()
            },
        },

        created() {
            this.getHtml()
        },

        mounted() {
            changeLangTooltipClipboard(this.$t('copied'))
            changeLangTitleClipboard(this.$t('copy'))
            changeLangTextShowMore(this.$t('showMore'))

            this.tinyBoxInit()
        },

        methods: {
            changeTextLangInMarkdown() {
                const clipboardEl = document.getElementsByClassName('v-markdown-it-code-copy')
                clipboardEl.forEach((el) => {
                    const tooltipText = el.getElementsByClassName('tooltiptext')[0]
                    tooltipText.innerHTML = this.$t('copied')
                })
            },

            tinyBoxInit() {
                this.$nextTick(() => {
                    this.$refs.article.querySelectorAll('.article-img').forEach((el, index) => {
                        const zoomSrc = el.dataset.zoomSrc || el.getAttribute('src')
                        const regexpString = `^${_escapeRegExp(process.env.IMAGES_URL)}\\/`
                        const imageRegexp = new RegExp(regexpString)

                        if (zoomSrc && imageRegexp.test(zoomSrc)) {
                            const splitImage = zoomSrc.split(/[/?#]/)
                            this.images.push(toImage(splitImage[splitImage.length - 1], 'full'))
                        } else {
                            this.images.push(zoomSrc)
                        }

                        el.addEventListener('click', () => {
                            this.index = index
                        })
                    })
                })
            },

            getHtml() {
                const md = this.renderer === 'lite' ? lite() : full()

                const contentPipeline = new ContentPipeline(this.contents, md)
                contentPipeline.appendHtmlFilter(new TransformImgTags())
                contentPipeline.appendHtmlFilter(new AddImgDimensions(this.uploads))
                contentPipeline.appendHtmlFilter(new ProgressiveImgPrepare('.pil-img'))
                const doc = contentPipeline.process()

                this.html = doc.toString()
            },
        },
    }
</script>

<style lang="scss">
    .theme-dark {
        code:not([class*='language-']) {
            background-color: #4e4c4a !important;
        }
    }

    .theme-sepia {
        code:not([class*='language-']) {
            background-color: #f1cdb0 !important;
        }
    }

    .default {
        code:not([class*='language-']) {
            background-color: #eee;
        }
    }

    .article-img-wrapper {
        max-height: 75vh;
        width: 100%;
        height: auto;
    }

    .article-img-tiny {
        max-height: 75vh;
    }

    .article-img-full {
        max-height: 75vh;
        width: 100%;
        object-fit: contain;
    }

    .tinybox-custom {
        z-index: 1040 !important;
    }

    .tinybox__thumbs {
        overflow-x: auto !important;
    }
</style>
