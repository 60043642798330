// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="login-form">
        <div class="auth-brand text-sm-center hidden-sm-down mt-1 mb-1">
            <p>Join us to find useful information required to improve your skills</p>
        </div>

        <el-alert
            v-if="authenticationError"
            :title="authenticationError.message"
            :closable="false"
            type="error"
            class="mb-05"
            show-icon
        />

        <div v-if="authenticationError && authenticationError.error === 'unverified_email'">
            If you did not receive your email
            <nuxt-link to="/account/activation" class="accented-link">
                click here
            </nuxt-link>
            to resend.
        </div>

        <el-form ref="form" :model="form" :rules="validations">
            <el-form-item prop="username">
                <el-input
                    v-model="form.username"
                    name="username"
                    placeholder="Username or Email"
                    autofocus
                    @keydown.native.enter="login"
                />
            </el-form-item>

            <el-form-item prop="password">
                <el-input
                    v-model="form.password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    @keydown.native.enter="login"
                />
            </el-form-item>
        </el-form>

        <div class="text-right mb-05">
            <a href="/password/reset">Forgot your password?</a>
        </div>

        <el-button
            :loading="loading"
            type="primary"
            class="full-width text-uppercase"
            @click="submit"
        >
            Sign In
        </el-button>
    </div>
</template>

<script>
    import axios from 'viblo-sdk/libs/axios'

    const validations = {
        username: { required: true, message: 'Username or Email is required' },
        password: { required: true, message: 'Password is required' },
    }

    export default {
        data() {
            return {
                loading: false,
                form: {
                    username: '',
                    password: '',
                },
                validations,
                authenticationError: null,
            }
        },

        methods: {
            submit() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        this.login()
                    }
                })
            },

            async login() {
                this.loading = true

                try {
                    const user = await axios.post('/auth/login', this.form, {
                        baseURL: process.env.APP_URL,
                    })

                    if (user) {
                        this.$gtag.event('authentication', {
                            eventCategory: 'Authentication',
                            eventAction: 'Sign In',
                            eventLabel: user.last_login ? 'Regular' : 'First',
                        })
                    }

                    this.authenticationError = null
                    this.$emit('loggedIn')
                } catch (err) {
                    this.loading = false

                    if (err.response && err.response.status === 401) {
                        this.authenticationError = err.response.data
                    } else {
                        this.authenticationError = {
                            error: 'unknown',
                            message: 'Sorry, something went wrong.',
                        }
                    }
                }
            },

            reset() {
                this.$refs.form.clearValidate()
                this.$refs.form.resetFields()
            },
        },
    }
</script>
