// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _get from 'lodash/get'
import { getUserFollowings } from 'viblo-sdk/api/users'
import { ACTION_FOLLOW_USER } from '../entities/users'
import { state, mutations, createFetchAction } from '../__modules/user-list'

const actions = {
    fetch: createFetchAction(({ username, params }) => getUserFollowings(username, params)),

    follow: async ({ dispatch, commit, rootState }, payload) => {
        const response = await dispatch(ACTION_FOLLOW_USER, payload, { root: true })
        const authUserId = _get(rootState, 'auth.user.id')
        const viewingProfile = _get(rootState, 'profile.profile', {})

        if (authUserId === viewingProfile.id) {
            commit('profile/put', {
                subscriptions_count: {
                    users: _get(viewingProfile, 'subscriptions_count.users', 0) + (payload.value === true ? 1 : -1),
                },
            }, { root: true })
        }

        return response
    },

    resetData: ({ commit }) => {
        commit('resetData')
    },
}

export default {
    state,
    actions,
    mutations,
}
