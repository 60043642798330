// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import axios from 'viblo-sdk/libs/axios'

export const fetchUserPosts = (username, stream = 'posts', params) => axios.get(`/users/${username}/${stream}`, { params })
export const getUsersList = params => axios.get('/users', { params })
export const getReputation = username => axios.get(`/users/${username}/reputations`).then(_ => _.data)
export const getFlaggedContents = params => axios.get('/me/flags', { params }).then(_ => _.data)
export const getAnswers = (username, params) => axios.get(`/users/${username}/answers`, { params }).then(_ => _.data)
export const getPostPinned = (username, params) => axios.get(`/users/${username}/pinned-posts`, { params }).then(_ => _.data)
export const getQuestions = (username, params) => axios.get(`/users/${username}/questions`, { params }).then(_ => _.data)
export const getHoverCard = username => axios.get(`/hovercard/users/${username}`).then(_ => _.data)
export const getStats = () => axios.get('me/stats').then(_ => _.data)
export const getTags = params => axios.get('/tags', { params }).then(response => response.data)

export const getTechnicalSkills = username => axios.get(`/u/${username}/skills`)
export const updateSkills = (username, data) => axios.post(`/u/${username}/skills`, data)
export const report = (username, { reason, source }) => axios.post(`/users/${username}/report`, { reason, source })

export const updateCompleteTour = (id, params) => axios.put(`me/complete-tour/${id}`, params)
export const updateCompleteSurvey = (id, params) => axios.put(`me/complete-survey/${id}`, params)
export const getScheduled = params => axios.get('/me/contents/scheduled', { params }).then(_ => _.data)
export const updateMultiSettings = data => axios.put('/me/settings', data).then(_ => _.data)

export const pinPostsProfile = (slug, data) => axios.post(`/pin-posts-profile-subdomain/${slug}`, data)

export const updateLayout = (username, data) => axios.put(`/users/${username}/update-layout`, data)
export const getImagesProfile = username => axios.get(`/users/${username}/images`).then(data => data.data)
