// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import Vue from 'vue'
import Router from 'vue-router'
import { getCookieData } from '~/utils/pages'
import _indexOf from 'lodash/indexOf'
import { getHost, getContentSubDomain } from '~/utils/pages'
import { fetchOrganization } from '~/api/organizations'
import { getProfile } from 'viblo-sdk/api/users'
import { LAYOUT_DEFAULT_SUBDOMAIN, LAYOUT_SUBDOMAIN_2 } from './utils/constant'

const GetStarted = () => import('~/pages/get-started.vue' /* webpackChunkName: "index" */).then(m => m.default)
const Index = () => import('~/pages/index.vue' /* webpackChunkName: "index" */).then(m => m.default)
const MayFestPosts = () => import('~/pages/posts/may-fest.vue' /* webpackChunkName: "index" */).then(m => m.default)
const NewestPosts = () => import('~/pages/posts/newest.vue' /* webpackChunkName: "index" */).then(m => m.default)
const EditorsChoice = () => import('~/pages/posts/editors-choice.vue' /* webpackChunkName: "index" */).then(m => m.default)
const TrendingPosts = () => import('~/pages/posts/trending.vue' /* webpackChunkName: "index" */).then(m => m.default)
const FollowingPosts = () => import('~/pages/posts/followings.vue' /* webpackChunkName: "index" */).then(m => m.default)
const ClippedPosts = () => import('~/pages/posts/clips.vue' /* webpackChunkName: "index" */).then(m => m.default)
const ContentCreatorPosts = () => import ('~/pages/posts/content-creator.vue' /* webpackChunkName: "index" */).then(m => m.default)

const AllSeries = () => import('~/pages/posts/series.vue' /* webpackChunkName: "index" */).then(m => m.default)
const AllVideos = () => import('~/pages/posts/videos.vue' /* webpackChunkName: "index" */).then(m => m.default)

const Questions = () => import('~/pages/questions/_feed.vue' /* webpackChunkName: "index" */).then(m => m.default)
const QuestionsNewest = () => import('~/pages/questions/newest.vue' /* webpackChunkName: "index" */).then(m => m.default)

const Organizations = () => import('~/pages/o/index.vue' /* webpackChunkName: "index" */).then(m => m.default)
const AllComments = () => import('~/pages/discussion/index.vue' /* webpackChunkName: "index" */).then(m => m.default)
const TopUsers = () => import('~/pages/authors.vue' /* webpackChunkName: "index" */).then(m => m.default)
const Search = () => import('~/pages/search.vue' /* webpackChunkName: "index" */).then(m => m.default)

const Explore = () => import('~/pages/explore.vue' /* webpackChunkName: "index" */).then(m => m.default)
const ExploreAuthors = () => import('~/pages/explore-authors' /* webpackChunkName: "index" */).then(m => m.default)
const ExploreTags = () => import('~/pages/explore-tags' /* webpackChunkName: "index" */).then(m => m.default)

const PostView = () => import('~/pages/p/_post.vue' /* webpackChunkName: "content_view" */).then(m => m.default)
const QuestionView = () => import('~/pages/q/_question.vue' /* webpackChunkName: "content_view" */).then(m => m.default)
const SeriesView = () => import('~/pages/s/_hashId.vue' /* webpackChunkName: "content_view" */).then(m => m.default)

const AllTags = () => import('~/pages/tags/index.vue' /* webpackChunkName: "tag" */).then(m => m.default)
const TagView = () => import('~/pages/tags/_tag.vue' /* webpackChunkName: "tag" */).then(m => m.default)
const TagPosts = () => import('~/pages/tags/_tag/index.vue' /* webpackChunkName: "tag" */).then(m => m.default)
const TagQuestions = () => import('~/pages/tags/_tag/questions.vue' /* webpackChunkName: "tag" */).then(m => m.default)
const TagSeries = () => import('~/pages/tags/_tag/series.vue' /* webpackChunkName: "tag" */).then(m => m.default)
const TagFollowers = () => import('~/pages/tags/_tag/followers.vue' /* webpackChunkName: "tag" */).then(m => m.default)

const UserView = () => import('~/pages/u/_user.vue' /* webpackChunkName: "user" */).then(m => m.default)
const UserReputations = () => import('~/pages/u/_user/reputations.vue' /* webpackChunkName: "user" */).then(m => m.default)
const UserSeries = () => import('~/pages/u/_user/series.vue' /* webpackChunkName: "user" */).then(m => m.default)
const UserPosts = () => import('~/pages/u/_user/posts.vue' /* webpackChunkName: "user" */).then(m => m.default)
const UserQuestions = () => import('~/pages/u/_user/questions.vue' /* webpackChunkName: "user" */).then(m => m.default)
const UserClips = () => import('~/pages/u/_user/clips/_clip.vue' /* webpackChunkName: "user" */).then(m => m.default)
const UserFollowing = () => import('~/pages/u/_user/following.vue' /* webpackChunkName: "user" */).then(m => m.default)
const OzganizationsFollowing = () => import('~/pages/u/_user/following/organizations.vue' /* webpackChunkName: "user" */).then(m => m.default)
const UserFollowers = () => import('~/pages/u/_user/followers.vue' /* webpackChunkName: "user" */).then(m => m.default)
const UserFollowingTags = () => import('~/pages/u/_user/following-tags.vue' /* webpackChunkName: "user" */).then(m => m.default)
const MyAnswers = () => import('~/pages/u/_user/answers.vue' /* webpackChunkName: "user" */).then(m => m.default)
const ContactUser = () => import('~/pages/u/_user/contact.vue' /* webpackChunkName: "user" */).then(m => m.default)

const MyContents = () => import('~/pages/contents' /* webpackChunkName: "my_contents" */).then(m => m.default)
const MyDrafts = () => import('~/pages/contents/posts/drafts' /* webpackChunkName: "my_contents" */).then(m => m.default)
const MyContentAnswers = () => import('~/pages/contents/answers' /* webpackChunkName: "my_contents" */).then(m => m.default)
const MyQuestions = () => import('~/pages/contents/questions' /* webpackChunkName: "my_contents" */).then(m => m.default)
const MyPublicPosts = () => import('~/pages/contents/posts/public' /* webpackChunkName: "my_contents" */).then(m => m.default)
const MyUnlisted = () => import('~/pages/contents/posts/unlisted' /* webpackChunkName: "my_contents" */).then(m => m.default)
const MySpam = () => import('~/pages/contents/posts/spam' /* webpackChunkName: "my_contents" */).then(m => m.default)
const MyNotReady = () => import('~/pages/contents/posts/not-ready' /* webpackChunkName: "my_contents" */).then(m => m.default)
const MyScheduledPosts = () => import('~/pages/contents/posts/scheduled' /* webpackChunkName: "my_contents" */).then(m => m.default)
const ReceivedQuestions = () => import('~/pages/contents/answer-requests/received-questions.vue' /* webpackChunkName: "my_contents" */).then(m => m.default)
const SentAnswerRequests = () => import('~/pages/contents/answer-requests/sent-requests.vue' /* webpackChunkName: "my_contents" */).then(m => m.default)
const MyGallery = () => import('~/pages/contents/gallery' /* webpackChunkName: "my_contents" */).then(m => m.default)
const TeachnicalSkills = () => import('~/pages/contents/technical-skills.vue' /* webpackChunkName: "my_contents" */).then(m => m.default)
const Series = () => import('~/pages/contents/series.vue' /* webpackChunkName: "my_contents" */).then(m => m.default)
const MyClipPosts = () => import('~/pages/contents/clips/posts.vue' /* webpackChunkName: "my_contents" */).then(m => m.default)
const MyClipQuestions = () => import('~/pages/contents/clips/questions.vue' /* webpackChunkName: "my_contents" */).then(m => m.default)
const MyClipSeries = () => import('~/pages/contents/clips/series.vue' /* webpackChunkName: "my_contents" */).then(m => m.default)
const MyViolations = () => import('~/pages/contents/deleted.vue' /* webpackChunkName: "my_contents" */).then(m => m.default)

const MyActivies = () => import('~/pages/me/activities/index.vue' /* webpackChunkName: "my_activities" */).then(m => m.default)

const ReviewContents = () => import('~/pages/review-contents.vue' /* webpackChunkName: "review-contents" */).then(m => m.default)
const PostsSpam = () => import('~/pages/review-contents/posts-spam.vue' /* webpackChunkName: "review-contents" */).then(m => m.default)
const DraftsMySocialShares = () => import('~/pages/review-contents/socialshare/drafts.vue' /* webpackChunkName: "review-contents" */).then(m => m.default)
const ScheduledMySocialShares = () => import('~/pages/review-contents/socialshare/scheduled.vue' /* webpackChunkName: "review-contents" */).then(m => m.default)
const PublishedMySocialShares = () => import('~/pages/review-contents/socialshare/published.vue' /* webpackChunkName: "review-contents" */).then(m => m.default)

const PublishPost = () => import('~/pages/publish/post.vue' /* webpackChunkName: "publishing" */).then(m => m.default)
const AskQuestion = () => import('~/pages/questions/ask.vue' /* webpackChunkName: "publishing" */).then(m => m.default)
const PublishVideo = () => import('~/pages/publish/video.vue' /* webpackChunkName: "publishing" */).then(m => m.default)
const PublishSeries = () => import('~/pages/publish/series.vue' /* webpackChunkName: "publishing" */).then(m => m.default)

const EditPost = () => import('~/pages/posts/_post/edit.vue' /* webpackChunkName: "publishing" */).then(m => m.default)
const EditSEOPost = () => import('~/pages/posts/_post/edit-seo.vue' /* webpackChunkName: "publishing" */).then(m => m.default)
const EditVideo = () => import('~/pages/videos/_hashId/edit.vue' /* webpackChunkName: "publishing" */).then(m => m.default)
const EditQuestion = () => import('~/pages/questions/_hashId/edit.vue' /* webpackChunkName: "publishing" */).then(m => m.default)
const EditSeries = () => import('~/pages/series/_hashId/edit.vue' /* webpackChunkName: "publishing" */).then(m => m.default)

const Settings = () => import('~/pages/settings.vue' /* webpackChunkName: "settings" */).then(m => m.default)
const NotificationSettings = () => import('~/pages/settings/notifications.vue' /* webpackChunkName: "settings" */).then(m => m.default)
const Appearances = () => import('~/pages/settings/appearances.vue' /* webpackChunkName: "settings" */).then(m => m.default)
const AllNotifications = () => import('~/pages/notifications.vue' /* webpackChunkName: "settings" */).then(m => m.default)
const Webhook = () => import('~/pages/settings/webhooks.vue' /* webpackChunkName: "settings" */).then(m => m.default)

const MyOrganizationsLayout = () => import('~/pages/organizations.vue' /* webpackChunkName: "organization-settings" */).then(m => m.default)
const MyOrganizations = () => import('~/pages/organizations/joinings.vue' /* webpackChunkName: "organization-settings" */).then(m => m.default)
const FollowingsOrganization = () => import('~/pages/organizations/followings.vue' /* webpackChunkName: "organization-settings" */).then(m => m.default)
const ConfirmOrganizationInvitation = () => import('~/pages/organizations/_organization/confirm-invitation.vue' /* webpackChunkName: "organization-settings" */).then(m => m.default)
const NewOrganization = () => import('~/pages/organizations/new-organization.vue' /* webpackChunkName: "organization-settings" */).then(m => m.default)

const OrganizationView = () => import('~/pages/o/_organization.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const Info = () => import('~/pages/o/_organization/description.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const EditInfoOrganization = () => import('~/pages/o/_organization/edit.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const OrganizationWebsiteVerify = () => import('~/pages/o/_organization/website-verify.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const PostByOrganization = () => import('~/pages/o/_organization/posts.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const VideoByOrganization = () => import('~/pages/o/_organization/videos.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const Submissions = () => import('~/pages/o/_organization/submissions.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const OrganizationFollowers = () => import('~/pages/o/_organization/followers.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const OrganizationMembers = () => import('~/pages/o/_organization/members.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const OrganizationStats = () => import('~/pages/o/_organization/stats.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const OrganizationStatsOverview = () => import('~/pages/o/_organization/stats/overview.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const OrganizationStatsContribution = () => import('~/pages/o/_organization/stats/contribution.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const OrganizationInvitations = () => import('~/pages/o/_organization/invitations.vue' /* webpackChunkName: "organizations" */).then(m => m.default)

const AllHelps = () => import('~/pages/helps/index.vue' /* webpackChunkName: "support" */).then(m => m.default)
const Help = () => import('~/pages/helps/_post.vue' /* webpackChunkName: "support" */).then(m => m.default)
const AllAnnouncements = () => import('~/pages/posts/announcements.vue' /* webpackChunkName: "support" */).then(m => m.default)
const Announcement = () => import('~/pages/announcements/_post.vue' /* webpackChunkName: "support" */).then(m => m.default)
const FAQs = () => import('~/pages/faq.vue' /* webpackChunkName: "support" */).then(m => m.default)
const Feedback = () => import('~/pages/feedback.vue' /* webpackChunkName: "support" */).then(m => m.default)
const RSS = () => import('~/pages/rss.vue' /* webpackChunkName: "support" */).then(m => m.default)

const Terms = () => import('~/pages/terms/index.vue' /* webpackChunkName: "support" */).then(m => m.default)

const Survey = () => import('~/pages/survey/_token.vue' /* webpackChunkName: "survey" */).then(m => m.default)

const UnsubscribeEmail = () => import('~/pages/unsubscribe/email/index.vue' /* webpackChunkName: "emails" */).then(m => m.default)

const WriteUpsByPuzzle = () => import('~/pages/write-ups/_puzzle.vue' /* webpackChunkName: "write-ups" */).then(m => m.default)

//subdomain route
// profile subdomain
const SubdomainUserView = () => import('~/pages/subdomains/u/_user.vue' /* webpackChunkName: "user" */).then(m => m.default)
const SubdomainUserPosts = () => import('~/pages/subdomains/u/_user/posts.vue' /* webpackChunkName: "user" */).then(m => m.default)
const SubdomainUserSeries = () => import('~/pages/subdomains/u/_user/series.vue' /* webpackChunkName: "user" */).then(m => m.default)
const SubdomainUserQuestions = () => import('~/pages/subdomains/u/_user/questions.vue' /* webpackChunkName: "user" */).then(m => m.default)
const SubdomainUserClips = () => import('~/pages/subdomains/u/_user/clips/_clip.vue' /* webpackChunkName: "user" */).then(m => m.default)
const SubdomainUserFollowing = () => import('~/pages/subdomains/u/_user/following.vue' /* webpackChunkName: "user" */).then(m => m.default)
const SubdomainOzganizationsFollowing = () => import('~/pages/subdomains/u/_user/following/organizations.vue' /* webpackChunkName: "user" */).then(m => m.default)
const SubdomainUserFollowers = () => import('~/pages/subdomains/u/_user/followers.vue' /* webpackChunkName: "user" */).then(m => m.default)
const SubdomainUserFollowingTags = () => import('~/pages/subdomains/u/_user/following-tags.vue' /* webpackChunkName: "user" */).then(m => m.default)
const SubdomainUserReputations = () => import('~/pages/subdomains/u/_user/reputations.vue' /* webpackChunkName: "user" */).then(m => m.default)
const SubdomainMyAnswers = () => import('~/pages/subdomains/u/_user/answers.vue' /* webpackChunkName: "user" */).then(m => m.default)
const SubdomainContactUser = () => import('~/pages/subdomains/u/_user/contact.vue' /* webpackChunkName: "user" */).then(m => m.default)
// organization subdomain
const SubdomainOrganizationView = () => import('~/pages/subdomains/o/_organization.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const SubdomainPostByOrganization = () => import('~/pages/subdomains/o/_organization/posts.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const SubdomainVideoByOrganization = () => import('~/pages/subdomains/o/_organization/videos.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const SubdomainSubmissions = () => import('~/pages/subdomains/o/_organization/submissions.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const SubdomainOrganizationMembers = () => import('~/pages/subdomains/o/_organization/members.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const SubdomainOrganizationFollowers = () => import('~/pages/subdomains/o/_organization/followers.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const SubdomainInfo = () => import('~/pages/subdomains/o/_organization/description.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const SubdomainEditInfoOrganization = () => import('~/pages/subdomains/o/_organization/edit.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const SubdomainOrganizationWebsiteVerify = () => import('~/pages/subdomains/o/_organization/website-verify.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const SubdomainOrganizationInvitations = () => import('~/pages/subdomains/o/_organization/invitations.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const SubdomainOrganizationStatsContribution = () => import('~/pages/subdomains/o/_organization/stats-contribution.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const SubdomainOrganizationStatsOverview = () => import('~/pages/subdomains/o/_organization/stats-overview.vue' /* webpackChunkName: "organizations" */).then(m => m.default)

// organization subdomain layout 2
const SubdomainOrganizationViewLayout2 = () => import('~/pages/layout-subdomains-2/o/_organization.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const SubdomainPostByOrganizationLayout2 = () => import('~/pages/layout-subdomains-2/o/_organization/posts.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const SubdomainOrganizationMembersLayout2 = () => import('~/pages/layout-subdomains-2/o/_organization/members.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const SubdomainOrganizationFollowersLayout2 = () => import('~/pages/layout-subdomains-2/o/_organization/followers.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const SubdomainOrganizationInvitationsLayout2 = () => import('~/pages/layout-subdomains-2/o/_organization/invitations.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const SubdomainOrganizationStatsOverviewLayout2 = () => import('~/pages/layout-subdomains-2/o/_organization/stats-overview.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const SubdomainOrganizationStatsContributionLayout2 = () => import('~/pages/layout-subdomains-2/o/_organization/stats-contribution.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const SubdomainVideoByOrganizationLayout2 = () => import('~/pages/layout-subdomains-2/o/_organization/videos.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const SubdomainSubmissionsLayout2 = () => import('~/pages/layout-subdomains-2/o/_organization/submissions.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const SubdomainAboutUsLayout2 = () => import('~/pages/layout-subdomains-2/o/_organization/about.vue' /* webpackChunkName: "organizations" */).then(m => m.default)
const SubdomainEditInfoOrganizationLayout2 = () => import('~/pages/layout-subdomains-2/o/_organization/edit.vue' /* webpackChunkName: "organizations" */).then(m => m.default)

// profile subdomain layout 2
const SubdomainUserViewLayout2 = () => import('~/pages/layout-subdomains-2/u/_user.vue' /* webpackChunkName: "user" */).then(m => m.default)
const SubdomainUserPostsLayout2 = () => import('~/pages/layout-subdomains-2/u/_user/posts.vue' /* webpackChunkName: "user" */).then(m => m.default)
const SubdomainUserFollowingLayout2 = () => import('~/pages/layout-subdomains-2/u/_user/following.vue' /* webpackChunkName: "user" */).then(m => m.default)
const SubdomainUserFollowersLayout2 = () => import('~/pages/layout-subdomains-2/u/_user/followers.vue' /* webpackChunkName: "user" */).then(m => m.default)
const SubdomainOrganizationsFollowingLayout2 = () => import('~/pages/layout-subdomains-2/u/_user/following/organizations.vue' /* webpackChunkName: "user" */).then(m => m.default)
const SubdomainMyAnswersLayout2 = () => import('~/pages/layout-subdomains-2/u/_user/answers.vue' /* webpackChunkName: "user" */).then(m => m.default)
const SubdomainUserSeriesLayout2 = () => import('~/pages/layout-subdomains-2/u/_user/series.vue' /* webpackChunkName: "user" */).then(m => m.default)
const SubdomainUserFollowingTagsLayout2 = () => import('~/pages/layout-subdomains-2/u/_user/following-tags.vue' /* webpackChunkName: "user" */).then(m => m.default)
const SubdomainUserQuestionsLayout2 = () => import('~/pages/layout-subdomains-2/u/_user/questions.vue' /* webpackChunkName: "user" */).then(m => m.default)
const SubdomainUserReputationsLayout2 = () => import('~/pages/layout-subdomains-2/u/_user/reputations.vue' /* webpackChunkName: "user" */).then(m => m.default)
const SubdomainContactUserLayout2 = () => import('~/pages/layout-subdomains-2/u/_user/contact.vue' /* webpackChunkName: "user" */).then(m => m.default)
const SubdomainUserBookmarksLayout2 = () => import('~/pages/layout-subdomains-2/u/_user/clips/_clip.vue' /* webpackChunkName: "user" */).then(m => m.default)

Vue.use(Router)

if (process.client) {
    window.history.scrollRestoration = 'manual'
}

const scrollBehavior = function (to, from, savedPosition) {
    // if the returned position is falsy or an empty object,
    // will retain current scroll position.
    let position = false

    // if no children detected
    if (to.matched.length < 2) {
        // scroll to the top of the page
        position = { x: 0, y: 0 }
    } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
        // if one of the children has scrollToTop option set to true
        position = { x: 0, y: 0 }
    }

    // savedPosition is only available for popstate navigations (back button)
    if (savedPosition) {
        position = savedPosition
    }

    // TODO make it wait for transition to complete if needed (after Nuxt has fixed the bug)

    // coords will be used if no selector is provided,
    // or if the selector didn't match any element.
    try {
        if (to.hash && document.querySelector(to.hash)) {
            // scroll to anchor by returning the selector
            return { selector: to.hash }
        }
    } catch (e) {
        if (e instanceof DOMException) {
            console.warn(e.message)
        }
    }

    return position
}

export async function createRouter (ssrContext) {
    let routes = []
    let isSpecialSubdomain, is_special_organization, is_special_profile = null
    let layout = LAYOUT_DEFAULT_SUBDOMAIN

    isSpecialSubdomain = process.server && ssrContext && ssrContext.req
        ? getCookieData(ssrContext.req.headers.cookie, 'is_special_subdomain')
        : getCookieData(window.document.cookie, 'is_special_subdomain')

    // handle route when load domain first time
    if (process.server && ssrContext
        && ssrContext.req
        && (!ssrContext.req.headers.cookie || typeof getCookieData(ssrContext.req.headers.cookie, 'is_special_subdomain') === 'undefined')) {
        const { VIBLO_ORGANIZATION_SUBDOMAINS, VIBLO_CNAME_ORGANIZATIONS } = require('../env/server')
        const isOrganizationSubDomain = _indexOf(VIBLO_ORGANIZATION_SUBDOMAINS.split(','),
            getContentSubDomain(ssrContext.req.headers.host)) !== -1
        const isCnameOrganization= _indexOf(VIBLO_CNAME_ORGANIZATIONS.split(','),
            ssrContext.req.headers.host) !== -1
        if (ssrContext.req.headers.host !== getHost(process.env.APP_URL)) {
            if (isOrganizationSubDomain || isCnameOrganization) {
                isSpecialSubdomain = 'organization'
            } else {
                isSpecialSubdomain = 'profile'
            }
        }
    }

    is_special_organization = isSpecialSubdomain === 'organization'
    is_special_profile = isSpecialSubdomain === 'profile'

    const host = process.client ? window.location.host : ssrContext?.req?.headers?.host
    const username = getContentSubDomain(host)

    if (is_special_organization) {
        const organization = await fetchOrganization(username).then(({ data }) => data)
 
        layout = organization.layout ? organization.layout : LAYOUT_DEFAULT_SUBDOMAIN
    } else if (is_special_profile) {
        const { data: profile } = await getProfile(username)

        layout = profile.layout ? profile.layout : LAYOUT_DEFAULT_SUBDOMAIN
    }

    if (is_special_organization && layout === LAYOUT_DEFAULT_SUBDOMAIN) {
        routes = [
            {
                path: '/',
                component: SubdomainOrganizationView,
                children: [
                    {
                        path: '',
                        component: SubdomainPostByOrganization
                    },
                    {
                        path: 'videos',
                        component: SubdomainVideoByOrganization
                    },
                    {
                        path: 'submissions',
                        component: SubdomainSubmissions
                    },
                    {
                        path: 'members',
                        component: SubdomainOrganizationMembers
                    },
                    {
                        path: 'followers',
                        component: SubdomainOrganizationFollowers
                    },
                    {
                        path: 'info',
                        component: SubdomainInfo
                    },
                    {
                        path: 'info/edit',
                        component: SubdomainEditInfoOrganization
                    },
                    {
                        path: 'info/website-verify',
                        component: SubdomainOrganizationWebsiteVerify
                    },
                    {
                        path: 'invitations',
                        component: SubdomainOrganizationInvitations
                    },
                    {
                      name: 'OrganizationStatsOverview',
                      path: '/stats',
                      component: SubdomainOrganizationStatsOverview
                    },
                    {
                      name: 'OrganizationStatsContribution',
                      path: '/stats/contribution',
                      component: SubdomainOrganizationStatsContribution
                    },
                ]
            },
            {
                name: 'detail-post',
                path: '/p/:post',
                component: PostView
            },
        ]
    } else if (is_special_organization && layout === LAYOUT_SUBDOMAIN_2) {
        routes = [
          {
              path: '/',
              component: SubdomainOrganizationViewLayout2,
              children: [
                  {
                      path: '',
                      component: SubdomainPostByOrganizationLayout2
                  },
                  {
                      path: 'members',
                      component: SubdomainOrganizationMembersLayout2
                  },
                  {
                      path: 'followers',
                      component: SubdomainOrganizationFollowersLayout2
                  },
                  {
                    path: 'invitations',
                    component: SubdomainOrganizationInvitationsLayout2
                  },
                  {
                    name: 'OrganizationStatsOverview',
                    path: '/stats',
                    component: SubdomainOrganizationStatsOverviewLayout2
                  },
                  {
                    name: 'OrganizationStatsContribution',
                    path: '/stats/contribution',
                    component: SubdomainOrganizationStatsContributionLayout2
                  },
                  {
                    path: 'videos',
                    component: SubdomainVideoByOrganizationLayout2
                  },
                  {
                    path: 'submissions',
                    component: SubdomainSubmissionsLayout2
                  },
                  {
                    path: 'info',
                    component: SubdomainAboutUsLayout2
                  },
                  {
                    path: 'info/edit',
                    component: SubdomainEditInfoOrganizationLayout2
                  },
              ]
          },
          {
            name: 'detail-post',
            path: '/p/:post',
            component: PostView
          },
        ]
    } else if (is_special_profile && layout === LAYOUT_DEFAULT_SUBDOMAIN) {
        routes = [
            {
                path: '/',
                component: SubdomainUserView,
                children: [
                    {
                        path: '',
                        component: SubdomainUserPosts
                    },
                    {
                        path: 'series',
                        component: SubdomainUserSeries
                    },
                    {
                        path: 'questions',
                        component: SubdomainUserQuestions
                    },
                    {
                        path: 'clips/:clip',
                        component: SubdomainUserClips
                    },
                    {
                        path: 'following',
                        component: SubdomainUserFollowing
                    },
                    {
                        path: 'following/organizations',
                        component: SubdomainOzganizationsFollowing
                    },
                    {
                        path: 'followers',
                        component: SubdomainUserFollowers
                    },
                    {
                        path: 'following-tags',
                        component: SubdomainUserFollowingTags
                    },
                    {
                        path: 'reputations',
                        component: SubdomainUserReputations
                    },
                    {
                        path: 'answers',
                        component: SubdomainMyAnswers
                    },
                    {
                        path: 'contact',
                        component: SubdomainContactUser
                    }
                ]
            },
            {
                name: 'detail-post',
                path: '/p/:post',
                component: PostView
            },
            {
                path: '/s/:hashId',
                component: SeriesView
            },
            {
                path: '/q/:question',
                component: QuestionView
            },
        ]

    } else if (is_special_profile && layout === LAYOUT_SUBDOMAIN_2) {
        routes = [
            {
                path: '/',
                component: SubdomainUserViewLayout2,
                children: [
                    {
                        path: '',
                        component: SubdomainUserPostsLayout2
                    },
                    {
                        path: 'following',
                        component: SubdomainUserFollowingLayout2
                    },
                    {
                        path: 'followers',
                        component: SubdomainUserFollowersLayout2
                    },
                    {
                        path: 'following/organizations',
                        component: SubdomainOrganizationsFollowingLayout2,
                    },
                    {
                        path: 'answers',
                        component: SubdomainMyAnswersLayout2
                    },
                    {
                        path: 'following-tags',
                        component: SubdomainUserFollowingTagsLayout2
                    },
                    {
                        path: 'series',
                        component: SubdomainUserSeriesLayout2
                    },
                    {
                        path: 'questions',
                        component: SubdomainUserQuestionsLayout2,
                    },
                    {
                        path: 'reputations',
                        component: SubdomainUserReputationsLayout2,
                    },
                    {
                        path: 'contact',
                        component: SubdomainContactUserLayout2,
                    },
                    {
                        path: 'clips/:clip',
                        component: SubdomainUserBookmarksLayout2,
                    },
                ]
            },
            {
              name: 'detail-post',
              path: '/p/:post',
              component: PostView
            },
            {
                path: '/s/:hashId',
                component: SeriesView
            },
            {
                path: '/q/:question',
                component: QuestionView
            },
        ]
    } else {
        routes = [
            {
                path: '/',
                component: Index,
                children: [
                    {
                        path: 'mayfest2024',
                        component: MayFestPosts
                    },
                    {
                        path: 'newest',
                        component: NewestPosts
                    },
                    {
                        path: 'editors-choice',
                        component: EditorsChoice
                    },
                    {
                        path: '',
                        component: NewestPosts
                    },
                    {
                        path: '/followings',
                        component: FollowingPosts
                    },
                    {
                        path: 'trending',
                        component: TrendingPosts
                    },
                    {
                        path: 'clip/:clip?',
                        component: ClippedPosts
                    },

                    {
                        path: 'announcements',
                        component: AllAnnouncements
                    },
                    {
                        path: 'series',
                        component: AllSeries
                    },
                    {
                        path: 'videos',
                        component: AllVideos
                    },
                    {
                        path: '/write-ups/:puzzle',
                        component: WriteUpsByPuzzle
                    },
                    {
                        path: 'content-creator',
                        component: ContentCreatorPosts
                    },
                ]
            },

            {
                path: '/me',
                redirect: '/me/posts/drafts',
                component: MyContents,
                children: [
                    {
                        path: '/me/posts',
                        redirect: '/me/posts/drafts',
                    },
                    {
                        path: 'posts/drafts',
                        component: MyDrafts
                    },
                    {
                        path: 'posts/public',
                        component: MyPublicPosts
                    },
                    {
                        path: 'posts/unlisted',
                        component: MyUnlisted
                    },
                    {
                        path: 'posts/spam',
                        component: MySpam
                    },
                    {
                        path: 'posts/not-ready',
                        component: MyNotReady
                    },
                    {
                        path: 'posts/scheduled',
                        component: MyScheduledPosts
                    },
                    {
                        path: 'answer-requests/received',
                        component: ReceivedQuestions
                    },
                    {
                        path: 'answer-requests/sent-history',
                        component: SentAnswerRequests
                    },
                    {
                        path: 'gallery',
                        component: MyGallery
                    },
                    {
                        path: 'technical-skills',
                        component: TeachnicalSkills
                    },
                    {
                        path: 'series',
                        component: Series
                    },
                    {
                        path: 'answers',
                        component: MyContentAnswers
                    },
                    {
                        path: 'questions',
                        component: MyQuestions
                    },
                    {
                        path: 'clips',
                        redirect: '/me/clips/posts',
                    },
                    {
                        path: 'clips/posts',
                        component: MyClipPosts
                    },
                    {
                        path: 'clips/questions',
                        component: MyClipQuestions
                    },
                    {
                        path: 'clips/series',
                        component: MyClipSeries
                    },
                    {
                        path: 'violations',
                        component: MyViolations
                    }
                ]
            },

            {
                path: '/o/:organization',
                component: OrganizationView,
                children: [
                    {
                        path: '',
                        component: PostByOrganization
                    },
                    {
                        path: 'videos',
                        component: VideoByOrganization
                    },
                    {
                        path: 'submissions',
                        component: Submissions
                    },
                    {
                        path: 'members',
                        component: OrganizationMembers
                    },
                    {
                        path: 'followers',
                        component: OrganizationFollowers
                    },
                    {
                        path: 'info',
                        component: Info
                    },
                    {
                        path: 'info/edit',
                        component: EditInfoOrganization
                    },
                    {
                        path: 'info/website-verify',
                        component: OrganizationWebsiteVerify
                    },
                    {
                        path: 'invitations',
                        component: OrganizationInvitations
                    }
                ]
            },

            {
                path: '/o/:organization/stats',
                component: OrganizationStats,
                children: [
                    {
                        name: 'OrganizationStatsOverview',
                        path: '',
                        component: OrganizationStatsOverview
                    },
                    {
                        name: 'OrganizationStatsContribution',
                        path: 'contribution',
                        component: OrganizationStatsContribution
                    },
                ],
            },

            {
                path: '/questions',
                component: QuestionsNewest
            },

            {
                path: '/questions/:feed(unsolved|followings|clips)',
                component: Questions
            },

            {
                path: '/discussion',
                component: AllComments
            },

            {
                path: '/organizations',
                component: Organizations
            },

            {
                path: '/authors',
                component: TopUsers
            },

            {
                path: '/search',
                component: Search
            },

            {
                path: '/explore',
                component: Explore
            },

            {
                path: '/explore/authors',
                component: ExploreAuthors
            },

            {
                path: '/explore/tags',
                component: ExploreTags
            },

            {
                name: 'detail-post',
                path: '/p/:post',
                component: PostView
            },
            {
                path: '/q/:question',
                component: QuestionView
            },
            {
                path: '/s/:hashId',
                component: SeriesView
            },

            {
                path: '/tags',
                component: AllTags
            },

            {
                path: '/tags/:tag',
                component: TagView,
                children: [
                    {
                        path: '',
                        component: TagPosts
                    },
                    {
                        path: 'questions',
                        component: TagQuestions
                    },
                    {
                        path: 'series',
                        component: TagSeries
                    },
                    {
                        path: 'followers',
                        component: TagFollowers
                    }
                ]
            },
            {
                path: '/u/:user',
                component: UserView,
                children: [
                    {
                        path: '',
                        component: UserPosts
                    },
                    {
                        path: 'series',
                        component: UserSeries
                    },
                    {
                        path: 'questions',
                        component: UserQuestions
                    },
                    {
                        path: 'clips/:clip',
                        component: UserClips
                    },
                    {
                        path: 'following',
                        component: UserFollowing
                    },
                    {
                        path: 'following/organizations',
                        component: OzganizationsFollowing
                    },
                    {
                        path: 'followers',
                        component: UserFollowers
                    },
                    {
                        path: 'following-tags',
                        component: UserFollowingTags
                    },
                    {
                        path: 'reputations',
                        component: UserReputations
                    },
                    {
                        path: 'answers',
                        component: MyAnswers
                    },
                    {
                        path: 'contact',
                        component: ContactUser
                    }
                ]
            },
            {
                name: 'write-post',
                path: '/publish/post',
                component: PublishPost
            },
            {
                path: '/publish/series',
                component: PublishSeries
            },
            {
                path: '/publish/video',
                component: PublishVideo
            },
            {
                path: '/questions/ask',
                component: AskQuestion
            },

            {
                path: '/posts/:post/edit',
                component: EditPost
            },
            {
                path: '/posts/:post/settings/seo',
                component: EditSEOPost
            },
            {
                path: '/videos/:video/edit',
                component: EditVideo
            },
            {
                path: '/questions/:hashId/edit',
                component: EditQuestion
            },
            {
                path: '/series/:hashId/edit',
                component: EditSeries
            },

            {
                path: '/settings',
                component: Settings,
                children: [
                    {
                        path: '',
                        component: Appearances
                    },
                    {
                        path: 'notifications',
                        component: NotificationSettings
                    },
                    {
                        path: 'appearances',
                        component: Appearances
                    },
                    {
                        path: 'webhooks',
                        component: Webhook
                    },
                ]
            },

            {
                path: '/me/organizations',
                component: MyOrganizationsLayout,
                children: [
                    {
                        path: '',
                        component: MyOrganizations
                    },
                    {
                        path: 'followings',
                        component: FollowingsOrganization
                    },
                ]
            },

            {
                path: '/me/organizations/create',
                component: NewOrganization
            },

            {
                path: '/o/:organization/invites/:user',
                component: ConfirmOrganizationInvitation
            },

            {
                path: '/notifications',
                component: AllNotifications
            },

            {
                path: '/get-started',
                component: GetStarted
            },
            {
                path: '/helps',
                component: AllHelps
            },
            {
                path: '/helps/:post',
                component: Help
            },
            {
                path: '/announcements/:post',
                component: Announcement
            },
            {
                path: '/faq',
                component: FAQs
            },
            {
                path: '/rss-channels',
                component: RSS
            },
            {
                path: '/feedback',
                component: Feedback
            },
            {
                path: '/terms',
                redirect: '/terms/vi_term',
                component: Terms
            },

            {
                path: '/terms/:locale',
                component: Terms
            },
            {
                path: '/survey/:token',
                component: Survey
            },
            {
                path: '/:username/unsubscribe/mail-channel',
                component: UnsubscribeEmail
            },
            {
                path: '/me/activities',
                component: MyActivies,
            },
            {
                path: '/review-contents',
                redirect: '/review-contents/social-shares/drafts',
                component: ReviewContents,
                children: [
                    {
                        path: 'social-shares',
                        redirect: '/review-contents/social-shares/drafts',
                    },
                    {
                        path: 'social-shares/drafts',
                        component: DraftsMySocialShares
                    },
                    {
                        path: 'social-shares/scheduled',
                        component: ScheduledMySocialShares
                    },
                    {
                        path: 'social-shares/published',
                        component: PublishedMySocialShares
                    },
                    {
                        path: 'posts-spam',
                        component: PostsSpam
                    },
                ]
            }
        ]
    }

    return new Router({
        mode: 'history',
        fallback: false,
        base: '/',
        linkActiveClass: 'active',
        linkExactActiveClass: 'nuxt-link-exact-active',
        scrollBehavior,
        routes: routes
    })
}
