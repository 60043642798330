import Vue from 'vue'
import cookies from 'axios/lib/helpers/cookies'

function getLangForSpa(message) {
    if (typeof window.$nuxt !== 'undefined') {
        return window.$nuxt.$_i18n.t(message)
    }

    return getLangWithFile(message)
}

function getLangWithFile(message) {
    const keys = message.split('.')
    const currentLocale = cookies.read('i18n_redirected') || 'vi'
    // eslint-disable-next-line
    let localeObject = require(`~/lang/${currentLocale}.js`).default
    keys.forEach((key) => {
        localeObject = localeObject[key]
    })

    return localeObject
}

function getLangForSSR(message) {
    return typeof Vue.prototype.$_i18n !== 'undefined'
        ? Vue.prototype.$_i18n.t(message)
        : getLangWithFile(message)
}

export function transText(message) {
    return process.client
        ? getLangForSpa(message)
        : getLangForSSR(message)
}

export function changeLangTooltipClipboard(text) {
    const clipboardEl = document.getElementsByClassName('v-markdown-it-code-copy')
    return clipboardEl.forEach((el) => {
        const tooltipText = el.getElementsByClassName('tooltiptext')[0]
        tooltipText.innerHTML = text
    })
}

export function changeLangTitleClipboard(text) {
    const clipboardEl = document.getElementsByClassName('v-markdown-it-code-copy')
    return clipboardEl.forEach((el) => {
        el.setAttribute('title', text)
    })
}

export function changeLangTextShowMore(text) {
    const clipboardEl = document.getElementsByClassName('v-markdown-it-show-more')
    return clipboardEl.forEach((el) => {
        const showMoreText = el.getElementsByClassName('show-more-text')[0]
        showMoreText.innerHTML = text
    })
}
